import React from 'react'

function Footer() {
  return (
    <div className="footer-section">
        <div className="2xl:pt-8 lg:pt-8">
          <div className="grid grid-cols-12 gap-6 mx-auto">
            
            <div className="bottom-links xl:col-span-3 md:col-span-3 col-span-12 flex flex-col text-left">
              <div className="font-bold text-white 2xl:text-[18px] lg:text-[16px] text-14px mb-4">
                About Us
              </div>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Who We Are
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Board of Governers
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Council
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Chapter Excos
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Committee Excos
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Global Connections
              </button>
            </div>
            <div className="bottom-links xl:col-span-2 md:col-span-2 col-span-12 flex flex-col">
              <div className="font-bold text-white 2xl:text-[18px] lg:text-[16px] text-14px mb-4">
                Quick Links
              </div>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Home
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Sign Up
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Login
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left">
                Contact Us
              </button>
            </div>
            <div className="bottom-links xl:col-span-3 md:col-span-3 col-span-12 flex flex-col">
              <div className="font-bold text-white 2xl:text-[18px] lg:text-[16px] text-14px mb-4">
                Policies
              </div>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Privacy Statement
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Terms of Use
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Code of Conduct
              </button>
              <button className="2xl:text-base lg:text-sm  block text-white footer-link text-left" >
                Brand Usage Guidelines
              </button>
            </div>
            <div className="xl:col-span-4 md:col-span-4 col-span-12 2xl:pr-[60px]">
              <div className="font-bold text-white 2xl:text-[18px] lg:text-[16px] text-14px  mb-4">
                Subscribe to Newsletter
              </div>
              <p className="2xl:text-base lg:text-sm tracking-wide text-white">
                Join our subscribers list to get the latest news, events updates and offers delivered directly in your inbox.
              </p>
              <div className="w-full mt-8 z-0">
                <form role="form" className="relative flex justify-between bg-white">
                  <input type="text" placeholder="Email Address" className="p-3 w-full focus:outline-none"/>
                  <button className="btn btn-red text-white">Subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="2xl:pb-8 lg:pb-8 mt-4">
          <div className="sm:grid md:grid-cols-1 sm:grid-cols-3 mx-auto">
            <div className="">
              <div className="font-bold text-white 2xl:text-[18px] lg:text-[16px] text-14px pb-3 text-center" >
              © SGTECH ALL RIGHTS RESERVED
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Footer