import React from "react";
import ModalBasic from "../../../pages/component/ModalBasic";
import moment from "moment";
import DropdownSelect from "../../dropdown/Dropdown";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
import ValidationText from "../../../utils/validation/ValidationText";

export default function BuyChapterModal(props) {
  return (
    <ModalBasic
      id="buy-chapter-detail-modal"
      modalOpen={props.setBuyChapterModal}
      setModalOpen={props.setOpenModal}
      title=""
      noCloseIcon={false}
    >
      {props.setBuyChapterModal === true ? (
        <div className="pb-2 px-8">
          <h1 className="text-4xl text-[#c00000] font-bold leading-loose pb-4 mobile-20">
            Buy Chapter 
          </h1>
          <div className="pb-10">
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold mb-3">
                      Number of Chapter:{" "}
                      <span className="text-[#c00000]">*</span>
                    </h2>
                    {/* <DropdownSelect
                                    drpIdentity={"BuyChapters"}
                                    optionArray={props.chapterListArr}
                                    setFilterParameters={props.setBuyChapterFilterParameters}
                                /> */}
                    {/* <DropDownForPayment
                      drpIdentity={"BuyChapters"}
                      optionArray={props.chapterListArr}
                      setFilterParameters={(id, drpIdentity) =>
                        props.setBuyChapterFilterParameters(id, drpIdentity)
                      }
                      value={props.purchaseChapter.noOfAdditionalChapters}
                    /> */}
                    <DropdownSelect
                      drpIdentity={"BuyChapters"}
                      optionArray={props.chapterListArr}
                      setFilterParameters={(id, drpIdentity) =>
                        props.setBuyChapterFilterParameters(id, drpIdentity)
                      }
                      value={props.purchaseChapter.noOfAdditionalChapters}
                    />
                  </div>
                </div>
                <ValidationText
                  error={
                    props.buyChapterValidState.error.noOfAdditionalChapters
                  }
                />
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold mb-3">
                      Payment Method: <span className="text-[#c00000]">*</span>
                    </h2>
                    <DropdownSelect
                      drpIdentity={"PaymentVia"}
                      optionArray={props.paymentsMethods}
                      setFilterParameters={(id, drpIdentity) =>
                        props.setBuyChapterFilterParameters(id, drpIdentity)
                      }
                      value={props.purchaseChapter.paymentViaId}
                    />
                  </div>
                </div>
                <ValidationText
                  error={props.buyChapterValidState.error.paymentVia}
                />
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="flex items-center">
                      <h2 className="text-xl text-[#757575]">
                        <span className="font-bold">
                          Chapter Fee for 1 Year:
                        </span>
                        <span className="pl-1">
                          {props.purchaseChapter.additionalChapterPrice}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Payment information table start */}
          <div className="">
            <h1 className="text-[#757575] text-[30px] font-bold mobile-20">
              Payment Information
            </h1>
            <div className="md:pt-8 pt-2">
              <div className="flex justify-center">
                <div className="overflow-x-auto w-full">
                  <table className="border-solid border-[1px] border-[#757575]  w-full">
                    <tr className="bg-[#7D9AAC] border-solid border-[1px] border-[#757575] text-white text-xl font-bold">
                      <th className="border-solid border-[1px] border-[#757575] text-white text-left md:py-4 py-2 md:px-5 px-3 w-[60%] whitespace-nowrap">
                        Description
                      </th>
                      <th className="border-solid border-[1px] border-[#757575] text-white text-center md:py-4 py-2 md:px-5 px-3 w-[15%] whitespace-nowrap">
                        Quantity
                      </th>
                      <th className="border-solid border-[1px] border-[#757575] text-white text-right md:py-4 py-2 md:px-5 px-3 w-[25%] whitespace-nowrap">
                        Amount(SGD)
                      </th>
                    </tr>

                    <tbody>
                      <>
                        <tr className="border-solid border-[1px] border-[#757575]">
                          <td className="border-solid border-[1px] border-[#757575] text-[#757575] text-lg text-left md:py-4 py-2 md:px-5 px-3 w-[60%] whitespace-nowrap">
                            Chapter Fee for the Period of{" "}
                            {moment(new Date()).format("DD MMM YYYY")} -{" "}
                            {moment(
                              props.purchaseChapter.endDate
                            ).format("DD MMM YYYY")}
                          </td>
                          <td className="border-solid border-[1px] border-[#757575] text-[#757575] text-lg text-center md:py-4 py-2 md:px-5 px-3 w-[15%] whitespace-nowrap">
                            {props.purchaseChapter.noOfAdditionalChapters}
                          </td>
                          <td className="border-solid border-[1px] border-[#757575] text-[#757575] text-right text-slg md:py-4 py-2 md:px-5 px-3 w-[25%] whitespace-nowrap">
                            {props.responseMessage === "Promo code applied successfully" ?
                              <>{`${props.purchaseChapter.previousSubTotalAmount.toFixed(
                                2
                              )}`}</> :
                              <>{`${props.purchaseChapter.subTotalAmount.toFixed(
                                2
                              )}`}</>

                            }

                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="md:pb-8 md:pt-10">
                {/* {props.promoCode === "promoCode" ? ( */}
                <div className="grid grid-cols-12 gap-6 lg:justify-between justify-end">
                  <div className="md:col-span-6 col-span-12 md:mt-0 mt-5">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <h2 className="text-[#757575] xl:text-base font-bold">
                          Promo Code
                        </h2>

                        <div className="grid grid-cols-12 gap-6">
                          {props.responseMessage === "" ? (
                            <div className="col-span-8">
                              <input
                                id="voucherCode"
                                name="voucherCode"
                                type="text"
                                className="custom-input relative block w-full border border-[#757575] text-gray-900 focus:outline-none sm:text-sm bg-white px-3"
                                value={props.purchaseChapter.voucherCode}
                                onChange={(e) =>
                                  props.handleChangeForBuyChapter(e)
                                }
                                autoComplete={"off"}
                              />
                            </div>
                          ) : (
                            <div className="col-span-8 disabled-input">
                              <input
                                id="voucherCode"
                                name="voucherCode"
                                type="text"
                                className="custom-input relative block w-full border border-[#757575] text-gray-900 focus:outline-none sm:text-sm bg-white px-3"
                                value={props.purchaseChapter.voucherCode}
                                disabled
                              />
                            </div>
                          )}

                          {props.responseMessage === "" ? (
                            <div className="col-span-4">
                              <button
                                className="btn btn-red text-lg text-white buy-now-btn apply-button"
                                onClick={() =>
                                  props.applyVoucherCodeForAdditionalChapter()
                                }
                              >
                                Apply
                              </button>
                            </div>
                          ) : (
                            <div className="col-span-4">
                              <button
                                className="btn btn-red text-lg text-white buy-now-btn apply-button"
                                onClick={props.removePromoCode}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>

                        {props.responseMessage != null &&
                          props.responseMessage !== "" ? (
                          <div className="grid grid-cols-12 gap-6">
                            {props.responseMessage ===
                              "Promo code applied successfully" ? (
                              <div className="col-span-8 small-font-size mt-3 bg-[#b7fbc8] text-[#000] p-3">
                                {props.responseMessage}
                              </div>
                            ) : (
                              <div className="col-span-8 small-font-size mt-3 bg-[#ffc7c7] text-[#000] p-3">
                                {props.responseMessage}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="md:col-span-6 col-span-12 text-right">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12 md:pl-8">
                        <div className="flex items-right text-right justify-between">
                          <span className="text-[#757575] xl:text-base">
                            Subtotal:
                          </span>
                          <span className="text-[#757575] xl:text-base text-right">
                            {props.responseMessage === "Promo code applied successfully" ?
                              <>{`${props.purchaseChapter.previousSubTotalAmount.toFixed(
                                2
                              )}`}</> :
                              <>{`${props.purchaseChapter.subTotalAmount.toFixed(
                                2
                              )}`}</>

                            }
                          </span>
                        </div>
                        <div className="flex items-right justify-between">
                          <span className="text-[#757575] xl:text-base ">
                            Discounted Amount:
                          </span>
                          <span className="text-[#757575] xl:text-base text-right">
                            {props.discountedAmount === 0 ?
                              <>
                                -
                              </>
                              :
                              <>
                                {props.isFixedPrice && Math.abs(props.discountedAmount) > props.purchaseChapter.previousSubTotalAmount ?
                                  <> {` + ${(Math.abs(props.discountedAmount)).toFixed(2)}`}</>
                                  :
                                  <> {` - ${(Math.abs(props.discountedAmount)).toFixed(2)}`}</>}{" "}
                              </>
                            }

                          </span>
                        </div>
                        {props.isFixedPrice ? (
                          <div className="flex items-right justify-between">
                            <span className="text-[#757575] xl:text-base ">
                              Total
                            </span>
                            <span className="text-[#757575] xl:text-base text-right">
                              {`${(
                                props.purchaseChapter.totalAmount -
                                props.purchaseChapter.gstAmount
                              ).toFixed(2)}`}
                            </span>
                          </div>
                        ) : null}

                        <div className="flex items-right justify-between">
                          <span className="text-[#757575] xl:text-base">
                            GST ({props.gstRate}%):
                          </span>
                          <span className="text-[#757575] xl:text-base text-right">
                            {`${props.purchaseChapter.gstAmount.toFixed(2)}`}
                          </span>
                        </div>

                        <div className="flex items-right justify-between">
                          <span className="text-[#757575] xl:text-base">
                            Grand Total Amount:
                          </span>
                          <span className="text-[#757575] xl:text-base text-right">
                            {`${props.purchaseChapter.totalAmount.toFixed(2)}`}
                          </span>
                        </div>
                        <div className="flex items-right justify-between">
                          <span className="text-[#757575] xl:text-base">
                            Payments/Credits Applied:
                          </span>
                          <span className="text-[#757575] xl:text-base text-right">
                            -
                          </span>
                        </div>
                        <div className="flex items-right font-bold justify-between">
                          <span className="text-[#757575] xl:text-base">
                            Net Total Amount:
                          </span>
                          <span className="text-[#757575] xl:text-base text-right">
                            {`${props.purchaseChapter.netTotal.toFixed(2)}`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Payment information table end*/}

          <div className="grid grid-col-3 gap-4">
            <label
              className="text-[#757575] xl:text-base font-bold"
              htmlFor="default"
            >PO Number:
            </label>
            <div className="flex items-center justify-between">
              <input
                id="default"
                className="custom-input relative block w-[195px] px-2 py-2 border border-[#757575] text-gray-900 focus:outline-none sm:text-sm bg-white"
                type="text"
                name="poNumber"
                value={props.purchaseChapter.poNumber}
                onChange={(event) => props.handleChangeInPoNumber(event)}
              //onBlur={() => props.validateField("contactNo")}
              />
            </div>
          </div>

          {props.loadingForPayment === true ? (
            <div className="pt-4">
              <ButtonLoader />
            </div>
          ) : (
            <>
              {props.purchaseChapter.noOfAdditionalChapters &&
                props.purchaseChapter.noOfAdditionalChapters > 0 ? (
                <button
                  className="btn btn-red text-base text-[#fff] font-bold px-12 py-2 my-4"
                  onClick={() => {
                    props.buyChapterForCorporateMember();
                  }}
                >
                  Buy Chapter
                </button>
              ) : (
                <button className="btn btn-red-disabled text-base text-[#fff] font-bold px-12 py-2 my-4 cursor-not-allowed">
                  Buy Chapter
                </button>
              )}
            </>
          )}
        </div>
      ) : null}
    </ModalBasic>
  );
}
