import moment from "moment";
import React from "react";

function GovernmentProgramStrategicMember(props) {
  return (
    <div>
      <div className="custom-checkbox-set">
        <div className="2xl:px-10 xl:px-10 lg:px-8 md:px-8 sm:px-4 px-4 2xl:py-5 2xl:pt-4 lg:py-4 lg:pt-2 pb-5">
          <div className="grid grid-cols-12 gap-6 w-full ">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <div className="flex items-baseline cursor-not-allowed register-desc mb-5 mt-5">
                    <input
                      id="stratigicMember"
                      name="stratigicMember"
                      type="checkbox"
                      className="checkbox-not-allowed h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                      // onChange={(event) => props.handleChangeCheckBox(event)}
                      disabled={true}
                      checked={props.govProjectsNameList.stratigicMember}
                      value={props.govProjectsNameList.stratigicMember}
                    />
                    <label className="communication-label ml-3 block text-[#757575] 2xl:text-[22px]">
                      Strategic Member
                      <span className="text-[#c00000]">*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-12 lg:col-span-12 col-span-12 mt-4">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-8 lg:col-span-8 col-span-12">
                    <h2 className="text-xl theme-color font-bold 2xl:mb-3">
                      Projects Involved
                    </h2>
                    <div className=" block text-[#757575] 2xl:text-[22px] govt-program-s-member">
                      {/* {props.governmentProgramme.projectName} */}

                      {props.pastProjects && props.pastProjects.length > 0 ?
                        <>
                          Past Projects
                          <ul className="project-list">
                            {props.pastProjects.map((project, key) => {
                              return (<><li key={key}>{project.pastProjectname}</li>
                                ({!project.startDate && !project.endDate ? 'N.A.' : <> {moment(project.startDate).format("DD/MM/YYYY")} - {!project.endDate ? 'On going' : moment(project.endDate).format("DD/MM/YYYY")} </>})
                              </>)
                            })}
                          </ul>
                        </>
                        : null}
                      {props.activeProjects !== null && props.activeProjects.length > 0 ?
                        <>
                          Active Projects
                          <ul className="project-list">
                            {props.activeProjects.map((project, key) => {
                              return (<><li key={key}>{project.activeProjectname}</li>
                                ({!project.startDate && !project.endDate ? 'N.A.' : <> {moment(project.startDate).format("DD/MM/YYYY")} - {!project.endDate ? 'On going' : moment(project.endDate).format("DD/MM/YYYY")} </>})
                              </>
                              )
                            })}
                          </ul>
                        </>
                        : null}
                      {props.futureProjects && props.futureProjects.length > 0 ?
                        <>
                          Future Projects
                          <ul className="project-list">
                            {props.futureProjects.map((project, key) => {
                              return (<><li key={key}>{project.futureProjectname}</li>
                                ({!project.startDate && !project.endDate ? 'N.A.' : <> {moment(project.startDate).format("DD/MM/YYYY")} - {!project.endDate ? 'On going' : moment(project.endDate).format("DD/MM/YYYY")} </>})
                              </>)
                            })}
                          </ul>
                        </>
                        : null}

                    </div>
                  </div>
                  {/* <div className="xl:col-span-4 lg:col-span-4 col-span-12">
                    <div className="flex items-center justify-end">
                      <button
                        className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                        onClick={() => props.onSave()}
                      >
                        Save
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Save Button */}
        </div>
      </div>
    </div>
  );
}

export default GovernmentProgramStrategicMember;
