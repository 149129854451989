import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import DropdownSelect from "../../components/dropdown/Dropdown";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import TextInput from "../../components/UIComponent/TextInput";
import ValidationText from "../../utils/validation/ValidationText";
import ImageCropperWithUpload from "../../components/ImageUpload/UploadImage";
import { CommonValidationMessages, ErrorMessage } from "../../utils/Messages";
import { validate, isValidForm } from "../../utils/validation/CommonValidator";
import { checkParameters } from "../../utils/Encryption";

// Import API Service
import DtsmeServices from "../../services/axiosServices/apiServices/DtsmeServices";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import SwalServices from "../../services/swalServices/SwalServices";
import TinyEceEditor from "../../components/ckEditor/TinyMCEEditor";

export default class AddEditPartnerConsultants extends Component {
    constructor(props) {
        super(props);
        this.dtsmeServices = new DtsmeServices();
        this.commonServices = new CommonServices();
        this.swalServices = new SwalServices();
        this.state = {
            isLoading: false,
            redirect: null,
            partnerConsultantInformationId: 0,
            techSolutionList: [],
            jobTypeList: [],

            partnerConsultantDetails: {
                partnerConsultantInformationId: 0,
                firstname: "",
                lastName: "",
                companyName: "",
                designation: "",
                description: "",
                jobTypeId: 0,
                profileImage: ''
            },
            validationRule: {
                firstname: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "contact person first name"
                        ),
                    },
                ],
                lastName: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "contact person last name"
                        ),
                    },
                ],
                companyName: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "company name"
                        ),
                    },

                ],
                designation: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "designation"
                        ),
                    },
                ],
                description: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "description"
                        ),
                    },
                ],
                jobTypeId: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "Job Type"),
                    }
                ],
                profileImage: [
                    {
                        type: "require",
                        message: CommonValidationMessages.UploadPdfRequired.replace("{0}", "Profile Image"),
                    }
                ],

            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }

    componentDidMount = () => {
        let params = new URLSearchParams(window.location.search);
        let marketplaceCompanyId = params.get("");
        if (marketplaceCompanyId && (marketplaceCompanyId = checkParameters(marketplaceCompanyId, "Number"))) {
            if (marketplaceCompanyId > 0) {
                this.setState({ partnerConsultantInformationId: marketplaceCompanyId });
            }
        }
        this.getAllDropdownsForDTSME();

    }

    // Add Edit DTSME Marketplace Detail
    addEditPartnerConsultantInformation = () => {
        let isValidInput = this.isValidateAllFields();
        if (isValidInput) {
            this.setState({ isLoading: true });
            this.dtsmeServices.addEditPartnerConsultantInformation(this.state.partnerConsultantDetails)
                .then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        // if(response.responseItem.responseContent.errorType==='EXISTS') {
                        //     this.swalServices.Error('This type of authority limit is already exist.');
                        // }
                        // else {
                        this.swalServices.Success("Partner Consultant Information sucessfully added!");
                        this.setState({ redirect: "/dtsme/PartnerConsultants" });
                        // }
                    } else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
        }
    };


    // Get All Dropdowns for DTSME 
    getAllDropdownsForDTSME = () => {
        this.setState({ isLoading: true });
        this.dtsmeServices.getAllDropdownsForDTSME().then(
            (response) => {
                if (response.statusCode === 200 && response.responseItem != null) {

                    let jobType = response.responseItem.responseContent.filter(
                        (drp) => drp.dropdownName === "DTSMEJobType"
                    );

                    this.setState({
                        jobTypeList: jobType,

                    });
                } else {
                    this.SwalServices.Error(response.message);
                }
                if (this.state.partnerConsultantInformationId > 0) {
                    this.getPartnerConsultantInformationById(this.state.partnerConsultantInformationId);
                }
                this.setState({ isLoading: false });
            }
        );
    };

    // Get go global account details by id
    getPartnerConsultantInformationById = (id) => {
        if (this.state.partnerConsultantInformationId != null) {
            this.dtsmeServices.getPartnerConsultantInformationById(id).then((response) => {
                console.log("By Id response :", response);
                if (response.statusCode === 200 && response.responseItem != null) {
                    let res = response.responseItem.responseContent;
                    res.lastName = response.responseItem.responseContent.lastname;
                    res.jobTypeId = response.responseItem.responseContent.jobtypeId;
                    this.setState({ partnerConsultantDetails: res });
                } else {
                    this.swalServices.Error(response.message);
                }
            }
            );
        }
    };

    // Upload Brochure File Method
    onFileChange = (event, identity) => {
        //debugger
        const file = event.target.files[0];
        // if (identity === "Upload_Certificate") {
        if (file && file.type === "application/pdf") {
            let uploadFile = { ...this.state.resFile };
            uploadFile.filename = file.name;
            uploadFile["file"] = file;
            uploadFile.storagePath = "DT-SME";
            let detail = { ...this.state.partnerConsultantDetails };
            detail.brochureFilePath = file.name;
            this.setState({ partnerConsultantDetails: detail },
                () => this.validateField("brochureFilePath")
            );
            this.commonServices.uploadPDF(uploadFile).then((response) => {
                if (response != null) {
                    let detail = { ...this.state.partnerConsultantDetails };
                    detail.brochureFilePath = response.fileName;
                    this.setState({ partnerConsultantDetails: detail });
                    this.swalServices.Success("File Uploaded");
                }
                else {
                    this.swalServices.Error(response.message);
                }
            })
        }
        else {
            this.swalServices.Error("Please Upload only PDF file !");
        }
        // }      
    };

    // Method for Upload Company Logo
    saveMainImage = (file, keyId, imageIdentity) => {
        this.setState({ isLoading: true });
        file.storagePath = "DT-SME\\MarketPlaceCompanyProfileLogo";
        this.commonServices.storeBase64Image(file).then((response) => {
            console.log("Check Image Upload Response :", response);
            if (response != null) {
                this.swalServices.Success("File Uploaded");
                // if (file.storagePath === "DT-SME\\MarketPlaceCompanyProfileLogo") {
                let detail = this.state.partnerConsultantDetails;
                detail.profileImage = response.fileName;
                this.setState({ partnerConsultantDetails: detail }, () => this.validateField("profileImage"));
                // }
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false })
            console.log("this.state.partnerConsultantDetails :", this.state.partnerConsultantDetails);
        })
    }


    // Internal handling Methods
    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.partnerConsultantDetails;
        detail[name] = value;
        this.setState({ partnerConsultantDetails: { ...detail } });
    }

    // Handle Change In Dropdown Method 
    setFilterParameters = (id, drpIdentity) => {
        let detail = this.state.partnerConsultantDetails;
        if (drpIdentity === "Job_Type") {
            detail["jobTypeId"] = id;
            this.validateField("jobTypeId");
        }
        this.setState({ partnerConsultantDetails: detail });
    };

    // Field validation method 
    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.partnerConsultantDetails,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.partnerConsultantDetails,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    setEditorData = (data) => {
        if (typeof data === 'string') {
            let detail = this.state.partnerConsultantDetails;
            detail["description"] = data;
            this.setState({ partnerConsultantDetails: detail });
        }
    };


    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <>
                <div className="main-body-section global-account-sec">
                    <div className="bg-white">
                        <div className=" heading-part px-7 py-4 border-b">
                            <h4 className="small-title font-bold theme-color">
                                DTSME Partner Consultant Details
                            </h4>
                        </div>
                        <div className="account-fields">
                            <div className="w-full 2xl:px-8 lg:px-4 px-4 mt-5">
                                <div className="grid grid-cols-12 gap-6 w-full ">
                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                        <div className="grid grid-cols-12 gap-6 w-full ">
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    First Name
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="firstname"
                                                            value={this.state.partnerConsultantDetails.firstname}
                                                            identity="firstname"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("firstname")}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.firstname} />
                                            </div>

                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Last Name{" "}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="lastName"
                                                            value={this.state.partnerConsultantDetails.lastName}
                                                            identity="lastName"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("lastName")}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.lastName} />
                                            </div>

                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Company Name
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="companyName"
                                                            value={this.state.partnerConsultantDetails.companyName}
                                                            identity="OrganizationProfile"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("companyName")}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.companyName} />
                                            </div>

                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Designation
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="w-full outline-none appearance-none">
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="designation"
                                                        value={this.state.partnerConsultantDetails.designation}
                                                        identity="OrganizationProfile"
                                                        handleChange={this.handleChange.bind(this)}
                                                        onBlur={(e) => this.validateField("designation")}
                                                    />
                                                </div>
                                                <ValidationText error={this.state.validState.error.designation} />
                                            </div>

                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Job Type
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="flex items-center">
                                                    <div className="w-full">
                                                        <DropdownSelect
                                                            drpIdentity={"Job_Type"}
                                                            optionArray={this.state.jobTypeList}
                                                            setFilterParameters={this.setFilterParameters.bind(this)}
                                                            value={this.state.partnerConsultantDetails.jobTypeId}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.jobTypeId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                        <div className="grid grid-cols-12 gap-6 w-full ">
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Profile Image
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="w-[320px]">
                                                    <div className="company-logo-cropper ml-auto mr-auto goglobal-company-logo">
                                                        <ImageCropperWithUpload
                                                            key="Main"
                                                            height={480}
                                                            width={360}
                                                            isCheckValidation={true}
                                                            MaxFileSize={2097152}
                                                            MaxFileSizeMessage={"2MB"}
                                                            onSaveImage={this.saveMainImage}
                                                            imageIdentity="DT-SME\MarketPlaceCompanyProfileLogo"
                                                            intitImage={this.state.partnerConsultantDetails.profileImage}
                                                            aspactRatio={360 / 480}
                                                            uploadBtn={"Upload Logo"}
                                                            dimensionText={"(Format: JPG/PNG Dimension: 360px by 480px Maximum Size: 2MB)"}
                                                        />

                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.profileImage} />
                                            </div>
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Description {""}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                {/* <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="description"
                                                    value={this.state.partnerConsultantDetails.description}
                                                    identity="description"
                                                    handleChange={this.handleChange.bind(this)}
                                                    onBlur={(e) => this.validateField("description")}
                                                /> */}

                                                <TinyEceEditor
                                                    key={"Workshop_About_CkEditor"}
                                                    onChange={(data) => {
                                                        this.setEditorData(data);
                                                    }}
                                                    data={this.state.partnerConsultantDetails.description}
                                                />
                                                <ValidationText error={this.state.validState.error.description} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap justify-end space-x-10 mt-12 pb-8">
                                    <div className="space-x-4">
                                        {
                                            this.state.isLoading ?
                                                <ButtonLoader />
                                                :
                                                <button
                                                    className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                                                    onClick={() => this.addEditPartnerConsultantInformation()}
                                                >
                                                    Save
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}