import { GetPagesMainModulesList, GetPagesDetailsList, GetPageDetailByIds, GetSubModulesListByMainModuleId, SavePageDetails, UpdatePageDetails, GetAllDropdownsForPages, AddEditPartialPage, GetPartialPageComponentsById, AddPageNameForSubModule, GetSubPagesListForSubModuleById, DeletePageNamesById, GetPageDetailByPagesDetailsId, GetAllDropdownsForViewPages, DeleteStandAlonePageById, AddEditFormDetails, GetFormDetailsByPagesDetailsId } from "../ApiEndPoints";
import { axiosGetMultiParams, axiosGetAuthorize, axiosPostAuthorize } from "../AxiosRequests";


export default class PagesManagementServices {

    async GetPagesMainModulesList(request) {
        return axiosGetAuthorize(GetPagesMainModulesList, request);
    }
    async getPagesDetailsList(request) {
        return axiosPostAuthorize(GetPagesDetailsList, request);
    }
    async getAllDropdownsForViewPages(request) {
        return axiosGetAuthorize(GetAllDropdownsForViewPages, request);
    }
    async getPageDetailByIds(request) {
        return axiosGetMultiParams(GetPageDetailByIds, request);
    }
    async GetSubModulesListByMainModuleId(request) {
        return axiosGetAuthorize(GetSubModulesListByMainModuleId, request);
    }
    async savePageDetails(request) {
        return axiosPostAuthorize(SavePageDetails, request);
    }
    async updatePageDetails(request) {
        return axiosPostAuthorize(UpdatePageDetails, request);
    }
    async getAllDropdownsForPages(request) {
        return axiosGetAuthorize(GetAllDropdownsForPages, request);
    }
    async addEditPartialPage(request) {
        return axiosPostAuthorize(AddEditPartialPage, request);
    }
    async getPartialPageComponentsById(request) {
        return axiosGetAuthorize(GetPartialPageComponentsById, request);
    }
    async addPageNameForSubModule(request) {
        return axiosPostAuthorize(AddPageNameForSubModule, request);
    }
    async getSubPagesListForSubModuleById(request) {
        return axiosGetAuthorize(GetSubPagesListForSubModuleById, request);
    }
    async deletePageNamesById(request) {
        return axiosPostAuthorize(DeletePageNamesById, request);
    }
    async getPageDetailByPagesDetailsId(request) {
        return axiosGetAuthorize(GetPageDetailByPagesDetailsId, request);
    }
    async deleteStandAlonePageById(request) {
        return axiosPostAuthorize(DeleteStandAlonePageById, request);
    }
    async addEditFormDetails(request) {
        return axiosPostAuthorize(AddEditFormDetails, request);
    }
    async getFormDetailsByPagesDetailsId(request) {
        return axiosGetAuthorize(GetFormDetailsByPagesDetailsId, request);
    }
}