import moment from "moment/moment";
import React, { useState } from "react";
import { isNullString } from "../../../utils/Utils";
import GenerateCreditNote from "./GenerateCreditNote";

function FinancialsRecord(props) {
  const [generateCreditNote, setGenerateCreditNote] = useState(false);
  const [isInvoiceCancel, setIsInvoiceCancel] = useState(false);
  return (
    <div className="w-full">
      {props.corporateFinanceInfo && props.corporateFinanceInfo.length > 0 && props.corporateFinanceInfo.map((value, key) => {
        return (
          <React.Fragment key={key}>
            <div className="px-5 2xl:py-5 pt-3 lg:py-5 py-5 2xl:pt-0 lg:pt-0 bg-white border-b border-[#f3f3f3]" >
              <div className="grid grid-cols-12 gap-6 w-full ">
                <div className="2xl:col-span-10 lg:col-span-9 col-span-8 2xl:pt-7 lg:mt-4 items-center">
                  <div className="grid grid-cols-12 gap-2">
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                      <div className="2xl:flex xl:flex lg:flex md:flex sm:flex flex flex-col gap-1 items-start justify-between">
                        <div className="left-side">
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Invoice Number : <span>{value.membershipPaymentInvoiceNo}</span>
                          </h2>
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Date : <span>{moment(value.paymentDate).format("DD MMM YYYY")}</span>
                          </h2>
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Payment Method : <span>{value.paymentVia}</span>
                          </h2>
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Payment Status : <span>{value.paymentStatus}</span>
                          </h2>
                        </div>
                        <div className="right-side">
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Promotion Code : <span className="">{isNullString(value.voucherCode) === "" ? "N.A" : value.voucherCode}</span>
                          </h2>
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Amount (Billable) : <span>{`$${parseFloat(value.totalAmount).toFixed(2)}`}</span>
                          </h2>
                          <h2 className="text-xl theme-color ">
                            Amount (Paid) : <span>{value.paymentStatus === "Success" ? <>{`$${parseFloat(value.totalAmount).toFixed(2)}`}</> : <>{value.paidPriceByReconciliation ? <>
                              {`$${parseFloat(value.paidPriceByReconciliation).toFixed(2)}`}</> : "$0.00"}</>} </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">

                    </div>
                  </div>
                </div>
                <div className="2xl:col-span-2 lg:col-span-3 col-span-4">
                  <div className="relative flex">
                    <div className="right-status-label absolute 2xl:top-[9px] 2xl:right-[-20px] xl:top-[10px] xl:right-[-20px]  lg:top-[10px] lg:right-[-20px] right-[-20px]">
                      <span className={`text-xl text-white font-semibold  2xl:px-2 xl:px-2 lg:px-3 md:px-6 sm:px-4 px-4 py-3 ${value.paymentStatus === "Success" ? "bg-[#7a9763]" : "bg-[#ffae00]"}`}>
                        {value.paymentStatus === "Success" ? "Paid" : value.paymentStatus}
                      </span>
                    </div>
                    <div className="right-btn 2xl:mt-20 xl:mt-20 lg:mt-20 md:mt-20 sm:mt-10 mt-11 w-full">
                      <div className="flex items-center justify-end mb-5">
                        <button className="2xl:px-7 sm:px-5 md:px-5 xl:px-5 lg:px-5 px-2  py-2 btn-red-border text-xl whitespace-nowrap border text-[#C00000]" onClick={() => props.getCorporateFinanceReceiptInvoice(value.membersReceiptId, false)}>
                          <span className="text-xl text-[#c00000] font-semibold tracking-normal underline">
                            View Invoice
                          </span>
                        </button>
                      </div>


                      {isNullString(value.creditNotePath) !== "" ? (
                        <>
                          <div className="flex items-center justify-end mb-5">
                            <button className="2xl:px-7 sm:px-5 md:px-5 xl:px-5 lg:px-5 px-2 py-2 btn-red-border  whitespace-nowrap text-xl border text-[#C00000]"
                              onClick={() => {
                                props.financeGetCreditNoteInvoice(value.creditNotePath);
                              }}>
                              <span className="text-xl text-[#c00000] font-semibold tracking-normal underline">
                                Download Credit Note
                              </span>
                            </button>
                          </div>
                        </>
                      ) : null}

                      {value.isReceiptGenerated === true && (value.paymentStatus === "Success" || value.paymentStatus === "Pending Cancellation") ?
                        <>
                          <div className="flex items-center justify-end mb-5">
                            <button className="2xl:px-7 sm:px-5 md:px-5 xl:px-5 lg:px-5 px-1 py-2 btn-red-border text-xl  whitespace-nowrap border text-[#C00000]" onClick={() => props.getCorporateFinanceReceiptInvoice(value.membershipPaymentId, true)}>
                              <span className="text-xl text-[#c00000] font-semibold tracking-normal underline">
                                View Receipt
                              </span>
                            </button>
                          </div>
                        </>
                        : null
                      }

                      {value.isReceiptGenerated === true && value.paymentStatus === "Success" ?
                        <>
                          {(value.membershipPaymentTypeId === 1 || value.membershipPaymentTypeId === 3 || value.membershipPaymentTypeId === 2) && value.isAvailableForCreditNote === true ?
                            <div className="flex items-center justify-end">
                              <button className="2xl:px-[15px] lg:px-[10px] px-2 py-2 btn btn-red text-white whitespace-nowrap" onClick={(e) => { e.stopPropagation(); setGenerateCreditNote(true); setIsInvoiceCancel(false); props.getCreditNoteInfoForCorporateMember(value.membershipPaymentId, value.paymentStatus, value.isReceiptGenerated); props.getCancellationReasonForCreditNote(); props.addEditCreditNote.membershipPaymentId = value.membershipPaymentId; }}>
                                <span className="text-xl text-white font-semibold tracking-normal">
                                  Generate Credit Note
                                </span>
                              </button>
                            </div>
                            : null}

                        </>
                        : value.paymentStatus === "Pending" && value.membershipPaymentTypeId !== 6 ?
                          <>
                            <div className="flex items-center justify-end">
                              <button className="2xl:px-[15px] lg:px-[10px] py-2 btn btn-red text-white"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setGenerateCreditNote(true);
                                  setIsInvoiceCancel(true);
                                  props.getCreditNoteInfoForCorporateMember(value.membershipPaymentId, value.paymentStatus, value.isReceiptGenerated);
                                  props.getCancellationReasonForCreditNote(); props.addEditCreditNote.membershipPaymentId = value.membershipPaymentId;
                                }}>
                                <span className="text-xl text-white font-semibold tracking-normal">
                                  Cancel Invoice
                                </span>
                              </button>
                            </div>
                          </>
                          : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      })}
      <div className="add-edite-profile-popup">
        <GenerateCreditNote
          generateCreditNote={generateCreditNote}
          setOpenModal={() => setGenerateCreditNote(false)}
          corporateCreditNotePaymentInfo={props.corporateCreditNotePaymentInfo}
          cancellationReason={props.cancellationReason}
          setFilterParameters={props.setFilterParameters}
          isOtherCancellationReason={props.isOtherCancellationReason}
          addEditCreditNote={props.addEditCreditNote}
          handleChange={props.handleChange}
          addFinanceCreditNote={props.addFinanceCreditNote}
          validate={props.validate}
          validCreditNote={props.validCreditNote}
          validate2={props.validate2}
          validCreditNote2={props.validCreditNote2}
          isInvoiceCancel={isInvoiceCancel}
          isLoading={props.isLoading}
        />
      </div>
    </div>
  );
}

export default FinancialsRecord;
