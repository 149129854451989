import React from "react";
import MoneyInput from "../../UIComponent/MoneyInput";
import NumberInput from "../../UIComponent/NumberInput";

const ChapterSection = (props) => {
  return (
    <>
      <div className="border border-t-0 border-[#e0e0e0] border-solid">
        <div className="grid 2xl:grid-cols-3 lg:grid-cols-2 gap-6 h-[50px] bg-zinc-100 w-full items-center pl-8 ">
          <h2 className="text-base text-[#757575]">Chapter Section</h2>
        </div>
        <div className="pr-24">
          <div className="grid 2xl:grid-cols-3 lg:grid-cols-2 gap-6 h-[50px]  w-full items-center 2xl:pl-8 lg:px-3 mt-8 mb-20 ">
            <div className="text-[#757575] text-[18px]">
              <h2 className="text-base text-[#757575] mb-4 ">
                Number of Free Chapter
              </h2>
              <NumberInput
                placeholder="1"
                type="text"
                name={props.col1FieldName}
                value={props.col1value}
                handleChange={props.handleChange}
                validateField={props.validateField}
                validState={props.validState}
                error={props.error1}
              />
            </div>
            <div className="sign-icon-input">
              <h2 className="text-base text-[#757575] mb-4 ">
                Price of Additional Chapter
              </h2>
              <MoneyInput
                placeholder="500"
                type="text"
                name={props.col2FieldName}
                value={props.col2value}
                handleChange={props.handleChange}
                validateField={props.validateField}
                validState={props.validState}
                error={props.error2}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChapterSection;
