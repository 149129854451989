import React from "react";
import MultiSelectDropdown from "../../../dropdown/MultiSelectDropdown";
import TextInput from "../../../UIComponent/TextInput";
import Option from "../../../dropdown/multiSelectDropdownOption/MultiSelectOption";
import DropdownSelect from "../../../dropdown/Dropdown";
import moment from "moment";
import DatePicker from "../../../datePicker/DatePicker";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";

function SGTechInterest(props) {
  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 px-3 lg:py-7 lg:pt-5 pt-4">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Reasons to Join SGTech
                </h2>

                {/* <DropdownSelect
                    drpIdentity={"corporateSGTechId"}
                    optionArray={props.sgTech}
                    setFilterParameters={props.setFilterParameters}
                    value={props.sgTechInterest.corporateSGTechId}
                    onBlur={(e) => props.validate("corporateSGTechId", props.sgTechInterest)}
                    />
                   <Validation error={props.validState.error.corporateSGTechId} /> */}
                <MultiSelectDropdown
                  drpIdentity="SGTechInterestSGTech"
                  options={props.sgTech}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option, }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  value={props.sgTechInterest.selectedSGTechArr}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Sponsorship
                </h2>
                {/* 
                  <DropdownSelect
                    drpIdentity={"corporateSponsorshipId"}
                    optionArray={props.sponsorship}
                    setFilterParameters={props.setFilterParameters}
                    value={props.sgTechInterest.corporateSponsorshipId}

                    onBlur={(e) => props.validate("corporateSponsorshipId", props.sgTechInterest)}

                    />
                   <Validation error={props.validState.error.corporateSponsorshipId} /> */}
                <MultiSelectDropdown
                  drpIdentity="SGTechInterestSponsorship"
                  options={props.sponsorship}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option, }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  value={props.sgTechInterest.selectedSponsorshipArr}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Business Matching
                </h2>

                {/* <DropdownSelect
                    drpIdentity={"corporateMatchingId"}
                    optionArray={props.matching}
                    setFilterParameters={props.setFilterParameters}
                    value={props.sgTechInterest.corporateMatchingId}

                    onBlur={(e) => props.validate("corporateMatchingId", props.sgTechInterest)}

                    />
                   <Validation error={props.validState.error.corporateMatchingId} /> */}

                <MultiSelectDropdown
                  drpIdentity="SGTechInterestMatching"
                  options={props.matching}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option, }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  value={props.sgTechInterest.selectedMatchingArr}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  What kind of strategic programmes will you be interested in?
                </h2>

                {/* <DropdownSelect
                    drpIdentity={"corporateStrategicProgrammesId"}
                    optionArray={props.program}
                    setFilterParameters={props.setFilterParameters}
                    value={props.sgTechInterest.corporateStrategicProgrammesId}

                    onBlur={(e) => props.validate("corporateStrategicProgrammesId", props.sgTechInterest)}

                  />
                  <Validation error={props.validState.error.corporateStrategicProgrammesId} /> */}

                <MultiSelectDropdown
                  drpIdentity="SGTechInterestProgrammes"
                  options={props.program}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option, }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  value={props.sgTechInterest.selectedProgramArr}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Updated Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input">
                  {props.sgTechInterest.updatedAt === "" || props.sgTechInterest.updatedAt === null ?

                    <TextInput
                      disabled={true}
                      placeholder=""
                      type="text"
                      name="linkedIn"
                      value="None"
                      identity="BasicInformation"
                    />
                    :
                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      handleChange={props.handleChange}
                      identity="updatedAt"
                      value={moment(props.sgTechInterest.updatedAt).format("yyyy-MM-DD")}
                    />
                  }
                </div>
                {/* <div className="disabled-input flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="updatedAt"
                    handleChange={props.handleChange}
                    identity="SGTechInterest"
                    value={moment(props.sgTechInterest.updatedAt).format("yyyy-MM-DD")}
                    // onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />
                  <Validation error={props.validState.error.areaofExpertise} />
                </div> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Updated By
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input flex items-center">
                  
                  <DropdownSelect
                    disabled={true}
                    drpIdentity={"updatedById"}
                    optionArray={props._by}
                    setFilterParameters={props.setFilterParameters}
                    value={props.sgTechInterest.updatedBy}
                  // onBlur={(e) => props.validate("updatedById", props.membershipSubscription)}
                  />

                  {/* <Validation error={props.validState.error.areaofExpertise} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Save Button */}
      <div className="grid grid-cols-12 gap-6 lg:pb-10 pt-5 pb-5">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12  2xl:px-10 xl:px-10 lg:px-8 md:px-4 sm:px-4 px-4">
          <div
            className="flex items-center justify-end"
          >
            {
              props && props.isLoading === true ?
                (
                  <ButtonLoader />
                )
                :
                (
                  <button
                    className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                    onClick={() => props.onSave()}
                  >
                    Save
                  </button>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default SGTechInterest;
