import React from "react";
import DropdownSelect from "../../../dropdown/Dropdown";
import moment from "moment";
import Validation from "../../../../utils/validation/ValidationText";
import TextInput from "../../../UIComponent/TextInput";
import DatePicker from "../../../datePicker/DatePicker";
import MultiSelectDropdown from "../../../dropdown/MultiSelectDropdown";
import Option from "../../../dropdown/multiSelectDropdownOption/MultiSelectOption";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";

function ProspectInformation(props) {
  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 px-3 lg:py-7 lg:pt-5 pt-4">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Source 1<span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"ProspectSource1"}
                  optionArray={props.source1}
                  setFilterParameters={props.setFilterParameters}
                  value={props.prospectInformation.source1Id}
                // onBlur={(e) => props.validate("source1Id", props.prospectInformation)}
                />
                <Validation
                  error={props.validState.error.source1Id}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Source 2<span className="text-[#c00000]">*</span>
                </h2>

                <DropdownSelect
                  drpIdentity={"ProspectSource2"}
                  optionArray={props.prospectSource2}
                  setFilterParameters={props.setFilterParameters}
                  value={props.prospectInformation.source2Id}
                  onBlur={(e) => props.validate("source2Id", props.prospectInformation)}
                />
                <Validation
                  error={props.validState.error.source2Id}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Source 3{/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="dropdwn-input-email flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="source3"
                    value={props.prospectInformation.source3}
                    identity="ProspectInformation"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("source3", props.prospectInformation)}
                  />

                  <Validation error={props.validState.error.source3} />
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  PIC1 <span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"ProspectPIC1"}
                  optionArray={props.corporatePIC1}
                  setFilterParameters={props.setFilterParameters}
                  value={props.prospectInformation.pic1Id}
                  onBlur={(e) => props.validate("pic1Id", props.prospectInformation)}
                />
                <Validation
                  error={props.validState.error.pic1Id}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  PIC2
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>

                <DropdownSelect
                  drpIdentity={"ProspectPIC2"}
                  optionArray={props.corporatePIC2}
                  setFilterParameters={props.setFilterParameters}
                  value={props.prospectInformation.pic2Id}
                  onBlur={(e) => props.validate("pic2Id", props.prospectInformation)}
                />
                <Validation
                  error={props.validState.error.pic2Id}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  First Engagement Date
                </h2>
                <div className="">
                  <DatePicker
                    // isDisable={true}
                    name="firstEngagementDate"
                    handleChange={props.handleChange}
                    identity="ProspectInformation"
                    value={moment(props.prospectInformation.firstEngagementDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("firstEngagementDate", props.prospectInformation)}
                  />
                </div>
                {/* <Validation error={props.validState.error.firstEngagementDate} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Prospect Status
                </h2>

                <DropdownSelect
                  drpIdentity={"ProspectStatus"}
                  optionArray={props.prospectStatus}
                  setFilterParameters={props.setFilterParameters}
                  value={props.prospectInformation.prospectStatusId}
                // onBlur={(e) => props.validate("prospectStatusId",props.prospectInformation)}
                />
                {/* <Validation error={props.validState.error.prospectStatusId}/> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Comments
                </h2>
                <div className="disabled-input">
                  <textarea
                    disabled
                    className="w-full"
                    // placeholder="Comments"
                    id=""
                    rows="6"
                    name="comments"
                    value={props.prospectInformation.comments}
                  //onChange={(e) => props.handleChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  How do you get to know us
                </h2>
                <div className="disabled-input">
                  <DropdownSelect
                    disabled={true}
                    drpIdentity={"getToKnow"}
                    optionArray={props.getToKnowUsArr}
                    setFilterParameters={props.setFilterParameters}
                    value={props.prospectInformation.getToKnow}
                  />
                </div>
                {/* <Validation error={props.validState.error.updatedBy} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Interested Chapters
                </h2>
                <div className="disabled-input ">
                  <MultiSelectDropdown
                    options={props.interestedChapter}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={props.handleChangeInDropdown}
                    allowSelectAll={true}
                    value={props.prospectInformation.selectedInterestChapterArr}
                    isDisabled
                  />
                </div>
              </div>
            </div>
          </div>





          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Updated By
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"ProspectUpdatedBy"}
                  optionArray={props._by}
                  setFilterParameters={props.setFilterParameters}
                  value={props.prospectInformation.updatedBy}
                // onBlur={(e) => props.validate("updatedById", props.prospectInformation)}
                />
                {/* <Validation error={props.validState.error.updatedBy} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Last Updated Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>

                <div className="disabled-input">
                  {props.prospectInformation.updatedAt === "" || props.prospectInformation.updatedAt === null ?

                    <TextInput
                      disabled={true}
                      placeholder=""
                      type="text"
                      name="linkedIn"
                      value="None"
                      identity="BasicInformation"
                    />
                    :
                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      handleChange={props.handleChange}
                      identity="updatedAt"
                      value={moment(props.prospectInformation.updatedAt).format("yyyy-MM-DD")}
                    />
                  }
                </div>
                {/* <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="corporateJoinedDate"
                    handleChange={props.handleChange}
                    identity="SIISInformation"
                    value={moment(props.prospectInformation.updatedAt).format("yyyy-MM-DD")}
                  onBlur={(e) => props.validate("updatedAt", props.prospectInformation)}
                  />

                  <Validation error={props.validState.error.areaofExpertise} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Save Button */}
      <div className="grid grid-cols-12 gap-6 lg:pb-10 md:pb-5 sm:mb-5 mb-5">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 px-3 lg:py-7 lg:pt-5 pt-4 pb-4 pr-[12px] lg:mt-0 sm:mt-0 mt-3">
          <a
            className="flex items-center justify-end"
          //href="/CreateCorporateMembers"
          >
            {
              props && props.isLoading === true ?
                (
                  <ButtonLoader />
                )
                :
                (
                  <button
                    className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                    onClick={() => props.onSave()}
                  >
                    Save
                  </button>
                )
            }
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProspectInformation;
