import React from "react";
import ValidationText from "../../../../utils/validation/ValidationText";
import DropdownSelect from "../../../dropdown/Dropdown";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";

function FinanceCard(props) {
  return (
    <div>
      <div className="custom-checkbox-set">
        <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 px-3 lg:py-7 lg:pt-5 pt-4">
          <div className="grid grid-cols-12 gap-6 w-full ">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <div className="flex items-baseline register-desc mb-5">
                    <input
                      id="quotationNeeded"
                      name="quotationNeeded"
                      type="checkbox"
                      className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                      onChange={(event) => props.handleChangeCheckBox(event)}
                      checked={props.corporateFinance.quotationNeeded ? props.corporateFinance.quotationNeeded : false}
                      value={props.corporateFinance.quotationNeeded ? props.corporateFinance.quotationNeeded : false}
                    />
                    <label className="communication-label ml-3 block text-[#757575] 2xl:text-[22px]">
                      Quotation Needed
                      <span className="text-[#c00000]">*</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <div className="flex items-baseline register-desc mb-5">
                    <input
                      id="sendInvoiceReminder"
                      name="sendInvoiceReminder"
                      type="checkbox"
                      className="cursor-pointer h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded check-box-custom"
                      onChange={(event) => props.handleChangeCheckBox(event)}
                      checked={props.corporateFinance.sendInvoiceReminder ? props.corporateFinance.sendInvoiceReminder : false}
                      value={props.corporateFinance.sendInvoiceReminder ? props.corporateFinance.sendInvoiceReminder : false}
                    />
                    <label className="communication-label ml-3 block text-[#757575] 2xl:text-[22px]">
                      Send Invoice Reminder
                      <span className="text-[#c00000]">*</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                    Credit Terms
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <DropdownSelect
                    drpIdentity={"CreditTerms"}
                    optionArray={props.corporateCreditTerms}
                    setFilterParameters={props.setFilterParameters}
                    value={props.corporateFinance.creditTermsId ? props.corporateFinance.creditTermsId : 0}
                  />
                  <ValidationText error={props.validState.error.creditTermsId} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Save Button */}
        <div className="grid grid-cols-12 gap-6 lg:pb-10 sm:pb-5 pb-5">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12 lg:mt-0 2xl:mt-0 sm:mt-0 mt-5 2xl:px-10 xl:px-10 lg:px-8 md:px-8 sm:px-4 px-4">
            <a
              className="flex items-center justify-end"
            //href="/CreateCorporateMembers"
            >
              {
                props && props.isLoading === true ?
                  (
                    <ButtonLoader />
                  )
                  :
                  (
                    <button
                      className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                      onClick={() => props.onSave()}
                    >
                      Save
                    </button>
                  )
              }
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinanceCard;
