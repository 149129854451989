import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import LogoImage from "../../images/logo.png";
import AuthenticationServices from "../../services/axiosServices/apiServices/AuthenticationServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { setAuthProps } from "../../utils/AuthenticationLibrary";
import { ErrorMessage } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import { MsalContext } from "@azure/msal-react";
import { withMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../HOC/authConfig";
import { InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
const msalInstance = new PublicClientApplication(msalConfig);

class Login extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.authServices = new AuthenticationServices();
    this.swalServices = new SwalServices();

    this.state = {
      isLoading: false,
      redirect: "",
      userDetails: {
        userName: "",
        password: "",
        isTwoFactor: false,
        varificationCode: "",
        currentDateTimeFromUserSystem: "",
      },
      validationRules: {
        userName: [
          {
            type: "require",
            message: ErrorMessage.FieldRequired.replace("{0}", "Email address"),
          },
          {
            type: "email",
            message: ErrorMessage.NOTVALIDPATTERN.replace(
              "{0}",
              "Email address"
            ),
          },
        ],
        password: [
          {
            type: "require",
            message: ErrorMessage.FieldRequired.replace("{0}", "Password"),
          },
          //   { type: 'password', message: ErrorMessage.PasswordNotValid }
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.userDetails,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.userDetails,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

   login = () => {
    if (this.isValidateAllFields()) {
      this.setState({ isLoading: true });
      let request = { ...this.state.userDetails }
      let currentdate = new Date();
      let currentDateTime = currentdate.getMonth() + 1 + "-" + currentdate.getDate() + "-" + currentdate.getFullYear() + " " +
        currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds();
      request.currentDateTimeFromUserSystem = currentDateTime;
      if (request.isTwoFactor === false) {
        request.varificationCode = "";
      }
      this.authServices.adminLogin(request).then((response) => {
        if (response.responseItem.responseContent.isLocked === true) {
          this.swalServices.Error(response.responseItem.responseContent.message);
        } else if (response.statusCode === 200 && response.responseItem) {
          if (response.responseItem.responseContent.isTwoFactor && response.responseItem.responseContent.isTwoFactor === true) {
            let user = { ...this.state.userDetails };
            user.isTwoFactor = true;
            this.setState({ userDetails: user })
          } else {
            let authDetails = response.responseItem.responseContent;
            setAuthProps(authDetails);
            // this.setState({ route: "/Dashboard" });
            window.location.href = "/Dashboard";
          }

        } else {
          this.swalServices.Error(ErrorMessage.InvalidAuthorization);
        }
        this.setState({ isLoading: false });
      });
    }
  };

  // loginWithVerificationCode = () => {
  //   if (this.isValidateAllFields()) {
  //     this.setState({ isLoading: true });
  //     this.authServices.adminLoginWithVarificationCode(this.state.userDetails).then((response) => {
  //       if (response.responseItem.responseContent.isLocked === true) {
  //         this.swalServices.Error(response.responseItem.responseContent.message);
  //       }
  //       else if (response.statusCode === 200 && response.responseItem) {
  //           let authDetails = response.responseItem.responseContent;
  //           setAuthProps(authDetails);
  //           // this.setState({ route: "/Dashboard" });
  //           window.location.href = "/Dashboard";
  //       } else {
  //         this.swalServices.Error(ErrorMessage.InvalidAuthorization);
  //       }
  //       this.setState({ isLoading: false });
  //     });
  //   }
  // };

  handleLogin(loginType) {
    this.setState({ isLoading: true });
    msalInstance["browserStorage"].clear();
    const isAuthenticated = this.props.msalContext.accounts.length > 0;
    if (!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
      msalInstance.loginPopup(loginRequest).then(response => {
        localStorage.setItem("homeAccountId", JSON.stringify(response.account.homeAccountId));
        let azureusername = response.account.username;
        let req = {};
        req.username = response.account.username;
        this.authServices.azureLogin(req).then((response) => {
          if (response.statusCode === 200 && response.responseItem) {
            let authDetails = response.responseItem.responseContent;
            setAuthProps(authDetails);
            window.location.href = "/Dashboard";
          }
          else {
            this.swalServices.Error(ErrorMessage.InvalidAuthorization);
          }
          this.setState({ isLoading: false });
        });
      })
        .catch(err => {
          // handle error
          if (err.errorCode === "interaction_in_progress") {
            this.handleLogin("popup");
          }
        });
    }

  }



  handleChange = (key, value) => {
    let newUserDetails = { ...this.state.userDetails };
    newUserDetails[key] = value;
    this.setState({ userDetails: newUserDetails });
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }

    return (
      <div>
        <div className="main-login-sec">

          <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-white w-full">
            <div className="login-main-bg max-w-md w-full space-y-8 p-8 rounded-sm shadow-red">
              <div className="">
                <img
                  className="mx-auto w-[280px]"
                  src={LogoImage}
                  alt="logo"
                />
                <h2 className="mt-9 text-center text-3xl font-extrabold text-gray-900">
                  Log in
                </h2>
              </div>
              <input type="hidden" name="remember" value="true" />
              {
                this.state.userDetails.isTwoFactor === true ? (
                  <input
                    id="varificationCode"
                    name="varificationCode"
                    type="text"
                    placeholder="Verification Code"
                    required
                    className="login-input appearance-none rounded-none relative block w-full px-3 py-2 border border-[#af0c0c] placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-[#c71212] focus:border-[#c71212] focus:z-10 sm:text-sm bg-white"

                    value={this.state.userDetails.varificationCode}
                    onChange={(event) =>
                      this.handleChange("varificationCode", event.target.value)
                    }
                  />
                ) : (
                  <div className="rounded-md -space-y-px relative">
                    <div>
                      <label htmlFor="email-address" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="login-input appearance-none rounded-none relative block w-full px-3 py-2 border border-[#af0c0c] placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-[#c71212] focus:border-[#c71212] focus:z-10 sm:text-sm bg-white"
                        placeholder="Email address"
                        value={this.state.userDetails.userName}
                        onChange={(event) =>
                          this.handleChange("userName", event.target.value)
                        }
                        onBlur={() => this.validateField("userName")}
                      />
                      <ValidationText
                        className="absolute left-0 top-[100%]"
                        error={this.state.validState.error.userName}
                      />
                    </div>


                    <div className="mt-4">
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className=" login-input appearance-none rounded-none relative block w-full px-3 py-2 border border-[#c71212] placeholder-gray-500 text-[#C00000] rounded-b-md focus:outline-none focus:ring-[#c71212] focus:border-[#c71212] focus:z-10 sm:text-sm bg-white"
                        placeholder="Password"
                        value={this.state.userDetails.password}
                        onChange={(event) =>
                          this.handleChange("password", event.target.value)
                        }
                        onBlur={() => this.validateField("password")}
                      />
                      <ValidationText
                        className="absolute left-0 top-[100%]"
                        error={this.state.validState.error.password}
                      />
                    </div>
                  </div>
                )
              }


              {this.state.userDetails.isTwoFactor === true ? null : (<div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-[#C00000] focus:ring-[#C00000] rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    {" "}
                    Remember me{" "}
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="/ForgetPassword"
                    className="font-medium text-[#C00000] hover:text-[#aa0000]"
                  >
                    {" "}
                    Forgot your password?{" "}
                  </a>
                </div>
              </div>)}

              {this.state.userDetails.isTwoFactor === true ?
                (<div className="vertify-otp-button">
                  {this.state.isLoading ? (
                    <>
                      <img
                        className="xl:w-7 lg:w-7 w-5 rotate-180	"
                        src="/round-right-icon.png"
                        alt=""
                        onClick={() => {
                          let data = { ...this.state.userDetails }
                          data.isTwoFactor = false;
                          data.varificationCode = "";
                          this.setState({ userDetails: data });
                        }}
                      ></img>
                      <ButtonLoader />
                    </>
                  ) :

                    <>
                      <img
                        className="xl:w-7 lg:w-7 w-5 rotate-180	"
                        src="/round-right-icon.png"
                        alt=""
                        onClick={() => {
                          let data = { ...this.state.userDetails }
                          data.isTwoFactor = false;
                          data.varificationCode = "";
                          this.setState({ userDetails: data });
                        }}
                      ></img>
                      {this.state.userDetails.varificationCode && this.state.userDetails.varificationCode !== null && this.state.userDetails.varificationCode !== "" ?
                        <button
                          type="submit"
                          className="group relative w-2/5 flex items-center justify-center py-3 px-4 h-[48px] border border-transparent text-sm font-medium text-white bg-[#C00000] hover:bg-[#aa0000] focus:outline-none"
                          onClick={() => this.login()}
                        >
                          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <svg
                              className="h-5 w-5 text-rose-300 group-hover:text-rose-100"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                          Verify
                        </button>
                        :

                        <button
                          type="submit"
                          className="group relative w-2/5 flex items-center justify-center py-3 px-4 h-[48px] border border-transparent 
                          text-sm font-medium text-white bg-[#C00000] focus:outline-none cursor-not-allowed btn-red"
                          disabled
                        >
                          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <svg
                              className="h-5 w-5 text-rose-300"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                          Verify
                        </button>}
                    </>
                  }
                </div>)
                : (
                  <div className="flex justify-center">
                    {this.state.isLoading ? (
                      <ButtonLoader />
                    ) : (
                      <button
                        type="submit"
                        className="group relative w-2/5 flex items-center justify-center py-3 px-4 h-[48px] border border-transparent text-sm font-medium text-white bg-[#C00000] hover:bg-[#aa0000] focus:outline-none"
                        onClick={() => this.login()}
                      >
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                          <svg
                            className="h-5 w-5 text-rose-300 group-hover:text-rose-100"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        Sign in
                      </button>
                    )}
                  </div>
                )}

              {this.state.userDetails.isTwoFactor === true ? null : (
                <div className="flex justify-center">
                  {this.state.isLoading ? (
                    <ButtonLoader />
                  ) : (
                    <button
                      type="submit"
                      className="group relative w-2/5 flex items-center justify-center py-3 px-4 h-[48px] border border-transparent text-sm font-medium text-white bg-[#C00000] hover:bg-[#aa0000] focus:outline-none"
                      onClick={() => { this.handleLogin("popup") }}
                    >

                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg
                          className="h-5 w-5 text-rose-300 group-hover:text-rose-100"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                      AzureLogin
                    </button>
                  )}
                </div>

              )}

            </div>
          </div>
        </div>
      </div >
    );
  }
}
export default Login = withMsal(Login);
