import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import TextInput from "../../components/UIComponent/TextInput";
import ValidationText from "../../utils/validation/ValidationText";
import ImageCropperWithUpload from "../../components/ImageUpload/UploadImage";
import { CommonValidationMessages } from "../../utils/Messages";
import { validate, isValidForm } from "../../utils/validation/CommonValidator";

// Import API Service
import DtsmeServices from "../../services/axiosServices/apiServices/DtsmeServices";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { checkParameters } from "../../utils/Encryption";
import TinyEceEditor from "../../components/ckEditor/TinyMCEEditor";

export default class AddEditDTSMEMentors extends Component {
    constructor(props) {
        super(props);
        this.dtsmeServices = new DtsmeServices();
        this.commonServices = new CommonServices();
        this.swalServices = new SwalServices();
        this.state = {
            isLoading: false,
            redirect: null,
            dtsmeMentorsInformationId: 0,
            techSolutionList: [],
            vendorCategoryList: [],
            dtsmeMentorDetail: {
                dtsmeMentorsInformationId: 0,
                dTSMEMentorName: "",
                dTSMEMentorCompany: "",
                dTSMEMentorDesignation: "",
                description: "",
                profileImage: ""
            },
            validationRule: {
                dTSMEMentorName: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "contact person name"
                        ),
                    },
                ],
                dTSMEMentorCompany: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "company name"
                        ),
                    },
                ],
                dTSMEMentorDesignation: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "designation"
                        ),
                    },
                ],
                description: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "description"
                        ),
                    },
                ],
                profileImage: [
                    {
                        type: "require",
                        message: CommonValidationMessages.UploadPdfRequired.replace("{0}", "profileImage"),
                    }
                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }

    componentDidMount = () => {
        let params = new URLSearchParams(window.location.search);
        let dTSMEMentorsId = params.get("");
        if (dTSMEMentorsId && (dTSMEMentorsId = checkParameters(dTSMEMentorsId, "Number"))) {
            if (dTSMEMentorsId > 0) {
                this.setState({ dtsmeMentorsInformationId: dTSMEMentorsId }, () => {
                    this.getDTSMEMentorsInformationById(dTSMEMentorsId);
                });
            }
        }

    }


    // Add Edit DTSME Marketplace Detail
    addEditDTSMEMentorsInformation = () => {
        let isValidInput = this.isValidateAllFields();
        if (isValidInput) {
            this.setState({ isLoading: true });
            this.dtsmeServices.addEditDTSMEMentorsInformation(this.state.dtsmeMentorDetail)
                .then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        this.swalServices.Success("Details saved sucessfully!");
                        this.setState({ redirect: "/dtsme/DTSMEMentors" });
                    } else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
        }
    };


    getDTSMEMentorsInformationById = (id) => {
        if (this.state.dtsmeMentorsInformationId != null) {
            this.dtsmeServices.getDTSMEMentorsInformationById(id).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    let res = { ...this.state.dtsmeMentorDetail };
                    console.log(res)
                    res.dtsmeMentorsInformationId = response.responseItem.responseContent.dtsmeMentorsInformationId;
                    res.dTSMEMentorName = response.responseItem.responseContent.dtsmeMentorName;
                    res.dTSMEMentorCompany = response.responseItem.responseContent.dtsmeMentorCompany;
                    res.dTSMEMentorDesignation = response.responseItem.responseContent.dtsmeMentorDesignation;
                    res.description = response.responseItem.responseContent.description;
                    res.profileImage = response.responseItem.responseContent.profileImage;
                    this.setState({ dtsmeMentorDetail: res });
                } else {
                    this.swalServices.Error(response.message);
                }
            }
            );
        }
    };

    // Method for Upload Company Logo
    saveMainImage = (file, keyId, imageIdentity) => {
        this.setState({ isLoading: true });
        file.storagePath = "DT-SME\\MarketPlaceCompanyProfileLogo";
        this.commonServices.storeBase64Image(file).then((response) => {
            console.log("Check Image Upload Response :", response);
            if (response != null) {
                this.swalServices.Success("File Uploaded");
                let detail = this.state.dtsmeMentorDetail;
                detail.profileImage = response.fileName;
                this.setState({ dtsmeMentorDetail: detail }, () => this.validateField("profileImage"));
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false })
            console.log("this.state.dtsmeMentorDetail :", this.state.dtsmeMentorDetail);
        })
    }


    // Internal handling Methods
    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.dtsmeMentorDetail;
        detail[name] = value;
        this.setState({ dtsmeMentorDetail: { ...detail } });
    }

    // Field validation method 
    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.dtsmeMentorDetail,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.dtsmeMentorDetail,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    setEditorData = (data) => {
        if (typeof data === 'string') {
            let detail = this.state.dtsmeMentorDetail;
            detail["description"] = data;
            this.setState({ dtsmeMentorDetail: detail });
        }
    };

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <>
                <div className="main-body-section global-account-sec">
                    <div className="bg-white">
                        <div className=" heading-part px-7 py-4 border-b">
                            <h4 className="small-title font-bold theme-color">
                                DT-SME Mentors
                            </h4>
                        </div>

                        <div className="account-fields">
                            <div className="w-full 2xl:px-8 lg:px-4 px-4 mt-5">
                                <div className="grid grid-cols-12 gap-6 w-full ">
                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                        <div className="grid grid-cols-12 gap-6 w-full ">
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Name
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="dTSMEMentorName"
                                                            value={this.state.dtsmeMentorDetail.dTSMEMentorName}
                                                            identity="dTSMEMentorName"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("dTSMEMentorName")}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.dTSMEMentorName} />
                                            </div>

                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Company Name
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="dTSMEMentorCompany"
                                                            value={this.state.dtsmeMentorDetail.dTSMEMentorCompany}
                                                            identity="dTSMEMentorCompany"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("dTSMEMentorCompany")}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.dTSMEMentorCompany} />
                                            </div>
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Designation
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="w-full outline-none appearance-none">
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="dTSMEMentorDesignation"
                                                        value={this.state.dtsmeMentorDetail.dTSMEMentorDesignation}
                                                        identity="dTSMEMentorDesignation"
                                                        handleChange={this.handleChange.bind(this)}
                                                        onBlur={(e) => this.validateField("dTSMEMentorDesignation")}
                                                    />
                                                </div>
                                                <ValidationText error={this.state.validState.error.dTSMEMentorDesignation} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                        <div className="grid grid-cols-12 gap-6 w-full ">
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Profile Image
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="w-[320px]">
                                                    <div className="company-logo-cropper ml-auto mr-auto goglobal-company-logo">
                                                        <ImageCropperWithUpload
                                                            key="Main"
                                                            height={480}
                                                            width={360}
                                                            isCheckValidation={true}
                                                            MaxFileSize={2097152}
                                                            MaxFileSizeMessage={"2MB"}
                                                            onSaveImage={this.saveMainImage}
                                                            imageIdentity="DT-SME\MarketPlaceCompanyProfileLogo"
                                                            intitImage={this.state.dtsmeMentorDetail.profileImage}
                                                            aspactRatio={360 / 480}
                                                            uploadBtn={"Profile Image"}
                                                            dimensionText={"(Format: JPG/PNG Dimension: 360px by 480px Maximum Size: 2MB)"}
                                                        />

                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.profileImage} />
                                            </div>
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Description
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                {/* <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="description"
                                                    value={this.state.dtsmeMentorDetail.description}
                                                    identity="description"
                                                    handleChange={this.handleChange.bind(this)}
                                                    onBlur={(e) => this.validateField("description")}
                                                /> */}
                                                <TinyEceEditor
                                                    key={"Workshop_About_CkEditor"}
                                                    onChange={(data) => {
                                                        this.setEditorData(data);
                                                    }}
                                                    data={this.state.dtsmeMentorDetail.description}
                                                />
                                                <ValidationText error={this.state.validState.error.description} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap justify-end space-x-10 mt-12 pb-8">
                                    <div className="space-x-4">
                                        {
                                            this.state.isLoading ?
                                                <ButtonLoader />
                                                :
                                                <button
                                                    className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                                                    onClick={() => this.addEditDTSMEMentorsInformation()}
                                                >
                                                    Save
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}