import React from "react";
import ImageCropperWithUpload from "../ImageUpload/UploadImage";
import TinyMCEEditor from "../ckEditor/TinyMCEEditor";

function SimpleText(props) {
  return (
    <div key={`RichTextWithImage_${props.identity}`}>
      <div className="simple-text">
        <div className="grid grid-cols-12 gap-6 mt-10 mb-10 border border-[#757575]">
          <div className="filter-types col-span-12 font-bold border-b border-[#757575] bg-[#f0f0f0] px-7 py-3 relative">
            <h2 className="filter-lable  font-medium theme-color">
              Rich Text With Image
            </h2>
            {props.pageTypeId === 3 || (props.isPrivilegePage && props.isPrivilegePage === true) ?
              <div className="close-icon-btn absolute top-[50%] right-[10px] translate-y-[-50%]">
                <button
                  className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                  onClick={() => props.removeComponent()}
                >
                  <div className="sr-only">Close</div>
                  <svg className="w-4 h-4 fill-current">
                    <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                  </svg>
                </button>
              </div>
              : null}
          </div>
          <div className="filter-types col-span-12 p-8">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="xl:col-span-5 lg:col-span-5 sm:col-span-12 input-sec-texts">
                <div className="flex items-center">
                  <div className="w-[60%] mr-4 pt-1">
                    <h2 className="filter-lable  font-medium theme-color">
                      Component Unique Id :
                    </h2>
                  </div>
                  <div className="w-[40%]">
                    {/* <TextInput /> */}
                    <input
                      id="default"
                      name="componentId"
                      className="w-full outline-none appearance-none form-input rounded-none bg-zinc-100"
                      type="text"
                      value={props.componentDetails.componentId}
                      onChange={(event) => props.handleChange(event, props.identity)}
                      disabled={true}
                    //onBlur={() => props.validateField("publishDate")}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-7 lg:col-span-7 sm:col-span-12 input-sec-texts">
                <div className="flex items-center">
                  <div className="w-[40%] mr-4 pt-1">
                    <h2 className="filter-lable  font-medium theme-color">
                      Component Name :
                    </h2>
                  </div>
                  <div className="w-[60%]">
                    {/* <TextInput /> */}
                    {/* <input
                      id="default"
                      name="componentName"
                      className="w-full outline-none appearance-none form-input rounded-none"
                      type="text"
                      value={props.componentDetails.componentName}
                      onChange={(event) => props.handleChange(event, props.identity)}
                    /> */}
                    <input
                      id="default"
                      name="componentName"
                      className={`w-full outline-none appearance-none form-input rounded-none ${(props.componentDetails.componentName) ? 'bg-zinc-100' : ''}`}
                      type="text"
                      value={props.componentDetails.componentName}
                      onChange={(event) => props.handleChange(event, props.identity)}
                      disabled={(props.componentDetails.componentName) ? true : false}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-span-12 mt-5"> */}
              {props.componentDetails.componentContent.imageOnLeft ?
                <div className="2xl:col-span-4 lg:col-span-6 col-span-12 mt-14" key={"RichTextImageSec_" + props.identity}>
                  <div className="speaker-img-cropper simple-txt-img" key={"RichTextCropDiv_" + props.identity}>
                    <ImageCropperWithUpload
                      key={"RichTextImgCropperComp_" + props.identity}
                      keyId={"RichTextImgCropperKey_" + props.identity}
                      height={355}
                      width={550}
                      isCheckValidation={true}
                      MaxFileSize={2097152}
                      MaxFileSizeMessage={"2MB"}
                      onSaveImage={(file, id, imageIdentity) => props.saveMainImage(file, id, imageIdentity, props.identity)}
                      imageIdentity={"\\PageManagement\\RichTextWithImage"}
                      intitImage={props.componentDetails.componentContent.image}
                      aspactRatio={550 / 355}
                      uploadBtn={"Upload Image"}
                      dimensionText={
                        "(Ratio: 550 X 355 Maximum Size: 2MB)"
                      }
                    />
                  </div>
                </div>
                :
                <div className="2xl:col-span-7 lg:col-span-7 col-span-12  mt-14" key={"RichTextDecSec_" + props.identity}>
                  {/* <MyEditor
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      props.setEditorData(data, props.identity);
                    }}
                    data={props.componentDetails.componentContent.data}
                  /> */}
                  <TinyMCEEditor
                    key={`RhtRichTextDecSec_ ${props.identity}`}
                    onChange={(data, editor) => { props.setEditorData(data, props.identity); }}
                    data={props.componentDetails.componentContent.data}
                  />
                </div>
              }

              <div className="2xl:col-span-1 lg:col-span-1 col-span-12 mt-14">
                <div className="swap-section text-center">
                  <button className="mx-auto my-0 mt-5" onClick={() => props.swapSection()}>
                    <div className="flex items-center font-semibold">
                      <span className="">
                        <img src="/Swap-icon.png" className="w-[50px]" alt="" />
                      </span>
                    </div>
                  </button>
                </div>
              </div>
              {props.componentDetails.componentContent.imageOnLeft ?
                <div className="2xl:col-span-7 lg:col-span-7 col-span-12  mt-14" key={"RichTextDecSec_" + props.identity}>
                  {/* <MyEditor
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      props.setEditorData(data, props.identity);
                    }}
                    data={props.componentDetails.componentContent.data}
                  /> */}
                  <TinyMCEEditor
                    key={`LftRichTextDecSec_ ${props.identity}`}
                    onChange={(data, editor) => { props.setEditorData(data, props.identity, editor); }}
                    data={props.componentDetails.componentContent.data}
                  />
                </div>
                :
                <div className="2xl:col-span-4 lg:col-span-6 col-span-12 mt-14" key={"RichTextImageSec_" + props.identity}>
                  <div className="speaker-img-cropper simple-txt-img" key={"RichTextImageComp_" + props.identity}>
                    <ImageCropperWithUpload
                      key={"RichTextImgCropper_" + props.identity}
                      keyId={"RichTextImgCropperKey_" + props.identity}
                      height={355}
                      width={550}
                      isCheckValidation={true}
                      MaxFileSize={2097152}
                      MaxFileSizeMessage={"2MB"}
                      onSaveImage={(file, id, imageIdentity) => props.saveMainImage(file, id, imageIdentity, props.identity)}
                      imageIdentity={"\\PageManagement\\RichTextWithImage"}
                      intitImage={props.componentDetails.componentContent.image}
                      aspactRatio={550 / 355}
                      uploadBtn={"Upload Image"}
                      dimensionText={
                        "(Ratio: 550 X 355 Maximum Size: 2MB)"
                      }
                    />
                  </div>
                </div>
              }
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleText;
