import React, { Component } from 'react'

//import api services
import PartnershipsServices from "../../services/axiosServices/apiServices/PartnershipsServices";
import SwalServices from "../../services/swalServices/SwalServices";

//import components and packagess
import AgencyProfile from '../../components/partnerships/AgencyProfile';
import AddEditAgencyOfficer from '../../components/partnerships/AddEditAgencyOfficer';
import { CommonValidationMessages } from '../../utils/Messages';
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import { CommonSuccessMessages } from '../../utils/Messages';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { checkParameters } from '../../utils/Encryption';

export default class AddEditGovernmentPartner extends Component {
    constructor(props) {
        super(props);
        this.partnershipServices = new PartnershipsServices();
        this.swalServices = new SwalServices();
        this.state = {
            totalResult: "",
            isLoading: false,
            redirect: null,
            agencyId: 0,
            agencyProfile: {
                agencyId: 0,
                agencyName: "",
                isKeyAgency: "true",
                agencyOfficerId: 0,
                sGTechSecretariatId: 0,
                internalNotes: "",
            },
            addEditOfficer: false,
            addNewOfficer: false,
            addEditAgencyOfficerDetails: {
                agencyId: 0,
                agencyOfficerId: 0,
                agentName: "",
                designation: "",
                emailAddress: "",
                contactNo: "",
            },
            agencyList: [],
            agencyListTest: [],
            agencyListSingleSelect: [],
            agencyListTestSelected: 0,
            agencyListSelectedName: "",
            agencyOfficerDetails: [],
            agencyOfficerDropdown: [],
            sGTechSecretariaList: [
                {
                    id: 1,
                    label: "Admin",
                },
            ],
            validationRule: {
                agentName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'agent name')
                    }
                ],
                designation: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'designation')
                    }
                ],
                emailAddress: [
                    {
                        type: 'email',
                        message: CommonValidationMessages.EmailAddressNotValid,
                    },
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'email address'),
                    },
                ],
                // contactNo: [
                //     {
                //         type: 'require',
                //         message: CommonValidationMessages.FieldRequired.replace('{0}', 'workphone number'),
                //     },
                //     {
                //         type: 'number',
                //         message: CommonValidationMessages.FieldRequired.replace('{0}', 'digits only'),
                //     },
                //     {
                //         type: 'minLength',
                //         minLength: 8,
                //         message: ('Please enter at least 8 digit'),
                //     },
                //     {
                //         type: 'maxLength',
                //         maxLength: 10,
                //         message: ('Please enter maximum 10 digit'),
                //     },
                // ],
            },
            agencyProfileValidationRule: {
                // agencyId: [
                //     {
                //         type: 'require',
                //         message: CommonValidationMessages.SelectRequired.replace('{0}', 'agency')
                //     }
                // ],
                agencyName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'agency')
                    }
                ],
                // agencyOfficerId: [
                //     {
                //         type: 'require',
                //         message: CommonValidationMessages.SelectRequired.replace('{0}', 'officer')
                //     }
                // ],
                sGTechSecretariatId: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'SGTech Secretariat')
                    }
                ],
            },
            validState: {
                isValid: true,
                error: {}
            },
            validState2: {
                isValid: true,
                error: {}
            },
        }
    }

    // Methods declaration section start
    componentDidMount() {
        // let params = this.props.params.projectId;
        let idString = window.location.href;
        var str_array = idString.split('/');
        let agencyId = str_array[str_array.length - 1];
        if (agencyId && agencyId !== "AddEditGovernmentPartner" && (agencyId = checkParameters(agencyId, "Number"))) {
            if (agencyId > 0) {
                this.setState({ agencyId: agencyId }, () => this.getGovernmentAgencyById(agencyId));
            } else {
                this.getAllAgencyDropdown();
            }
        } else {
            this.getAllAgencyDropdown();
        }
    }

    //API methods start 

    // Get all agency officer list method
    getAllAgencyOfficerList = (id) => {
        this.setState({ isLoading: true });
        this.partnershipServices.getAllAgencyOfficerList(id).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let newAgencyOfficerList = response.responseItem.responseContent;
                newAgencyOfficerList.forEach((key, index) => {
                    key.updatedAt = moment(key.projectStartDate).format('DD MMM YYYY');
                });
                this.setState({ agencyOfficerDetails: newAgencyOfficerList });
                // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });

                //Agency officer list dropdown
                let agencyNameList = response.responseItem.responseContent;
                agencyNameList = agencyNameList.map(p => ({
                    value: p.agencyOfficerId, label: p.agentName
                }));
                this.setState({ agencyOfficerDropdown: agencyNameList }, () => this.getAllAgencyDropdown());
            }
            else {
                this.getAllAgencyDropdown();
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    //Get all agency name in dropdown 
    getAllAgencyDropdown = () => {
        this.partnershipServices.getAllAgencyDropdown().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let agencyNameList = response.responseItem.responseContent;
                agencyNameList = agencyNameList.map(p => ({
                    value: p.id, label: p.name
                }));
                let agencyNameListTest = response.responseItem.responseContent.map(p => ({
                    id: p.id, name: p.name
                }));

                this.setState({ agencyList: agencyNameList });
                this.setState({ agencyListTest: agencyNameListTest });
                this.setState({ agencyListSingleSelect: agencyNameListTest });
                this.setState({ agencyById: agencyNameList.value }, () => this.getAllSecretariatList())
            }
            else {
                this.getAllSecretariatList();
                this.swalServices.Error(response.message);
            }
        });
    };
    //Get all SGTech Secretariat in dropdown 
    getAllSecretariatList = () => {
        this.partnershipServices.getAllSecretariatList().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let agencyNameList = response.responseItem.responseContent;
                agencyNameList = agencyNameList.map(p => ({
                    value: p.id, label: p.name
                }));
                this.setState({ sGTechSecretariaList: agencyNameList });
            }
            else {
                this.swalServices.Error(response.message);
            }
        });
    };
    //Delete agency officer partners
    deleteAgencyOfficer = (value) => {
        let request = {
            id: value,
            deletedBy: 115,
            appId: 15,
        };
        this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "agency officer"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "agency officer"),
            CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
                if (response) {
                    this.partnershipServices.deleteGovernmentAgencyOfficer(request).then((response) => {
                        if (response.statusCode === 200) {
                            this.swalServices.Success("Deleted");
                            this.getAllAgencyOfficerList(this.state.agencyId);
                        } else {
                            this.swalServices.Error(response.message);
                        }
                        this.setState({ isLoading: false });
                    });
                }
            })
    };
    //Add edit agency officer methiod
    addEditAgencyOfficer = () => {
        let isValidAll = this.isValidateAllFields();
        if (isValidAll) {
            this.setState({ isLoading: true });
            let details = this.state.addEditAgencyOfficerDetails;
            details.agencyId = this.state.agencyId;
            this.partnershipServices.addEditAgencyOfficer(details).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "Email") {
                    this.swalServices.Error("Agency officer with same email address already exists.");
                }
                else if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success("Agency details updated successfully.");
                }
                else {
                    this.swalServices.Error(response.message);
                }
                this.resetFields();
                this.getAllAgencyOfficerList(this.state.agencyId);
                this.setState({ addEditOfficer: false });
                this.setState({ addNewOfficer: false });
                this.setState({ isLoading: false });
            });
        }
    };
    //Add edit government agency profile methiod
    addEditAgencyPartner = () => {
        // let isValidAll = this.isValidateAllFields();

        this.setState({ isLoading: true });
        this.resetFields();
        let details = this.state.agencyProfile;
        details.isKeyAgency = details.isKeyAgency === "true" ? true : false;
        details.agencyId = details.agencyId !== null ? details.agencyId : 0;
        details.agencyName = this.state.agencyListSelectedName;
        this.setState({ agencyProfile: details });
        if (this.isValidateAllFields2()) {
            this.partnershipServices.addEditAgencyPartner(details).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "AgencyName") {
                    this.swalServices.Error("Government agency with same agency name is already exists.");
                }
                else if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success("Agency details updated successfully.");
                    this.setState({ agencyId: response.responseItem.responseContent.keyId }, () => this.getGovernmentAgencyById(response.responseItem.responseContent.keyId));
                }
                else {
                    this.swalServices.Error(response.message);
                }
                // this.resetAgencyProfileFields();
                this.setState({ isLoading: false });
            });
        }
    };
    //Get agency officer by id method
    getAgencyOfficerById = (id) => {
        this.setState({ addEditOfficer: true });
        this.setState({ addNewOfficer: false });
        this.partnershipServices.getAgencyOfficerById(id).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({
                    addEditAgencyOfficerDetails: response.responseItem.responseContent,
                });
            } else {
                this.swalServices.Error(response.message);
            }
        });
    };
    //Get government agency by id method
    getGovernmentAgencyById = (id) => {
        this.partnershipServices.getGovernmentAgencyById(id).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let detail = this.state.agencyProfile;
                detail.agencyId = response.responseItem.responseContent.agencyId;
                detail.agencyName = response.responseItem.responseContent.agencyName;
                detail.agencyOfficerId = response.responseItem.responseContent.agencyOfficerId;
                detail.internalNotes = response.responseItem.responseContent.internalNotes;
                detail.isKeyAgency = response.responseItem.responseContent.isKeyAgency === true ? "true" : "false";
                detail.sGTechSecretariatId = response.responseItem.responseContent.sgTechSecretariatId;

                this.setState({
                    agencyProfile: detail, agencyListTestSelected: response.responseItem.responseContent.agencyId,
                    agencyListSelectedName: response.responseItem.responseContent.agencyName,
                    agencyId: detail.agencyId
                }, () => {
                    this.getAllAgencyOfficerList(detail.agencyId);
                });
            } else {
                this.getAllAgencyDropdown();
                this.swalServices.Error(response.message);
            }
        });
    };
    //Api methods end

    //Internal methods start
    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.addEditAgencyOfficerDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };
    validateField = (key) => {
        const newValidState = validate(key, this.state.addEditAgencyOfficerDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    };

    isValidateAllFields2 = () => {
        let validrule = { ...this.state.agencyProfileValidationRule };
        if (this.state.agencyOfficerDetails.length > 0) {

            let agencyOfficerId = [
                {
                    type: 'require',
                    message: CommonValidationMessages.SelectRequired.replace('{0}', 'officer')
                }
            ];
            validrule.agencyOfficerId = agencyOfficerId;
            this.setState({ agencyProfileValidationRule: validrule });
        }
        // if (this.state.agencyListSelectedName !== "") {
        //     let agencyId = [
        //         {
        //             type: 'require',
        //             message: CommonValidationMessages.SelectRequired.replace('{0}', 'agency')
        //         }
        //     ];
        // }

        const newValidState = isValidForm(this.state.agencyProfile, this.state.agencyProfileValidationRule, this.state.validState2);
        this.setState({ validState2: newValidState });
        return newValidState.isValid;
    };
    validateField2 = (key) => {
        const newValidState = validate(key, this.state.agencyProfile, this.state.agencyProfileValidationRule, this.state.validState2);
        this.setState({ validState2: newValidState });
    };

    handleChangeInDropdown(selected, drpIdentity) {
        if (drpIdentity === "AgencyName") {
            var details = this.state.agencyProfile;
            // details.selectedAgency = selected.value;
            details.agencyId = selected.value;
            details.agencyName = selected.label;
            if (selected.value > 0) {
                this.getGovernmentAgencyById(selected.value);
            }
            this.validateField2("agencyId");
            this.setState({ agencyProfile: details });
        }
        if (drpIdentity === "AgencyOfficer") {
            var details2 = this.state.agencyProfile;
            details2.agencyOfficerId = selected.value;
            this.setState({ agencyProfile: details2 });
            this.validateField2("agencyOfficerId");
        }
        if (drpIdentity === "SGTechSecretariat") {
            var details3 = this.state.agencyProfile;
            details3.sGTechSecretariatId = selected.value;
            this.setState({ agencyProfile: details3 });
            this.validateField2("sGTechSecretariatId");
        }
    };

    searchByName = (event) => {
        let searchText = "";
        if (event.target.value.length > 2) {
            searchText = event.target.value.toLowerCase();
            const matches = this.state.agencyList.find(s => s.label.toLowerCase().includes(searchText));
            this.getGovernmentAgencyById(matches.value);
        }
    }

    //Handle changes in radio button
    handleRadioButtons(event) {
        const value = event.target.value;
        const id = event.target.id;
        const name = event.target.name;
        let detail = this.state.agencyProfile;
        detail[id] = value;
        detail[name] = value;
        this.setState({ agencyProfile: { ...detail } });
    }

    //Handle change for agency profile
    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.agencyProfile;
        detail[name] = value;
        this.setState({ agencyProfile: { ...detail } });
    };
    //Handle change for add edit agency officer
    handleChange2(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.addEditAgencyOfficerDetails;
        detail[name] = value;
        this.setState({ addEditAgencyOfficerDetails: detail });
    };

    resetFields() {
        let reset = this.state.addEditAgencyOfficerDetails;
        reset.agencyId = "";
        reset.agencyOfficerId = 0;
        reset.agentName = "";
        reset.designation = "";
        reset.emailAddress = "";
        reset.contactNo = "";
        this.setState({ addEditAgencyOfficerDetails: reset });
    };

    resetValidState = () => {
        let resetValid = this.state.validState;
        resetValid.isValid = true;
        resetValid.error = {};
        this.setState({ validate: resetValid });
    }

    resetAgencyProfileFields() {
        let reset = this.state.agencyProfile;
        reset.agencyId = 0;
        reset.isKeyAgency = "true";
        reset.agencyOfficerId = 0;
        reset.sGTechSecretariatId = 0;
        reset.internalNotes = "";
        this.setState({ agencyProfile: reset });
        this.getAllAgencyOfficerList(0);
    };

    onAddEditAgencyOfficer() {
        this.setState({ addEditOfficer: true });
    };
    
    onAddEditAgencyOfficer2() {
        this.setState({ addEditOfficer: false });
        this.setState({ addNewOfficer: true });
    };

    setFilterParametersTest = (id, name, drpIdentity) => {
        var arr = [...this.state.agencyListSingleSelect]
        var found = arr.find(element => element.name.includes(name));
        if (found && found.name) {
            this.getGovernmentAgencyById(id);
            const agencyListSelectedName = found.name;
            this.setState({ agencyListTestSelected: id, agencyListTest: found, agencyListSelectedName: agencyListSelectedName })
        }
    }

    handleChangeTest = (e) => {
        var arr = [...this.state.agencyListSingleSelect]
        let value = ""
        value = e.target.value;
        if (value === "" || value === null) {
            this.resetAgencyProfileFields();
        }
        const found = arr.filter(element => element.name.toLowerCase().includes(value.toLowerCase()));
        this.setState({ agencyListSelectedName: value, agencyListTest: found });
    }

    cancelAddEditOfficer = () => {
        this.setState({ addEditOfficer: false });
        this.setState({ addNewOfficer: false });
        this.resetValidState();
    }
    //Method section end

    render() {
        return (
            <div>
                <div className="main-body-section">
                    <div>
                        <AgencyProfile
                            agencyList={this.state.agencyList}
                            agencyOfficerDropdown={this.state.agencyOfficerDropdown}
                            SGTechSecretariaList={this.state.sGTechSecretariaList}
                            agencyProfile={this.state.agencyProfile}
                            handleChange={this.handleChange.bind(this)}
                            handleChangeInDropdown={this.handleChangeInDropdown.bind(this)}
                            handleRadioButtons={this.handleRadioButtons.bind(this)}
                            addEditAgencyPartner={this.addEditAgencyPartner.bind(this)}
                            validState={this.state.validState2}
                            validateField={this.validateField2.bind(this)}
                            searchByName={this.searchByName.bind(this)}
                            setFilterParametersTest={this.setFilterParametersTest.bind(this)}
                            handleChangeTest={this.handleChangeTest.bind(this)}
                            agencyListTest={this.state.agencyListTest}
                            agencyListSelectedName={this.state.agencyListSelectedName}
                            agencyListTestSelected={this.state.agencyListTestSelected}
                        />
                    </div>
                    <div className='mt-10'>
                        <AddEditAgencyOfficer
                            agencyOfficerDetails={this.state.agencyOfficerDetails}
                            addEditAgencyOfficerDetails={this.state.addEditAgencyOfficerDetails}
                            deleteAgencyOfficer={this.deleteAgencyOfficer.bind(this)}
                            addEditOfficer={this.state.addEditOfficer}
                            addNewOfficer={this.state.addNewOfficer}
                            onAddEditAgencyOfficer={this.onAddEditAgencyOfficer.bind(this)}
                            onAddEditAgencyOfficer2={this.onAddEditAgencyOfficer2.bind(this)}
                            resetFields={this.resetFields.bind(this)}
                            addEditAgencyOfficer={this.addEditAgencyOfficer.bind(this)}
                            getAgencyOfficerById={this.getAgencyOfficerById.bind(this)}
                            handleChange={this.handleChange2.bind(this)}
                            validState={this.state.validState}
                            validateField={this.validateField.bind(this)}
                            cancelAddEditOfficer={this.cancelAddEditOfficer.bind(this)}
                            resetValidState={this.resetValidState.bind(this)}
                            agencyId={this.state.agencyId}
                        />
                    </div>
                    <div className="flex flex-wrap justify-between space-x-10 mt-12 px-6">
                        <Link
                            to="/Partnerships"
                            className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white">
                            Back
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}