import React from "react";
import DatePicker from "../../datePicker/DatePicker";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";
import moment from "moment";
import Validation from "../../../utils/validation/ValidationText";

function AccountInformation(props) {
    return (
        <div className="w-full  2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 px-5 lg:py-7 lg:pt-5 pt-4 border-t-[1.5px] border-[#c9c9c9]">
            {/* Account Information Start */}
            <div className="2xl:py-5 2xl:pt-0 lg:py-5 lg:pt-0 py-4">
                <div className="grid grid-cols-12 gap-6 w-full items-center">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    Account Status <span className="text-[#c00000]">*</span>
                                </h2>
                                <DropdownSelect
                                    drpIdentity={"accountStatus"}
                                    optionArray={props.status}
                                    setFilterParameters={props.setFilterParameters}
                                    value={props.siisIndividualAccountInfo.accountStatus}

                                />
                                <Validation
                                    error={props.validState.error.accountStatus}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    Joined Date <span className="text-[#c00000]">*</span>
                                </h2>
                                <DatePicker
                                    name="individualJoinedDate"
                                    handleChange={props.handleChange}
                                    identity="individualJoinedDate"
                                    value={moment(props.siisIndividualAccountInfo.individualJoinedDate).format("yyyy-MM-DD")}
                                    onBlur={(e) => props.validate("individualJoinedDate", props.siisIndividualAccountInfo)}
                                />

                                <Validation error={props.validState.error.individualJoinedDate} />
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                    Updated By <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className="disabled-input">
                                    <DropdownSelect
                                        drpIdentity={"updatedBy"}
                                        optionArray={props._By}
                                        setFilterParameters={props.setFilterParameters}
                                        value={props.siisIndividualAccountInfo.updatedBy}

                                    />
                                </div>
                                <Validation error={props.validState.error.updatedBy} />
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                    Updated Date
                                </h2>
                                {/* <DatePicker
                                    name="updatedAt"
                                    handleChange={props.handleChange}
                                    identity="SIISInformation"
                                    value={moment(props.siisIndividualAccountInfo.updatedAt).format("yyyy-MM-DD")}
                                    onBlur={(e) => props.validate("updatedAt", props.siisIndividualAccountInfo)}

                                /> */}
                                <div className="disabled-input">
                                    {props.siisIndividualAccountInfo.updatedAt === "" || props.siisIndividualAccountInfo.updatedAt === null ?

                                        <TextInput
                                            disabled={true}
                                            placeholder=""
                                            type="text"
                                            name="linkedIn"
                                            value="None"
                                            identity="BasicInformation"
                                        />
                                        :
                                        <DatePicker
                                            isDisable={true}
                                            name="updatedAt"
                                            handleChange={props.handleChange}
                                            identity="updatedAt"
                                            value={moment(props.siisIndividualAccountInfo.updatedAt).format("yyyy-MM-DD")}
                                        />
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* Mark for deleted  */}
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12 mt-3">
                        <div className="grid grid-cols-12 gap-6 ">
                            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                <div className="flex items-baseline register-desc mb-5">
                                    <input
                                        id="markForDeleted"
                                        name="markForDeleted"
                                        type="checkbox"
                                        className="cursor-pointer h-6 w-6 text-[#C00000] focus:ring-[#C00000] check-box-custom"
                                        onChange={(event) => props.handleChangeCheckBox(event)}
                                        checked={props.siisIndividualAccountInfo.markForDeleted}
                                        value={props.siisIndividualAccountInfo.markForDeleted}
                                    />
                                    <label className="communication-label ml-3 block text-[#757575] 2xl:text-[22px]">
                                        Mark for Delete
                                        <span className="text-[#c00000]">*</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End mark for delete */}
                    {/* Deletion Date Deletion Reason */}

                    {props.siisIndividualAccountInfo.markForDeleted ?
                        <>
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                        <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                            Deletion Reason
                                        </h2>
                                        <DropdownSelect
                                            drpIdentity={"DeletionReasonId"}
                                            optionArray={props.deletionReasonList}
                                            setFilterParameters={props.setFilterParameters}
                                            value={props.siisIndividualAccountInfo.deletionReasonId}
                                        />
                                        <Validation error={props.validState.error.deletionReasonId} />
                                    </div>
                                </div>
                            </div>
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                            Deletion Date
                                        </h2>
                                        <DatePicker
                                            name="deletionDate"
                                            handleChange={props.handleChange}
                                            identity="DeletionDate"
                                            value={moment(props.siisIndividualAccountInfo.deletionDate).format("yyyy-MM-DD")}
                                            onBlur={(e) => props.validate("deletionDate", props.siisIndividualAccountInfo)}
                                        />
                                        <Validation error={props.validState.error.deletionDate} />
                                    </div>
                                </div>
                            </div>
                        </>
                        :

                        null}


                </div>
            </div>
            {/* Save Button */}
            <div className="grid grid-cols-12 gap-6 lg:pb-0 pb-5">
                <div className="xl:col-span-12 lg:col-span-12 col-span-12 2xl:pl-10 xl:pl-10 lg:pl-8 md:pl-4 sm:pl-4 pl-4 ">
                    <a
                        className="flex items-center justify-end"
                    // href="/CreateCorporateMembers"
                    >
                        <button
                            className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                            onClick={() => props.addEditIndividualSIISInformation()}
                        >
                            Save
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default AccountInformation;