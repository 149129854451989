import DatePicker from "../datePicker/DatePicker";
import moment from "moment";
const SearchBar = (props) => {
    return (
        <>
            <div className="searchbar-section border border-[#757575] w-full 2xl:h-14  lg:h-[20px] pl-5 pr-4 mr-11 xl:flex lg:flex items-center justify-between">
                <div
                    // role="form"
                    className="relative flex z-1 justify-between w-full "
                >
                    <input
                        type="text"
                        placeholder=""
                        className="w-full custom-input text-[#757575]"
                        onChange={(e) => props.handleSearchByName(e.target.value)}
                    />
                    <div className="theme-color" >
                        <img
                            className="transform 2xl:w-7 w-5"
                            src="/search-icon.png" alt=""
                        ></img>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SearchBar;
