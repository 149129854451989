import React, { Component } from "react";
import SectionWithBorder from "../../../components/UIComponent/SectionBorder";
import ChapterSection from "../../../components/membership/membershipPricing/ChapterSection";
import Row3Input from "../../../components/membership/membershipPricing/Row3Input";
import MembershipPricingServices from "../../../services/axiosServices/apiServices/MembershipPricingServices";
import { NavLink } from "react-router-dom";
import SwalServices from "../../../services/swalServices/SwalServices";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../../utils/Messages";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";

export default class MembershipPricing extends Component {
  constructor(props) {
    super(props);
    this.membershipPricingServices = new MembershipPricingServices();
    this.swalServices = new SwalServices();

    this.state = {
      membershipPricing: {
        membershipPricingId: 0,
        baseOrdinaryFee: 0,
        baseAssociateFee: 0,
        annualOrdinaryFee1M: 0,
        annualOrdinaryFee10M: 0,
        annualOrdinaryFee30M: 0,
        annualOrdinaryFee50M: 0,
        annualOrdinaryFeeMax: 0,
        annualAssociateFee1M: 0,
        annualAssociateFee10M: 0,
        annualAssociateFee30M: 0,
        annualAssociateFee50M: 0,
        annualAssociateFeeMax: 0,
        annualFreeChapter: 0,
        annualAdditionalChapterPrice: 0,
        startUpEntranceFee: 0,
        startUp1stYearFee: 0,
        startUp2ndYearFee: 0,
        startUp3rdYearFee: 0,
        startUp4thYearFee: 0,
        startUp5thYearFee: 0,
        startUpFreeChapter: 0,
        startUpAdditionalChapterPrice: 0,
      },
      validationRule: {
        annualFreeChapter: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpFreeChapter: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        baseOrdinaryFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        baseAssociateFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryFee1M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryFee10M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryFee30M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryFee50M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryFeeMax: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateFee1M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateFee10M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateFee30M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateFee50M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateFeeMax: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAdditionalChapterPrice: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpEntranceFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUp1stYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUp2ndYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUp3rdYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUp4thYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUp5thYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpAdditionalChapterPrice: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }
  handleChange(event) {
    const value = event.target.value;
    var num = parseFloat(value).toFixed(0);
    const name = event.target.name;
    let detail = this.state.membershipPricing;
    detail[name] = num;
    this.setState({ membershipPricing: { ...detail } });
  }
  
  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.membershipPricing,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.membershipPricing,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  addMembershipPricing = () => {
    let isValid = this.isValidateAllFields();
    if (isValid) {
      this.membershipPricingServices
        .addEditMembershipPricing(this.state.membershipPricing)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success(
              "Membership pricing updated successfully."
            );
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  getMembershipPricing = () => {
    this.membershipPricingServices.getMembershipPricing().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        // this.swalServices.Success("Success");
        this.setState({
          membershipPricing: response.responseItem.responseContent,
        });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };
  componentDidMount() {
    // For Set Created by based on admin login
    let cookie = getAuthProps();
    const adminUserId = (cookie && cookie.adminUserId) ? Number(cookie.adminUserId) : 0;
    if (adminUserId > 0) {
        this.setState({ createdBy: adminUserId });
    }
    // END
    this.getMembershipPricing();
  }

  render() {
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">
                  Base Pricing Table
                </h4>
              </div>
            </div>
            <div className="custom-card shadow-lg mb-10">
              <div className="">
                <SectionWithBorder
                  column1="Fees"
                  column2="Ordinary Membership (OM)"
                  column3="Associate Member (AM)"
                >
                  <div className="sign-icon-input px-7">
                    <div className="pr-24">
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="Entrance Fee (One-Time)"
                        col1value={this.state.membershipPricing.baseOrdinaryFee}
                        col2value={
                          this.state.membershipPricing.baseAssociateFee
                        }
                        col1FieldName="baseOrdinaryFee"
                        col2FieldName="baseAssociateFee"
                        col1InputType="Money"
                        col2InputType="Money"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.baseOrdinaryFee}
                        error2={this.state.validState.error.baseAssociateFee}
                      />
                    </div>
                  </div>
                </SectionWithBorder>

                <div className="annual-member-fee">
                  <SectionWithBorder
                    column1="Annual Membership Fee"
                    column2=""
                    column3=""
                  >
                    <h2 className="text-base ml-12 mt-10 text-zinc-500">
                      Tier
                    </h2>
                    <div className="px-7">
                      <div className="pr-24">
                        <Row3Input
                          className="text-base text-[#757575]"
                          caption="Revenue <$1M"
                          col1value={
                            this.state.membershipPricing.annualOrdinaryFee1M
                          }
                          col2value={
                            this.state.membershipPricing.annualAssociateFee1M
                          }
                          col1FieldName="annualOrdinaryFee1M"
                          col2FieldName="annualAssociateFee1M"
                          col1InputType="Money"
                          col2InputType="Money"
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                          error1={
                            this.state.validState.error.annualOrdinaryFee1M
                          }
                          error2={
                            this.state.validState.error.annualAssociateFee1M
                          }
                        />

                        <Row3Input
                          className="text-base text-[#757575]"
                          caption="Revenue from $1M to $10M"
                          col1value={
                            this.state.membershipPricing.annualOrdinaryFee10M
                          }
                          col2value={
                            this.state.membershipPricing.annualAssociateFee10M
                          }
                          col1FieldName="annualOrdinaryFee10M"
                          col2FieldName="annualAssociateFee10M"
                          col1InputType="Money"
                          col2InputType="Money"
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                          error1={
                            this.state.validState.error.annualOrdinaryFee10M
                          }
                          error2={
                            this.state.validState.error.annualAssociateFee10M
                          }
                        />

                        <Row3Input
                          className="text-base text-[#757575]"
                          caption="Revenue <$10M to $30M"
                          col1value={
                            this.state.membershipPricing.annualOrdinaryFee30M
                          }
                          col2value={
                            this.state.membershipPricing.annualAssociateFee30M
                          }
                          col1FieldName="annualOrdinaryFee30M"
                          col2FieldName="annualAssociateFee30M"
                          col1InputType="Money"
                          col2InputType="Money"
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                          error1={
                            this.state.validState.error.annualOrdinaryFee30M
                          }
                          error2={
                            this.state.validState.error.annualAssociateFee30M
                          }
                        />

                        <Row3Input
                          className="text-base text-[#757575]"
                          caption="Revenue <$30M to $50M"
                          col1value={
                            this.state.membershipPricing.annualOrdinaryFee50M
                          }
                          col2value={
                            this.state.membershipPricing.annualAssociateFee50M
                          }
                          col1FieldName="annualOrdinaryFee50M"
                          col2FieldName="annualAssociateFee50M"
                          col1InputType="Money"
                          col2InputType="Money"
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                          error1={
                            this.state.validState.error.annualOrdinaryFee50M
                          }
                          error2={
                            this.state.validState.error.annualAssociateFee50M
                          }
                        />

                        <Row3Input
                          className="text-base text-[#757575]"
                          caption="Revenue >$50M"
                          col1value={
                            this.state.membershipPricing.annualOrdinaryFeeMax
                          }
                          col2value={
                            this.state.membershipPricing.annualAssociateFeeMax
                          }
                          col1FieldName="annualOrdinaryFeeMax"
                          col2FieldName="annualAssociateFeeMax"
                          col1InputType="Money"
                          col2InputType="Money"
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                          error1={
                            this.state.validState.error.annualOrdinaryFeeMax
                          }
                          error2={
                            this.state.validState.error.annualAssociateFeeMax
                          }
                        />
                      </div>
                    </div>
                  </SectionWithBorder>
                </div>
                <div className="">
                  <ChapterSection
                    col1value={this.state.membershipPricing.annualFreeChapter}
                    col2value={
                      this.state.membershipPricing.annualAdditionalChapterPrice
                    }
                    col1FieldName="annualFreeChapter"
                    col2FieldName="annualAdditionalChapterPrice"
                    col1InputType="Number"
                    col2InputType="Money"
                    handleChange={this.handleChange.bind(this)}
                    validateField={this.validateField.bind(this)}
                    validState={this.state.validState}
                    error1={this.state.validState.error.annualFreeChapter}
                    error2={
                      this.state.validState.error.annualAdditionalChapterPrice
                    }
                  />
                </div>
              </div>

              <div className="mt-8">
                <SectionWithBorder
                  column1="Start Up Membership Fee"
                  column2=""
                  column3=""
                >
                  <div className="px-7">
                    <div className="pr-24">
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="Entrance Fee"
                        col1InputType="Money"
                        col1value={
                          this.state.membershipPricing.startUpEntranceFee
                        }
                        col1FieldName="startUpEntranceFee"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.startUpEntranceFee}
                      />
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="1st Year from incorporation date"
                        col1InputType="Money"
                        col1value={
                          this.state.membershipPricing.startUp1stYearFee
                        }
                        col1FieldName="startUp1stYearFee"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.startUp1stYearFee}
                      />
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="2nd Year from incorporation date"
                        col1InputType="Money"
                        col1value={
                          this.state.membershipPricing.startUp2ndYearFee
                        }
                        col1FieldName="startUp2ndYearFee"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.startUp2ndYearFee}
                      />
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="3rd Year from incorporation date"
                        col1InputType="Money"
                        col1value={
                          this.state.membershipPricing.startUp3rdYearFee
                        }
                        col1FieldName="startUp3rdYearFee"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.startUp3rdYearFee}
                      />
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="4th Year from incorporation date"
                        col1InputType="Money"
                        col1value={
                          this.state.membershipPricing.startUp4thYearFee
                        }
                        col1FieldName="startUp4thYearFee"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.startUp4thYearFee}
                      />
                      <Row3Input
                        className="text-base text-[#757575]"
                        caption="5th Year from incorporation date"
                        col1InputType="Money"
                        col1value={
                          this.state.membershipPricing.startUp5thYearFee
                        }
                        col1FieldName="startUp5thYearFee"
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.startUp5thYearFee}
                      />
                    </div>
                  </div>
                </SectionWithBorder>

                <ChapterSection
                  col1value={this.state.membershipPricing.startUpFreeChapter}
                  col2value={
                    this.state.membershipPricing.startUpAdditionalChapterPrice
                  }
                  col1FieldName="startUpFreeChapter"
                  col2FieldName="startUpAdditionalChapterPrice"
                  col1InputType="Number"
                  col2InputType="Money"
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  validState={this.state.validState}
                  error1={this.state.validState.error.startUpFreeChapter}
                  error2={
                    this.state.validState.error.startUpAdditionalChapterPrice
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
          <NavLink to="/Dashboard" className="btn btn-gray text-xl text-white">
            {" "}
            Back
          </NavLink>
            <button
              className="text-lg font-bold btn btn-red text-white"
              onClick={() => this.addMembershipPricing()}
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}
