import React, { Component } from "react";
import OrganisationBasicInformation from "../../../components/membership/corporateMembers/OrganisationBasicInformation";
import EmployeesList from "../../../components/membership/corporateMembers/EmployeesList";
import EngagementTrackingNotes from "../../../components/membership/corporateMembers/EngagementTrackingNotes";
import ChapterInformation from "../../../components/membership/corporateMembers/ChapterInformation";
import FinancialsRecord from "../../../components/membership/corporateMembers/FinancialsRecord";
import OrganisationChapterRole from "../../../components/membership/corporateMembers/OrganisationChapterRole";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import SIISCorporateAccountInformation from ".././../../components/membership/corporateMembers/corporateMemberSegment/SIISCorporateAccountInformation";
import ProspectInformation from "../../../components/membership/corporateMembers/corporateMemberSegment/ProspectInformation";
import { validate, isValidForm, } from "../../../utils/validation/CommonValidator";
import SwalServices from "../../../services/swalServices/SwalServices";
import { CommonSuccessMessages, CommonValidationMessages, ErrorMessage, SuccessMessage } from "../../../utils/Messages";
import FinanceCard from "../../../components/membership/corporateMembers/corporateMemberSegment/FinanceCard";
import GovernmentProgramStrategicMember from "../../../components/membership/corporateMembers/corporateMemberSegment/GovernmentProgramStrategicMember";
import CorporateMembershipSubscription from "../../../components/membership/corporateMembers/corporateMemberSegment/CorporateMembershipSubscription";
import OrganisationBasicInformations from "../../../components/membership/corporateMembers/corporateMemberSegment/OrganisationBasicInformation";
import OrganisationProfile from "../../../components/membership/corporateMembers/corporateMemberSegment/OrganisationProfile";
import SGTechInterest from "../../../components/membership/corporateMembers/corporateMemberSegment/SGTechInterest";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";

import LoginToCorporateModal from "./LoginToCorporateModal";
import { setAuthProps } from "../../../utils/AuthenticationLibrary";
import {
  engagementTrackingValRules,
  organisationKeyRolesValRules,
  organizationProfileValRules,
  organizationBasicInformationValRules,
  officialRepInformationRules,
  chapterInformationValRules,
  memberSubscriptionValRules,
  prospectInformationValRules,
  siisAccountInformationValRules,
  organizationEmployeesValRules,
  financeCardValRules,
  addEditCreditNoteValRules,
  addEditCreditNoteValRules2,
  validationRuleForGoGlobal,
  searchByEmailValRule,
  purchaseChapterValidationRule,
} from "./CorporateValidations";
import moment from "moment";
import { saveAs } from 'file-saver';
import FinanceServices from "../../../services/axiosServices/apiServices/FinanceServices";
import { convertToDecimal, isNullBoolean, isNullString } from "../../../utils/Utils";
import { monthDifference } from "../../../utils/DateUtils";
import { checkParameters } from "../../../utils/Encryption";
import GoGlobalInforamtion from "../../../components/membership/corporateMembers/corporateMemberSegment/GoGlobalInformation";
import GoGlobalServices from "../../../services/axiosServices/apiServices/GoGlobalService";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import { WebPath } from "../../../utils/AppSetting";
import OfficialRepInformation from "../../../components/membership/corporateMembers/corporateMemberSegment/OfficialRepInformation";


export default class CreateCorporateMembers extends Component {
  constructor(props) {
    super(props);
    this.corporateMemberServices = new CorporateMemberServices();
    this.financeServices = new FinanceServices();
    this.commonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.goGlobalServices = new GoGlobalServices();
    this.financeService = new FinanceServices();

    this.state = {
      availableChapterListForUpcomingTenure: [],
      setLoginToCorporateModal: false,
      isLoadingForJoinChapter: false,
      adminRoleId: 0,
      isLoading: false,
      isLoadingForRenewal: false,
      Organisationrole: [],
      chaptercommitteerol: [],
      chapterCommitteeRole: [],
      openSection: "SIISAccountInformation",
      mrMrs: [],
      targetMarket: [],
      status: [],
      reason: [],
      _by: [],
      recorded_by: [],
      source1: [],
      prospectStatus: [],
      recordType: [],
      billingCategory: [],
      sgTech: [],
      sponsorship: [],
      matching: [],
      programs: [],
      engagementType: [],
      chaptersList: [],
      emailDomain: [],
      domainArr: [],
      membershipTypes: [],
      countriesWithPresence: [],
      coProductSoftware: [],
      coProductService: [],
      businessSector: [],
      areaOfExpertise: [],
      coProductHardware: [],
      corporateGrossTurnovers: [],
      organizationRoleArray: [],
      corporateInternalFlags: [],
      countriesSingleDrp: [],
      prospectSource2: [],
      corporateMemberId: 0,
      authorizedCaps: [],
      shareHoldingLocals: [],
      revFromOutsideSG: [],
      paidUpCaps: [],
      shareHoldingForeign: [],
      Organisationemail: [],
      companyEmploymentSize: [],
      corporatePIC1: [],
      corporatePIC2: [],
      corporateCreditTerms: [],
      interestedChapter: [],
      getToKnowUsArr: [],
      mainGSTRate: 0,
      corporateSIISAccount: {
        corporateSIISAccountId: 0,
        isAccountActive: 0,
        corporateReasonLeavingId: 0,
        corporateJoinedDate: "",
        updatedDate: "",
        updatedById: 0,
        corporateProspectSourceId: 0,
        corporateProspectStatusId: 0,
        corporateMemberRecordTypeId: 0,
        corporateMemberBillingCategoryId: 0,
        corporateSGTechId: 0,
        corporateSponsorshipId: 0,
        corporateMatchingId: 0,
        corporateStrategicProgrammesId: 0,
        adHocActivityEvent: false,
        monthlySGTechEdition: false,
        sGTechAnnouncements: false,
        fortnightlyEvents: false,
        businessOpportunities: false,
        talentCapabilities: false,
        corporateMemberId: 0,
        selectedSGTech: [],
        selectedSponsorship: [],
        selectedMatching: [],
        selectedProgram: [],
      },
      siisAccountInformation: {
        corporateSIISAccountId: 0,
        siisAccountId: "",
        engagementScore: 0,
        accountStatus: 7,
        corporatejoinedDate: null,
        corporateReasonLeavingId: 0,
        renewalNotes: "",
        updatedAt: null,
        createdBy: 1,
        internalFlagId: 0,
        companyDomain: "",
        quotationNeeded: false,
        sendInvoiceReminder: 0,
        creditTermsId: 0,
        stratigicMember: false,
        projectsInvolved: 0,
        isFeatured: false,
        projectName: "",
        isGoGlobal: false,
      },
      chapterEmailId:0,
      chapterInformation: {
        workgroupMappingId: 0,
        corporateMemberId: 0,
        availableChapters: 0,
        joinedChapters: 0,
        updatedBy: 1,
        updatedAt: null,
        purchasedChapters: 0,
        email: '',
        emailId: 0,
        isEditTrue:false
        // startDate: '',
        // endDate: '',
      },
      setBuyChapterModal: false,
      chapterListArr: [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
      ],
      paymentsMethods: [
        // {
        //   id: 1,
        //   name: "Master Card",
        // },
        {
          id: 2,
          name: "Cheque",
        },
        {
          id: 3,
          name: "Pay Now",
        },
        {
          id: 4,
          name: "Bank Transfer",
        },
      ],
      purchaseChapter: {
        noOfAdditionalChapters: 0,
        availableChapter: 0,
        additionalChapterPrice: null,
        subTotalAmount: 0,
        gstAmount: 0,
        discountedAmount: 0,
        totalAmount: 0,
        invoicePath: "",
        paymentVia: "",
        paymentStatus: 0,
        paymentViaId: 0,
        voucherCode: "",
        previousSubTotalAmount: 0,
        paymentsCreditsApplied: 0,
        netTotal: 0,
        poNumber: "",
        startDate: '',
        endDate: '',
        membershipTypeId: 0,
        membershipTypeName: '',
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
      },
      responseMessage: "",
      voucherCode: "",
      discountedAmount: 0,
      isFixedPrice: false,
      loadingForPayment: false,
      buyChapterValidState: {
        isValid: true,
        error: {},
      },
      chapterList: [],
      deletedChapterList: [],
      validStateEmp: {
        isValid: true,
        error: {},
      },
      engagementTrackingList: [],
      employeeList: [],
      // isEditEmployee: false,
      isEmployeeModalEditable: false,
      // isCreateNewEmployee: false,
      nameSearch: "",
      isLoaderOn: false,
      validState: {
        isValid: true,
        error: {},
      },
      validStateOrg: {
        isValid: true,
        error: {},
      },
      validStateSIISInfo: {
        isValid: true,
        error: {}
      },

      validStateChapter: {
        isValid: true,
        error: {}
      },

      validMemberSub: {
        isValid: true,
        error: {}
      },

      validProspectMem: {
        isValid: true,
        error: {}
      },

      validSiisAccountInfo: {
        isValid: true,
        error: {}
      },

      validOrgBasicInfo: {
        isValid: true,
        error: {}
      },

      validRepOfficialInfo: {
        isValid: true,
        error: {},
      },

      validStateFinanceCard: {
        isValid: true,
        error: {}
      },
      disableUenNumber: false,
      organizationBasicInformation: {
        organisationInformationId: 0,
        companyUEN: "",
        companyName: "",
        incorporationDate: "",
        designation: "",
        companyDomain: "",
        brandName: "",
        // selectedDesignation: [],
        // firstName: "",
        // lastName: "",
        // email: "",
        // contactNo: "",
        // mrMrsId: 0,
      },

      repOfficialInformation: {
        organisationInformationId: 0,
        firstName: "",
        lastName: "",
        emailID: "",
        contactNo: "",
        mrMrsId: 0,
        selectedDesignation: [],
      },
      designationList: [],
      organizationProfile: {
        organisationProfileId: 0,
        logoImage: "",
        orgGrossTurnoverId: 0,
        companyDescription: "",
        businessContactNumber: "",
        website: "",
        businessEmail: "",
        mailingAddress: "",
        mailingAddress2: "",
        mailingAddress3: "",
        countryId: 0,
        city: "",
        postalCode: "",
        linkedIn: "",
        selectedCountries: "",
        selectedSoftwares: "",
        selectedSectors: "",
        selectedServices: "",
        selectedExpertise: "",
        selectedHardwares: "",
        updatedBy: 0,
        updatedAt: "",
        selectedCountriesArr: [],
        selectedServicesArr: [],
        selectedHardwaresArr: [],
        selectedExpertiseArr: [],
        selectedSoftwaresArr: [],
        selectedSectorsArr: [],
      },
      prospectInformation: {
        corporateProspectId: 0,
        source1Id: 0,
        source2Id: 0,
        source3: "",
        pic1Id: 0,
        pic2Id: 0,
        firstEngagementDate: null,
        prospectStatusId: 0,
        updatedBy: 0,
        updatedAt: null,
        comments: "",
        getToKnow: "",
        interestedChaptersId: "",
        selectedInterestChapterArr: [],

      },
      organizationBusinessDetails: {
        organisationBusinessDetailId: 0,
        authorizedCapId: 0,
        shareHoldingLocalId: 0,
        revFromOutsideSGId: 0,
        paidUpCapId: 0,
        shareHoldingForeignId: 0,
        staffStrengthId: 0,
        targetMarketId: 0,
        updatedBy: 0,
        updatedAt: null,
        selectedTargetMarketIds: "",
        selectedTargetMarketArr: []
      },
      membershipSubscription: {
        corporateMemberSubscriptionId: 0,
        membershipTypeId: 0,
        billingCategoryId: 1,
        subscriptionStartDate: null,
        subcriptionEndDate: null,
        updatedBy: 0,
        updatedAt: null,
        incorporationDate: "",
        membershipRevenueId: 0,
        tacFromId: 0,
        tacName: '',
        isAligibleForNewApplication: false
      },
      sgTechInterest: {
        selectedSGTechArr: [],
        selectedSponsorshipArr: [],
        selectedMatchingArr: [],
        selectedProgramArr: [],
        selectedSGTechIds: "",
        selectedSponsorshipIds: "",
        selectedMatchingIds: "",
        selectedProgramIds: "",
        updatedBy: 0,
        updatedAt: null
      },
      corporateCommunication: {
        corporateCommunicationId: 0,
        adHocActivityEvent: true,
        monthlySGTechEdition: true,
        sGTechAnnouncements: true,
        fortnightlyEvents: true,
        businessOpportunities: true,
        talentCapabilities: true,
        updatedBy: 0,
        updatedAt: null
      },
      employeeDetails: {
        mrMrsId: 1,
        individualMemberId: 0,
        lastName: "",
        firstName: "",
        email: "",
        emailText: "",
        title: "",
        designation: "",
        contactNo: "",
        corporateMemberId: 0,
        emailDomainId: 0,
        emailString: "",
        selectedDesignation: [],
        searchByEmail: '',
      },
      organisationDetails: {
        //organisationRoleId: 0,
        individualMemberId: 0,
        mrMrs: 1,
        firstName: "",
        lastName: "",
        email: "",
        contactNo: "",
        designation: "",
        organisationRole: 0,
        corporateMemberId: 0,
        emailDomainId: 0,
        emailText: "",
        emailString: "",
        selectedDesignation: [],
        selectedOrganisationRole: [],
        organisationRoleList: [],
        searchByEmail: ''
      },
      engagementTrackingDetails: {
        corporateEngagementTrackingId: 0,
        corporateEngagementTypeId: 0,
        corporateEngagementDate: "",
        engagementDate: "",
        engagementDescription: "",
        corporateEngagementDescription: "",
        corporateEngagementRecordedById: 0,
        corporateEngagementFollowUpById: 0,
        corporateMemberId: 0,
        updatedAt: "",
        updatedBy: 0,
      },
      corporateFinance: {
        quotationNeeded: false,
        sendInvoiceReminder: true,
        creditTermsId: 1,
        financeId: 0,
        corporateMemberId: 0,
      },
      governmentProgramme: {
        stratigicMember: true,
        projectsInvolved: 0,
        governmentProgrammeId: 0,
        corporateMemberId: 0,
        projectName: "",
      },
      engagemmentTrackingList: [],
      organisationRoleArray: [],
      validStateProfile: {
        isValid: true,
        error: {},
      },
      validStateRoles: {
        isValid: true,
        error: {},
      },
      activeProjects: [],
      futureProjects: [],
      pastProjects: [],
      govProjectsNameList: [],
      corporateFinanceInfo: [],
      corporateCreditNotePaymentInfo: {},
      cancellationReason: [],
      selectedCancellationReason: [],
      isOtherCancellationReason: false,
      addEditCreditNote: {
        membershipPaymentId: 0,
        entranceRefund: 0,
        annualRefund: 0,
        chapterRefund: 0,
        subTotalRefund: 0,
        gstRefund: 0,
        discountRefund: 0,
        totalRefund: 0,
        cancellationReason: "",
        creditNoteMonth: "",
        compareEntranceRefund: 0,
        compareAnnualRefund: 0,
        compareChapterRefund: 0,
        membershipPaymentTypeId: 0,
        paidTotalAmount: 0
      },
      gstRate: 0,
      withoutGstRate: 1.07,
      addEditCreditNoteRules: [],
      validCreditNote: {
        isValid: true,
        error: {}
      },
      validCreditNote2: {
        isValid: true,
        error: {}
      },
      addEditOrganisationModel: false,
      isAddEditAOrganisation: false,
      isAddEditProfileButtonLoader: false,
      isCreateNewOrgRole: false,
      isCreateNewOrgRoleForEmployee: false,
      isOrganisationModalEditable: false,
      searchByEmployeeName: "",
      addEditModel: false,
      addEditModelForEmployee: false,
      // Go Global
      goGlobalDetails: {
        corporateMemberId: 0,
        goGlobalMemberId: 0,
        //companyTypeId: "",
        companyName: "",
        companyDescription: "",
        companyWebsite: "",
        region: "",
        city: "",
        serviceProvided: "",
        countryName: "",
        countryId: 0,
        businessSector: "",
        typeOfPartner: "",
        goGlobalContactName: "",
        goGlobalContactEmail: "",
        companyLogo: "",
        postalCode: "",
        isGoGlobalAccount: true,
        selectedBusinessSector: [],
        selectedServiceProvided: [],
        selectedPartnerType: [],
        selectedRegion: [],
        selectedCountryName: [],
        selectedCity: [],
        companyDomain: "",
        companyTypeId: 0,
        organisationUENLicenseNumberVATID: "",
        countryWithPresence: "",
        businessContactNumber: "",
        companyAddress: "",
        firstName: "",
        lastName: "",
        selectedCountryWithPresence: [],
      },
      companyType: [],
      countryWithPresence: [],
      countryNameList: [],
      businessSectorList: [],
      //providedServiceList: [],
      serviceProvidedList: [],
      partnerTypeList: [],
      regionList: [],
      cityNameList: [],
      isSelectedGoGlobal: false,
      charLimit: 1000,
      countCompanyDescription: 0,
      countCompanyDescriptionForSIIS: 0,
      validStateForGoGlobal: {
        isValid: true,
        error: {},
      },

      tacNames: [],
      // isLoginToCorporateAllowed: true,
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let corporateId = params.get("")
    let loginUser = getAuthProps();
    if (loginUser?.adminUserId && loginUser.adminUserId > 0) {
      this.setState({ adminRoleId: loginUser.adminUserId });
    } else {
      this.setState({ adminRoleId: 0 });
    }
    if (corporateId && (corporateId = checkParameters(corporateId, "Number"))) {
      if (corporateId != null && corporateId > 0) {
        this.setState({ corporateMemberId: corporateId }, () => { this.setState({ disableUenNumber: true }); });
      }
    }
    this.getAllDropdownForCorporateMember();
    this.getAllDropDownsForGoGlobalMembers();
    this.getGSTRate();
  }

  getGSTRate = () => {
    this.financeServices.getGSTRate().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let data = response.responseItem.responseContent.gstRate ? response.responseItem.responseContent.gstRate : 0;
        this.setState({ mainGSTRate: data }, () => {
        });
      } else {
      }
    });
  }

  getEmployeeList = (id, nameSearch) => {
    if (id > 0 && id != null) {
      let request = [id, nameSearch]
      this.corporateMemberServices.getEmployeeList(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          this.setState({ employeeList: response.responseItem.responseContent });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getOrganisationRoleList = (id) => {
    if (id > 0 && id != null) {
      this.corporateMemberServices.getOrganisationRoleList(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          this.setState({ Organisationrole: response.responseItem.responseContent }, () => {
            this.getChapterCommitteeRoles(this.state.corporateMemberId);
          });
        }
        else {
          this.getChapterCommitteeRoles(this.state.corporateMemberId);
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getChapterCommitteeRoles = (id) => {
    if (id > 0 && id != null) {
      this.corporateMemberServices.getChapterCommitteeRoles(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          this.setState({ chaptercommitteerol: response.responseItem.responseContent });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  handleChangeCheckBoxFinanceCard = (e) => {
    const { name, checked } = e.target;
    let det = this.state.corporateFinance;
    det[name] = checked;
    this.setState({ corporateFinance: { ...det } });
  };
  handleChangeCheckBoxGovProgram = (e) => {
    const { name, checked } = e.target;
    let det = this.state.governmentProgramme;
    det[name] = checked;
    this.setState({ governmentProgramme: det });
  };



  setFilterParametersFinanceCard = (id, drpIdentity) => {
    let request = this.state.corporateFinance;

    if (drpIdentity === "CreditTerms") {
      request["creditTermsId"] = id;
      this.validateFinanceCard("creditTermsId");
    }
    this.setState({ corporateFinance: request });
  }
  setFilterParametersEmployee = (id, drpIdentity) => {
    let request = this.state.employeeDetails;

    if (drpIdentity === "mrMrsId") {
      request["mrMrsId"] = id;
    }
    this.setState({ employeeDetails: request });
  }

  addEditFinanceCardDetails = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      var request = this.state.corporateFinance;
      request.corporateMemberId = this.state.corporateMemberId;
      request.createdBy = 1;
      request.createdAppId = 115;
      if (this.isValidFinanceCard()) {
        this.setState({ isLoading: true });
        this.corporateMemberServices.addEditFinanceCardDetails(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success("Finance details updated successfully.");
          }
          else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        })
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  addEditGovernmentProgramme = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      var request = this.state.governmentProgramme;
      request.corporateMemberId = this.state.corporateMemberId;
      request.createdBy = 1;
      request.createdAppId = 115;
      this.setState({ isLoading: true });
      this.corporateMemberServices.addEditGovernmentProgramme(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Government program details updated successfully.");
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  getGovernmentProgrammeDetailByProjectId = (corporateMemberId) => {
    //Akshay
    if (corporateMemberId > 0 && corporateMemberId != null) {
      this.corporateMemberServices.getGovernmentProgrammeDetailByProjectId(corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          if (response.responseItem.responseContent.projects.length > 0) {
            let activeArr = [];
            let pastArr = [];
            let futureArr = [];
            let active = [];
            let past = [];
            let future = [];
            response.responseItem.responseContent.projects.forEach(project => {

              if (project['activeProjectname'] !== null) {
                active.activeProjectname = project.activeProjectname;
                active.startDate = project.projectStartDate;
                active.endDate = project.projectEndDate;
                activeArr.push(active);
                active = [];

              }
              if (project['futureProjectname'] !== null) {

                future.futureProjectname = project.futureProjectname;
                future.startDate = project.projectStartDate;
                future.endDate = project.projectEndDate;
                futureArr.push(future);
                future = [];

              }
              if (project['pastProjectname'] !== null) {

                past.pastProjectname = project.pastProjectname;
                past.startDate = project.projectStartDate;
                past.endDate = project.projectEndDate;
                pastArr.push(past);
                past = [];

              }
            });
            this.setState({
              govProjectsNameList: response.responseItem.responseContent,
              activeProjects: activeArr, futureProjects: futureArr,
              pastProjects: pastArr
            });
          }
          this.setState({
            govProjectsNameList: response.responseItem.responseContent,
          });

        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }
  ///////////////////////////////////////

  getAllDropdownForCorporateMember = () => {
   
    this.corporateMemberServices.getAllDropdownForCorporateMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let status = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "AccountStatus"
        );
        let reason = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ReasonForLeaving"
        );
        let _by = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "By"
        );
        let source1 = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ProspectSource1"
        );
        let prospectStatus = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ProspectStatus"
        );
        let recordType = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "RecordType"
        );
        let billingCategory = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "BillingCategory"
        );
        let domain = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Domains"
        );
        let domainArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Domains"
        );
        let tac = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "TACNames"
        );
        let membershipTypes = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipTypes"
        );
        let corporateInternalFlags = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporateInternalFlags"
        );
        let countriesSingleDrp = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Countries"
        );
        let source2 = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ProspectSource2"
        );
        let authorizedCaps = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "AuthorizedCap"
        );
        let shareHoldingLocals = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ShareHoldingLocal"
        );
        let revFromOutsideSG = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "RevFromOutsideSG"
        );
        let paidUpCaps = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "PaidUpCap"
        );
        let shareHoldingForeign = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ShareHoldingForeign"
        );
        let companyEmploymentSize = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CompanyEmploymentSize"
        );
        let corporatePIC1 = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporatePIC1"
        );
        let corporatePIC2 = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporatePIC2"
        );
        let corporateCreditTerms = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporateCreditTerms"
        );
        let getToKnowUsArr = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "GetToKnowUs");


        //let sgTech = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "SGTech");
        //let sponsorship = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Sponsership");
        //let matching = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Matching");
        // let program = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "StrategicProgrammes");

        // let domainArr = response.responseItem.responseContent.filter(
        //   (drp) => drp.dropdownName === "Domains"
        // );
        domainArr.map(function (item) {
          return delete item["dropdownName"];
        });
        domainArr = domainArr.map((p) => ({
          id: p.id,
          name: "@" + p.name,
        }));

        let sgTech = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "SGTech"
        );
        sgTech.map(function (item) {
          return delete item["dropdownName"];
        });
        sgTech = sgTech.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let sponsorship = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Sponsership"
        );
        sponsorship.map(function (item) {
          return delete item["dropdownName"];
        });
        sponsorship = sponsorship.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let matching = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Matching"
        );
        matching.map(function (item) {
          return delete item["dropdownName"];
        });
        matching = matching.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let program = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "StrategicProgrammes"
        );
        program.map(function (item) {
          return delete item["dropdownName"];
        });
        program = program.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let engagement = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "EngagementType"
        );
        let chapter = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Chapters"
        );

        let interestedChapter = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Chapters');
        interestedChapter.map(function (item) { return delete item['dropdownName']; });
        interestedChapter = interestedChapter.map(p => ({ value: p.id, label: p.name }));


        let mrMrs = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MrMrs"
        );

        let countries = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Countries"
        );
        countries.map(function (item) {
          return delete item["dropdownName"];
        });
        countries = countries.map((p) => ({ value: p.id, label: p.name }));

        let coProductSoftware = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CoProductSoftwares");
        coProductSoftware.map(function (item) {
          return delete item["dropdownName"];
        });
        coProductSoftware = coProductSoftware.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let business = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "BussinessSectors");
        business.map(function (item) {
          return delete item["dropdownName"];
        });
        business = business.map((p) => ({ value: p.id, label: p.name }));

        let coService = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CoProductServices");
        coService.map(function (item) {
          return delete item["dropdownName"];
        });
        coService = coService.map((p) => ({ value: p.id, label: p.name }));

        let areaOfExp = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AreaOfExpertise");
        areaOfExp.map(function (item) {
          return delete item["dropdownName"];
        });
        areaOfExp = areaOfExp.map((p) => ({ value: p.id, label: p.name }));

        let coProductHardware = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CoProductHardware");
        coProductHardware.map(function (item) {
          return delete item["dropdownName"];
        });
        coProductHardware = coProductHardware.map((p) => ({ value: p.id, label: p.name }));

        let corporateGrossTurnovers = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporateGrossTurnovers"
        );

        let designation = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Designation");
        designation.map(function (item) {
          return delete item["dropdownName"];
        });
        designation = designation.map((p) => ({ value: p.id, label: p.name }));

        let organisationRoles = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "OrganisationRoles");
        organisationRoles.map(function (item) {
          return delete item["dropdownName"];
        });
        organisationRoles = organisationRoles.map((p) => ({ value: p.id, label: p.name }));

        let target = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "TargetMarket");
        target.map(function (item) {
          return delete item["dropdownName"];
        });
        target = target.map((p) => ({ value: p.id, label: p.name }));

        let recorded_by = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "By"
        );

        recorded_by.map(function (item) {
          return delete item["dropdownName"];
        });
        recorded_by = recorded_by.map((p) => ({ value: p.id, label: p.name }));

        this.setState({
          targetMarket: target, status: status, reason: reason, _by: _by, source1: source1,
          prospectStatus: prospectStatus, recordType: recordType, billingCategory: billingCategory,
          engagementType: engagement, sgTech: sgTech, sponsorship: sponsorship, matching: matching,
          programs: program, chaptersList: chapter, mrMrs: mrMrs, countriesWithPresence: countries,
          coProductSoftware: coProductSoftware, areaOfExpertise: areaOfExp, coProductService: coService,
          businessSector: business, organizationRoleArray: organisationRoles, membershipTypes: membershipTypes,
          corporateInternalFlags: corporateInternalFlags, corporateGrossTurnovers: corporateGrossTurnovers,
          coProductHardware: coProductHardware, countriesSingleDrp: countriesSingleDrp, prospectSource2: source2,
          authorizedCaps: authorizedCaps, shareHoldingLocals: shareHoldingLocals, revFromOutsideSG: revFromOutsideSG,
          paidUpCaps: paidUpCaps, shareHoldingForeign: shareHoldingForeign, companyEmploymentSize: companyEmploymentSize,
          corporatePIC1: corporatePIC1, corporatePIC2: corporatePIC2, corporateCreditTerms: corporateCreditTerms,
          interestedChapter: interestedChapter, getToKnowUsArr: getToKnowUsArr, designationList: designation,
          recorded_by: recorded_by, tacNames: tac
        }, () => {
          if (this.state.corporateMemberId > 0) {
            this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);

            // this.getGovernmentProgrammeDetailByProjectId(this.state.corporateMemberId);
            this.setState({ emailDomain: domain }, () =>
              this.getDomainByOrganisationId(this.state.corporateMemberId),
              this.getEmailInformationById(this.state.corporateMemberId),
              this.getChapterInformationById(this.state.corporateMemberId),

            );
            this.setState({ domainArr: domainArr }, () =>
              this.getDomainByOrganisationId(this.state.corporateMemberId),
              this.getEmailInformationById(this.state.corporateMemberId)
            );
          }
        });
      } else {
        if (this.state.corporateMemberId > 0) {
          this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
          this.setState({ emailDomain: [] }, () =>
            this.getDomainByOrganisationId(this.state.corporateMemberId)
          );
          this.setState({ domainArr: [] }, () =>
            this.getDomainByOrganisationId(this.state.corporateMemberId)
          );
        }
        //this.swalServices.Error(response.message);
      }
    });
  };

  validateOrganisationProfile = (key) => {
    const returnValidState = validate(key, this.state.organizationProfile, organizationProfileValRules, this.state.validStateProfile);
    this.setState({ validStateProfile: returnValidState });
  }

  isValidOrganisationProfile = () => {
    const returnValidState = isValidForm(this.state.organizationProfile, organizationProfileValRules, this.state.validStateProfile);
    this.setState({ validStateProfile: returnValidState });
    return returnValidState.isValid;
  }

  validateOrganisationKeyRoles = (key) => {
    const returnValidState = validate(key, this.state.organisationDetails, organisationKeyRolesValRules, this.state.validStateRoles);
    this.setState({ validStateRoles: returnValidState });
  }
  validateRepKeyRoles = (key) => {
    const returnValidState = validate(key, this.state.repOfficialInformation, officialRepInformationRules, this.state.validStateRoles);
    this.setState({ validStateRoles: returnValidState });
  }

  validateSearchByEmailField = (key) => {
    const returnValidState = validate(key, this.state.organisationDetails, searchByEmailValRule, this.state.validStateRoles);
    this.setState({ validStateRoles: returnValidState });
    return returnValidState.isValid;
  }

  validateSearchByEmailFieldForEmployee = (key) => {
    const returnValidState = validate(key, this.state.employeeDetails, searchByEmailValRule, this.state.validStateEmp);
    this.setState({ validStateEmp: returnValidState });
    return returnValidState.isValid;
  }


  // validateSearchByEmailFieldForEmployeeDetails = (key) => {
  //   const returnValidState = validate(key, this.state.employeeDetails, searchByEmailValRule, this.state.validStateRoles);
  //   this.setState({ validStateRoles: returnValidState });
  //   return returnValidState.isValid;
  // }

  isValidOrganisationKeyRoles = () => {
    const returnValidState = isValidForm(this.state.organisationDetails, organisationKeyRolesValRules, this.state.validStateRoles);
    this.setState({ validStateRoles: returnValidState });
    return returnValidState.isValid;
  }

  validateEmployeeInfo = (key, object) => {
    const returnValidState = validate(key, this.state.employeeDetails, organizationEmployeesValRules, this.state.validStateEmp);
    this.setState({ validStateEmp: returnValidState, });
  };

  isValidateAllEmployeeInfo = () => {
    const returnValidState = isValidForm(this.state.employeeDetails, organizationEmployeesValRules, this.state.validStateEmp);
    this.setState({ validStateEmp: returnValidState });
    return returnValidState.isValid;
  };

  validateFinanceCard = (key) => {
    const returnValidState = validate(key, this.state.corporateFinance, financeCardValRules, this.state.validStateFinanceCard);
    this.setState({ validStateFinanceCard: returnValidState, });
  }

  isValidFinanceCard = () => {
    const returnValidState = isValidForm(this.state.corporateFinance, financeCardValRules, this.state.validStateFinanceCard);
    this.setState({ validStateFinanceCard: returnValidState });
    return returnValidState.isValid;
  };

  addEditSIISCorporateAccountInformation = () => {
    var request = this.state.siisAccountInformation;
    request.corporateMemberId = this.state.corporateMemberId;
    request.accountStatus = request.accountStatus === 0 ? 7 : request.accountStatus;
    request.createdBy = request.createdBy === 0 ? 1 : request.createdBy;
    request.createdAppId = 115;
    if (this.isValidSiisAccountInfo()) {
      this.setState({ isLoading: true });
      this.corporateMemberServices.addEditSIISCorporateAccountInformation(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Corporate SIIS Information"));
            this.setState({ corporateMemberId: response.responseItem.responseContent.keyId },
              () => this.getSIISCorporateAccountInformationById(response.responseItem.responseContent.keyId));
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Corporate SIIS Information"));
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  renewCorporateMember = () => {
    let request = {};
    let details = { ...this.state.membershipSubscription }
    request.corporateMemberId = this.state.corporateMemberId;
    request.membershipTypeId = details.membershipTypeId;
    request.revenueTypeId = details.membershipRevenueId;
    if (details.isAligibleForNewApplication === true) {
      request.transactionTypeId = 1;
      request.isAppliedForRenewal = 0
    } else {
      request.transactionTypeId = 2;
      request.isAppliedForRenewal = 1
    }
    request.membershipTenureId = 0;
    request.incorporationDate = details.incorporationDate;
    request.numberOfChapters = 0;
    this.setState({ isLoadingForRenewal: true });
    this.corporateMemberServices.renewCorporateMember(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent != null && response.responseItem.responseContent.errorMessage === "") {
          this.swalServices.Success("Applied for renewal successfully!");
          // NavigateWithData(Routes.MembershipPayment, { isRenewal: 1, }, [this.state.corporateMemberId]);
        } else if (response.responseItem.responseContent != null && response.responseItem.responseContent.errorMessage === "Task Exists") {
          this.swalServices.Error("Account details already exists in membership tasklist");
        }
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoadingForRenewal: false });
    });
  }





  addEditOfficialRefInformation = () => {

    if (this.state.corporateMemberId) {
      let BusinessEmail = this.state.Organisationemail.find(option => option.id === this.state.repOfficialInformation.emailID)?.name;

      var request = this.state.repOfficialInformation;
      request.CorporateMemberId = this.state.corporateMemberId;
      request.MrMrsId = this.state.repOfficialInformation.mrMrsId;
      request.RepresentativeFirstName = this.state.repOfficialInformation.firstName;
      request.RepresentativeLastName = this.state.repOfficialInformation.lastName;
      request.BusinessContactNumber = this.state.repOfficialInformation.contactNo;
      request.BusinessEmail = BusinessEmail;

      if (request.selectedDesignation && request.selectedDesignation.length > 0) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.designation = arr1.join([',']);
      } else {
        request.designation = "";
      }


      this.swalServices
        .Confirm(
          CommonSuccessMessages.Confirm_Edit.replace("Are you Sure?"),
          CommonSuccessMessages.Are_You_Sure.replace("Official Rep information"),
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        )
        .then((response) => {
          let resp = response;
          if (resp === true) {


            if (this.isValidRepInfo()) {
              this.setState({ isLoading: true });
              this.corporateMemberServices.addEditOfficialRepInformation(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                  if (response.responseItem.responseContent.errorMessage === '') {

                    this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Official Rep Information"));
                    this.getRepOfficialInformationById();
                    // this.getOrganisationRoleList(this.state.corporateMemberId);
                    // this.setState({corporateMemberId : response.responseItem.responseContent.keyId}, 
                    //   () => this.getSIISCorporateAccountInformationById(response.responseItem.responseContent.keyId));
                  }
                  else {
                    this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Rep Official information"));
                  }
                }
                else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
              })
            }
          }
          else {
            this.getRepOfficialInformationById();
          }
        });
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }









  }

  addEditOrganisationBasicInformation = () => {

    if (this.state.corporateMemberId) {
      var request = this.state.organizationBasicInformation;
      request.CorporateMemberId = this.state.corporateMemberId;
      // request.RepresentativeFirstName = this.state.organizationBasicInformation.firstName;
      // request.RepresentativeLastName = this.state.organizationBasicInformation.lastName;
      // request.BusinessContactNumber = this.state.organizationBasicInformation.contactNo;
      // request.BusinessEmail = this.state.organizationBasicInformation.email;
      request.companyDomain = "";
      request.createdBy = 1;
      request.createdAppId = 115;

      if (request.selectedDesignation && request.selectedDesignation.length > 0) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.designation = arr1.join([',']);
      } else {
        request.designation = "";
      }

      this.swalServices
        .Confirm(
          CommonSuccessMessages.Confirm_Edit.replace("Are you Sure?"),
          CommonSuccessMessages.Are_You_Sure.replace("Organisation basic information"),
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        )
        .then((response) => {
          let resp = response;
          if (resp === true) {


            if (this.isValidOrgBasicInfo()) {
              this.setState({ isLoading: true });
              this.corporateMemberServices.addEditOrganisationBasicInformation(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                  if (response.responseItem.responseContent.errorMessage === '') {
                    this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Organisation basic information"));
                    this.getOrganisationBasicInformationById();
                    this.getOrganisationRoleList(this.state.corporateMemberId);
                    // this.setState({corporateMemberId : response.responseItem.responseContent.keyId}, 
                    //   () => this.getSIISCorporateAccountInformationById(response.responseItem.responseContent.keyId));
                  }
                  else {
                    this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Organisation basic information"));
                  }
                }
                else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
              })
            }
          }
          else {
            this.getOrganisationBasicInformationById();
          }
        });
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  addEditOrganisationProfile = () => {
    if (this.state.corporateMemberId) {
      var request = this.state.organizationProfile;
      request.CorporateMemberId = this.state.corporateMemberId;
      request.selectedCountries = "";
      request.selectedSoftwares = "";
      request.selectedSectors = "";
      request.selectedServices = "";
      request.selectedExpertise = "";
      request.selectedHardwares = "";
      if (this.state.organizationProfile.selectedCountriesArr.length > 0) {
        // var arr1 = this.state.organizationProfile.selectedCountriesArr.map(function (item) {
        //   delete item.bad;
        //   return item.value;
        // });
        // request.selectedCountries = arr1.join([',']);
        var arr1 = []
        this.state.organizationProfile.selectedCountriesArr.forEach(item => {
          if (item && item.value) {
            arr1.push(item.value);
          }
        });
        if (arr1.length > 0) {
          request.selectedCountries = arr1.join([',']);
        }
      }
      if (this.state.organizationProfile.selectedSoftwaresArr.length > 0) {
        // var selectedSoftwares = this.state.organizationProfile.selectedSoftwaresArr.map(function (item) {
        //   delete item.bad;
        //   return item.value;
        // });
        // request.selectedSoftwares = selectedSoftwares.join([',']);
        var arr1 = []
        this.state.organizationProfile.selectedSoftwaresArr.forEach(item => {
          if (item && item.value) {
            arr1.push(item.value);
          }
        });
        if (arr1.length > 0) {
          request.selectedSoftwares = arr1.join([',']);
        }
      }
      if (this.state.organizationProfile.selectedSectorsArr.length > 0) {
        // var selectedSectors = this.state.organizationProfile.selectedSectorsArr.map(function (item) {
        //   delete item.bad;
        //   return item.value;
        // });
        // request.selectedSectors = selectedSectors.join([',']);
        var arr1 = []
        this.state.organizationProfile.selectedSectorsArr.forEach(item => {
          if (item && item.value) {
            arr1.push(item.value);
          }
        });
        if (arr1.length > 0) {
          request.selectedSectors = arr1.join([',']);
        }
      }
      if (this.state.organizationProfile.selectedServicesArr.length > 0) {
        // var selectedServices = this.state.organizationProfile.selectedServicesArr.map(function (item) {
        //   delete item.bad;
        //   return item.value;
        // });
        // request.selectedServices = selectedServices.join([',']);
        var arr1 = []
        this.state.organizationProfile.selectedServicesArr.forEach(item => {
          if (item && item.value) {
            arr1.push(item.value);
          }
        });
        if (arr1.length > 0) {
          request.selectedServices = arr1.join([',']);
        }
      }
      if (this.state.organizationProfile.selectedExpertiseArr.length > 0) {
        // var selectedExpertise = this.state.organizationProfile.selectedExpertiseArr.map(function (item) {
        //   delete item.bad;
        //   return item.value;
        // });
        // request.selectedExpertise = selectedExpertise.join([',']);
        var arr1 = []
        this.state.organizationProfile.selectedExpertiseArr.forEach(item => {
          if (item && item.value) {
            arr1.push(item.value);
          }
        });
        if (arr1.length > 0) {
          request.selectedExpertise = arr1.join([',']);
        }
      }
      if (this.state.organizationProfile.selectedHardwaresArr.length > 0) {
        // var selectedHardwares = this.state.organizationProfile.selectedHardwaresArr.map(function (item) {
        //   delete item.bad;
        //   return item.value;
        // });
        // request.selectedHardwares = selectedHardwares.join([',']);
        var arr1 = []
        this.state.organizationProfile.selectedHardwaresArr.forEach(item => {
          if (item && item.value) {
            arr1.push(item.value);
          }
        });
        if (arr1.length > 0) {
          request.selectedHardwares = arr1.join([',']);
        }
      }
      request.mailingAdress = this.state.organizationProfile.mailingAddress;
      request.mailingAdress2 = this.state.organizationProfile.mailingAddress2;
      request.mailingAdress3 = this.state.organizationProfile.mailingAddress3;
      request.logo = this.state.organizationProfile.logoImage;
      request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      request.createdAppId = 115;
      request.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      if (this.isValidOrganisationProfile()) {
        if (this.state.countCompanyDescriptionForSIIS <= this.state.charLimit) {
          this.setState({ isLoading: true });
          this.corporateMemberServices.addEditOrganisationProfile(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              if (response.responseItem.responseContent.errorMessage === '') {
                var res = this.state.organizationProfile;
                res.organisationProfileId = response.responseItem.responseContent.keyId;
                res.updatedAt = new Date();
                res.updatedBy = 1;
                this.setState({ organizationProfile: res }, () => {
                  this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Organisation profile"));
                  this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
                }
                );
              }
              else {
                this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Organisation profile"));
              }
            }
            else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          })
        } else {
          this.swalServices.Error('You reach the character enter limit');
        }
      }

    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  addEditOrganisationBussinessDetails = () => {
    if (this.state.corporateMemberId) {
      var request = this.state.organizationBusinessDetails;
      request.organisationBusinessDetailId = this.state.organizationBusinessDetails.organisationBusinessDetailId;
      request.authorizedCapId = this.state.organizationBusinessDetails.authorizedCapId;
      request.shareHoldingLocalId = this.state.organizationBusinessDetails.shareHoldingLocalId;
      request.revFromOutsideSGId = this.state.organizationBusinessDetails.revFromOutsideSGId;
      request.paidUpCapId = this.state.organizationBusinessDetails.paidUpCapId;
      request.shareHoldingForeignId = this.state.organizationBusinessDetails.shareHoldingForeignId;
      request.staffStrengthId = this.state.organizationBusinessDetails.staffStrengthId;
      request.corporateMemberId = this.state.corporateMemberId;
      request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      request.createdAppId = 115;
      //request.targetMarketId = this.state.organizationBusinessDetails.authorizedCapId;
      request.targetMarketIds = "";
      if (this.state.organizationBusinessDetails.selectedTargetMarketArr.length > 0) {
        var arr1 = this.state.organizationBusinessDetails.selectedTargetMarketArr.map(function (item) {
          delete item.bad;
          return item.value;
        });
        request.targetMarketIds = arr1.join([',']);
      }
      this.setState({ isLoading: true });
      this.corporateMemberServices.addEditOrganisationBussinessDetails(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            var res = this.state.organizationBusinessDetails;
            res.organisationBusinessDetailId = response.responseItem.responseContent.keyId;
            res.updatedAt = new Date();
            res.updatedBy = 1;
            this.setState({ organizationBusinessDetails: res }, () => {
              this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Organisation business details"));
              this.getOrganisationBussinessDetailsById();
            });
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Organisation business details"));
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  addEditCorporateMembershipSubscription = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {

      let request = this.state.membershipSubscription;
      request.corporateMemberSubscriptionId = this.state.membershipSubscription.corporateMemberSubscriptionId;
      request.membershipTypeId = this.state.membershipSubscription.membershipTypeId;
      request.billingCategoryId = this.state.membershipSubscription.billingCategoryId;
      request.subscriptionStartDate = this.state.membershipSubscription.subscriptionStartDate;
      request.subcriptionEndDate = this.state.membershipSubscription.subcriptionEndDate;
      request.corporateMemberId = this.state.corporateMemberId;
      request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      request.createdAppId = 115;
      request.tacFromId = this.state.membershipSubscription.tacFromId;
      request.tacName = this.state.membershipSubscription.tacName;
      if (this.isValidMemberSub()) {
        this.setState({ isLoading: true });
        this.corporateMemberServices.addEditCorporateMembershipSubscription(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === '') {
              var res = this.state.membershipSubscription;
              res.updatedAt = new Date();
              res.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
              res.corporateMemberSubscriptionId = response.responseItem.responseContent.keyId;
              this.setState({ membershipSubscription: res }, () => { this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Corporate membership subscription")); });
            }
            else {
              this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Corporate membership subscription"));
            }
          }
          else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        })
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  addEditCorporateProspect = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      let request = this.state.prospectInformation;
      request.corporateProspectId = this.state.prospectInformation.corporateProspectId;
      request.prospectSource1 = this.state.prospectInformation.source1Id;
      request.prospectSource2 = this.state.prospectInformation.source2Id;
      request.prospectSource3 = this.state.prospectInformation.source3;
      request.pIC1 = this.state.prospectInformation.pic1Id;
      request.pIC2 = this.state.prospectInformation.pic2Id;
      request.firstEngagementDate = this.state.prospectInformation.firstEngagementDate;
      request.prospectStatusId = this.state.prospectInformation.prospectStatusId;
      request.corporateMemberId = this.state.corporateMemberId;
      request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      request.createdAppId = 115;
      if (this.isValidPropectMem()) {
        this.setState({ isLoading: true });
        this.corporateMemberServices.addEditCorporateProspect(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === '') {
              var res = this.state.prospectInformation;
              res.corporateProspectId = response.responseItem.responseContent.keyId;
              res.updatedAt = new Date();
              res.updatedBy = 1;
              this.setState({ prospectInformation: res }, () => { this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Prospect information")); });
            }
            else {
              this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Prospect information"));
            }
          }
          else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        })
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  addEditSGTechIntrest = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      var request = this.state.sgTechInterest;
      request.corporateMemberId = this.state.corporateMemberId;
      request.corporateReasonId = "";
      request.corporateSponsorshipId = "";
      request.corporateMatchingId = "";
      request.corporateStrategicProgrammesId = "";
      if (this.state.sgTechInterest.selectedSGTechArr.length > 0) {
        var arr1 = this.state.sgTechInterest.selectedSGTechArr.map(function (item) {
          delete item.bad;
          return item.value;
        });
        request.corporateReasonId = arr1.join([',']);
      }
      if (this.state.sgTechInterest.selectedSponsorshipArr.length > 0) {
        var corporateSponsorshipId = this.state.sgTechInterest.selectedSponsorshipArr.map(function (item) {
          delete item.bad;
          return item.value;
        });
        request.corporateSponsorshipId = corporateSponsorshipId.join([',']);
      }
      if (this.state.sgTechInterest.selectedMatchingArr.length > 0) {
        var corporateMatchingId = this.state.sgTechInterest.selectedMatchingArr.map(function (item) {
          delete item.bad;
          return item.value;
        });
        request.corporateMatchingId = corporateMatchingId.join([',']);
      }
      if (this.state.sgTechInterest.selectedProgramArr.length > 0) {
        var corporateStrategicProgrammesId = this.state.sgTechInterest.selectedProgramArr.map(function (item) {
          delete item.bad;
          return item.value;
        });
        request.corporateStrategicProgrammesId = corporateStrategicProgrammesId.join([',']);
      }
      request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      request.createdAppId = 115;

      this.setState({ isLoading: true });
      this.corporateMemberServices.addEditSGTechIntrest(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            var res = this.state.sgTechInterest;
            res.updatedAt = new Date();
            res.updatedBy = 1;
            this.setState({ sgTechInterest: res }, () => {
              this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "SGTech Interest"));
              this.getSGTechIntrestById();
            });
            // var res = this.state.prospectInformation;
            // res.corporateProspectId = response.responseItem.responseContent.keyId;
            // this.setState({ prospectInformation: res }, () => { this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "SGTech Interest")); });
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "SGTech Interest"));
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  addEditCommunication = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      var request = this.state.corporateCommunication;
      request.corporateCommunicationId = this.state.corporateCommunication.corporateCommunicationId;
      request.adHocActivityEvent = this.state.corporateCommunication.adHocActivityEvent;
      request.monthlySGTechEdition = this.state.corporateCommunication.monthlySGTechEdition;
      request.sGTechAnnouncements = this.state.corporateCommunication.sGTechAnnouncements;
      request.fortnightlyEvents = this.state.corporateCommunication.fortnightlyEvents;
      request.businessOpportunities = this.state.corporateCommunication.businessOpportunities;
      request.talentCapabilities = this.state.corporateCommunication.talentCapabilities;
      request.corporateMemberId = this.state.corporateMemberId;
      request.createdBy = 1;
      request.createdAppId = 115;

      this.setState({ isLoading: true });
      this.corporateMemberServices.addEditCorporateCommunication(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            var res = this.state.corporateCommunication;
            res.corporateCommunicationId = response.responseItem.responseContent.keyId;
            this.setState({ corporateCommunication: res }, () => {
              this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Communication"));
              this.getCommunicationById();
            });
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Communication"));
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  addEditEmployeeInformation = () => {

    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      let request = this.state.employeeDetails;
      request.corporateMemberId = this.state.corporateMemberId;

      // let domain = this.state.emailDomain.find(
      //   (x) => x.id === request.emailDomainId
      // );
      // request.email = `${request.emailText}@${domain.name}`;
      request.email = request.emailText;

      if (request.selectedDesignation && request.selectedDesignation.length > 0) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.title = arr1.join([',']);
      } else {
        request.title = "";
      }

      if (this.isValidateAllEmployeeInfo()) {
        this.setState({ isAddEditProfileButtonLoader: true })
        this.corporateMemberServices.addEditEmployeeInformation(request).then((response) => {

          if (
            response.statusCode === 200 &&
            response.responseItem != null &&
            response.responseItem.responseContent.errorType === "EXISTS"
          ) {

            this.swalServices.Error(
              "Member already exists with same email address."
            );
            //this.setState({ addEditModel: false });
            this.setState({ addEditModelForEmployee: false });
          }
          else if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success(
              "Employee information inserted successfully."
            );
            this.getEmployeeList(this.state.corporateMemberId, "");
            this.getOrganisationRoleList(this.state.corporateMemberId);
            this.setState({ addEditModelForEmployee: false });
            //this.setState({ addEditModel: false });
          } else {
            this.swalServices.Error("Error");
          }
          this.setState({ isAddEditProfileButtonLoader: false })
        });
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  };

  resetEmployeeInfo = (email = '') => {
    let reset = this.state.employeeDetails;
    var domainId = this.state.emailDomain.find(
      (domain) => domain.id === this.state.corporateMemberId
    ).id;
    reset.mrMrsId = 0;
    reset.individualMemberId = 0;
    reset.lastName = "";
    reset.firstName = "";
    reset.emailText = email;
    reset.title = "";
    reset.designation = "";
    reset.contactNo = "";
    reset.emailDomainId = domainId;
    reset.selectedDesignation = [];
    reset.searchByEmail = "";

    let res = this.state.validStateEmp;
    res.isValid = true;
    res.error = {};

    this.setState({ employeeDetails: reset, validStateEmp: res });
  }

  addEditOraganisation = () => {
    if (this.state.corporateMemberId > 0) {
        if (this.isValidOrganisationKeyRoles()) {
            let request = this.state.organisationDetails;
            request.emailString = `${request.emailText}@abc.com`;
            request.corporateMemberId = this.state.corporateMemberId;
            request.email = request.emailText;
            if (request.selectedOrganisationRole && request.selectedOrganisationRole.length > 0) {
                const array = request.selectedOrganisationRole.map(item => {
                    const { bad, ...cleanItem } = item; 
                    return cleanItem.value; 
                });
                request.organisationRole = array.join(','); 
            } else {
                request.organisationRole = "";
            }
            request.organisationRoleList = request.selectedOrganisationRole;

            let request2 = this.state.chapterList;
            const result = request2.map(chapter => ({
                email: chapter.email,
                groupName: chapter.groupName
            }));

            const emailExists = result.some(item => item.email === request.email);

            const groupNameExists = request.selectedOrganisationRole.some(role =>
                result.some(item => item.groupName === role.label)
            );
            if (emailExists && groupNameExists) {
                if (emailExists) {
                    this.swalServices.Error("Member is already assigned as Main Chapter Representative.");
                }
                if (groupNameExists) {
                    this.swalServices.Error("Member is already assigned as Main Chapter Representative.");
                }
                return; 
            }

            if (request.selectedDesignation && request.selectedDesignation.length > 0) {
                const arr1 = request.selectedDesignation.map(item => {
                    const { bad, ...cleanItem } = item; 
                    return cleanItem.label; 
                });
                request.designation = arr1.join(','); 
            } else {
                request.designation = "";
            }

  
            this.setState({ isAddEditAOrganisation: true });

            this.corporateMemberServices.addEditOrganisation(request).then((response) => {
                if (response.statusCode === 200) {
                    if (response.responseItem && response.responseItem.responseContent.errorType === "EXISTS") {
                        this.setState({ addEditOrganisationModel: false });
                        this.swalServices.Error("Member already exists with the same email address.");
                    } else {
                        this.swalServices.Success("Employee information inserted successfully.");
                        this.setState({ addEditOrganisationModel: false });
                        this.getOrganisationRoleList(this.state.corporateMemberId);
                        this.getEmployeeList(this.state.corporateMemberId, "");

                        this.resetOrganisationDetails();
                    }
                } else {
                    this.swalServices.Error("Error");
                }
            });
        }
    } else {
        this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
};

resetOrganisationDetails = () => {
    const reset = {
        organisationRoleId: 0,
        mrMrs: "",
        firstName: "",
        lastName: "",
        email: "",
        contactNo: "",
        designation: "",
        organisationRole: "",
        selectedDesignation: [],
        selectedOrganisationRole: [],
        individualMemberId: 0,
        emailString: ""
    };
    this.setState({ organisationDetails: reset }, () => 
        this.getEmployeeList(this.state.corporateMemberId, "")
    );
};



  addEditEngagementTracking = () => {
    let request = this.state.engagementTrackingDetails;
    request.corporateEngagementDate = request.engagementDate;
    request.corporateMemberId = this.state.corporateMemberId;
    request.corporateEngagementDescription = request.engagementDescription;
    request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
    if (this.state.corporateMemberId) {
      if (this.isValidengagement()) {
        this.setState({ isLoading: true });
        this.corporateMemberServices.addEditEngagementTracking(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.getEngagementTrackingList(this.state.corporateMemberId);
            let details = this.state.engagementTrackingDetails;

            details.corporateEngagementTrackingId = 0;
            details.corporateEngagementTypeId = 0;
            details.corporateEngagementDate = "";
            details.engagementDate = "";
            details.engagementDescription = "";
            details.corporateEngagementDescription = "";
            details.corporateEngagementRecordedById = 0;
            details.corporateEngagementFollowUpById = 0;
            details.corporateMemberId = 0;
            details.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;

            this.swalServices.Success("Engagement inserted successfully.");
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  };

  getSIISCorporateAccountInformationById = (corporateMemberId) => {
    if (corporateMemberId > 0) {
      this.corporateMemberServices.getSIISCorporateAccountInformationById(corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let res = { ...this.state.siisAccountInformation };
          res.corporateSIISAccountId = response.responseItem.responseContent.corporateSIISAccountId;
          res.siisAccountId = response.responseItem.responseContent.siisAccountId;
          res.engagementScore = response.responseItem.responseContent.engagementScore;
          res.accountStatus = response.responseItem.responseContent.accountStatus;
          res.corporatejoinedDate = response.responseItem.responseContent.corporateJoinedDate;
          res.corporateReasonLeavingId = response.responseItem.responseContent.corporateReasonLeavingId;
          res.renewalNotes = response.responseItem.responseContent.renewalNotes;
          res.updatedAt = response.responseItem.responseContent.updatedDate;
          res.updatedBy = response.responseItem.responseContent.updatedBy;
          res.internalFlagId = response.responseItem.responseContent.internalFlagId;
          res.companyDomain = response.responseItem.responseContent.companyDomain;
          res.isFeatured = response.responseItem.responseContent.isFeatured;
          res.projectName = response.responseItem.responseContent.projectName;
          res.isGoGlobal = response.responseItem.responseContent.isGoGlobal;
          this.setState({ siisAccountInformation: res, isSelectedGoGlobal: response.responseItem.responseContent.isGoGlobal },
            () => {
              var chapInfo = this.state.chapterInformation

              chapInfo.availableChapters = response.responseItem.responseContent.availableChapters ? response.responseItem.responseContent.availableChapters : 0;
              chapInfo.joinedChapters = response.responseItem.responseContent.joinedChapters ? response.responseItem.responseContent.joinedChapters : 0;
              chapInfo.purchasedChapters = response.responseItem.responseContent.purchasedChapters ? response.responseItem.responseContent.purchasedChapters : 0;
              chapInfo.updatedBy = response.responseItem.responseContent.chapInformationUpdatedBy;
              chapInfo.updatedAt = response.responseItem.responseContent.chapInformationUpdatedDate;
              this.setState({ chapterInformation: chapInfo });

              var finance = this.state.corporateFinance;
              finance.creditTermsId = response.responseItem.responseContent.creditTermsId !== null ? response.responseItem.responseContent.creditTermsId : 1;
              finance.quotationNeeded = response.responseItem.responseContent.quotationNeeded;
              finance.sendInvoiceReminder = response.responseItem.responseContent.sendInvoiceReminder;

              this.setState({ corporateFinance: finance })

              var gov = this.state.governmentProgramme;
              gov.projectsInvolved = response.responseItem.responseContent.projectsInvolved;
              gov.stratigicMember = response.responseItem.responseContent.stratigicMember;
              gov.projectName = response.responseItem.responseContent.projectName;

              this.setState({ governmentProgramme: gov }, () => {
                this.getOrganisationRoleList(this.state.corporateMemberId);
                this.getRepOfficialInformationById(this.state.corporateMemberId);
                

              })
            });
        }
        else {
          this.getOrganisationRoleList(this.state.corporateMemberId);

          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getOrganisationBasicInformationById = (corporateMemberId) => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      this.corporateMemberServices.getOrganisationBasicInformationById(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.organizationBasicInformation };
            res.organisationInformationId = response.responseItem.responseContent.organisationInformationId;
            res.companyUEN = response.responseItem.responseContent.companyUEN;
            res.companyName = response.responseItem.responseContent.companyName;
            res.incorporationDate = response.responseItem.responseContent.incorporationDate;
            res.brandName = response.responseItem.responseContent.brandName;
            // res.firstName = response.responseItem.responseContent.representativeFirstName;
            // res.lastName = response.responseItem.responseContent.representativeLastName;
            // res.email = response.responseItem.responseContent.businessEmail;
            // res.contactNo = response.responseItem.responseContent.businessContactNumber;
            // res.mrMrsId = response.responseItem.responseContent.mrMrsId;

            if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
              var array = response.responseItem.responseContent.designation.split(',');
              var designation = [];
              array.forEach(topic => {
                let found = this.state.designationList.find(deg => deg.label === topic);
                if (found) {
                  designation.push(found);
                }
              });
              res.selectedDesignation = designation;
            }

            this.setState({ organizationBasicInformation: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }


  getRepOfficialInformationById = () => {

    const { corporateMemberId } = this.state;

    if (corporateMemberId <= 0) {
      return;
    }

    this.corporateMemberServices.getRepOfficialInformationById(corporateMemberId)
      .then(response => {
        const { statusCode, responseItem, message } = response;

        if (statusCode === 200 && responseItem?.responseContent !== null) {
          const {
            representativeFirstName,
            representativeLastName,
            businessContactNumber,
            businessEmail,
            mrMrsId,
            organisationInformationId,
          } = responseItem.responseContent;

          this.setState(prevState => {
            const BusinessEmailId = prevState.Organisationemail.find(option => option.name === businessEmail)?.id;

            if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
              var array = response.responseItem.responseContent.designation.split(',');
              var designation = [];
              array.forEach(topic => {
                let found = this.state.designationList.find(deg => deg.label === topic);
                if (found) {
                  designation.push(found);
                }
              });
            }
            if (response.responseItem.responseContent.title && response.responseItem.responseContent.title !== 0 && response.responseItem.responseContent.title !== "0") {
              let array1 = (response.responseItem.responseContent.title !== null || response.responseItem.responseContent.title !== "") ? response.responseItem.responseContent.title.split(',') : [];
              var industriesServedArray = [];
              const filteredArray = array1.filter(value => value !== "0");
              if (filteredArray && filteredArray.length > 0) {
                  filteredArray?.forEach(topic => {
                      industriesServedArray.push(this.state.designationList.find(topics => topics.value === Number(topic)));
                  });
              }
              //officialdata.selectedDesignation = industriesServedArray
          }

            return {
              repOfficialInformation: {
                ...prevState.repOfficialInformation,
                firstName: representativeFirstName,
                lastName: representativeLastName,
                contactNo: businessContactNumber,
                email: businessEmail,
                mrMrsId,
                organisationInformationId,
                emailID: BusinessEmailId,
                selectedDesignation : designation
              }
            };
          });
        } else {
          //this.swalServices.Error(message);
        }
      })
      .catch(error => {
        this.swalServices.Error("Failed to fetch representative information.");
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }
  getChapterInformation = (id) => {
    if (id > 0 && id != null) {

      this.corporateMemberServices.getChapterInformation(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          
            
            var chapterDetails = this.state.chapterInformation;
            chapterDetails.workgroupMappingId = response.responseItem.responseContent.workgroupMappingId
            chapterDetails.emailId = response.responseItem.responseContent.emailId
            chapterDetails.isEditTrue = response.responseItem.responseContent.isEditTrue


            this.setState({ chapterInformation: chapterDetails, chapterEmailId : response.responseItem.responseContent.emailId})
          
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
        this.getPurchaseChapterDataById();
      })
    }
  }
  getChapterInformationById = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      this.corporateMemberServices.getChapterInformationById(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.length > 0) {
            const deletedChapterList = response.responseItem.responseContent.filter(chapter => chapter.leftDate !== null);
            const joinedChapterList = response.responseItem.responseContent.filter(chapter => chapter.leftDate === null);
            var date = new Date(Math.max(...response.responseItem.responseContent.map(e => new Date(e.joinDate))));
            var date2 = new Date(Math.max(...response.responseItem.responseContent.map(e => new Date(e.leftDate))));
            var chapterDetails = this.state.chapterInformation;
            if (date > date2) {
              chapterDetails.updatedAt = date;
            }
            else {
              chapterDetails.updatedAt = date2;
            }
            chapterDetails.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
            this.setState({ chapterInformation: chapterDetails }, () =>
           
              this.setState({ chapterList: joinedChapterList, deletedChapterList: deletedChapterList }),
              this.getPurchaseChapterDataById()
            );
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
        this.getPurchaseChapterDataById();
      })
    }
  }

  getPurchaseChapterDataById = () => {
    this.corporateMemberServices.getPurchaseChapterDataById(this.state.corporateMemberId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        //let data = this.state.chapterInformation;
        //data.startDate = moment(data.startDate).format("DD MMM YYYY");
        //data.endDate = moment(data.endDate).format("DD MMM YYYY");
        let purchaseChap = { ...this.state.purchaseChapter };
        purchaseChap.startDate = moment(response.responseItem.responseContent.startDate).format("DD MMM YYYY");
        purchaseChap.endDate = moment(response.responseItem.responseContent.endDate).format("DD MMM YYYY");
        purchaseChap.additionalChapterPrice = response.responseItem.responseContent.additionalChapterPrice;
        purchaseChap.membershipTypeId = response.responseItem.responseContent.membershipTypeId;
        purchaseChap.membershipTypeName = response.responseItem.responseContent.membershipTypeName;
        purchaseChap.companyName = response.responseItem.responseContent.companyName;
        purchaseChap.firstName = response.responseItem.responseContent.firstName;
        purchaseChap.lastName = response.responseItem.responseContent.lastName;
        purchaseChap.email = response.responseItem.responseContent.email;
        this.setState({
          purchaseChapter: purchaseChap
          //, chapterInformation : data
        }, () => {
          this.getAllAvailableChapterListForUpcomingTenure();
        });
      }
      else {
        this.swalServices.Error(response.message);
        this.getAllAvailableChapterListForUpcomingTenure();
      }
      this.setState({ isLoading: false });
    })
  }
  getAllAvailableChapterListForUpcomingTenure = () => {
    if (this.state.corporateMemberId && this.state.corporateMemberId > 0) {
      this.corporateMemberServices.getAllAvailableChapterListForUpcomingTenure(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem !== null) {
          if (response.responseItem.responseContent !== null && response.responseItem.responseContent.length > 0) {
            this.setState({ availableChapterListForUpcomingTenure: response.responseItem.responseContent })
          }
        } else {
          this.swalServices.Error(response.message);
        }
      })
    }
  }
  getOrganisationProfileById = (corporateMemberId) => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {

      this.corporateMemberServices.getOrganisationProfileById(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let res = { ...this.state.organizationProfile };
          res.organisationProfileId = response.responseItem.responseContent.organisationProfileId;
          res.businessEmail = response.responseItem.responseContent.businessEmail;
          res.city = response.responseItem.responseContent.city;
          res.companyDescription = response.responseItem.responseContent.companyDescription;
          res.countryId = response.responseItem.responseContent.countryId;
          res.updatedAt = response.responseItem.responseContent.lastUpdatedDate;
          res.linkedIn = response.responseItem.responseContent.linkedIn;
          res.logoImage = response.responseItem.responseContent.logo;
          res.mailingAddress = response.responseItem.responseContent.mailingAdress;
          res.mailingAddress2 = response.responseItem.responseContent.mailingAdress2;
          res.mailingAddress3 = response.responseItem.responseContent.mailingAdress3;
          res.orgGrossTurnoverId = response.responseItem.responseContent.orgGrossTurnoverId;
          res.postalCode = response.responseItem.responseContent.postalCode;
          res.updatedBy = response.responseItem.responseContent.updatedBy;
          res.website = response.responseItem.responseContent.website;
          res.businessContactNumber = response.responseItem.responseContent.businessContactNumber;
          res.selectedCountries = response.responseItem.responseContent.countryMappingIds;
          res.selectedExpertise = response.responseItem.responseContent.expertiseMappingIds;
          res.selectedHardwares = response.responseItem.responseContent.hardwareMappingIds;
          res.selectedSectors = response.responseItem.responseContent.sectorMappingIds;
          res.selectedServices = response.responseItem.responseContent.serviceMappingIds;
          res.selectedSoftwares = response.responseItem.responseContent.softwareMappingIds;

          var array = (res.selectedCountries && res.selectedCountries !== '0') ? res.selectedCountries.split(',') : [];
          res.selectedCountriesArr = [];
          array.forEach(topic => {
            res.selectedCountriesArr.push(this.state.countriesWithPresence.find(topics => topics.value === Number(topic)));
          });
          array = (res.selectedSoftwares && res.selectedSoftwares !== '0') ? res.selectedSoftwares.split(',') : [];
          res.selectedSoftwaresArr = [];
          array.forEach(topic => {
            res.selectedSoftwaresArr.push(this.state.coProductSoftware.find(topics => topics.value === Number(topic)));
          });
          array = (res.selectedServices && res.selectedServices !== '0') ? res.selectedServices.split(',') : [];
          res.selectedServicesArr = [];
          array.forEach(topic => {
            res.selectedServicesArr.push(this.state.coProductService.find(topics => topics.value === Number(topic)));
          });
          array = (res.selectedSectors && res.selectedSectors !== '0') ? res.selectedSectors.split(',') : [];
          res.selectedSectorsArr = [];
          array.forEach(topic => {
            res.selectedSectorsArr.push(this.state.businessSector.find(topics => topics.value === Number(topic)));
          });
          array = (res.selectedExpertise && res.selectedExpertise !== '0') ? res.selectedExpertise.split(',') : [];
          res.selectedExpertiseArr = [];
          array.forEach(topic => {
            res.selectedExpertiseArr.push(this.state.areaOfExpertise.find(topics => topics.value === Number(topic)));
          });
          array = (res.selectedHardwares && res.selectedHardwares !== '0') ? res.selectedHardwares.split(',') : [];
          res.selectedHardwaresArr = [];
          array.forEach(topic => {
            res.selectedHardwaresArr.push(this.state.coProductHardware.find(topics => topics.value === Number(topic)));
          });

          let str = res.companyDescription;
          this.setState({ countCompanyDescriptionForSIIS: str.length });
          this.setState({ organizationProfile: res });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getOrganisationBussinessDetailsById = (corporateMemberId) => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {

      this.corporateMemberServices.getOrganisationBussinessDetailsById(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.organizationBusinessDetails };
            res.organisationBusinessDetailId = response.responseItem.responseContent.organisationBusinessDetailId;
            res.authorizedCapId = response.responseItem.responseContent.authorizedCapId;
            res.shareHoldingLocalId = response.responseItem.responseContent.shareHoldingLocalId;
            res.revFromOutsideSGId = response.responseItem.responseContent.revFromOutsideSGId;
            res.paidUpCapId = response.responseItem.responseContent.paidUpCapId;
            res.shareHoldingForeignId = response.responseItem.responseContent.shareHoldingForeignId;
            res.staffStrengthId = response.responseItem.responseContent.staffStrengthId;
            res.selectedTargetMarketIds = response.responseItem.responseContent.targetMarketIds;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.lastUpdatedDate;
            var array = (res.selectedTargetMarketIds && res.selectedTargetMarketIds !== '0') ? res.selectedTargetMarketIds.split(',') : [];
            res.selectedTargetMarketArr = [];
            array.forEach(topic => {
              res.selectedTargetMarketArr.push(this.state.targetMarket.find(topics => topics.value === Number(topic)));
            });

            this.setState({ organizationBusinessDetails: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getCorporateMembershipSubscriptionById = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {

      this.corporateMemberServices.getCorporateMembershipSubscriptionById(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.membershipSubscription };
            res.corporateMemberSubscriptionId = response.responseItem.responseContent.corporateMemberSubscriptionId;
            res.membershipTypeId = response.responseItem.responseContent.membershipTypeId;
            res.billingCategoryId = response.responseItem.responseContent.billingCategoryId;
            res.subscriptionStartDate = response.responseItem.responseContent.subscriptionStartDate;
            res.subcriptionEndDate = response.responseItem.responseContent.subcriptionEndDate;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedAt;
            res.membershipRevenueId = response.responseItem.responseContent.membershipRevenueId;
            res.incorporationDate = response.responseItem.responseContent.incorporationDate;
            res.tacFromId = response.responseItem.responseContent.tacFromId;
            res.tacName = response.responseItem.responseContent.tacName;
            res.isAligibleForNewApplication = response.responseItem.responseContent.isAligibleForNewApplication

            this.setState({ membershipSubscription: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getProspectInformationById = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      this.corporateMemberServices.getProspectInformationById(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.prospectInformation };
            res.corporateProspectId = response.responseItem.responseContent.corporateProspectId;
            res.source1Id = response.responseItem.responseContent.prospectSource1;
            res.source2Id = response.responseItem.responseContent.prospectSource2;
            res.source3 = response.responseItem.responseContent.prospectSource3;
            res.pic1Id = response.responseItem.responseContent.piC1;
            res.pic2Id = response.responseItem.responseContent.piC2;
            res.firstEngagementDate = response.responseItem.responseContent.firstEngagementDate;
            res.prospectStatusId = response.responseItem.responseContent.prospectStatusId;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedAt;
            res.comments = response.responseItem.responseContent.comments;
            res.getToKnow = response.responseItem.responseContent.getToKnow;
            if (response.responseItem.responseContent.getToKnow !== '' && response.responseItem.responseContent.getToKnow !== '0') {
              var array = response.responseItem.responseContent.getToKnow.split(',');
              const options = [];
              array.forEach(topic => {
                options.push(this.state.getToKnowUsArr.find(topics => topics.value === Number(topic)));
              });
              res.getToKnow = options;
            }
            // // var getto = this.state.getToKnowUsArr.find(x=>x.name === res.getToKnow)
            // let getto = this.state.getToKnowUsArr.find(
            //   (x) => x.name === res.getToKnow
            // );
            // res.getToKnow = getto.id;
            res.interestedChaptersId = response.responseItem.responseContent.interestedChaptersId;

            var selectedInterestChapterArr = (res.interestedChaptersId && res.interestedChaptersId !== '') ? res.interestedChaptersId.split(',') : [];
            res.selectedInterestChapterArr = [];
            selectedInterestChapterArr.forEach(topic => {
              res.selectedInterestChapterArr.push(this.state.interestedChapter.find(topics => topics.value === Number(topic)));
            });

            this.setState({ prospectInformation: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getSGTechIntrestById = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {

      this.corporateMemberServices.getSGTechIntrestById(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.sgTechInterest };
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.lastUpdatedDate;
            res.selectedSGTechIds = response.responseItem.responseContent.sgTechReasonIds;
            res.selectedSponsorshipIds = response.responseItem.responseContent.sgTechSponsorshipIds;
            res.selectedMatchingIds = response.responseItem.responseContent.sgTechMatchingIds;
            res.selectedProgramIds = response.responseItem.responseContent.sgTechProgramIds;

            var array = (res.selectedSGTechIds && res.selectedSGTechIds !== '0') ? res.selectedSGTechIds.split(',') : [];
            res.selectedSGTechArr = [];
            array.forEach(topic => {
              res.selectedSGTechArr.push(this.state.sgTech.find(topics => topics.value === Number(topic)));
            });
            array = (res.selectedSponsorshipIds && res.selectedSponsorshipIds !== '0') ? res.selectedSponsorshipIds.split(',') : [];
            res.selectedSponsorshipArr = [];
            array.forEach(topic => {
              res.selectedSponsorshipArr.push(this.state.sponsorship.find(topics => topics.value === Number(topic)));
            });
            array = (res.selectedMatchingIds && res.selectedMatchingIds !== '0') ? res.selectedMatchingIds.split(',') : [];
            res.selectedMatchingArr = [];
            array.forEach(topic => {
              res.selectedMatchingArr.push(this.state.matching.find(topics => topics.value === Number(topic)));
            });
            array = (res.selectedProgramIds && res.selectedProgramIds !== '0') ? res.selectedProgramIds.split(',') : [];
            res.selectedProgramArr = [];
            array.forEach(topic => {
              res.selectedProgramArr.push(this.state.programs.find(topics => topics.value === Number(topic)));
            });

            this.setState({ sgTechInterest: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getCommunicationById = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      this.corporateMemberServices.getCommunicationById(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.corporateCommunication };
            res.corporateCommunicationId = response.responseItem.responseContent.corporateCommunicationId;
            res.adHocActivityEvent = response.responseItem.responseContent.adHocActivityEvent;
            res.monthlySGTechEdition = response.responseItem.responseContent.monthlySGTechEdition;
            res.sGTechAnnouncements = response.responseItem.responseContent.sgTechAnnouncements;
            res.fortnightlyEvents = response.responseItem.responseContent.fortnightlyEvents;
            res.businessOpportunities = response.responseItem.responseContent.businessOpportunities;
            res.talentCapabilities = response.responseItem.responseContent.talentCapabilities;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedDate;
            this.setState({ corporateCommunication: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getEmployeeInfoById = (id) => {
    //akshay
    this.corporateMemberServices.getEmployeeInfoById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {


        if (response.responseItem.responseContent.title !== null && response.responseItem.responseContent.title !== "") {
          var array = response.responseItem.responseContent.title.split(',');
          var designation = [];
          array.forEach(topic => {
            designation.push(this.state.designationList.find(deg => deg.label === topic));
          });
        }
        this.setState({
          employeeDetails: response.responseItem.responseContent, isEmployeeModalEditable: false,
          // isCreateNewOrgRoleForEmployee: false
        }, () => {
          let data = { ...this.state.employeeDetails };
          data.selectedDesignation = designation;
          this.setState({ employeeDetails: data });
        });

        let details = response.responseItem.responseContent;
        // var fields = details.email.split("@");
        // details.emailText = fields[0];
        details.emailText = details.email;
        details.emailDomainId = details.corporateMemberId;
        this.setState({ organisationRoleDetail: details });
      } else {
        // this.swalServices.Error(response.message);
      }
    });
  };

  getCorporateOrganisationRoleById = (id) => {
    if (id > 0) {

      this.corporateMemberServices.getCorporateOrganisationRoleById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
            var array = response.responseItem.responseContent.designation.split(',');
            var designation = [];
            array.forEach(topic => {
              designation.push(this.state.designationList.find(deg => deg.label === topic));
            });
          }

          if (response.responseItem.responseContent.organisationRole !== null && response.responseItem.responseContent.organisationRole !== "") {
            var array2 = response.responseItem.responseContent.organisationRole.split(',');
            var organisationRole = [];
            array2.forEach(topic => {
              organisationRole.push(this.state.organizationRoleArray.find(deg => deg.value === Number(topic)));
            });
          }
          response.responseItem.responseContent.searchByEmail = '';
          this.setState(
            { organisationDetails: response.responseItem.responseContent, isCreateNewOrgRole: false, isOrganisationModalEditable: false },
            () => {
              let data = { ...this.state.organisationDetails };
              data.selectedDesignation = designation;
              data.selectedOrganisationRole = organisationRole;
              this.setState({ organisationDetails: data });
            }
          );
          let details = response.responseItem.responseContent;
          // var fields = details.email.split("@");
          // details.emailText = fields[0];
          details.emailText = details.email;
          details.emailDomainId = details.corporateMemberId;
          response.responseItem.responseContent.searchByEmail = '';
          this.setState({ organisationDetails: details });
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };

  searchIndividualMemberByEmailForOrgRole = (identity) => {
    if (this.state.corporateMemberId > 0 && ((identity === "Organisarion Key Roles" && this.validateSearchByEmailField("searchByEmail")) || (identity === "Organisarion Employees" && this.validateSearchByEmailFieldForEmployee("searchByEmail")))) {
      let req = {
        email: '',
        corporateMemberId: 0,
      }
      if (identity === "Organisarion Key Roles") {
        req.email = this.state.organisationDetails.searchByEmail;
      }
      if (identity === "Organisarion Employees") {
        req.email = this.state.employeeDetails.searchByEmail;
      }
      req.corporateMemberId = this.state.corporateMemberId;
      this.corporateMemberServices.searchIndividualMemberByEmailForOrgRole(req).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError === '0') {
          let isEditable = false;
          if (response.responseItem.responseContent.mrMrsId > 0) {
            response.responseItem.responseContent.mrMrs = response.responseItem.responseContent.mrMrsId;
          } else {
            isEditable = true
          }
          if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
            var array = response.responseItem.responseContent.designation.split(',');
            var designation = [];
            array.forEach(topic => {
              designation.push(this.state.designationList.find(deg => deg.label === topic));
            });
          } else {
            isEditable = true
          }
          if (response.responseItem.responseContent.organisationRole !== null && response.responseItem.responseContent.organisationRole !== "") {
            var array2 = response.responseItem.responseContent.organisationRole.split(',');
            var organisationRole = [];
            array2.forEach(topic => {
              organisationRole.push(this.state.organizationRoleArray.find(deg => deg.value === Number(topic)));
            });
          } else {
            isEditable = true
          }
          if (identity === "Organisarion Key Roles") {
            let organisationDetailsObj = { ...this.state.organisationDetails };
            response.responseItem.responseContent.searchByEmail = organisationDetailsObj.searchByEmail;
            this.setState(
              { organisationDetails: response.responseItem.responseContent },
              () => {
                let data = { ...this.state.organisationDetails };
                data.selectedDesignation = designation;
                data.selectedOrganisationRole = organisationRole;
                this.setState({ organisationDetails: data });
              }
            );
            this.resetEmployeeInfo()
            let details = response.responseItem.responseContent;
            // var fields = details.email.split("@");
            // details.emailText = fields[0];
            details.emailText = req.email;
            details.emailDomainId = details.corporateMemberId;
            this.setState({ organisationDetails: details, isOrganisationModalEditable: isEditable });
          }
          if (identity === "Organisarion Employees") {
            // individual employess 
            let employeeDetailsObj = { ...this.state.employeeDetails };
            response.responseItem.responseContent.searchByEmail = employeeDetailsObj.searchByEmail;
            this.setState(
              { employeeDetails: response.responseItem.responseContent },
              () => {
                let data = { ...this.state.employeeDetails };
                data.selectedDesignation = designation;
                data.selectedOrganisationRole = organisationRole;
                this.setState({ employeeDetails: data });
              }
            );
            // this.resetEmployeeInfo()
            let details = response.responseItem.responseContent;
            // var fields = details.email.split("@");
            // details.emailText = fields[0];
            details.emailText = req.email;
            details.emailDomainId = details.corporateMemberId;
            this.setState({ employeeDetails: details, isEmployeeModalEditable: isEditable });
          }

        }
        else if (response.responseItem.responseContent.hasError === '1') {
          this.setState({ isCreateNewOrgRole: true, isOrganisationModalEditable: true, isEmployeeModalEditable: true, isCreateNewOrgRoleForEmployee: true }, () => {
            if (identity === "Organisarion Key Roles") {
              this.handleReset(req.email);
            }
            if (identity === "Organisarion Employees") {
              this.resetEmployeeInfo(req.email)
            }
            this.swalServices.Alert('No records found');
          });
        }
        else if (response.responseItem.responseContent.hasError === '2') {
          this.setState({ isCreateNewOrgRole: true, isOrganisationModalEditable: false, isEmployeeModalEditable: false, isCreateNewOrgRoleForEmployee: true }, () => {
            if (identity === "Organisarion Key Roles") {
              this.handleReset("");
            }
            if (identity === "Organisarion Employees") {
              this.resetEmployeeInfo("")
            }
            this.swalServices.Alert(response.responseItem.responseContent.error);
          });
        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }
    else {
      this.swalServices.Alert('Please enter valid email address');
    }
  };
  // setLoginToCorporateModalTrue = () => {
  //   this.setState({ setLoginToCorporateModal: true });
  // };
  handleChangeForLoginToCorporateModal = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "corporateModalPassword") {
      this.setState({ passwordForCorporateModal: value });
    }
  };
  // loginToCorporateMemberForIndividualMember = () => {
  //   let request = {};
  //   request.email = this.state.organizationBasicInformation.email;
  //   request.password = this.state.passwordForCorporateModal;
  //   this.corporateMemberServices
  //     .loginToCorporateMemberForIndividualMember(request)
  //     .then((response) => {
  //       if (response.statusCode === 200 && response.responseItem != null) {
  //         let authData = {};
  //         authData.companyUEN =
  //           response.responseItem.responseContent.companyUEN;
  //         authData.corporateMemberId =
  //           response.responseItem.responseContent.corporateMemberId;
  //         authData.isAuthenticated =
  //           response.responseItem.responseContent.isAuthenticated;
  //         authData.message = response.responseItem.responseContent.message;
  //         authData.companyName =
  //           response.responseItem.responseContent.companyName;
  //         authData.token = response.responseItem.responseContent.token;
  //         authData.sessionTimeout =
  //           response.responseItem.responseContent.sessionTimeout;
  //         authData.isCorporateMember = true;
  //         // authData.isLoginToCorporateAllowed = true;
  //         this.setState({ isLoginToCorporateAllowed: false });
  //         // removeAllCookies();
  //         this.setState({ authDetails: authData }, () => {
  //           setAuthProps(authData);
  //           // Navigate(Routes.CorporateDashboard);
  //           this.swalServices.Success("Login SuccessFully");
  //           // this.setLoginToCorporateModal(false);
  //           this.setState({ setLoginToCorporateModal: false });
  //         });
  //       } else {
  //         this.swalServices.Error(ErrorMessage.InvalidAuthorization);
  //         this.setState({ setLoginToCorporateModal: false });
  //       }
  //       this.setState({ isLoading: false });
  //     });
  // };
  // searchIndividualMemberByEmailForEmployee = (id) => {
  //   if (this.state.corporateMemberId > 0 && this.validateSearchByEmailFieldForEmployeeDetails("searchByEmail")) {
  //     let req = {
  //       email: '',
  //       corporateMemberId: 0,
  //     }
  //     req.email = this.state.employeeDetails.searchByEmail;
  //     req.corporateMemberId = this.state.corporateMemberId;
  //     this.corporateMemberServices.searchIndividualMemberByEmailForOrgRole(req).then((response) => {
  //       if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError === '0') {
  //         if (response.responseItem.responseContent.mrMrsId > 0) {
  //           response.responseItem.responseContent.mrMrs = response.responseItem.responseContent.mrMrsId;
  //         }
  //         if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
  //           var array = response.responseItem.responseContent.designation.split(',');
  //           var designation = [];
  //           array.forEach(topic => {
  //             designation.push(this.state.designationList.find(deg => deg.label === topic));
  //           });
  //         }
  //         // if (response.responseItem.responseContent.organisationRole !== null && response.responseItem.responseContent.organisationRole !== "") {
  //         //   var array2 = response.responseItem.responseContent.organisationRole.split(',');
  //         //   var organisationRole = [];
  //         //   array2.forEach(topic => {
  //         //     organisationRole.push(this.state.organizationRoleArray.find(deg => deg.value === Number(topic)));
  //         //   });
  //         // }
  //         let employeeDetailsObj = { ...this.state.employeeDetails };
  //         response.responseItem.responseContent.searchByEmail = employeeDetailsObj.searchByEmail;
  //         this.setState(
  //           { employeeDetails: response.responseItem.responseContent },
  //           () => {
  //             let data = { ...this.state.employeeDetails };
  //             data.selectedDesignation = designation;
  //             //data.selectedOrganisationRole = organisationRole;
  //             this.setState({ employeeDetails: data });
  //           }
  //         );
  //         let details = response.responseItem.responseContent;
  //         // var fields = details.email.split("@");
  //         // details.emailText = fields[0];
  //         details.emailText = req.email;
  //         details.emailDomainId = details.corporateMemberId;
  //         this.setState({ employeeDetails: details });
  //       }
  //       else if (response.responseItem.responseContent.hasError === '1') {
  //         let resetEmployeeDetails = {};
  //         resetEmployeeDetails.individualMemberId = 0;
  //         //resetEmployeeDetails.mrMrs = 0;
  //         resetEmployeeDetails.firstName = '';
  //         resetEmployeeDetails.lastName = '';
  //         resetEmployeeDetails.email = '';
  //         resetEmployeeDetails.emailText = req.email;
  //         resetEmployeeDetails.contactNo = '';
  //         resetEmployeeDetails.designation = '';
  //         resetEmployeeDetails.contactNo = '';
  //         resetEmployeeDetails.mrMrsId = 0;
  //         //resetEmployeeDetails.roleId = 0;
  //         resetEmployeeDetails.emailDomainId = 0;
  //         //resetEmployeeDetails.organisationRoleId = 0;
  //         resetEmployeeDetails.emailString = '';
  //         resetEmployeeDetails.selectedDesignation = [];
  //         //resetEmployeeDetails.selectedOrganisationRole = [];
  //         //resetEmployeeDetails.organisationRoleList = [];
  //         resetEmployeeDetails.searchByEmail = req.email;
  //         this.setState({ employeeDetails: resetEmployeeDetails, isOrganisationModalEditable: true }, () => { this.swalServices.Alert('No records found'); })
  //         this.setState({ isCreateNewEmployee: true, isEmployeeModalEditable: true }, () => {
  //           //this.handleReset(req.email);
  //           this.swalServices.Alert('No records found');
  //         });
  //       }
  //       else {
  //         this.swalServices.Error(response.message);
  //       }
  //     });
  //   }
  //   else {
  //     this.swalServices.Alert('Please enter valid email address');
  //   }
  // };

  getEngagementTrackingById = (id) => {
    this.corporateMemberServices.getEngagmentDetailsById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          engagementTrackingDetails: response.responseItem.responseContent,
        });
      } else {
        //this.swalServices.Error(response.message);
      }
    });
  };

  getEngagementTrackingList = (id) => {
    if (id > 0 && id != null) {
      this.corporateMemberServices.getEngagementTrackingList(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.length > 0) {
            var updatedDate = new Date(Math.max(...response.responseItem.responseContent.map(e => new Date(e.lastUpdatedDate))));
            //const found = response.responseItem.responseContent.find(element => new Date(element.lastUpdatedDate) === updatedDate);
            var engagement = this.state.engagementTrackingDetails;
            engagement.lastUpdatedDate = updatedDate;
            engagement.updatedAt = updatedDate;
            engagement.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
            this.setState({
              engagemmentTrackingList: response.responseItem.responseContent,
            }, () => this.setState({ engagementTrackingDetails: engagement }));
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };
  updateChapterById = () => {
    if (this.state.corporateMemberId) {
      
        let request = {};
        const emailId = this.state.chapterInformation.emailId || this.state.officialRepinformation.emailID;
        let chapterInformation = this.state.chapterInformation
        chapterInformation.emailId = emailId;
        const emailObject = this.state.Organisationemail.find(x => x.id === emailId);
        request.email = emailObject ? emailObject.name : null;
        request.corporateMemberId = this.state.corporateMemberId;
        request.workgroupMappingId = this.state.chapterInformation.workgroupMappingId;
        request.corporateChapterId = 0;
        request.createdBy = 1;
        request.createdAppId = 115;
        if (this.isValidChapter()) {
          this.setState({ isLoadingForJoinChapter: true });
          this.corporateMemberServices.updateChapterById(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "Chapter") {
              this.swalServices.Error("You have already joined this chapter.");
              this.setState({ isLoadingForJoinChapter: false });
              // this.getChapterInformationById();
            }
            else if (response.statusCode === 200 && response.responseItem != null && this.state.chapterInformation.isEditTrue === true) {
              this.swalServices.Success("Chapter Representative updated successfully.");
              this.setState({ isLoadingForJoinChapter: false });
              this.setState(prevState => {
                const chapters = { ...prevState.chapterInformation };
              
                // Resetting the chapter information
                chapters.workgroupMappingId = 0;
                chapters.corporateMemberId = 0;
                chapters.availableChapters = 0;
                chapters.joinedChapters = 0;
                chapters.updatedBy = 1;
                chapters.updatedAt = null;
                chapters.purchasedChapters = 0;
                chapters.email = '';
                chapters.emailId = 0;
                chapters.isEditTrue = false;
              
                return { chapterInformation: chapters };
              }, () => {
                // Fetch updated chapter and corporate account information
                this.getChapterInformationById();
                this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
              });
              
            }
            
            else if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Chapter joined successfully.");
              this.setState({ isLoadingForJoinChapter: false });
              var chapters = this.state.chapterInformation;
              chapters.availableChapters = chapters.availableChapters - 1;
              chapters.joinedChapters = chapters.joinedChapters + 1;
              this.setState(prevState => {
                const chapters = { ...prevState.chapterInformation };
              
                // Resetting the chapter information
                chapters.workgroupMappingId = 0;
                chapters.corporateMemberId = 0;
                chapters.availableChapters = 0;
                chapters.joinedChapters = 0;
                chapters.updatedBy = 1;
                chapters.updatedAt = null;
                chapters.purchasedChapters = 0;
                chapters.email = '';
                chapters.emailId = 0;
                chapters.isEditTrue = false;
              
                return { chapterInformation: chapters };
              }, () => {
                // Fetch updated chapter and corporate account information
                this.getChapterInformationById();
                this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
              });
            }
            else {
              this.setState({ isLoadingForJoinChapter: false });
              this.swalServices.Error(response.message);
            }
          });
        }

       else {
        this.swalServices.Alert("There is no chapters available");
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  };
  joinChapterById = () => {
    if (this.state.corporateMemberId) {
      if (this.state.chapterInformation.isEditTrue === true || this.state.chapterInformation.availableChapters > 0) {
        let request = {};
        const emailId = this.state.chapterInformation.emailId || this.state.repOfficialInformation.emailID;
        let chapterInformation = this.state.chapterInformation
        chapterInformation.emailId = emailId;
        const emailObject = this.state.Organisationemail.find(x => x.id === emailId);
        request.email = emailObject ? emailObject.name : null;
        request.corporateMemberId = this.state.corporateMemberId;
        request.workgroupMappingId = this.state.chapterInformation.workgroupMappingId;
        request.corporateChapterId = this.state.chapterInformation.corporateChapterId ? this.state.chapterInformation.corporateChapterId : 0;
        request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
        //request.email = this.state.Organisationemail.find((x) => x.id === this.state.chapterInformation.emailId).name ? this.state.Organisationemail.find((x) => x.id === this.state.chapterInformation.emailId).name : this.state.Organisationemail.find(option => option.id === this.state.repOfficialInformation.emailID)?.name;
        request.createdAppId = 115;
        if (this.isValidChapter()) {
          this.setState({ isLoadingForJoinChapter: true });
          this.corporateMemberServices.joinChapterById(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "Chapter") {
              this.swalServices.Error("You have already joined this chapter.");
              // this.getChapterInformationById();
            }
            else if (response.statusCode === 200 && response.responseItem != null && this.state.chapterInformation.isEditTrue === true) {
              this.swalServices.Success("Chapter Representative updated successfully.");
            
              this.setState(prevState => {
                const chapters = { ...prevState.chapterInformation };
              
                // Resetting the chapter information
                chapters.workgroupMappingId = 0;
                chapters.corporateMemberId = 0;
                chapters.availableChapters = 0;
                chapters.joinedChapters = 0;
                chapters.updatedBy = 1;
                chapters.updatedAt = null;
                chapters.purchasedChapters = 0;
                chapters.email = '';
                chapters.emailId = 0;
                chapters.isEditTrue = false;
              
                return { chapterInformation: chapters };
              }, () => {
                // Fetch updated chapter and corporate account information
                this.getChapterInformationById();
                this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
              });
              
            }
            
            else if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Chapter joined successfully.");
              var chapters = this.state.chapterInformation;
              chapters.availableChapters = chapters.availableChapters - 1;
              chapters.joinedChapters = chapters.joinedChapters + 1;
              chapters.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
              this.setState(prevState => {
                const chapters = { ...prevState.chapterInformation };
              
                // Resetting the chapter information
                chapters.workgroupMappingId = 0;
                chapters.corporateMemberId = 0;
                chapters.availableChapters = 0;
                chapters.joinedChapters = 0;
                chapters.updatedBy = 1;
                chapters.updatedAt = null;
                chapters.purchasedChapters = 0;
                chapters.email = '';
                chapters.emailId = 0;
                chapters.isEditTrue = false;
              
                return { chapterInformation: chapters };
              }, () => {
                // Fetch updated chapter and corporate account information
                this.getChapterInformationById();
                this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
              });
            }
            else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoadingForJoinChapter: false });
          });
        }

      } 
      else {
        this.swalServices.Alert("There is no chapters available");
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  };

  openBuyChapterModal = () => {
    let data = this.state.purchaseChapter
    data.noOfAdditionalChapters = 0;
    //data.availableChapter = 0;
    //data.additionalChapterPrice = null;
    data.subTotalAmount = 0;
    data.gstAmount = 0;
    data.discountedAmount = 0;
    data.totalAmount = 0;
    data.invoicePath = "";
    data.paymentVia = "";
    data.paymentStatus = 0;
    data.paymentViaId = 0;
    data.voucherCode = "";
    data.previousSubTotalAmount = 0;
    data.paymentsCreditsApplied = 0;
    data.netTotal = 0;
    data.poNumber = "";
    //data.startDate = '';
    //data.endDate = '';
    //data.membershipTypeId = 0;
    //data.membershipTypeName = '';
    //data.companyName = '';
    //data.firstName = '';
    //data.lastName = '';
    //data.email = '';
    this.setState({ setBuyChapterModal: true, purchaseChapter: data });
  };

  // closeBuyChapterModal = () => {
  //   this.setState({ setBuyChapterModal: false });
  // };

  setBuyChapterFilterParameters = (id, drpIdentity) => {
    let gstRate = this.state.mainGSTRate ? this.state.mainGSTRate : 0;
    let detail = { ...this.state.purchaseChapter };
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const today = new Date();
    const subscriptionEndDate = new Date(detail.endDate);
    const todayInSeconds = today.getTime();
    const subscriptionEndDateInSeconds = subscriptionEndDate.getTime();
    // const todayInMonths = today.getMonth();
    // const subscriptionEndDateInMonths = subscriptionEndDate.getMonth();
    // const todayInYears = today.getFullYear();
    // const subscriptionEndDateInYears = subscriptionEndDate.getFullYear();
    // const diffDays = (subscriptionEndDateInSeconds - todayInSeconds) / (1000 * 60 * 60 * 24);
    let diffMonths = (monthDifference(subscriptionEndDate, today, true) - 1);
    const proratingFactor = (diffMonths * 1) / 12;
    const gstConst = parseFloat((gstRate / 100).toFixed(2));
    const gstConstItem = parseFloat((1 + (gstRate / 100)).toFixed(2));
    //const gstConst = 0.07;
    if (drpIdentity === "BuyChapters") {
      id = isNaN(id) ? 0 : Number(id);
      detail["noOfAdditionalChapters"] = id;
      let totalPrice = detail.additionalChapterPrice * Number(id) * proratingFactor;
      detail.gstAmount = totalPrice * gstConst;
      detail.subTotalAmount = totalPrice;
      detail.discountedAmount = 0;
      detail.totalAmount = totalPrice + detail.gstAmount;
      detail.paymentsCreditsApplied = 0;
      detail.netTotal = detail.totalAmount;
      detail.voucherCode = "";

      this.setState({ purchaseChapter: detail, voucherCode: "", responseMessage: "", discountedAmount: 0 }, () => {
        this.validateBuyChapterFields("noOfAdditionalChapters");
      });

    }
    if (drpIdentity === "PaymentVia") {
      detail["paymentViaId"] = id;

      let found = this.state.paymentsMethods.find(x => x.id === id);
      if (found) {
        detail["paymentVia"] = found.name;
      } else {
        detail["paymentVia"] = "";
      }
      this.setState({ purchaseChapter: detail }, () => {
        this.validateBuyChapterFields("paymentVia");
      });

    }
  };

  handleChangeInPoNumber(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = { ...this.state.purchaseChapter };
    detail[name] = value;
    this.setState({ purchaseChapter: detail });
  }

  handleChangeForBuyChapter(event) {
    const value = event.target.value;
    const name = event.target.name;

    let voucher = { ...this.state.purchaseChapter };
    voucher.voucherCode = value
    this.setState({ purchaseChapter: voucher });
  }

  buyChapterForCorporateMember = () => {
    let gstRate = this.state.mainGSTRate;
    let gstItem = (gstRate / 100);
    let request = {};
    if (this.isValidateBuyChapter()) {
      let paymentData = { ...this.state.purchaseChapter };
      request.corporateId = this.state.corporateMemberId;
      // request.taskId = this.state.companyInfo.taskId;
      request.finalCalculatedPriceId = 0;
      // request.entranceFee = this.state.paymentData.entranceFee;
      // request.annualFee = this.state.paymentData.annualFee;
      // request.includedChapter = this.state.paymentData.includedChapter;
      request.additionalChapter = paymentData.noOfAdditionalChapters;
      request.additionalChapterFee = parseFloat((paymentData.subTotalAmount).toFixed(2));
      //request.additionalChapterFee = parseFloat((paymentData.additionalChapterPrice * gstItem).toFixed(2));
      request.subTotalAmount = parseFloat((paymentData.subTotalAmount).toFixed(2));
      request.gSTAmount = parseFloat((Math.abs(paymentData.gstAmount)).toFixed(2));
      request.gSTAmount = isNaN(request.gSTAmount) ? 0 : request.gSTAmount;
      request.paymentsCreditsApplied = paymentData.paymentsCreditsApplied;
      if (this.state.isFixedPrice && this.state.discountedAmount > this.state.purchaseChapter.previousSubTotalAmount) {
        request.discountedAmount = parseFloat(Math.abs(this.state.discountedAmount) - this.state.purchaseChapter.previousSubTotalAmount).toFixed(2);

      } else {
        request.discountedAmount = parseFloat(Math.abs(this.state.discountedAmount)).toFixed(2);
      }

      if (this.state.responseMessage === "Promo code applied successfully") {
        request.voucherCode = paymentData.voucherCode;
      } else {
        request.voucherCode = "";
      }
      request.totalAmount = parseFloat(paymentData.totalAmount).toFixed(2)
      request.netTotal = parseFloat(paymentData.netTotal).toFixed(2);
      request.invoicePath = "";
      request.paymentVia = paymentData.paymentVia;
      request.paymentStatus = "Pending";
      // request.membershipTenureId = this.state.pricingTable.year;
      request.createdAt = new Date();
      request.createdBy = this.state.corporateMemberId;
      request.createdAppId = 115;
      request.paymentDescription = "Buy Chapter";
      request.membersReceiptId = 0;
      request.gstRate = this.state.mainGSTRate;
      request.poNumber = paymentData.poNumber;
      this.setState({ loadingForPayment: true });
      this.financeServices
        .buyChapterForCorporateMember(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem.responseContent != null) {
            if (this.state.purchaseChapter.totalAmount === 0 || this.state.purchaseChapter.totalAmount === '0.00') {
              // let totalAmount = this.state.purchaseChapter.totalAmount.toFixed(2);
              // if (totalAmount === 0.00 || totalAmount === '0.00' || totalAmount === -0.00 || totalAmount === '-0.00') {
              //   let webSuccesFullPaymentLine = WebPath + 'paymentSuccessful?referenceNo=' + response.responseItem.responseContent.referenceNo + '&paymentType=BuyChapter'
              //   window.open(webSuccesFullPaymentLine, '_blank')
              //   this.setState({ setBuyChapterModal: false });
              //   this.setState({ loadingForPayment: false });
            } else {
              let reconciliationReq = {};
              reconciliationReq.corporateMemberId = this.state.corporateMemberId;
              reconciliationReq.companyName = this.state.purchaseChapter.companyName;
              reconciliationReq.firstName = this.state.purchaseChapter.firstName;
              reconciliationReq.lastName = this.state.purchaseChapter.lastName;
              reconciliationReq.email = this.state.purchaseChapter.email;

              this.financeServices.sendReconciliationPaymentLinkForBuyChapter(reconciliationReq).then((response) => {
                if (response != null) {
                  this.swalServices.SuccessWithAction("Please check your email for reconciliation payment link.", "OK").then((response) => {
                    this.setState({ setBuyChapterModal: false });
                    this.setState({ loadingForPayment: false });
                  })
                }
              });
            }
          } else {
            this.swalServices.Error(response.message);
          }
          //this.setState({ isLoading: false });
          this.setState({ loadingForPayment: false });
        });
    }

  };

  applyVoucherCodeForAdditionalChapter = () => {
    if (this.state.purchaseChapter.totalAmount > 0) {
      let request = {};
      request.corporateMemberId = this.state.corporateMemberId;
      request.voucherCode = this.state.purchaseChapter.voucherCode;
      request.totalAmount = this.state.purchaseChapter.totalAmount;
      request.membershipType = this.state.purchaseChapter.membershipTypeName;
      request.membershipTypeId = this.state.purchaseChapter.membershipTypeId;
      request.membershipPaymentTypeId = 2;
      request.subtotalPrice = this.state.purchaseChapter.subTotalAmount;

      if (this.state.purchaseChapter.voucherCode !== null && this.state.purchaseChapter.voucherCode !== "") {
        this.financeServices.applyVoucherCodeForMembershipPayment(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem !== null) {
            if (response.responseItem.responseContent !== null) {

              if (response.responseItem.responseContent.isValid && response.responseItem.responseContent.discountedAmount > this.state.purchaseChapter.subTotalAmount) {
                this.swalServices.Confirm("", "You are currently enjoying a lower rate for your purchase. Do you still want to apply this promo code?", "Confirm", "Cancel").then((res) => {
                  if (res && res === true) {
                    this.setState({
                      responseMessage: response.responseItem.responseContent.isValid ? "Promo code applied successfully" : response.responseItem.responseContent.responseMessage,
                      discountedAmount: response.responseItem.responseContent.discountedAmount,
                      isFixedPrice: response.responseItem.responseContent.isValid,
                    }, () => {
                      if (this.state.discountedAmount != null && this.state.discountedAmount !== "") {
                        // this.swalServices.Success("Promo code applied successfully");
                      }
                      this.setDiscountedPrice(this.state.discountedAmount, this.state.isFixedPrice);
                    });
                  } else {
                    let clear = { ...this.state.purchaseChapter };
                    clear.voucherCode = "";
                    this.setState({ purchaseChapter: clear });
                  }
                })
              }
              else {
                this.setState({
                  responseMessage: response.responseItem.responseContent.responseMessage,
                  discountedAmount: response.responseItem.responseContent.discountedAmount,
                  isFixedPrice: response.responseItem.responseContent.isValid,
                }, () => {
                  if (this.state.discountedAmount !== null && this.state.discountedAmount !== "") {
                    // this.swalServices.Success("Promo code applied successfully");
                  }
                  this.setDiscountedPrice(this.state.discountedAmount, false);
                });
              }

            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
      }
      else {
        this.swalServices.Warning("Please enter voucher code!");
      }
    } else {
      this.swalServices.Warning("Please select number of chapters!");
    }

  }

  setDiscountedPrice = (discountedPrice, fixedPrice) => {
    let detail = { ...this.state.purchaseChapter };

    let isFixedPrice = this.state.isFixedPrice;
    let gstRate = this.state.mainGSTRate ? this.state.mainGSTRate : 0;
    const gstConst = parseFloat((gstRate / 100).toFixed(2));
    const gstConstItem = parseFloat((1 + (gstRate / 100)).toFixed(2));

    if (isFixedPrice === true) {

      let totalPrice = this.state.discountedAmount;
      detail.previousSubTotalAmount = detail.subTotalAmount;
      detail.gstAmount = totalPrice * gstConst;
      detail.subTotalAmount = detail.subTotalAmount;
      detail.discountedAmount = detail.previousSubTotalAmount - this.state.discountedAmount;
      let discount = detail.previousSubTotalAmount - this.state.discountedAmount;
      detail.totalAmount = totalPrice + detail.gstAmount;
      detail.paymentsCreditsApplied = 0;
      detail.netTotal = detail.totalAmount;
      //[************* End Exclusive GST *************]//

      this.setState({ purchaseChapter: detail, discountedAmount: discount });
    } else {

      let totalPrice = detail.subTotalAmount - this.state.discountedAmount;
      detail.previousSubTotalAmount = detail.subTotalAmount;
      detail.subTotalAmount = detail.subTotalAmount;
      detail.gstAmount = totalPrice * gstConst;
      detail.discountedAmount = this.state.discountedAmount;
      detail.totalAmount = totalPrice * gstConstItem;
      detail.paymentsCreditsApplied = 0;
      detail.netTotal = detail.totalAmount;
      this.setState({ purchaseChapter: detail });
    }
  }

  deleteChapter = (id) => {
    let request = {
      deletedBy: 115,
      appId: 51,
      ids: id,
      id: id,
      individualMemberId: this.state.corporateMemberId,
    };
    if (request.individualMemberId > 0) {
      this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Chapter"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Chapter"),
        CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
          if (response) {
            this.setState({ isLoadingForJoinChapter: true });
            this.corporateMemberServices.deleteChapters(request).then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                var chapters = this.state.chapterInformation;
                chapters.availableChapters = chapters.availableChapters + 1;
                chapters.joinedChapters = chapters.joinedChapters - 1;
                chapters.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
                this.setState({ chapterInformation: chapters }, () =>
                  this.getChapterInformationById()
                );
                //this.getJoinedChapter(this.state.corporateUserId);
              }
              else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoadingForJoinChapter: false });
            });
          }
        });
    }

  };

  deleteOrganisationRole = (id) => {
    if (id > 0 && id != null) {
      let request = this.state.organisationDetails;
      request.individualMemberId = id;
      this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Organisation Role"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Organisation Role"),
        CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
          if (response) {
            this.corporateMemberServices.deleteCorporateOrganisationRole(request).then((response) => {
              if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
                this.swalServices.Success(
                  "Organisation role deleted successfully."
                );
                let reset = this.state.organisationDetails;
                reset.organisationRoleId = 0;
                reset.mrMrs = "";
                reset.firstName = "";
                reset.lastName = "";
                reset.email = "";
                reset.contactNo = "";
                reset.designation = "";
                reset.organisationRole = "";
                reset.emailString = "";
                reset.individualMemberId = 0;
                reset.selectedDesignation = [];
                reset.selectedOrganisationRole = [];
                this.setState({ organisationDetails: reset });
                this.getOrganisationRoleList(this.state.corporateMemberId);
                this.setState({ addEditOrganisationModel: false })
              }
              else if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "OrganisationRoleId") {
                if (response.responseItem.responseContent.errorMessage === "2") {
                  this.swalServices.Error('Last official representative cannot be deleted.');
                }
                else if (response.responseItem.responseContent.errorMessage === "3") {
                  this.swalServices.Error('Last finance/billing rep cannot be deleted.');
                }
              }
              else {
                this.swalServices.Error(response.message);
              }
            });
          }
        });
    }
  };

  saveMainImage = (file, keyId, imageIdentity) => {
    this.setState({ isLoaderEnabled: true });
    file.storagePath = "CorporateMembers";
    this.commonServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("File Uploaded");
        if (file.storagePath === "CorporateMembers") {
          let detail = this.state.organizationProfile;
          detail["logoImage"] = response.fileName;
          this.setState({ organizationProfile: { ...detail } }, () => this.setState({ isLoaderEnabled: false }));
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
    })
  }

  onEditEmployee = (id) => {
    this.getEmployeeInfoById(id);
  };

  openSection = (section) => {
    this.setState({ openSection: this.state.openSection === section ? "" : section, });
    if (section === "SIISAccountInformation") {
      this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
    } else if (section === "OrganisationBasicInformation") {
      this.getOrganisationBasicInformationById();
    }

    else if (section === "OfficialRepInformation") {
      this.getRepOfficialInformationById(this.state.corporateMemberId);
    }
    else if (section === "ChapterInformation") {
      this.getChapterInformationById();
      this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
    }
    else if (section === "OrganisationProfile") {
      this.getOrganisationProfileById();
    }
    else if (section === "OrganisationBusinessDetail") {
      this.getOrganisationBussinessDetailsById();
    }
    else if (section === "CorporateSubscription") {
      this.getCorporateMembershipSubscriptionById();
    }
    else if (section === "ProspectInformation") {
      this.getProspectInformationById();
    }
    else if (section === "SGTechInterest") {
      this.getSGTechIntrestById();
    }
    else if (section === "Communication") {
      this.getCommunicationById();
    }
    else if (section === "EngagementTracking") {
      this.getEngagementTrackingList(this.state.corporateMemberId);
    } else if (section === "Employees") {
      this.getEmployeeList(this.state.corporateMemberId, "");
    }
    else if (section === "GovernmentProgram") {
      this.getGovernmentProgrammeDetailByProjectId(this.state.corporateMemberId);
    }
    else if (section === "FinancialRecords") {
      this.getCorporateFinancialInfo(this.state.corporateMemberId);
    }
    else if (section === "GoGlobalInformation") {
      this.getCorporateDetailForGoGLobal(this.state.corporateMemberId);
    }
  };

  editOrganisationRole = (value, e) => {
    this.getCorporateOrganisationRoleById(value);
  };

  onEditEngagement = (id) => {
    this.getEngagementTrackingById(id);
  };

  // Start All validation Method 
  // Organisation Role Validation
  validateOrganisationRole = (key, object) => {
    const returnValidState = validate(key, this.state.organisationDetails, this.state.organisationRoleValidationRule, this.state.validStateOrg);
    this.setState({ validStateOrg: returnValidState, });
  };

  isValidateAllOrganisationRoles = () => {
    const returnValidState = isValidForm(this.state.organisationDetails, this.state.organisationRoleValidationRule, this.state.validStateOrg);
    this.setState({ validStateOrg: returnValidState });
    return returnValidState.isValid;
  };
  // Organisation Role Validation

  // Engagement tracking Validation
  engagementValidate = (key, object) => {
    const returnValidState = validate(key, this.state.engagementTrackingDetails, engagementTrackingValRules, this.state.validState);
    this.setState({ validState: returnValidState, });
  };

  isValidengagement = () => {
    const returnValidState = isValidForm(this.state.engagementTrackingDetails, engagementTrackingValRules, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  };
  //  End engagement tracking Validation

  // Chapter Information Validation
  chapterInfoValidate = (key, object) => {
    const returnValidState = validate(key, this.state.chapterInformation, chapterInformationValRules, this.state.validStateChapter);
    this.setState({ validStateChapter: returnValidState, });
  };

  isValidChapter = () => {
    const returnValidState = isValidForm(this.state.chapterInformation, chapterInformationValRules, this.state.validStateChapter);
    this.setState({ validStateChapter: returnValidState });
    return returnValidState.isValid;
  };

  //End Chapter Information validation

  // In Chapter Information Buy Chapter Validation

  validateBuyChapterFields = (key) => {
    const newValidState = validate(
      key,
      this.state.purchaseChapter,
      purchaseChapterValidationRule,
      this.state.buyChapterValidState
    );
    this.setState({ buyChapterValidState: newValidState });
  };

  isValidateBuyChapter = () => {
    const returnValidState = isValidForm(
      this.state.purchaseChapter,
      purchaseChapterValidationRule,
      this.state.buyChapterValidState
    );
    this.setState({ buyChapterValidState: returnValidState });
    return returnValidState.isValid;
  };
  // In Chapter Information Buy Chapter Validation

  // Corporate Membership Subscription Validation
  memberSubValidate = (key, object) => {
    const returnValidState = validate(key, this.state.membershipSubscription, memberSubscriptionValRules, this.state.validMemberSub);
    this.setState({ validMemberSub: returnValidState, });
  };

  isValidMemberSub = () => {
    let valRule = { ...memberSubscriptionValRules };
    if (this.state.membershipSubscription?.membershipTypeId > 0 && this.state.membershipSubscription?.membershipTypeId === 5) {
      let tacFromIdValRule = [
        {
          type: 'require',
          message: CommonValidationMessages.SelectRequired.replace('{0}', 'TAC from type'),
        },
      ];
      valRule.tacFromId = tacFromIdValRule;
      if (this.state.membershipSubscription.tacFromId > 0 && this.state.membershipSubscription.tacFromId === 1) {
        let tacNameValRule = [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'TAC name'),
          },
        ];
        valRule.tacName = tacNameValRule;
      }
    }
    const returnValidState = isValidForm(this.state.membershipSubscription, valRule, this.state.validMemberSub);
    this.setState({ validMemberSub: returnValidState });
    return returnValidState.isValid;
  };
  //End Corporate Membership Subscription validation

  // Propspect Member Validation 
  prospectMemValidate = (key, object) => {
    const returnValidState = validate(key, this.state.prospectInformation, prospectInformationValRules,
      this.state.validProspectMem);
    this.setState({ validProspectMem: returnValidState, });
  };

  isValidPropectMem = () => {
    const returnValidState = isValidForm(this.state.prospectInformation, prospectInformationValRules, this.state.validProspectMem);
    this.setState({ validProspectMem: returnValidState });
    return returnValidState.isValid;
  };
  //End Prospect Member validation

  // Siis account information Validation 
  siisAccountInfoValidate = (key, object) => {
    const returnValidState = validate(key, this.state.siisAccountInformation, siisAccountInformationValRules, this.state.validSiisAccountInfo);
    this.setState({ validSiisAccountInfo: returnValidState, });
  };

  isValidSiisAccountInfo = () => {
    const returnValidState = isValidForm(this.state.siisAccountInformation, siisAccountInformationValRules, this.state.validSiisAccountInfo);
    this.setState({ validSiisAccountInfo: returnValidState });
    return returnValidState.isValid;
  };
  //End siis account information validation

  // organisation basic information Validation 
  orgBasicInfoValidate = (key, object) => {
    const returnValidState = validate(
      key,
      this.state.organizationBasicInformation, organizationBasicInformationValRules, this.state.validOrgBasicInfo);
    this.setState({ validOrgBasicInfo: returnValidState, });
  };
  // repInfoValidate = (key, object) => {


  //   if(this.state.repOfficialInformation){
  //     const returnValidState = validate(
  //       key,
  //       this.state.repOfficialInformation, officialRepInformationRules, this.state.validRepOfficialInfo);
  //       this.setState({ validRepOfficialInfo: returnValidState});
  //       return;
  //   }

  //   return
  // };


  repInfoValidate = (key, object) => {
    const { repOfficialInformation, validRepOfficialInfo } = this.state;
    if (repOfficialInformation) {
      const returnValidState = validate(
        key,
        repOfficialInformation,
        officialRepInformationRules,
        validRepOfficialInfo
      );
      this.setState({ validRepOfficialInfo: returnValidState });
      return returnValidState;
    }
    return null;
  };

  isValidOrgBasicInfo = () => {
    let valRules = { organizationBasicInformationValRules };
    if (this.state.organizationBasicInformation.organisationInformationId > 0) {
      delete valRules.organizationBasicInformationValRules.selectedDesignation;
      delete valRules.organizationBasicInformationValRules.email;
      delete valRules.organizationBasicInformationValRules.contactNo;
    }
    const returnValidState = isValidForm(this.state.organizationBasicInformation, valRules.organizationBasicInformationValRules, this.state.validOrgBasicInfo);
    this.setState({ validOrgBasicInfo: returnValidState });
    return returnValidState.isValid;
  };

  isValidRepInfo = () => {
    const returnValidState = isValidForm(this.state.repOfficialInformation, officialRepInformationRules, this.state.validRepOfficialInfo);
    this.setState({ validOrgBasicInfo: returnValidState });
    return returnValidState.isValid;
  };
  //End organisation basic information validation

  //Start corporate basic Information Validation
  validateCorporateBasic = (key, object) => {
    const returnValidState = validate(key, this.state.corporateBasicInformation, this.state.corporateBasicValidationRule, this.state.validState);
    this.setState({ validState: returnValidState, });
  };

  isValiCorporateBasic = () => {
    const returnValidState = isValidForm(this.state.corporateBasicInformation, this.state.corporateBasicValidationRule, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  };
  // /End corporate basic Information Validation

  // corporateInformationValidate = (key, object) => {
  //   const returnValidState = validate(key, this.state.corporateSIISAccount, this.state.corporateInformationvalidationRule, this.state.validState);
  //   this.setState({
  //     validState: returnValidState,
  //   });
  // };

  // isValidcorporateInformation = () => {
  //   const returnValidState = isValidForm(this.state.corporateSIISAccount, this.state.corporateInformationvalidationRule, this.state.validState);
  //   this.setState({ validState: returnValidState });
  //   return returnValidState.isValid;
  // };

  // ValidateChapterDetails = (key, object) => {
  //   const returnValidState = validate(key, this.state.chapterDetails, this.state.chapterDetailsValidationRule, this.state.validState);
  //   this.setState({ validState: returnValidState, });
  // };

  // isValidChapterDetails = () => {
  //   const returnValidState = isValidForm(this.state.chapterDetails, this.state.chapterDetailsValidationRule, this.state.validState);
  //   this.setState({ validState: returnValidState });
  //   return returnValidState.isValid;
  // };

  // End All validation methods

  // handleChangeCheckBox = (e) => {
  //   const { name, checked } = e.target;
  //   let det = this.state.corporateSIISAccount;
  //   det[name] = checked;
  //   this.setState({ corporateSIISAccount: { ...det } });
  // };

  getDomainByOrganisationId = (id) => {
    // var domainId = this.state.emailDomain.filter(domain => domain.id === id);
    // this.setState({emailDomain : domainId});
    if (id > 0) {
      var domainId = this.state.emailDomain.find((domain) => domain.id === id);
      if (domainId) {
        let setDomain = this.state.employeeDetails;
        let setDomainOrg = this.state.organisationDetails;
        if (domainId.id && domainId.id > 0) {
          setDomain.emailDomainId = domainId.id;
          setDomainOrg.emailDomainId = domainId.id;
          this.setState({
            employeeDetails: setDomain,
            organisationDetails: setDomain,
          });
        }
      }
    }
  };

  handleReset = (email = '') => {
    let reset = {
      ...this.state.organisationDetails,
      mrMrs: 1,
      firstName: "",
      lastName: "",
      email: "",
      contactNo: "",
      designation: "",
      organisationRole: 0,
      corporateMemberId: 0,
      emailDomainId: 0,
      emailText: email,
      emailString: "",
      selectedDesignation: [],
      selectedOrganisationRole: [],
      searchByEmail: ''
    };
    let res = this.state.validStateRoles;
    res.isValid = true;
    res.error = {};
    this.setState({ validStateRoles: res });
    this.setState({ organisationDetails: reset });
  };

  // onEdit = (id) => {
  //   this.getEngagementTrackingById(id);
  // }
  getEmailInformationById = (id) => {
    if (id > 0 && id != null) {
      this.corporateMemberServices.getEmailInformationById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          this.setState({ Organisationemail: response.responseItem.responseContent }
          );
        }
        else {

          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }
  setFilterParameters = (id, drpIdentity) => {
    let engagement = this.state.engagementTrackingDetails;
    let basicInfo = this.state.organizationBasicInformation;
    let siisInfo = this.state.siisAccountInformation;
    let chapter = this.state.chapterInformation;
    let orgProfile = this.state.organizationProfile;
    let bizDetails = this.state.organizationBusinessDetails;
    let subscription = this.state.membershipSubscription;
    let prospect = this.state.prospectInformation;
    let org = this.state.organisationDetails;
    let repInfo = this.state.repOfficialInformation;
    let cancel_reason = this.state.cancellationReason;
    if (drpIdentity === "updatedById") {
      siisInfo["createdBy"] = id
    }

    if (drpIdentity === "corporateReasonLeaving") {
      siisInfo["corporateReasonLeavingId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "corporateInternalFlags") {
      siisInfo["internalFlagId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "Chapters") {
      chapter["workgroupMappingId"] = id;
      this.chapterInfoValidate("workgroupMappingId")
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "organisationEmailForChapter") {
      chapter["emailId"] = id;
      this.chapterInfoValidate("emailId")
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "grossTurnOver") {
      orgProfile["orgGrossTurnoverId"] = id;
      this.validateOrganisationProfile("orgGrossTurnoverId");
    }
    else if (drpIdentity === "orgCountry") {
      orgProfile["countryId"] = id;
      this.validateOrganisationProfile("countryId");
    }
    else if (drpIdentity === "AuthorizedCap") {
      bizDetails["authorizedCapId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "ShareHoldingLocal") {
      bizDetails["shareHoldingLocalId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "RevFromOutsideSG") {
      bizDetails["revFromOutsideSGId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "PaidUpCap") {
      bizDetails["paidUpCapId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "ShareHoldingForeign") {
      bizDetails["shareHoldingForeignId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "StaffStrength") {
      bizDetails["staffStrengthId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "MembershipTypesSubscription") {
      subscription["membershipTypeId"] = id;
      this.memberSubValidate("membershipTypeId");
    }
    else if (drpIdentity === "TACFromTypeSubscription") {

      subscription["tacFromId"] = id;
      subscription["tacName"] = id > 1 && this.state.tacNames.find(x => x.id === id) ? this.state.tacNames?.find(x => x.id === id)?.name : '';

    }
    else if (drpIdentity === "ProspectSource1") {
      prospect["source1Id"] = id;
      this.prospectMemValidate("source1Id");
    }
    else if (drpIdentity === "ProspectSource2") {
      prospect["source2Id"] = id;
      this.prospectMemValidate("source2Id");
    }
    else if (drpIdentity === "ProspectPIC1") {
      prospect["pic1Id"] = id;
      this.prospectMemValidate("pic1Id");
    }
    else if (drpIdentity === "ProspectPIC2") {
      prospect["pic2Id"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "ProspectStatus") {
      prospect["prospectStatusId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "getToKnow") {
      prospect["getToKnow"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "EngagementType") {
      engagement["corporateEngagementTypeId"] = id;
      this.engagementValidate("corporateEngagementTypeId");
    }
    else if (drpIdentity === "RecordedBy") {
      engagement["corporateEngagementRecordedById"] = id;
      this.engagementValidate("corporateEngagementRecordedById");
    }
    else if (drpIdentity === "FollowUpBy") {
      engagement["corporateEngagementFollowUpById"] = id;
      // this.engagementValidate("corporateEngagementFollowUpById");
    }
    else if (drpIdentity === "Org_MrMrs") {
      org["mrMrs"] = id;
      // this.validateOrganisationRole("mrMrs");
      this.validateOrganisationKeyRoles("mrMrs");
    }
    else if (drpIdentity === "Org_EmailDomain") {
      org["emailDomainId"] = id;

      //this.validateField("membershipTypeId");
    }
    else if (drpIdentity === "Org_OrganisationRoles") {
      org["organisationRole"] = id;
      this.validateOrganisationKeyRoles("organisationRole");

      // this.validateOrganisationRole("organisationRole");
    }
    else if (drpIdentity === "NamePrefix") {
      basicInfo["mrMrsId"] = id;
      this.orgBasicInfoValidate("mrMrsId");
    }
    else if (drpIdentity === "NamePrefixForOfficialRep") {
      repInfo["mrMrsId"] = id;
      this.repInfoValidate("mrMrsId");
    }


    else if (drpIdentity === "cancel_reason") {
      //let othersArr = cancel_reason.filter((x) => x.id == id).find(({ name }) => name === "Others");
      //this.setState({ isOtherCancellationReason: othersArr !== null && othersArr !== undefined ? true : false });
      let data = { ...this.state.addEditCreditNote }
      let details = { ...this.state.corporateCreditNotePaymentInfo }
      let findData = cancel_reason.filter((x) => x.id === id);
      if (findData.length > 0) {
        details.cancellationReason = findData[0].id;
        if (findData[0].name === "Others") {
          data.cancellationReason = '';
          this.setState({ isOtherCancellationReason: true });
          this.validFinanceCreditNoteForOtherReason("cancellationReason", data);
        } else {
          data.cancellationReason = findData[0].name;
          this.setState({ isOtherCancellationReason: false });
        }
      } else {
        data.cancellationReason = '';
        details.cancellationReason = 0;
        this.setState({ isOtherCancellationReason: false, corporateCreditNotePaymentInfo: details });
      }
      this.validateFinanceCreditNote("cancellationReason", details);
      this.setState({ addEditCreditNote: data, corporateCreditNotePaymentInfo: details })
    }
    else if (drpIdentity === "Organisationemail") {
      repInfo["emailID"] = id;
      // this.officialRepinformationValidate("emailID");
      this.setState({ repInfo: repInfo }, () => {
        if (this.state.repInfo.emailID > 0) {
          this.getofficialrepinformation(this.state.repInfo.emailID)
          this.repInfoValidate('emailID')
        } else {
          this.repInfoValidate('emailID')
        }
      })


      // this.validateOrganisationRole("organisationRole");
    }
    this.setState({
      siisAccountInformation: siisInfo, organizationProfile: orgProfile, chapterInformation: chapter,
      membershipSubscription: subscription, prospectInformation: prospect, engagementTrackingDetails: engagement,
      organisationDetails: org
    });
  };
  getofficialrepinformation = (id) => {
        if (id > 0 && id != null) {
    
      this.corporateMemberServices.getofficialrepinformation(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let officialdata = { ...this.state.repOfficialInformation }
          officialdata.contactNo = response.responseItem.responseContent.businessContactNumber
          officialdata.firstName = response.responseItem.responseContent.representativeFirstName
          officialdata.lastName = response.responseItem.responseContent.representativeLastName
          officialdata.mrMrsId = response.responseItem.responseContent.mrMrsId
          if (response.responseItem.responseContent.title && response.responseItem.responseContent.title !== 0 && response.responseItem.responseContent.title !== "0") {
            let array1 = (response.responseItem.responseContent.title !== null || response.responseItem.responseContent.title !== "") ? response.responseItem.responseContent.title.split(',') : [];
            var industriesServedArray = [];
            const filteredArray = array1.filter(value => value !== "0");
            if (filteredArray && filteredArray.length > 0) {
                filteredArray?.forEach(topic => {
                    industriesServedArray.push(this.state.designationList.find(topics => topics.label === topic));
                });
            }
            officialdata.selectedDesignation = industriesServedArray
        }

          this.setState({ repOfficialInformation: officialdata });
        }
        else {

          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }
  handleChangeMultiDropdown(selected, identity) {
    var profile = this.state.organizationProfile;
    let bizDetails = this.state.organizationBusinessDetails;
    let interest = this.state.sgTechInterest;
    if (identity === "countriesWithPresence") {
      profile.selectedCountriesArr = selected;
      this.setState({ corporateBasicInformation: profile });
      this.validateOrganisationProfile("selectedCountriesArr");
    }
    else if (identity === "coSoftwares") {
      profile.selectedSoftwaresArr = selected;
      this.setState({ organizationProfile: profile });
      // this.validateField("optionSelected");
    }
    else if (identity === "businessSectors") {
      profile.selectedSectorsArr = selected;
      this.validateOrganisationProfile("selectedSectorsArr");
      this.setState({ organizationProfile: profile });
    }
    else if (identity === "coProductServices") {
      profile.selectedServicesArr = selected;
      this.setState({ organizationProfile: profile });
      // this.validateField("optionSelected");
    }
    else if (identity === "coHardwares") {
      profile.selectedHardwaresArr = selected;
      this.setState({ organizationProfile: profile });
      // this.validateField("optionSelected");
    }
    else if (identity === "areaOfExp") {
      profile.selectedExpertiseArr = selected;
      this.validateOrganisationProfile("selectedExpertiseArr");
      this.setState({ organizationProfile: profile });
    }
    else if (identity === "orgCountry") {
      profile.countryId = selected.value;
      this.validateOrganisationProfile("countryId");
      this.setState({ organizationProfile: profile });
    }

    else if (identity === "TargetMarket") {
      bizDetails.selectedTargetMarketArr = selected;
      this.setState({ organizationBusinessDetails: bizDetails });
      // this.validateField("optionSelected");
    }
    else if (identity === "SGTechInterestSGTech") {
      interest.selectedSGTechArr = selected;
      this.setState({ sgTechInterest: interest });
      // this.validateField("optionSelected");
    }
    else if (identity === "SGTechInterestSponsorship") {
      interest.selectedSponsorshipArr = selected;
      this.setState({ sgTechInterest: interest });
      // this.validateField("optionSelected");
    }
    else if (identity === "SGTechInterestMatching") {
      interest.selectedMatchingArr = selected;
      this.setState({ sgTechInterest: interest });
      // this.validateField("optionSelected");
    }
    else if (identity === "SGTechInterestProgrammes") {
      interest.selectedProgramArr = selected;
      this.setState({ sgTechInterest: interest });
      // this.validateField("optionSelected");
    } else if (identity === "Designation_BasicInfo") {
      let detail = this.state.repOfficialInformation;
      detail.selectedDesignation = selected;
      this.setState({ repOfficialInformation: detail });
    }
    else if (identity === "Designation_Employee") {
      let detail = this.state.employeeDetails;
      detail.selectedDesignation = selected;
      this.setState({ employeeDetails: detail });
    }
    else if (identity === "Designation_KeyRoles") {
      let detail = this.state.organisationDetails;
      detail.selectedDesignation = selected;
      this.setState({ organisationDetails: detail });
    } else if (identity === "Org_OrganisationRoles") {
      let detail = this.state.organisationDetails;
      detail.selectedOrganisationRole = selected;
      this.setState({ organisationDetails: detail });
    } else if (identity === "RecordedBy_Engagement") {
      let engagement = this.state.engagementTrackingDetails;
      engagement.corporateEngagementRecordedById = selected && selected.value && selected.value > 0 ? selected.value : 0;
      this.setState({ engagementTrackingDetails: engagement });
    }

  }

  handleChangeCheckBoxSIIS = (e) => {
    const { name, checked } = e.target;
    let det = this.state.siisAccountInformation;
    det[name] = checked;
    this.setState({ siisAccountInformation: { ...det } });
  };

  handleChange(event, identity) {
    const value = event.target.value;
    const name = event.target.name;

    if (identity === "SIISInformation") {
      let detail = this.state.siisAccountInformation;
      detail[name] = value;
      this.setState({ siisAccountInformation: { ...detail } });
    }
    if (identity === "OfficialRepInformation") {
      let detail = this.state.repOfficialInformation;
      detail[name] = value;
      this.setState({ repOfficialInformation: { ...detail } });
    }
    if (identity === "BasicInformation") {
      let detail = this.state.organizationBasicInformation;
      detail[name] = value;
      if (name === "email") {
        let fields = detail.email.split("@");
        detail["companyDomain"] = fields[1];
      }
      this.setState({ organizationBasicInformation: { ...detail } });
    }

    if (identity === "OrganizationProfile") {
      let detail = this.state.organizationProfile;
      detail[name] = value;
      this.setState({ organizationProfile: { ...detail } }, () => {
        if (name === "companyDescription") {
          let str = this.state.organizationProfile.companyDescription;
          this.setState({ countCompanyDescriptionForSIIS: str.length });
        }

      });
    }
    else if (identity === "EngagementTracking") {
      let detail = this.state.engagementTrackingDetails;
      detail[name] = value;
      this.setState({ engagementTrackingDetails: { ...detail } });
    }
    else if (identity === "MemebershipSubscription") {
      let detail = this.state.membershipSubscription;
      detail[name] = value;
      this.setState({ membershipSubscription: { ...detail } });
    }
    else if (identity === "ProspectInformation") {
      let detail = this.state.prospectInformation;
      detail[name] = value;
      this.setState({ prospectInformation: { ...detail } });
    }
    else if (identity === "IndividualContribution") {
      let detail = this.state.contributionDetails;
      detail[name] = value;
      this.setState({ contributionDetails: { ...detail } });
    }
    else if (identity === "Employee") {
      let detail = this.state.employeeDetails;
      detail[name] = value;
      if (name === "emailText") {
        //detail["emailString"] = value + "@abc.com";
        detail["emailString"] = value;
        this.validateEmployeeInfo("emailString");
      }
      if (name === "searchByEmail") {
        detail.searchByEmail = value;
        this.setState({ employeeDetails: { ...detail } }, () => {
          this.validateSearchByEmailFieldForEmployee("searchByEmail");
        });
      }
      detail[name] = value;
      this.setState({ employeeDetails: { ...detail } });
    }
    else if (identity === "Organisation") {
      let detail = this.state.organisationDetails;
      detail[name] = value;
      if (name === "emailText") {
        // detail["emailString"] = value + "@abc.com";
        detail["emailString"] = value;
        this.validateOrganisationKeyRoles("emailString");
      }
      else if (name === 'searchByEmail') {
        detail["searchByEmail"] = value;
        this.validateSearchByEmailField("searchByEmail");
      }
      this.setState({ organisationDetails: { ...detail } });
    }
    else if (identity === "CreditNote") {
      let detail = { ...this.state.addEditCreditNote };
      let val = value;
      let usedMonth = 0;
      let totalMonth = monthDifference(this.state.corporateCreditNotePaymentInfo.subcriptionEndDate, this.state.corporateCreditNotePaymentInfo.subscriptionStartDate, false);;
      //let data = { ...this.state.corporateCreditNotePaymentInfo };
      if (name === "creditNoteMonth") {
        if (detail.paymentStatus !== 'Pending') {

          detail[name] = val;
          usedMonth = monthDifference(detail.creditNoteMonth, this.state.corporateCreditNotePaymentInfo.subscriptionStartDate, false);
          let paidMonth = ((totalMonth - usedMonth) / totalMonth)

          detail.entranceRefund = this.state.corporateCreditNotePaymentInfo.entranceFee;
          detail.annualRefund = this.state.corporateCreditNotePaymentInfo.annualFee;
          detail.chapterRefund = this.state.corporateCreditNotePaymentInfo.addtionalChapterFee;

          detail.entranceRefund = val !== "" ? parseFloat((paidMonth * detail.entranceRefund).toFixed(2)) : detail.entranceRefund;
          detail.annualRefund = val !== "" ? parseFloat((paidMonth * detail.annualRefund).toFixed(2)) : detail.annualRefund;
          detail.chapterRefund = val !== "" ? parseFloat((paidMonth * detail.chapterRefund).toFixed(2)) : detail.chapterRefund;

          detail.subTotalRefund = parseFloat((parseFloat(detail.entranceRefund) + parseFloat(detail.annualRefund) + parseFloat(detail.chapterRefund)).toFixed(2));
          detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
          detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));

        }
      }
      else if ((val = convertToDecimal(value)) || val === 0) {
        // if(data.entranceFee < val ){
        detail[name] = val;
        this.validFinanceCreditNoteForOtherReason(name, detail);
        // }
        //detail[name] = val;
        detail.subTotalRefund = parseFloat((parseFloat(detail.entranceRefund) + parseFloat(detail.annualRefund) + parseFloat(detail.chapterRefund)).toFixed(2));
        detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
        detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));
        detail.cancellationReason = value;
      }
      this.setState({ addEditCreditNote: { ...detail } });
    }
  }

  handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    let det = this.state.corporateCommunication;
    det[name] = checked;
    this.setState({ corporateCommunication: { ...det } });
  };

  handleChangeInDropdownProspect = (selected) => {
    var resource = this.state.prospectInformation
    resource.selectedInterestChapterArr = selected
    this.setState({ prospectInformation: resource });
    // this.validateField("optionSelected");
  };

  getCorporateFinancialInfo = (corporateMemberId) => {
    if (corporateMemberId > 0 && corporateMemberId != null) {
      this.corporateMemberServices.getCorporateFinancialInfo(corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          if (response.responseItem.responseContent.length > 0) {
            this.setState({ corporateFinanceInfo: response.responseItem.responseContent });
          }
          else {
            this.swalServices.Alert('No records found.');
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    } else {

    }
  };

  financeGetCreditNoteInvoice = (value) => {
    let request = [];
    request.push(value);
    if (isNullString(value) !== "") {
      this.financeService
        .financeGetCreditNoteInvoice(request)
        .then((response) => {
          if (response.data !== null && response.status === 200) {
            var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

            var filename = "";
            filename = `Credit-Note-${formattedDate}`;

            var file = new Blob([response.data], { type: "application/pdf" });

            URL.createObjectURL(file);
            window.open(URL.createObjectURL(file), "_blank");

            saveAs(file, filename + ".pdf");
          } else {
            this.swalServices.Error(response.message);
          }
        });
    } else {
      this.swalServices.Error("Credit Note is not available");
    }
  };

  getCorporateFinanceReceiptInvoice = (receiptInvoiceId, isReceipt) => {
    if (receiptInvoiceId > 0 && receiptInvoiceId != null) {
      let request = [
        this.state.corporateMemberId,
        receiptInvoiceId,
        isReceipt
      ]
      this.corporateMemberServices.getCorporateFinanceReceiptInvoice(request).then((response) => {
        if (response.data !== null && response.status === 200) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

          var filename = "";
          if (isReceipt === true) {
            filename = `Receipt-${formattedDate}`;
          } else {
            filename = `Invoice-${formattedDate}`;
          }
          var file = new Blob([response.data], { type: "application/pdf" });

          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");

          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {

    }
  }

  getCreditNoteInfoForCorporateMember = (membershipPaymentId, paymentStatus, isReceipt) => {
    if (this.state.corporateMemberId > 0 && membershipPaymentId > 0) {
      let request = [this.state.corporateMemberId, membershipPaymentId];
      this.financeServices.getCreditNoteInfoForCorporateMember(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          this.setState({ corporateCreditNotePaymentInfo: response.responseItem.responseContent });
          let creditNote = { ...this.state.addEditCreditNote };
          if (isNullString(paymentStatus) === 'Pending' && isNullBoolean(isReceipt) === false) {
            creditNote.entranceRefund = 0
            creditNote.annualRefund = 0
            creditNote.chapterRefund = 0;
            creditNote.subTotalRefund = 0;
            creditNote.gstRefund = 0;
            creditNote.discountRefund = 0;
            creditNote.totalRefund = 0;
            creditNote.paidTotalAmount = response.responseItem.responseContent.paidPriceByReconciliation ? response.responseItem.responseContent.paidPriceByReconciliation : 0;
            creditNote.compareEntranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.compareAnnualRefund = response.responseItem.responseContent.annualFee;
            creditNote.compareChapterRefund = response.responseItem.responseContent.addtionalChapterFee;
            creditNote.creditNoteMonth = response.responseItem.responseContent.subscriptionStartDate ? response.responseItem.responseContent.subscriptionStartDate : new Date();// moment();

          } else {
            creditNote.entranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.annualRefund = response.responseItem.responseContent.annualFee;
            creditNote.chapterRefund = response.responseItem.responseContent.addtionalChapterFee;
            creditNote.subTotalRefund = response.responseItem.responseContent.subTotalAmount;
            creditNote.gstRefund = response.responseItem.responseContent.gstAmount;
            creditNote.discountRefund = response.responseItem.responseContent.discountedAmount;
            creditNote.paidTotalAmount = response.responseItem.responseContent.totalAmount;
            creditNote.totalRefund = response.responseItem.responseContent.totalAmount;
            creditNote.compareEntranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.compareAnnualRefund = response.responseItem.responseContent.annualFee;
            creditNote.compareChapterRefund = response.responseItem.responseContent.addtionalChapterFee;
            creditNote.creditNoteMonth = "";//response.responseItem.responseContent.subscriptionStartDate ? response.responseItem.responseContent.subscriptionStartDate : new Date();// moment();
          }
          creditNote.membershipPaymentTypeId = response.responseItem.responseContent.membershipPaymentTypeId;
          creditNote.paymentStatus = paymentStatus;
          this.setState({ addEditCreditNote: creditNote, gstRate: response.responseItem.responseContent.gstRate });
        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };

  getCancellationReasonForCreditNote = () => {
    this.financeServices.getCancellationReasonForCreditNote().then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null && response.responseItem.responseContent.length > 0) {
        let cancelReason = response.responseItem.responseContent;
        this.setState({ cancellationReason: cancelReason });
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  }


  isValidFinanceCreditNote = () => {
    const newValidState = isValidForm(this.state.corporateCreditNotePaymentInfo, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
    return newValidState.isValid;
  }

  validateFinanceCreditNote = (key, providedState) => {
    providedState = providedState ? providedState : this.state.corporateCreditNotePaymentInfo;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
  }

  isValidFinanceCreditNoteForOtherReason = () => {
    const newValidState = isValidForm(this.state.addEditCreditNote, addEditCreditNoteValRules2, this.state.validCreditNote2);
    this.setState({ validCreditNote2: newValidState });
    return newValidState.isValid;
  }

  validFinanceCreditNoteForOtherReason = (key, providedState) => {
    providedState = providedState ? providedState : this.state.addEditCreditNote;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules2, this.state.validCreditNote2);
    this.setState({ validCreditNote2: newValidState });
  }

  addFinanceCreditNote = () => {
    if (this.isValidFinanceCreditNote()) {
      if (this.isValidFinanceCreditNoteForOtherReason()) {
        if (this.state.addEditCreditNote.paidTotalAmount >= this.state.addEditCreditNote.totalRefund) {
          this.setState({ isLoading: true })
          let request = {
            corporateMemberId: this.state.corporateMemberId,
            bookingId: this.state.addEditCreditNote.membershipPaymentId,
            isCorporateMember: true,
            creditNoteMonth: this.state.addEditCreditNote.membershipPaymentTypeId === 1 || this.state.addEditCreditNote.membershipPaymentTypeId === 2 || this.state.addEditCreditNote.membershipPaymentTypeId === 3 ? this.state.addEditCreditNote.creditNoteMonth : this.state.corporateCreditNotePaymentInfo.subcriptionEndDate,
            creditNoteTotalRefund: this.state.addEditCreditNote.totalRefund,
            creditNoteStatusId: 1,
            creditNoteItem: [],
            creditNoteCancellationReason: this.state.addEditCreditNote.cancellationReason,
            CreatedAppId: 114,
            CreatedAt: moment(),
            CreatedBy: 1,
            creditSubTotalAmount: this.state.addEditCreditNote.subTotalRefund,
            creditNoteGSTAmount: this.state.addEditCreditNote.gstRefund,
            creditNoteDiscountAmount: this.state.addEditCreditNote.discountRefund ? this.state.addEditCreditNote.discountRefund : 0,
            membershipPaymentTypeId: this.state.addEditCreditNote.membershipPaymentTypeId,
          }
          let creditNoteItemList = [];
          let stardDate = this.state.corporateCreditNotePaymentInfo.subscriptionStartDate;
          let endDate = this.state.corporateCreditNotePaymentInfo.subcriptionEndDate;
          let membershipTypeName = this.state.corporateCreditNotePaymentInfo.membershipTypeName;
          creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Entrance Fee`, creditNoteItemAmount: this.state.addEditCreditNote.entranceRefund });
          creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Annual Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.annualRefund });
          creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Additional Chapter Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.chapterRefund });
          // creditNoteItemList.push({ creditNoteItem: "Membership Subtotal Amount", creditNoteItemAmount: this.state.addEditCreditNote.subTotalRefund });
          // creditNoteItemList.push({ creditNoteItem: "Membership GST Amount", creditNoteItemAmount: this.state.addEditCreditNote.gstRefund });
          // creditNoteItemList.push({ creditNoteItem: "Membership Discounted Amount", creditNoteItemAmount: this.state.addEditCreditNote.discountRefund });
          // creditNoteItemList.push({ creditNoteItem: "Membership Total Amount", creditNoteItemAmount: this.state.addEditCreditNote.totalRefund });
          request.creditNoteItem = creditNoteItemList;
          this.financeServices.addFinanceCreditNote(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              if (response.responseItem.responseContent.errorMessage === '') {
                this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Credit Note"));
                this.getCorporateFinancialInfo(this.state.corporateMemberId);
                //this.addCreditNoteInvoiceRecordsForBatchJob(response.responseItem.responseContent.keyId);
              }
              else {
                this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Credit note"));
              }
            }
            else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        } else {
          this.swalServices.Warning("Refund Amount Must be Less then Total Amount of Paid");
          this.setState({ isLoading: false });
        }
      }
    }
  }

  addCreditNoteInvoiceRecordsForBatchJob = (financeCreditNoteId) => {
    let request = [financeCreditNoteId];
    this.financeServices.addCreditNoteInvoiceRecordsForBatchJob(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        //let cancelReason = response.responseItem.responseContent;
        // this.setState({ cancellationReason: cancelReason });
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  }

  setOrganisationModel = (value) => {
    this.setState({ addEditOrganisationModel: value });
  }

  handleisAddEditAOrganisation = (isCreateNewOrgRole) => {
    if (isCreateNewOrgRole === true) {
      this.setState({ isAddEditAOrganisation: false, isCreateNewOrgRole: true });
    }
    else {
      this.setState({ isAddEditAOrganisation: false, isCreateNewOrgRole: false });
    }
  }

  handleisAddEditAOrganisationEmployees = (isCreateNewOrgRoleForEmployee) => {
    if (isCreateNewOrgRoleForEmployee === true) {
      this.setState({ isAddEditProfileButtonLoader: false, isCreateNewOrgRoleForEmployee: true });
    }
    else {
      this.setState({ isAddEditProfileButtonLoader: false, isCreateNewOrgRoleForEmployee: false });
    }
  }

  searchByName = (value) => {
    if (value.length > 2) {
      this.getEmployeeList(this.state.corporateMemberId, value)
    }
    else if (value.length < 3) {
      this.getEmployeeList(this.state.corporateMemberId, "")
    }
  }

  setLoader = (boolean) => {
    this.setState({ isLoaderOn: boolean });
  }

  setAddEditModel = (boolean) => {
    //this.setState({ addEditModel : boolean })
    this.setState({ addEditModelForEmployee: boolean })
    this.resetEmployeeInfo()
  }

  markIndivisualMemberAsNonMember = (id) => {
    this.setLoader(true);
    this.corporateMemberServices.markIndivisualMemberAsNonMember(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        //this.setState({ employeeList: response.responseItem.responseContent })
        this.swalServices.Success("Delete employee information successfully");
        this.setState({ addEditModelForEmployee: false }, () => {
          this.getEmployeeList(this.state.corporateMemberId, "");
          this.setLoader(false);
        });
      } else {
        this.swalServices.Error(response.message);
        this.setLoader(false);
      }
    });
  }

  // Go Global 

  getAllDropDownsForGoGlobalMembers = () => {
    this.goGlobalServices.getAllDropDownsForGoGlobalMembers().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        // var countries = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Country");
        // countries.map(function (item) { delete item["dropdownName"]; });
        // countries = countries.map((p) => ({ value: p.id, label: p.name }));
        // this.setState({ countryNameList: countries });

        var business = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "BusinessSectors");
        business.map(function (item) { delete item["dropdownName"]; });
        business = business.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ businessSectorList: business });

        var service = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Services");
        service.map(function (item) { delete item["dropdownName"]; });
        service = service.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ serviceProvidedList: service });

        var partner = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "PartnerType");
        partner.map(function (item) { delete item["dropdownName"]; });
        partner = partner.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ partnerTypeList: partner });

        // var region = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Region");
        // region.map(function (item) { delete item["dropdownName"]; });
        // region = region.map((p) => ({ value: p.id, label: p.name }));
        // this.setState({ regionList: region });

        // var city = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "City");
        // city.map(function (item) { delete item["dropdownName"]; });
        // city = city.map((p) => ({ value: p.id, label: p.name }));
        // this.setState({ cityNameList: city });

        var companyType = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CompanyType"
        );
        this.setState({ companyType: companyType });

        var countryWithPresence = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CountryWithPresence");
        countryWithPresence.map(function (item) { delete item["dropdownName"]; });
        countryWithPresence = countryWithPresence.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ countryWithPresence: countryWithPresence });

        // var countryWithPresence = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CountryWithPresence");
        // countryWithPresence.map(function (item) { delete item["dropdownName"]; });
        // countryWithPresence = countryWithPresence.map((p) => ({ value: p.id, label: p.name }));
        // this.setState({ countryWithPresence: countryWithPresence });

      }
    });
  }

  handleChangeForGoGlobal = (e) => {
    let { name, value } = e.target;
    let details = this.state.goGlobalDetails;
    details[name] = value;
    this.setState({ goGlobalDetails: details }, () => {
      this.validateGoGlobalField(name);
    });
  }

  handleChangeForGoGlobal2 = (e) => {
    let { name, value } = e.target;
    let details = this.state.goGlobalDetails;
    details[name] = value;
    this.setState({ goGlobalDetails: details }, () => {
      //this.validateGoGlobalField(name);
    });
  }

  handleChangeMultiDropdownForGoGlobal(selected, identity) {
    var details = { ...this.state.goGlobalDetails }
    if (identity === "Region") {
      details.selectedRegion = selected;
      details.selectedCountryName = [];
      details.selectedCity = [];
      details.countryName = "";
      details.countryId = 0;
      details.city = "";
      this.setState({ goGlobalDetails: details, countryNameList: [], cityNameList: [] }, () => {
        this.validateGoGlobalField("selectedRegion");
        //this.getDropdownDataByName('Region', '');
      });
    }
    if (identity === "City") {
      // if (selected.value > 0) {
      //   details.city = selected.label;
      // } else {
      //   details.city = '';
      // }
      details.selectedCity = selected;
      details.city = '';
      this.setState({ goGlobalDetails: details }, () => {
        this.validateGoGlobalField("selectedCity");
      });
    }
    if (identity === "ServiceProvided") {
      details.selectedServiceProvided = selected;
      this.setState({ goGlobalDetails: details }, () => {
        this.validateGoGlobalField("selectedServiceProvided");
      });
    }
    if (identity === "TypeOfPartner") {
      details.selectedPartnerType = selected;
      this.setState({ goGlobalDetails: details }, () => {
        this.validateGoGlobalField("selectedPartnerType");
      });
    }
    if (identity === "Country") {
      // if (selected.value > 0) {
      //   details.countryName = selected.label;
      //   details.countryId = selected.value;
      //   details.city = "";
      // } else {
      details.selectedCountryName = selected
      details.countryName = "";
      //details.countryId = 0;
      details.city = "";
      details.selectedCity = [];
      //}
      this.setState({ goGlobalDetails: details }, () => {
        this.validateGoGlobalField("selectedCountryName");
        this.getDropdownDataByName(identity, '');
      });
    }
    if (identity === "BusinessSector") {
      details.selectedBusinessSector = selected;
      this.setState({ goGlobalDetails: details }, () => {
        this.validateGoGlobalField("selectedBusinessSector");
      });
    }

    if (identity === "CountryWithPresence") {
      details.selectedCountryWithPresence = selected;
      this.setState({ goGlobalDetails: details }, () => {
        this.validateGoGlobalField("selectedCountryWithPresence");
      });
    }
    this.setState({ goGlobalDetails: details });
  }

  addEditGoGlobalDetails = () => {
    if (this.isValidateAllFieldsForGoGlobal()) {
      if (this.state.countCompanyDescription <= this.state.charLimit) {
        let request = { ...this.state.goGlobalDetails };
        if (request.selectedBusinessSector && request.selectedBusinessSector.length > 0) {
          var arr1 = request.selectedBusinessSector.map(function (item) {
            delete item.bad;
            return item.value;
          });
          request.businessSector = arr1.join([',']);
        } else {
          request.businessSector = "";
        }
        if (request.selectedServiceProvided && request.selectedServiceProvided.length > 0) {
          var arr1 = request.selectedServiceProvided.map(function (item) {
            delete item.bad;
            return item.value;
          });
          request.serviceProvided = arr1.join([',']);
        } else {
          request.serviceProvided = "";
        }
        if (request.selectedPartnerType && request.selectedPartnerType.length > 0) {
          var arr1 = request.selectedPartnerType.map(function (item) {
            delete item.bad;
            return item.value;
          });
          request.typeOfPartner = arr1.join([',']);
        } else {
          request.typeOfPartner = "";
        }
        // if (request.selectedRegion && request.selectedRegion.length > 0) {
        //   var arr1 = request.selectedRegion.map(function (item) {
        //     delete item.bad;
        //     return item.label;
        //   });
        //   request.region = arr1.join([',']);
        // } else {
        //   request.region = "";
        // }

        // if (request.selectedCountryName && request.selectedCountryName.length > 0) {
        //   var arr1 = request.selectedCountryName.map(function (item) {
        //     delete item.bad;
        //     return item.label;
        //   });
        //   request.countryName = arr1.join([',']);
        // } else {
        //   request.countryName = "";
        // }

        // if (request.selectedCity && request.selectedCity.length > 0) {
        //   var arr1 = request.selectedCity.map(function (item) {
        //     delete item.bad;
        //     return item.label;
        //   });
        //   request.city = arr1.join([',']);
        // } else {
        //   request.city = "";
        // }

        if (request.selectedCountryWithPresence && request.selectedCountryWithPresence.length > 0) {
          var arr1 = request.selectedCountryWithPresence.map(function (item) {
            delete item.bad;
            return item.value;
          });
          request.countryWithPresence = arr1.join([',']);
        } else {
          request.countryWithPresence = "";
        }

        // if (request.companyTypeId === 1) {
        //   request.organisationUENLicenseNumberVATID = "";
        //   request.companyAddress = "";
        //   request.businessContactNumber = "";
        // }
        this.goGlobalServices.addEditGoGlobalDetails(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem.responseContent != null) {
            if (response.responseItem.responseContent.errorType === 'exists') {
              this.swalServices.Error(response.responseItem.responseContent.errorMessage);
            } else {
              this.swalServices.Success("Go Global detail save successfully");
              this.getCorporateDetailForGoGLobal(this.state.corporateMemberId);
            }

          }
          else {
            this.swalServices.Error(response.message);
          }
        });
      } else {
        this.swalServices.Error('You reach the character enter limit');
      }
    }

  }
  setVideoDescription = (data) => {
    if (typeof data === 'string') {
      let detail = this.state.goGlobalDetails;
      // var re = new RegExp('<a href="([^"]+)"', "g");
      // var result = data.replace(
      //   re,
      //   '<a href=\'$1\' target="_blank" rel="noopener noreferrer"'
      // );
      detail["companyDescription"] = data;
      this.setState({ goGlobalDetails: { ...detail } }, () => {
        let str = this.state.goGlobalDetails.companyDescription.replaceAll("<p>", "")
          .replaceAll("</p>", "")
          .replaceAll("\n", "")
          .replaceAll("&nbsp;", " ").replaceAll("</br>", " ");
        this.setState({ countCompanyDescription: str.length })
      }
      );
    }
  };

  getCorporateDetailForGoGLobal = (corporateMemberId) => {
    if (corporateMemberId && corporateMemberId > 0) {
      this.goGlobalServices.getCorporateDetailForGoGLobal(corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          let data = response.responseItem.responseContent;
          let goGlobalDetails = { ...this.state.goGlobalDetails }
          goGlobalDetails.corporateMemberId = data.corporateMemberId;
          goGlobalDetails.goGlobalMemberId = data.goGlobalMemberId;
          goGlobalDetails.companyName = data.companyName;
          goGlobalDetails.companyDescription = data.companyDescription;
          goGlobalDetails.companyWebsite = data.companyWebsite;
          goGlobalDetails.region = data.region;
          goGlobalDetails.city = data.city;
          goGlobalDetails.serviceProvided = data.serviceProvided;
          goGlobalDetails.countryId = data.countryId;
          goGlobalDetails.countryName = data.countryName;
          goGlobalDetails.businessSector = data.businessSector;
          goGlobalDetails.typeOfPartner = data.typeOfPartner;
          goGlobalDetails.goGlobalContactName = data.goGlobalContactName;
          goGlobalDetails.goGlobalContactEmail = data.goGlobalContactEmail;
          goGlobalDetails.companyLogo = data.companyLogo;
          goGlobalDetails.companyTypeId = data.companyTypeId;
          goGlobalDetails.firstName = data.firstName;
          goGlobalDetails.lastName = data.lastName;
          goGlobalDetails.organisationUENLicenseNumberVATID = data.organisationUENLicenseNumberVATID;
          goGlobalDetails.companyAddress = data.companyAddress;
          goGlobalDetails.businessContactNumber = data.businessContactNumber;
          goGlobalDetails.countryWithPresence = data.countryWithPresence;
          //companyDomain: "",

          // var array1 = (goGlobalDetails.region && goGlobalDetails.region !== '0') ? goGlobalDetails.region.split(',') : [];
          // goGlobalDetails.selectedRegion = [];
          // array1.forEach(topic => {
          //   goGlobalDetails.selectedRegion.push(this.state.regionList.find(topics => topics.label === topic));
          // });

          var array2 = (goGlobalDetails.businessSector && goGlobalDetails.businessSector !== '0') ? goGlobalDetails.businessSector.split(',') : [];
          goGlobalDetails.selectedBusinessSector = [];
          array2.forEach(topic => {
            goGlobalDetails.selectedBusinessSector.push(this.state.businessSectorList.find(topics => topics.value === Number(topic)));
          });

          var array3 = (goGlobalDetails.serviceProvided && goGlobalDetails.serviceProvided !== '0') ? goGlobalDetails.serviceProvided.split(',') : [];
          goGlobalDetails.selectedServiceProvided = [];
          array3.forEach(topic => {
            goGlobalDetails.selectedServiceProvided.push(this.state.serviceProvidedList.find(topics => topics.value === Number(topic)));
          });

          var array3 = (goGlobalDetails.typeOfPartner && goGlobalDetails.typeOfPartner !== '0') ? goGlobalDetails.typeOfPartner.split(',') : [];
          goGlobalDetails.selectedPartnerType = [];
          array3.forEach(topic => {
            goGlobalDetails.selectedPartnerType.push(this.state.partnerTypeList.find(topics => topics.value === Number(topic)));
          });

          var array = (goGlobalDetails.countryWithPresence && goGlobalDetails.countryWithPresence !== '0') ? goGlobalDetails.countryWithPresence.split(',') : [];
          goGlobalDetails.selectedCountryWithPresence = [];
          array.forEach(topic => {
            goGlobalDetails.selectedCountryWithPresence.push(this.state.countryWithPresence.find(topics => topics.value === Number(topic)));
          });



          this.setState({ goGlobalDetails: goGlobalDetails }, () => {
            //this.getDropdownDataByName('Region', '');
            //this.getDropdownDataByName('Country', goGlobalDetails.countryName);
            // let str = this.state.goGlobalDetails.companyDescription.replaceAll("<p>", "")
            //   .replaceAll("</p>", "")
            //   .replaceAll("\n", "")
            //   .replaceAll("&nbsp;", " ").replaceAll("</br>", " ");
            // this.setState({ countCompanyDescription: str.length })
          });
        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }

  // saveMainImage = (file, keyId, imageIdentity) => {
  //   //file.storagePath = imageIdentity;
  //   this.corporateMemberServices.storeBase64Image(file).then((response) => {
  //     if (response != null) {
  //       let detail = this.state.accountDetails;
  //       if (file.storagePath === "CorporateMembers") {
  //         detail["companyLogo"] = response.fileName;
  //         this.setState({ accountDetails: { ...detail } });
  //       }
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //   });
  // };

  saveMainImageForGoGlobal = (file, keyId, imageIdentity) => {
    this.setState({ isLoaderEnabled: true });
    //file.storagePath = "CorporateMembers";
    this.commonServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("File Uploaded");
        if (file.storagePath === "CorporateMembers") {
          let detail = this.state.goGlobalDetails;
          detail["companyLogo"] = response.fileName;
          this.setState({ goGlobalDetails: { ...detail } }, () => this.setState({ isLoaderEnabled: false }));
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
    })
  }

  validateGoGlobalField = (key) => {
    const newValidState = validate(
      key,
      this.state.goGlobalDetails,
      validationRuleForGoGlobal,
      this.state.validStateForGoGlobal
    );
    this.setState({ validStateForGoGlobal: newValidState });
  };

  isValidateAllFieldsForGoGlobal = () => {
    const newValidState = isValidForm(
      this.state.goGlobalDetails,
      validationRuleForGoGlobal,
      this.state.validStateForGoGlobal
    );
    this.setState({ validStateForGoGlobal: newValidState });
    return newValidState.isValid;
  };

  getDropdownDataByName = (identity, selected) => {
    //if (identity === 'Region') {    
    // if (identity === 'Region') {
    //   let selectedRegionList = [...this.state.goGlobalDetails.selectedRegion];
    //   selectedRegionList && selectedRegionList.map((x, keyId) => {
    //     if (keyId === 0) { selected = x.label; }
    //     else { selected = selected + ',' + x.label; }
    //   });
    //   selected = selected ? selected : '';
    // }
    let selectedList = [];
    if (identity === 'Region') {
      selectedList = [...this.state.goGlobalDetails.selectedRegion];
    } else {
      selectedList = [...this.state.goGlobalDetails.selectedCountryName];
    }
    selectedList && selectedList.map((x, keyId) => {
      if (keyId === 0) { selected = x.label; }
      else { selected = selected + ',' + x.label; }
    });
    selected = selected ? selected : '';

    if (identity && selected && selected !== '') {
      let request = {
        name: selected,
        isRegion: identity === 'Region' ? 1 : 0,
      };
      this.goGlobalServices.getDropdownDataByName(request).then((response) => {
        if (response.responseItem.responseContent != null) {
          if (identity === 'Region') {
            var countries = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Country");
            countries.map(function (item) { delete item["dropdownName"]; });
            countries = countries.map((p) => ({ value: p.id, label: p.name }));
            this.setState({ countryNameList: countries }, () => {
              let data = { ...this.state.goGlobalDetails };
              let array = data.countryName ? data.countryName.split(',') : [];
              data.selectedCountryName = [];
              array.forEach(topic => {
                data.selectedCountryName.push(this.state.countryNameList.find(topics => topics.label === topic));
              });
              this.setState({ goGlobalDetails: data }, () => {
                this.getDropdownDataByName('Country', data.countryName);
              })
            });
          } else if (identity === 'Country') {
            var city = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "City");
            city.map(function (item) { delete item["dropdownName"]; });
            city = city.map((p) => ({ value: p.id, label: p.name }));
            this.setState({ cityNameList: city }, () => {
              let data = { ...this.state.goGlobalDetails };
              let array = data.city ? data.city.split(',') : [];
              data.selectedCity = [];
              array.forEach(topic => {
                data.selectedCity.push(this.state.cityNameList.find(topics => topics.label === topic));
              });
              this.setState({ goGlobalDetails: data });
            });
          }
        } else {
          this.swalServices.Error("Something went wrong.");
        }
      });
    }
  }

  setFilterParametersGoGlobal = (id, drpIdentity) => {
    let request = this.state.goGlobalDetails;
    if (drpIdentity === "CompanyType") {
      request.companyTypeId = id;
      this.validateGoGlobalField("companyTypeId");
    }
    this.setState({ goGlobalDetails: request });
  }

  onCancel = () => {
    let chapDetails = this.state.chapterInformation
    chapDetails.isEditTrue = false
    chapDetails.workgroupMappingId = 0
    chapDetails.emailId = this.state.repOfficialInformation.emailID > 0 ? this.state.repOfficialInformation.emailID : 0 
    this.setState({ chapterInformation : chapDetails })
  }

  render() {
    return (
      <div className="main-body-section">
        {/* New  Start*/}
        {/* SIIS Corporate Account Information Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="SIISACC"
              type="checkbox"
              name="corporate-d"
              value={"SIISAccountInformation"}
              checked={this.state.openSection === "SIISAccountInformation"}
              onClick={() => this.openSection("SIISAccountInformation")}
              onChange={() => { }}
            />
            <label
              className="heading-part lg:px-7 sm:px-3 px-3 py-4 lg:flex sm:block block items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="SIISACC"
            >
              <span className="small-title font-bold theme-color 2xl:pl-5 xl:pl-5 lg:pl-5 md:pl-[35px] sm:pl-[35px] pl-[35px] block">
                SIIS Corporate Account Information
              </span>
              <div className="flex  md-pl-0 2xl:pl-5 xl:pl-5 lg:pl-5 md:pl-[35px] sm:pl-[35px] pl-[35px] items-left justify-between text-[18px] text-[#757575]">
                <p className="lg:text-[18px] text-[14px]">SIIS Individual ID: {this.state.siisAccountInformation.siisAccountId ? this.state.siisAccountInformation.siisAccountId : "SIIS"} | Engagement Score System: XX</p>
              </div>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
              <SIISCorporateAccountInformation
                status={this.state.status}
                reason={this.state.reason}
                _by={this.state._by}
                corporateInternalFlags={this.state.corporateInternalFlags}
                siisAccountInformation={this.state.siisAccountInformation}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditSIISCorporateAccountInformation.bind(this)}
                handleChange={this.handleChange.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                validState={this.state.validSiisAccountInfo}
                validate={this.siisAccountInfoValidate.bind(this)}
                handleChangeCheckBoxSIIS={this.handleChangeCheckBoxSIIS.bind(this)}
                isLoading={this.state.isLoading}
              />
            </div>
          </div>
        </div>
        {/* SIIS Corporate Account Information End*/}

        {/* Go Global Information Start*/}
        {this.state.isSelectedGoGlobal && this.state.isSelectedGoGlobal === true ?
          <>
            <div className="bg-white border border-[#c9c9c9] mb-3">
              <div className="tab w-full border-t ">
                <input
                  className="absolute opacity-0 toggle-main"
                  id="GoGloInfo"
                  type="checkbox"
                  name="corporate-d"
                  value={"GoGlobalInformation"}
                  checked={this.state.openSection === "GoGlobalInformation"}
                  onChange={() => { }}
                  onClick={() => this.openSection("GoGlobalInformation")}
                />
                <label
                  className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                  htmlFor="GoGloInfo"
                >
                  <span className="small-title font-bold theme-color pl-5">
                    Go Global Profile
                  </span>
                </label>
                <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                  <GoGlobalInforamtion
                    goGlobalDetails={this.state.goGlobalDetails}
                    countryNameList={this.state.countryNameList}
                    businessSectorList={this.state.businessSectorList}
                    providedServiceList={this.state.serviceProvidedList}
                    partnerTypeList={this.state.partnerTypeList}
                    regionList={this.state.regionList}
                    cityNameList={this.state.cityNameList}
                    companyType={this.state.companyType}
                    countryWithPresence={this.state.countryWithPresence}
                    handleChangeForGoGlobal={this.handleChangeForGoGlobal.bind(this)}
                    handleChangeForGoGlobal2={this.handleChangeForGoGlobal2.bind(this)}
                    handleChangeMultiDropdown={this.handleChangeMultiDropdownForGoGlobal.bind(this)}
                    onSave={this.addEditGoGlobalDetails.bind(this)}
                    saveMainImageForGoGlobal={this.saveMainImageForGoGlobal.bind(this)}
                    validStateForGoGlobal={this.state.validStateForGoGlobal}
                    validateGoGlobalField={this.validateGoGlobalField.bind(this)}
                    setVideoDescription={this.setVideoDescription.bind(this)}
                    setFilterParameters={this.setFilterParametersGoGlobal.bind(this)}
                    countCompanyDescription={this.state.countCompanyDescription}
                    charLimit={this.state.charLimit}
                  />
                  {/* <SIISCorporateAccountInformation
                status={this.state.status}
                reason={this.state.reason}
                _by={this.state._by}
                corporateInternalFlags={this.state.corporateInternalFlags}
                siisAccountInformation={this.state.siisAccountInformation}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditSIISCorporateAccountInformation.bind(this)}
                handleChange={this.handleChange.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                validState={this.state.validSiisAccountInfo}
                validate={this.siisAccountInfoValidate.bind(this)}
                handleChangeCheckBoxSIIS={this.handleChangeCheckBoxSIIS.bind(this)}
                isLoading={this.state.isLoading}
              /> */}
                </div>
              </div>
            </div>
          </>
          :
          null
        }

        {/* Go Global Information End*/}

        {/* Engagement Tracking and Notes Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full  border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="engtrack"
              type="checkbox"
              name="corporate-d"
              value={"EngagementTracking"}
              checked={this.state.openSection === "EngagementTracking"}
              onChange={() => { }}
              onClick={() => this.openSection("EngagementTracking")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="engtrack"
            >
              <span className="small-title font-bold theme-color pl-5">
                Engagement Tracking and Notes
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <EngagementTrackingNotes
                engagementType={this.state.engagementType}
                _By={this.state._by}
                engagementList={this.state.engagemmentTrackingList}
                engagementTrackingDetails={this.state.engagementTrackingDetails}
                isCorporate={this.state.corporateMemberId}
                validState={this.state.validState}
                onEdit={this.onEditEngagement.bind(this)}
                onClick={this.addEditEngagementTracking.bind(this)}
                handleChange={this.handleChange.bind(this)}
                setFilterParameters={this.setFilterParameters.bind(this)}
                validate={this.engagementValidate.bind(this)}
                isLoading={this.state.isLoading}
                recorded_by={this.state.recorded_by}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* Engagement Tracking and Notes End */}
        {/* Prospect Information Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="ProspectInfo"
              type="checkbox"
              name="corporate-d"
              value={"ProspectInformation"}
              checked={this.state.openSection === "ProspectInformation"}
              onChange={() => { }}
              onClick={() => this.openSection("ProspectInformation")}
            />
            <label
              className="heading-part px-7 py-4 mb-0 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="ProspectInfo"
            >
              <span className="small-title font-bold theme-color pl-5">
                Prospect Information
              </span>
              {/* <div className="flex items-left justify-between text-[18px] text-[#757575]">
                <p>SIIS Individual ID: S12345 | Engagement Score System: XX</p>
              </div> */}
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <ProspectInformation
                prospectInformation={this.state.prospectInformation}
                status={this.state.status}
                _by={this.state._by}
                source1={this.state.source1}
                corporatePIC1={this.state.corporatePIC1}
                corporatePIC2={this.state.corporatePIC2}
                prospectSource2={this.state.prospectSource2}
                prospectStatus={this.state.prospectStatus}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditCorporateProspect.bind(this)}
                handleChange={this.handleChange.bind(this)}
                validState={this.state.validProspectMem}
                validate={this.prospectMemValidate.bind(this)}
                interestedChapter={this.state.interestedChapter}
                getToKnowUsArr={this.state.getToKnowUsArr}
                handleChangeInDropdown={this.handleChangeInDropdownProspect.bind(this)}
                isLoading={this.state.isLoading}
              />
            </div>
          </div>
        </div>
        {/* Prospect Information End */}
        {/* Finance Card Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="Financecard"
              type="checkbox"
              name="corporate-d"
              value={"Financecard"}
              checked={this.state.openSection === "Financecard"}
              onChange={() => { }}
              onClick={() => this.openSection("Financecard")}
            />
            <label
              className="heading-part px-7 py-4 mb-0 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="Financecard"
            >
              <span className="small-title font-bold theme-color pl-5">
                Finance Card
              </span>
              {/* <div className="flex items-left justify-between text-[18px] text-[#757575]">
                <p>SIIS Individual ID: S12345 | Engagement Score System: XX</p>
              </div> */}
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <FinanceCard
                corporateFinance={this.state.corporateFinance}
                handleChangeCheckBox={this.handleChangeCheckBoxFinanceCard.bind(this)}
                corporateCreditTerms={this.state.corporateCreditTerms}
                setFilterParameters={this.setFilterParametersFinanceCard.bind(this)}
                onSave={this.addEditFinanceCardDetails.bind(this)}
                validState={this.state.validStateFinanceCard}
                isLoading={this.state.isLoading}
              />
            </div>
          </div>
        </div>
        {/* Finance Card End */}
        {/* Government Program/Strategic Member  Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="GovernmentProgram"
              type="checkbox"
              name="corporate-d"
              value={"GovernmentProgram"}
              checked={this.state.openSection === "GovernmentProgram"}
              onChange={() => { }}
              onClick={() => this.openSection("GovernmentProgram")}
            />
            <label
              className="heading-part px-7 py-4 mb-0 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="GovernmentProgram"
            >
              <span className="small-title font-bold theme-color pl-5">
                Government Program/Strategic Member
              </span>
              {/* <div className="flex items-left justify-between text-[18px] text-[#757575]">
                <p>SIIS Individual ID: S12345 | Engagement Score System: XX</p>
              </div> */}
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <GovernmentProgramStrategicMember
                governmentProgramme={this.state.governmentProgramme}
                handleChangeCheckBox={this.handleChangeCheckBoxGovProgram.bind(this)}
                onSave={this.addEditGovernmentProgramme.bind(this)}
                activeProjects={this.state.activeProjects}
                pastProjects={this.state.pastProjects}
                futureProjects={this.state.futureProjects}
                govProjectsNameList={this.state.govProjectsNameList}
                isLoading={this.state.isLoading}
              />
            </div>
          </div>
        </div>
        {/* Government Program/Strategic Member End */}
        {/* Corporate Membership Subscription Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="CorporateSubscrib"
              type="checkbox"
              name="corporate-d"
              value={"CorporateSubscrib"}
              checked={this.state.openSection === "CorporateSubscription"}
              onChange={() => { }}
              onClick={() => this.openSection("CorporateSubscription")}
            />
            <label
              className="heading-part px-7 py-4 mb-0 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="CorporateSubscrib"
            >
              <span className="small-title font-bold theme-color pl-5">
                Corporate Membership Subscription
              </span>
              {/* <div className="flex items-left justify-between text-[18px] text-[#757575]">
                <p>SIIS Individual ID: S12345 | Engagement Score System: XX</p>
              </div> */}
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <CorporateMembershipSubscription
                _by={this.state._by}
                billingCategory={this.state.billingCategory}
                membershipTypes={this.state.membershipTypes}
                membershipSubscription={this.state.membershipSubscription}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditCorporateMembershipSubscription.bind(this)}
                handleChange={this.handleChange.bind(this)}
                validState={this.state.validMemberSub}
                validate={this.memberSubValidate.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                renewCorporateMember={this.renewCorporateMember.bind(this)}
                isLoading={this.state.isLoading}
                isLoadingForRenewal={this.state.isLoadingForRenewal}
                tacNames={this.state.tacNames}

              />
            </div>
          </div>
        </div>
        {/* Corporate Membership Subscription End */}
        {/* Corporate Chapter Information Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="chepter"
              type="checkbox"
              name="corporate-d"
              value={"ChapterInformation"}
              checked={this.state.openSection === "ChapterInformation"}
              onChange={() => { }}
              onClick={() => this.openSection("ChapterInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="chepter"
            >
              <span className="small-title font-bold theme-color pl-5">
                Corporate Chapter Information
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <ChapterInformation
                chapterList={this.state.chapterList}
                chapterEmailId = {this.state.chapterEmailId}
                repOfficialInformation={this.state.repOfficialInformation}
                deletedChapterList={this.state.deletedChapterList}
                chaptersList={this.state.chaptersList}
                chapterInformation={this.state.chapterInformation}
                Organisationemail={this.state.Organisationemail}
                isLoadingForJoinChapter={this.state.isLoadingForJoinChapter}
                availableChapters={this.state.availableChapters}
                deleteChapter={this.deleteChapter.bind(this)}
                save={this.joinChapterById.bind(this)}
                updateChapterById={this.updateChapterById.bind(this)}
                getChapterInformation  = {this.getChapterInformation.bind(this)}
                setFilterParameters={this.setFilterParameters.bind(this)}
                validState={this.state.validStateChapter}
                validate={this.chapterInfoValidate.bind(this)}
                _by={this.state._by}
                // buy chapter
                buyChapter={this.openBuyChapterModal.bind(this)}
                //closeBuyChapterModal={this.closeBuyChapterModal.bind(this)}
                setOpenModal={() =>
                  this.setState({ setBuyChapterModal: false })

                }
                //onClose={this.closeBuyChapterModal.bind(this)}
                setBuyChapterModal={this.state.setBuyChapterModal}
                chapterListArr={this.state.chapterListArr}
                paymentsMethods={this.state.paymentsMethods}
                purchaseChapter={this.state.purchaseChapter}
                setBuyChapterFilterParameters={(id, drpIdentity) =>
                  this.setBuyChapterFilterParameters(id, drpIdentity)
                }
                handleChangeInPoNumber={this.handleChangeInPoNumber.bind(this)}
                gstRate={this.state.mainGSTRate}
                discountedAmount={this.state.discountedAmount}
                handleChangeForBuyChapter={this.handleChangeForBuyChapter.bind(this)}
                buyChapterForCorporateMember={() =>
                  this.buyChapterForCorporateMember()
                }
                loadingForPayment={this.state.loadingForPayment}
                responseMessage={this.state.responseMessage}
                applyVoucherCodeForAdditionalChapter={this.applyVoucherCodeForAdditionalChapter.bind(this)}
                isFixedPrice={this.state.isFixedPrice}
                removePromoCode={() => {
                  let data = { ...this.state.purchaseChapter }
                  data.voucherCode = "";
                  this.setState({ discountedAmount: 0, responseMessage: "", voucherCode: "", purchaseChapter: data, isFixedPrice: false }, () => this.setBuyChapterFilterParameters(this.state.purchaseChapter.noOfAdditionalChapters, "BuyChapters"));
                }}
                buyChapterValidState={this.state.buyChapterValidState}
                availableChapterListForUpcomingTenure={this.state.availableChapterListForUpcomingTenure}
                onCancel = {this.onCancel.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* Corporate Chapter Information End */}
        {/* Corporate Basic Information Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="OBI"
              type="checkbox"
              name="corporate-d"
              value={"OrganisationBasicInformation"}
              checked={
                this.state.openSection === "OrganisationBasicInformation"
              }
              onChange={() => { }}
              onClick={() => this.openSection("OrganisationBasicInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="OBI"
            >
              <span className="small-title font-bold theme-color pl-5">
                Organisation Basic Information
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <OrganisationBasicInformations
                membershipTypes={this.state.membershipTypes}
                organizationBasicInformation={this.state.organizationBasicInformation}
                disableUenNumber={this.state.disableUenNumber}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditOrganisationBasicInformation.bind(this)}
                handleChange={this.handleChange.bind(this)}
                targetMarket={this.state.targetMarket}
                validState={this.state.validOrgBasicInfo}
                validate={this.orgBasicInfoValidate.bind(this)}
                mrMrs={this.state.mrMrs}
                designationList={this.state.designationList}
                membershipSubscription={this.state.membershipSubscription}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                isLoading={this.state.isLoading}
              // setLoginToCorporateModalTrue={this.setLoginToCorporateModalTrue.bind(
              //   this
              // )}
              // isLoginToCorporateAllowed={this.state.isLoginToCorporateAllowed}
              />
            </div>
          </div>
        </div>
        {/* Corporate Basic Information End */}

        {/* Official Rep information Start */}

        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="ORI"
              type="checkbox"
              name="corporate-d"
              value={"OfficialRepInformation"}
              checked={
                this.state.openSection === "OfficialRepInformation"
              }
              onChange={() => { }}
              onClick={() => this.openSection("OfficialRepInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="ORI"
            >
              <span className="small-title font-bold theme-color pl-5">
                Official Rep Information
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">

              <OfficialRepInformation
                repOfficialInformation={this.state.repOfficialInformation}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditOfficialRefInformation.bind(this)}
                handleChange={this.handleChange.bind(this)}
                validState={this.state.validRepOfficialInfo}
                validate={this.repInfoValidate.bind(this)}
                mrMrs={this.state.mrMrs}
                isLoading={this.state.isLoading}
                Organisationemail={this.state.Organisationemail}
                designationList={this.state.designationList}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
              // setLoginToCorporateModalTrue={this.setLoginToCorporateModalTrue.bind(
              //   this
              // )}
              // isLoginToCorporateAllowed={this.state.isLoginToCorporateAllowed}
              />
            </div>
          </div>
        </div>

        {/* Official Rep information End */}
        {/* Organisation Profile Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="OPRS"
              type="checkbox"
              name="corporate-d"
              value={"OrganisationProfile"}
              checked={this.state.openSection === "OrganisationProfile"}
              onChange={() => { }}
              onClick={() => this.openSection("OrganisationProfile")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="OPRS"
            >
              <span className="small-title font-bold theme-color pl-5">
                Organisation Profile
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <OrganisationProfile
                organizationProfile={this.state.organizationProfile}
                countriesWithPresence={this.state.countriesWithPresence}
                coProductSoftware={this.state.coProductSoftware}
                coProductService={this.state.coProductService}
                businessSector={this.state.businessSector}
                areaOfExpertise={this.state.areaOfExpertise}
                coProductHardware={this.state.coProductHardware}
                corporateGrossTurnovers={this.state.corporateGrossTurnovers}
                organizationRoleArray={this.state.organizationRoleArray}
                countriesSingleDrp={this.state.countriesSingleDrp}
                isLoading={this.state.isLoading}
                _By={this.state._by}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditOrganisationProfile.bind(this)}
                handleChange={this.handleChange.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                saveMainImage={this.saveMainImage.bind(this)}
                validState={this.state.validStateProfile}
                validate={this.validateOrganisationProfile.bind(this)}
                countCompanyDescriptionForSIIS={this.state.countCompanyDescriptionForSIIS}
                charLimit={this.state.charLimit}
              />
            </div>
          </div>
        </div>
        {/* Organisation Profile End */}
        {/* Organisation Business Details Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="OBDS"
              type="checkbox"
              name="corporate-d"
              value={"OrganisationBusinessDetail"}
              checked={this.state.openSection === "OrganisationBusinessDetail"}
              onChange={() => { }}
              onClick={() => this.openSection("OrganisationBusinessDetail")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="OBDS"
            >
              <span className="small-title font-bold theme-color pl-5">
                Organisation Business Details
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <OrganisationBasicInformation
                authorizedCaps={this.state.authorizedCaps}
                shareHoldingLocals={this.state.shareHoldingLocals}
                revFromOutsideSG={this.state.revFromOutsideSG}
                paidUpCaps={this.state.paidUpCaps}
                shareHoldingForeign={this.state.shareHoldingForeign}
                countriesWithPresence={this.state.countriesWithPresence}
                targetMarket={this.state.targetMarket}
                validState={this.state.validState}
                companyEmploymentSize={this.state.companyEmploymentSize}
                _by={this.state._by}
                organizationBusinessDetails={this.state.organizationBusinessDetails}
                isLoading={this.state.isLoading}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditOrganisationBussinessDetails.bind(this)}
                handleChange={this.handleChange.bind(this)}
                validate={this.validateCorporateBasic.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* Organisation Business Details End */}
        {/* SGTech Interest Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="SGTIN"
              type="checkbox"
              name="corporate-d"
              value={"SGTechInterest"}
              checked={this.state.openSection === "SGTechInterest"}
              onChange={() => { }}
              onClick={() => this.openSection("SGTechInterest")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="SGTIN"
            >
              <span className="small-title font-bold theme-color pl-5">
                SGTech Interest
              </span>
              {/* <div className="flex items-left justify-between text-[18px] text-[#757575]">
                <p>SIIS Individual ID: S12345 | Engagement Score System: XX</p>
              </div> */}
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <SGTechInterest
                _by={this.state._by}
                sgTech={this.state.sgTech}
                sponsorship={this.state.sponsorship}
                matching={this.state.matching}
                program={this.state.programs}
                sgTechInterest={this.state.sgTechInterest}
                isLoading={this.state.isLoading}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditSGTechIntrest.bind(this)}
                handleChange={this.handleChange.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* SGTech Interest End */}

        {/* Communication Start */}
        {/* <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="Communication"
              type="checkbox"
              name="corporate-d"
              value={"Communication"}
              checked={this.state.openSection === "Communication"}
              onChange={() => {}}
              onClick={() => this.openSection("Communication")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="Communication"
            >
              <span className="small-title font-bold theme-color pl-5">
                Communication
              </span>
     
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <Communication
                _by={this.state._by}
                corporateCommunication={this.state.corporateCommunication}
                isLoading={this.state.isLoading}
                onSave={this.addEditCommunication.bind(this)}
                handleChange={this.handleChange.bind(this)}
                handleChangeCheckBox={this.handleChangeCheckBox.bind(this)}
              />
            </div>
          </div>
        </div> */}
        {/* Communication End */}

        {/* Organisation Employees Section Start  */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="emply"
              type="checkbox"
              name="corporate-d"
              value={"Employees"}
              checked={this.state.openSection === "Employees"}
              onChange={() => { }}
              onClick={() => this.openSection("Employees")}
            />
            <label
              className="heading-part lg:px-7 sm:px-3 px-3 py-4 lg:flex sm:flex flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="emply"
            >
              <span className="small-title font-bold theme-color 2xl:pl-5 xl:pl-5 lg:pl-5 md:pl-[35px] sm:pl-[35px]  pl-[35px] lg:w-[40%]">
                Organisation Employees
              </span>
              {/* <div className="">
                <h1 className="">
                  Employee Information
                </h1>
                
              </div> */}
              <div className="lg:flex sm:flex flex lg:pl-0 xl:pl-0 2xl:pl-0  md:pl-[20px] pl-[15px]">
                <div className="flex items-center lg:justify-end 2xl:justify-end justify-start mt-1 mb-1 right-id-sec 2xl:w-[100%] lg:w-[100%] w-[100%] px-5">
                  <button
                    className="flex items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.resetEmployeeInfo();
                      this.setState({ addEditModelForEmployee: true, isCreateNewEmployee: true, isEditEmployee: false });
                      this.handleisAddEditAOrganisationEmployees(true);
                    }}
                  >
                    <span className="text-xl text-[#c00000] font-semibold mr-4 min-w-[30px] min-h-[20px] border border-[#c00000]">
                      +
                    </span>
                    <span className="text-xl text-[#c00000] font-semibold tracking-normal hidden lg:block 2xl:block xl:block sm:block  ">
                      Add Employee
                    </span>
                  </button>
                </div>
                <div className="lg:flex items-center justify-between right-id-sec 2xl:w-[450px] lg:w-[100%] sm:w-[100%] xl:w-[100%] md:w-[100%] w-[100%] hidden lg:flex 2xl:flex xl:flex sm:flex  ">
                  <input
                    className={`w-full outline-none appearance-none form-input rounded-none border-[#757575]  text-[#757575]}`}
                    placeholder="Search"
                    type="text"
                    name=""
                    onChange={(e) => this.searchByName(e.target.value)}
                  />
                </div>
              </div>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <EmployeesList
                employeeList={this.state.employeeList}
                emailDomain={this.state.emailDomain}
                employeeDetails={this.state.employeeDetails}
                validState={this.state.validStateEmp}
                domainArr={this.state.domainArr}
                mrMrs={this.state.mrMrs}
                isLoaderOn={this.state.isLoaderOn}
                onEditEmployee={this.onEditEmployee.bind(this)}
                saveEmployee={this.addEditEmployeeInformation.bind(this)}
                setFilterParameters={this.setFilterParametersEmployee.bind(this)}
                handleChange={this.handleChange.bind(this)}
                validate={this.validateEmployeeInfo.bind(this)}
                designationList={this.state.designationList}
                //addEditModelForEmployee={this.state.addEditModelForEmployee}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                setLoader={this.setLoader.bind(this)}
                setAddEditModel={this.setAddEditModel.bind(this)}
                markIndivisualMemberAsNonMember={this.markIndivisualMemberAsNonMember.bind(this)}
                addEditModelForEmployee={this.state.addEditModelForEmployee}
                isCreateNewEmployee={this.state.isCreateNewEmployee}
                isEditEmployee={this.state.isEditEmployee}
                isEmployeeModalEditable={this.state.isEmployeeModalEditable}
                searchIndividualMemberByEmailForOrgRole={this.searchIndividualMemberByEmailForOrgRole.bind(this)}
                isCreateNewOrgRoleForEmployee={this.state.isCreateNewOrgRoleForEmployee}
                handleisAddEditAOrganisationEmployees={this.handleisAddEditAOrganisationEmployees.bind(this)}
                isAddEditProfileButtonLoader={this.state.isAddEditProfileButtonLoader}
              //searchIndividualMemberByEmailForEmployee={this.searchIndividualMemberByEmailForEmployee.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* Organisation Employees Section End */}

        {/* Financials Record Start */}
        <div className="bg-[#f5faff] border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="finance"
              type="checkbox"
              name="corporate-d"
              value={"FinancialRecords"}
              checked={this.state.openSection === "FinancialRecords"}
              onChange={() => { }}
              onClick={() => this.openSection("FinancialRecords")}
            />
            <label
              className="bg-white heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="finance"
            >
              <span className="small-title font-bold theme-color pl-5">
                Financials
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal">
              <FinancialsRecord
                corporateFinanceInfo={this.state.corporateFinanceInfo}
                getCorporateFinanceReceiptInvoice={this.getCorporateFinanceReceiptInvoice.bind(this)}
                financeGetCreditNoteInvoice={this.financeGetCreditNoteInvoice.bind(this)}
                getCreditNoteInfoForCorporateMember={this.getCreditNoteInfoForCorporateMember.bind(this)}
                corporateCreditNotePaymentInfo={this.state.corporateCreditNotePaymentInfo}
                getCancellationReasonForCreditNote={this.getCancellationReasonForCreditNote.bind(this)}
                cancellationReason={this.state.cancellationReason}
                setFilterParameters={this.setFilterParameters.bind(this)}
                isOtherCancellationReason={this.state.isOtherCancellationReason}
                addEditCreditNote={this.state.addEditCreditNote}
                handleChange={this.handleChange.bind(this)}
                addFinanceCreditNote={this.addFinanceCreditNote.bind(this)}
                validate={this.validateFinanceCreditNote.bind(this)}
                validCreditNote={this.state.validCreditNote}
                validate2={this.validFinanceCreditNoteForOtherReason.bind(this)}
                validCreditNote2={this.state.validCreditNote2}
                isLoading={this.state.isLoading}
              />
            </div>
          </div>
        </div>

        {/* Events Bookings Start */}
        {/* <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="eventb"
              type="checkbox"
              name="corporate-d"
              value={"EventsBooking"}
              checked={this.state.openSection === "EventsBooking"}
              onChange={() => {}}
              onClick={() => this.openSection("EventsBooking")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="eventb"
            >
              <span className="small-title font-bold theme-color pl-5">
                Event Booking
              </span>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <EventsBookings />
            </div>
          </div>
        </div> */}
        {/* Events Bookings End */}
        {/* Organisation Key Role & Chapter/Committee Roles Start */}
        <div className="w-full">
          <OrganisationChapterRole
            mrMrs={this.state.mrMrs}
            emailDomain={this.state.emailDomain}
            domainArr={this.state.domainArr}
            Organisationrole={this.state.Organisationrole}
            chaptercommitteerol={this.state.chaptercommitteerol}
            organisationDetails={this.state.organisationDetails}
            organizationRoleArray={this.state.organizationRoleArray}
            isAddEditAOrganisation={this.state.isAddEditAOrganisation}
            editOrganisationRole={this.editOrganisationRole.bind(this)}
            addEditOraganisation={this.addEditOraganisation.bind(this)}
            setFilterParameters={this.setFilterParameters.bind(this)}
            handleChange={this.handleChange.bind(this)}
            deleteOrganisationRole={this.deleteOrganisationRole.bind(this)}
            handleReset={this.handleReset.bind(this)}
            validate={this.validateOrganisationKeyRoles.bind(this)}
            validState={this.state.validStateRoles}
            addEditOrganisationModel={this.state.addEditOrganisationModel}
            setOrganisationModel={this.setOrganisationModel.bind(this)}
            handleisAddEditAOrganisation={this.handleisAddEditAOrganisation.bind(this)}
            designationList={this.state.designationList}
            isCreateNewOrgRole={this.state.isCreateNewOrgRole}
            isOrganisationModalEditable={this.state.isOrganisationModalEditable}
            handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
            searchIndividualMemberByEmailForOrgRole={this.searchIndividualMemberByEmailForOrgRole.bind(this)}
          />
        </div>
        {/* Organisation Key Role & Chapter/Committee Roles End */}
        {/* New  End*/}
        {/* <div className="corporate-login-modal">
          <LoginToCorporateModal
            // dashboardDetails={this.state.dashboardDetails}
            organizationBasicInformation={
              this.state.organizationBasicInformation
            }
            setLoginToCorporateModal={this.state.setLoginToCorporateModal}
            // passwordForCorporateModal={this.state.passwordForCorporateModal}
            setOpenModal={() =>
              this.setState({ setLoginToCorporateModal: false })
            }
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setLoginToCorporateModal: false });
            }}
            handleChangeForLoginToCorporateModal={this.handleChangeForLoginToCorporateModal.bind(
              this
            )}
            loginToCorporateMemberForIndividualMember={this.loginToCorporateMemberForIndividualMember.bind(
              this
            )}
          />
        </div> */}
        {/* Organisation Key Role & Chapter/Committee Roles End */}
        {/* New  End*/}
      </div>

    );
  }
}

