import { axiosGetAuthorize, axiosGetFileAuthorizeblob, axiosPostAuthorize,
    //  axiosGetMultiParams,
     axiosGetAuthorizeMultiParams } from '../AxiosRequests';
import { UpdateChapterById,GetChapterInformation,Getofficialrepinformation,GetEmailInformationById,GetAllCorporateMemberList, GetCorporateMembersById, DeleteCorporateMembers, GetPurchaseChapterDataById, AddEditCorporateMembers, DeleteChapters, GetAllDropdownsForCorporateMember, AddEditCorporateSIISAccountSegment, GetCorporateBasicSIISInformation, AddEditCorporateEngagementTracking, GetCorporateEngagementTrackingList, AddEditCorporateChapter, 
    // GetCorporateChapter, 
    GetEmployeeInfomationForCorporateMemberAdmin, GetCorporateEngagementDetailsById, GetAllCorporateOrganisationRole, GetChapterCommitteeRoles, AddEditEmployeeInformation, GetEmployeeInformationById, AddEditCorporateOrganisationRole, DeleteCorporateOrganisationRole, GetCorporateOrganisationRoleById, GetChapterListByCorporateId, AddEditEvenBookingData, AddEditFinancialDetail, AddEditOrganisationEmployees, DeleteChapterById, JoinChapterById, AddEditOrganisationKeyRoles, AddEditSGTechIntrest, AddEditOrganisationBussinessDetails, AddEditOrganisationProfile, AddEditChapterInformation, AddEditCorporateMembershipSubscription,
    //  AddEditGovernmentProgramme, 
     AddEditFinanceCardDetails, AddEditProspectInformation, AddEditEngagementTrackingList,AddEditOfficialRepInformation, AddEditSIISCorporateAccountInformation,GetRepOfficialInformationById, AddEditOrganisationBasicInformation, GetAllCorporateSectionsById, GetSIISCorporateAccountInformationById, GetOrganisationBasicInformationById, GetChapterInformationById, GetOrganisationProfileById, GetOrganisationBussinessDetailsById, GetCorporateMembershipSubscriptionById, AddEditCorporateProspect, GetProspectInformationById, GetSGTechIntrestById, AddEditCorporateCommunication, GetCommunicationById, AddEditGovernmentPrograme, GetGovernmentProgrammeDetailByProjectId, GetCorporateFinancialInfo, GetCorporateFinanceReceiptInvoice, RenewCorporateMember, SearchIndividualMemberByEmailForOrgRole, MarkIndivisualMemberAsNonMember, GetAllAvailableChapterListForUpcomingTenure,GetCorporateMemberDirectoryList ,LoginToCorporateMemberForIndividualMember} from '../ApiEndPoints'

export default class CorporateMemberServices {

    async getAllCorporateMemberList(request) {
        return axiosPostAuthorize(GetAllCorporateMemberList, request)
    }
    async getofficialrepinformation(request) {
        return axiosGetAuthorize(Getofficialrepinformation, request);
    }
    async getEmailInformationById(request) {
        return axiosGetAuthorize(GetEmailInformationById, request);
    }

    async getCorporateMembersById(request) {
        return axiosGetAuthorize(GetCorporateMembersById, request)
    }

    async deleteCorporateMembers(request) {
        return axiosPostAuthorize(DeleteCorporateMembers, request)
    }

    async addEditCorporateMembers(request) {
        return axiosPostAuthorize(AddEditCorporateMembers, request)
    }
    // async addEditOrganisationBasicInformation(request){
    //     return axiosPostAuthorize(AddEditOrganisationBasicInformation,request);
    // }
    async getAllDropdownForCorporateMember(request) {
        return axiosGetAuthorize(GetAllDropdownsForCorporateMember, request);
    }
    async addEditCorporateSIISAccountSegment(request) {
        return axiosPostAuthorize(AddEditCorporateSIISAccountSegment, request)
    }
    async getCorporateBasicSIISInformation(request) {
        return axiosGetAuthorize(GetCorporateBasicSIISInformation, request);
    }
    async getEngagementTrackingList(request) {
        return axiosGetAuthorize(GetCorporateEngagementTrackingList, request);
    }
    async getEngagmentDetailsById(request) {
        return axiosGetAuthorize(GetCorporateEngagementDetailsById, request)
    }
    async getBasicInformation(request) {
        return axiosGetAuthorize(GetCorporateBasicSIISInformation, request)
    }
    async addEditEngagementTracking(request) {
        return axiosPostAuthorize(AddEditCorporateEngagementTracking, request)
    }
    async addEditChapters(request) {
        return axiosPostAuthorize(AddEditCorporateChapter, request);
    }
    // async getChapters(request){
    //     return axiosGetAuthorize(GetCorporateChapter,request);
    // }
    async getChapters(request) {
        return axiosGetAuthorize(GetChapterListByCorporateId, request);
    }
    async getEmployeeList(request) {
        return axiosGetAuthorizeMultiParams(GetEmployeeInfomationForCorporateMemberAdmin, request);
    }
    async getOrganisationRoleList(request) {
        return axiosGetAuthorize(GetAllCorporateOrganisationRole, request);
    }
    async getChapterCommitteeRoles(request) {
        return axiosGetAuthorize(GetChapterCommitteeRoles, request);
    }
    async addEditEmployeeInformation(request) {
        return axiosPostAuthorize(AddEditEmployeeInformation, request);
    }
    async getEmployeeInfoById(request) {
        return axiosGetAuthorize(GetEmployeeInformationById, request);
    }
    async addEditOrganisation(request) {
        return axiosPostAuthorize(AddEditCorporateOrganisationRole, request);
    }
    async deleteCorporateOrganisationRole(request) {
        return axiosPostAuthorize(DeleteCorporateOrganisationRole, request);
    }
    async getCorporateOrganisationRoleById(request) {
        return axiosGetAuthorize(GetCorporateOrganisationRoleById, request);
    }
    async deleteChapters(request) {
        return axiosPostAuthorize(DeleteChapters, request);
    }

    //New services for create corporate members
    async addEditSIISCorporateAccountInformation(request) {
        return axiosPostAuthorize(AddEditSIISCorporateAccountInformation, request)
    }
    async addEditEngagementTrackingList(request) {
        return axiosPostAuthorize(AddEditEngagementTrackingList, request)
    }
    async addEditProspectInformation(request) {
        return axiosPostAuthorize(AddEditProspectInformation, request)
    }
    async addEditCorporateProspect(request) {
        return axiosPostAuthorize(AddEditCorporateProspect, request)
    }
    async addEditFinanceCardDetails(request) {
        return axiosPostAuthorize(AddEditFinanceCardDetails, request)
    }
    async addEditGovernmentProgramme(request) {
        return axiosPostAuthorize(AddEditGovernmentPrograme, request)
    }
    async addEditCorporateMembershipSubscription(request) {
        return axiosPostAuthorize(AddEditCorporateMembershipSubscription, request)
    }
    async addEditChapterInformation(request) {
        return axiosPostAuthorize(AddEditChapterInformation, request)
    }
    async addEditOrganisationBasicInformation(request) {
        return axiosPostAuthorize(AddEditOrganisationBasicInformation, request)
    }
    async addEditOfficialRepInformation(request) {
        return axiosPostAuthorize(AddEditOfficialRepInformation, request)
    }
    async addEditOrganisationProfile(request) {
        return axiosPostAuthorize(AddEditOrganisationProfile, request)
    }
    async addEditOrganisationBussinessDetails(request) {
        return axiosPostAuthorize(AddEditOrganisationBussinessDetails, request)
    }
    async addEditSGTechIntrest(request) {
        return axiosPostAuthorize(AddEditSGTechIntrest, request)
    }
    async addEditOrganisationKeyRoles(request) {
        return axiosPostAuthorize(AddEditOrganisationKeyRoles, request)
    }
    async joinChapterById(request) {
        return axiosPostAuthorize(JoinChapterById, request)
    }
    async updateChapterById(request) {
        return axiosPostAuthorize(UpdateChapterById, request)
    }
    async deleteChapterById(request) {
        return axiosPostAuthorize(DeleteChapterById, request)
    }
    async addEditCorporateCommunication(request) {
        return axiosPostAuthorize(AddEditCorporateCommunication, request)
    }
    async addEditOrganisationEmployees(request) {
        return axiosPostAuthorize(AddEditOrganisationEmployees, request)
    }
    async addEditFinancialDetail(request) {
        return axiosPostAuthorize(AddEditFinancialDetail, request)
    }
    async addEditEvenBookingData(request) {
        return axiosPostAuthorize(AddEditEvenBookingData, request)
    }
    async getAllCorporateSectionsById(request) {
        return axiosPostAuthorize(GetAllCorporateSectionsById, request)
    }
    async getSIISCorporateAccountInformationById(request) {
        return axiosGetAuthorize(GetSIISCorporateAccountInformationById, request)
    }
    async getOrganisationBasicInformationById(request) {
        return axiosGetAuthorize(GetOrganisationBasicInformationById, request)
    }
    async getChapterInformation(request) {
        return axiosGetAuthorize(GetChapterInformation, request)
    }
    async getRepOfficialInformationById(request) {
        return axiosGetAuthorize(GetRepOfficialInformationById, request)
    }
    async getChapterInformationById(request) {
        return axiosGetAuthorize(GetChapterInformationById, request)
    }
    async getOrganisationProfileById(request) {
        return axiosGetAuthorize(GetOrganisationProfileById, request)
    }
    async getOrganisationBussinessDetailsById(request) {
        return axiosGetAuthorize(GetOrganisationBussinessDetailsById, request)
    }
    async getCorporateMembershipSubscriptionById(request) {
        return axiosGetAuthorize(GetCorporateMembershipSubscriptionById, request)
    }
    async getProspectInformationById(request) {
        return axiosGetAuthorize(GetProspectInformationById, request)
    }
    async getSGTechIntrestById(request) {
        return axiosGetAuthorize(GetSGTechIntrestById, request)
    }
    async getCommunicationById(request) {
        return axiosGetAuthorize(GetCommunicationById, request)
    }
    async getGovernmentProgrammeDetailByProjectId(request) {
        return axiosGetAuthorize(GetGovernmentProgrammeDetailByProjectId, request);
    }

    async getCorporateFinancialInfo(request) {
        return axiosGetAuthorize(GetCorporateFinancialInfo, request);
    }

    async getCorporateFinanceReceiptInvoice(request) {
        return axiosGetFileAuthorizeblob(GetCorporateFinanceReceiptInvoice, request);
    }

    async renewCorporateMember(request) {
        return axiosPostAuthorize(RenewCorporateMember, request);
    }
    async searchIndividualMemberByEmailForOrgRole(request) {
        return axiosPostAuthorize(SearchIndividualMemberByEmailForOrgRole, request);
    }
    async markIndivisualMemberAsNonMember(request) {
        return axiosGetAuthorize(MarkIndivisualMemberAsNonMember, request);
    }
    async getPurchaseChapterDataById(request) {
        return axiosGetAuthorize(GetPurchaseChapterDataById, request)
    }
    async getAllAvailableChapterListForUpcomingTenure(request) {
        return axiosGetAuthorize(GetAllAvailableChapterListForUpcomingTenure, request);
    }
    getAllDropdownForCorporateDashboard() {
        return axiosGetAuthorize(GetAllDropdownsForCorporateMember);
    }
    async getCorporateMemberDirectoryList(request) {
        return axiosPostAuthorize(GetCorporateMemberDirectoryList, request);
    }
    async loginToCorporateMemberForIndividualMember(request) {
        return axiosPostAuthorize(LoginToCorporateMemberForIndividualMember, request);
    }
}
