import React, { Component } from "react";
import SectionWithBorder from "../../components/membership/SectionWithBorder";

import CorporateMemberServices from "../../services/axiosServices/apiServices/CorporateMemberServices";
import Table from "../../components/table/Table";
import SwalServices from "../../services/swalServices/SwalServices";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import BatchJobExtractionServices from "../../services/axiosServices/apiServices/BatchJobExtractionServices";
import { saveAs } from "file-saver";

export default class MembershipDirectoryReport extends Component {
  constructor(props) {
    super(props);
    this.corporateMemberServices = new CorporateMemberServices();
    this.batchJobExtractionServices = new BatchJobExtractionServices();
    this.swalServices = new SwalServices();
    this.state = {
      membershipReport: {
        FilterByBusinessSectors: "",
        FilterByAreaOfExpertise: "",
        FilterByCountries: "",
        FilterByProfile: false,
        FilterByChapters: "",
      },
      setMemberDirectoryModal: false,
      councilIndex: 0,
      memberDirectoryList: [],
      companyWithProfileList: [],
      BussinessSectors: [],
      AreaOfExpertise: [],
      Countries: [],
      Chapters: [],
      totalResultes: 0,
      totalResultFilter: 0,
      dataGetting: false,
      editEnquiryDetails: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        filterByBusinessSectors: "",
        filterByAreaOfExpertise: "",
        filterByCountries: "",
        filterByChapters: "",
        filterByProfile: false,
        disableViewProfile: false,
        //status: 0,
        //filterByType: 0,
        //filterByStatus: 0,
        //IsCompaniesWithProfile: 0,
      },
      businessSectors: [],
      AreaExperties: [],
      CountriesPresence: [],
      Chapters: [],
      chapters: [],
      memberDirectory: {
        businessSectorId: 0,
        areaOfExpertiesId: 0,
        countriesPresenceId: 0,
        chaptersId: 0,
        optionSelected: [],
      },
      enquiryDetails: {
        senderCorporateMemberId: 0,
        senderEmail: "",
        senderCorporateName: "",
        receiverCorporateName: "",
        message: "",
        senderIndividualName: "",
        senderContactNo: "",
      },
      receiverCompanyName: "",
      selectedCorporateMemebrId: 0,
      charLimit: 500,
      selectedOptions: {
        selectedBusinessSector: [],
        selectedAreaOfExpertise: [],
        selectedCountriesPresence: [],
        selectedChapters: [],
      },
      validationRule: {
        receiverCorporateName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "receiver company"
            ),
          },
        ],
        senderIndividualName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "sender name"
            ),
          },
        ],
        message: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "message"
            ),
          },
        ],
        senderCorporateName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "sender company"
            ),
          },
        ],
        senderEmail: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "sender email"
            ),
          },
          {
            type: "email",
            message: CommonValidationMessages.EmailAddressNotValid,
          },
        ],
        senderContactNo: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "contact number"
            ),
          },
          // {
          //   type: "number",
          //   message: Messages.CommonValidationMessages.FieldRequired.replace(
          //     "{0}",
          //     "digits only"
          //   ),
          // },
          {
            type: "maxLength",
            maxLength: 20,
            message: CommonValidationMessages.Max.replace("{0}", "20"),
          },
          // {
          //   type: "minLength",
          //   minLength: 8,
          //   message: Messages.CommonValidationMessages.Min.replace("{0}", "8"),
          // },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },

      getMemberDirectoryData: [],
      totalPages: 1,
      totalCount: [],
      resourceList: [],
      isLoading: false,
      submitLoading: false,
    };
  }

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.enquiryDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };
  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.enquiryDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  // Next page
  nextPage = (pgNo) => {
    if (pgNo < this.state.totalPages) {
      this.onPageChange(pgNo + 1);
    }
  };

  // Privious page
  prvPage = (pgNo) => {
    if (pgNo > 1) {
      this.onPageChange(pgNo - 1);
    }
  };

  handlePageChange = (pageNumber) => {
    if (this.state.pagination.pageNo !== pageNumber) {
      var resource = this.state.pagination;
      resource.pageNo = pageNumber;
      this.setState({ pagination: resource });
    }
  };

  filterByCompanyProfile = (value, e) => {
    let details = this.state.pagination;
    details.filterByProfile = e.target.checked;
    details.pageNo = 1;
    details.pageSize = 10;
    this.setState({ pagination: details, councilIndex: 0 }, () => {
      this.getCorporateMemberDirectoryList();
    });
  };

  handleChangeInDropdown = (selected, drpIdentity) => {
    var details = this.state.pagination;
    var options = this.state.selectedOptions;

    details.pageNo = 1;
    if (drpIdentity === "Business") {
      details.filterByBusinessSectors = "";

      if (selected.length > 0) {
        selected = selected.filter((x) => x.value !== "*");
      }

      options.selectedBusinessSector = selected;
      this.setState({ selectedOptions: options });

      var dataArr = this.state.businessSectors;
      if (selected.value === "*") {
        dataArr.businessSectors = this.state.BussinessSectors;
      } else {
        dataArr.businessSectors = selected;
      }

      if (dataArr.businessSectors.length > 0) {
        var arr1 = dataArr.businessSectors.map(function (item) {
          //delete item.label;
          return item.value;
        });
        details.filterByBusinessSectors = arr1.join([","]);
      }
    } else if (drpIdentity === "AreaExperties") {
      details.filterByAreaOfExpertise = "";

      if (selected.length > 0) {
        selected = selected.filter((x) => x.value !== "*");
      }

      options.selectedAreaOfExpertise = selected;
      this.setState({ selectedOptions: options });

      var dataArr = this.state.AreaExperties;
      if (selected.value === "*") {
        dataArr.AreaExperties = this.state.AreaOfExpertise;
      } else {
        dataArr.AreaExperties = selected;
      }

      if (dataArr.AreaExperties.length > 0) {
        var arr1 = dataArr.AreaExperties.map(function (item) {
          //delete item.label;
          return item.value;
        });
        details.filterByAreaOfExpertise = arr1.join([","]);
      }
    } else if (drpIdentity === "CountriesPresence") {
      details.filterByCountries = "";

      if (selected.length > 0) {
        selected = selected.filter((x) => x.value !== "*");
      }

      options.selectedCountriesPresence = selected;
      this.setState({ selectedOptions: options });

      var dataArr = this.state.CountriesPresence;

      if (selected.value === "*") {
        dataArr.CountriesPresence = this.state.Countries;
      } else {
        dataArr.CountriesPresence = selected;
      }

      if (dataArr.CountriesPresence.length > 0) {
        var arr1 = dataArr.CountriesPresence.map(function (item) {
          //delete item.label;
          return item.value;
        });
        details.filterByCountries = arr1.join([","]);
      }
    } else if (drpIdentity === "Chapters") {
      details.filterByChapters = "";

      if (selected.length > 0) {
        selected = selected.filter((x) => x.value !== "*");
      }

      options.selectedChapters = selected;
      this.setState({ selectedOptions: options });

      var dataArr = this.state.Chapters;
      if (selected.value === "*") {
        dataArr.Chapters = this.state.chapters;
      } else {
        dataArr.Chapters = selected;
      }

      if (dataArr.Chapters.length > 0) {
        var arr1 = dataArr.Chapters.map(function (item) {
          //delete item.label;
          return item.value;
        });
        details.filterByChapters = arr1.join([","]);
      }
    }

    this.setState({ pagination: details });
    this.getCorporateMemberDirectoryList();
  };

  showDirectoryDetail = (e, id, index) => {
    this.setState({ councilIndex: index });
    e.stopPropagation();
    this.resetValidState();
    this.resetEnquiryDetails();
    this.setState({ editEnquiryDetails: false });
    this.setState({ setMemberDirectoryModal: true });
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getCorporateMemberDirectoryList();
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.enquiryDetails;
    detail[name] = value;
    detail.receiverCorporateName = this.state.receiverCompanyName;
    this.setState({ enquiryDetails: { ...detail } });
  }

  actions = (element, index, value) => {
    return element !== "corporateMemberId" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt break-all text-center">
        {
          // this.state.memberDirectoryList[index].areaOfExpertiseName === "-"
          // || this.state.memberDirectoryList[index].bussinessSectorName === "-" ||
          this.state.memberDirectoryList[index].chapterNames === "-" ||
          // || this.state.memberDirectoryList[index].countriesWithPresence === "-"
          this.state.memberDirectoryList[index].companyDescription === "" ? (
            // || this.state.memberDirectoryList[index].companyWebsite === "" ||
            // this.state.memberDirectoryList[index].linkedIn === ""
            <button
              className="flex items-center justify-center cursor-not-allowed"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  receiverCompanyName:
                    this.state.memberDirectoryList[index].companyName,
                });
                this.showDirectoryDetail(e, value, index);
              }}
              disabled={true}
            >
              <span className="text-[#757575] font-bold mr-8 2xl:text-[15px]">
                View Profile
              </span>
            </button>
          ) : (
            <button
              className="flex items-center justify-center"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  receiverCompanyName:
                    this.state.memberDirectoryList[index].companyName,
                });
                this.showDirectoryDetail(e, value, index);
              }}
            >
              <span className="text-[#C00000] font-bold mr-8 2xl:text-[15px]">
                View Profile
              </span>
            </button>
          )
        }
      </td>
    );
   
  };

  getAllDropDown = () => {
    this.setState({ isLoading: true });
    this.corporateMemberServices
      .getAllDropdownForCorporateDashboard()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // var BussinessSectors = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "BussinessSectors");

          var BussinessSectors = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "BussinessSectors"
          );
          BussinessSectors.map(function (item) {
            delete item["dropdownName"];
          });
          BussinessSectors = BussinessSectors.map((p) => ({
            value: p.id,
            label: p.name,
          }));

          var AreaOfExpertise = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "AreaOfExpertise"
          );
          AreaOfExpertise.map(function (item) {
            delete item["dropdownName"];
          });
          AreaOfExpertise = AreaOfExpertise.map((p) => ({
            value: p.id,
            label: p.name,
          }));

          var Countries = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Countries"
          );
          Countries.map(function (item) {
            delete item["dropdownName"];
          });
          Countries = Countries.map((p) => ({
            value: p.id,
            label: p.name,
          }));

          var Chapters = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Chapters"
          );
          Chapters.map(function (item) {
            delete item["dropdownName"];
          });
          Chapters = Chapters.map((p) => ({
            value: p.id,
            label: p.name,
          }));

          //var AreaOfExpertise = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AreaOfExpertise");
          //var Countries = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Countries");
          //var Chapters = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Chapters");
          // var SGTechArr = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'SGTech');
          // var SponsorshipArr = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Sponsorship');
          // var MatchingArr = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Matching');
          // var StrategicProgrammesArr = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Programs');
          // var Committee = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Committee');
          // var MrMrsArr = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'MrMrs');
          // var organisationsArr = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Organisation');

          BussinessSectors.map(function (item) {
            delete item["dropdownName"];
          });
          AreaOfExpertise.map(function (item) {
            delete item["dropdownName"];
          });
          Countries.map(function (item) {
            delete item["dropdownName"];
          });
          Chapters.map(function (item) {
            delete item["dropdownName"];
          });

          this.setState(
            {
              BussinessSectors: BussinessSectors,
              AreaOfExpertise: AreaOfExpertise,
              Countries: Countries,
              Chapters: Chapters,
            },
            () => this.getCorporateMemberDirectoryList()
          );
          return response.responseItem.responseContent;
        } else {
          this.getCorporateMemberDirectoryList();
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  getCorporateMemberDirectoryList = () => {
    this.setState({ isLoading: true }, () => {
      this.corporateMemberServices
        .getCorporateMemberDirectoryList(this.state.pagination)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem !== null) {
            let details = response.responseItem.responseContent.itemList;

            // var array = details split(',')
            // let emptyField = details.filter(drpfilter => drpfilter.companyName === null);
            // let map1 = emptyField.map((map1) => {
            //   return (map1.companyName.replace("null", "-"));
            // });
            const checkEmaptyOrNot = (details) => {
              Object.keys(details).forEach((key) => {
                if (
                  details[key].areaOfExpertiseName === "" ||
                  details[key].companyName === ""
                ) {
                  this.setState({ disableViewProfile: true });
                  // details[key].areaOfExpertiseName = '-';
                }
              });
            };

            const swapValue = (details) => {
              Object.keys(details).forEach((key) => {
                if (!details[key].areaOfExpertiseName) {
                  details[key].areaOfExpertiseName = "-";
                }
                if (!details[key].companyName) {
                  details[key].companyName = "-";
                }
                if (!details[key].bussinessSectorName) {
                  details[key].bussinessSectorName = "-";
                }
                if (!details[key].countriesWithPresence) {
                  details[key].countriesWithPresence = "-";
                }
                if (!details[key].chapterNames) {
                  details[key].chapterNames = "-";
                }
              });
            };
            swapValue(details);
            this.setState(
              {
                memberDirectoryList:
                  response.responseItem.responseContent.itemList,
              },
              () => this.setState({ dataGetting: true })
            );
            this.setState({
              totalResultes: response.responseItem.responseContent.totalCount,
            });

            //Company with profile
            const results = this.state.memberDirectoryList.filter((obj) => {
              return (
                obj.companyDescription !== "" &&
                obj.chapterNames !== "-" &&
                obj.chapterNames !== ""
              );
            });
            this.setState({ companyWithProfileList: results }, () =>
              this.setState({ dataGetting: true })
            );
            this.setState({ totalResultFilter: results.length }, () => {
              this.setState({ isLoading: false, submitLoading: false });
            });
          } else {
            this.swalServices.Error(response.message);
            this.setState({ isLoading: false, submitLoading: false });
          }
        });
    });
  };

  addMembershipEnquiry = (id) => {
    this.setState({ selectedCorporateMemebrId: id });
    let request = this.state.enquiryDetails;
    request.receiverCorporateMemberId = id;
    let isValidAll = this.isValidateAllFields();
    if (isValidAll) {
      this.setState({ submitLoading: true }, () => {
        this.corporateMemberServices
          .addMembershipContactEnquiry(request)
          .then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success(
                "Member Enquiry added successfully. Please check email"
              );
              this.setState({ editEnquiryDetails: false });
              this.setState({ setMemberDirectoryModal: false });
              this.resetEnquiryDetails();
              this.getCorporateMemberDirectoryList();
              // this.downloadCorporateMemberDirectoryReport();
            } else {
              this.swalServices.Error(response.message);
              this.setState({ submitLoading: false });
            }
          });
      });
    }
  };

  resetEnquiryDetails() {
    let resetData = this.state.enquiryDetails;
    resetData.senderCorporateMemberId = 0;
    resetData.senderEmail = "";
    resetData.senderCorporateName = "";
    resetData.receiverCorporateName = "";
    resetData.message = "";
    resetData.senderIndividualName = "";
    resetData.senderContactNo = "";
    this.setState({ enquiryDetails: resetData });
  }

  resetValidState() {
    let resetValidation = this.state.validState;
    resetValidation.isValid = true;
    resetValidation.error = {};
  }

  onEditEnquiryDetails = () => {
    this.setState({ editEnquiryDetails: true });
  };

  handleSearchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getCorporateMemberDirectoryList();
  };

  downloadCorporateMemberDirectoryReport = () => {
    this.setState({ isLoading: true });

    const {
      filterByBusinessSectors,
      filterByAreaOfExpertise,
      filterByCountries,
      filterByChapters,
      filterByProfile,
    } = this.state.pagination;

    const request = {
      filterByBusinessSectors,
      filterByAreaOfExpertise,
      filterByCountries,
      filterByChapters,
      filterByProfile,
    };

    this.batchJobExtractionServices
      .downloadCorporateMemberDirectoryReport(request)
      .then((response) => {
        if (response.status === 200 && response.data != null) {
          let blob = new Blob([response.data]);
          saveAs(blob, `MemberDirectoryReport` + ".xlsx");
          this.swalServices.Success("Download Successfully");
        } else {
          this.swalServices.Error(response.message);
        }
      })
      .catch((error) => {
        console.error("Error downloading report:", error);
        this.swalServices.Error("Error downloading report");
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  componentDidMount() {
    this.getAllDropDown();
  }
  render() {
    return (
      <>
       
        <div className="">
          <div className="main-body-section bg-white h-auto justify-between ">
            <SectionWithBorder
              filterByCompanyProfile={this.filterByCompanyProfile.bind(this)}
              pagination={this.state.pagination}
              businessSector={this.state.BussinessSectors}
              areaOfExperties={this.state.AreaOfExpertise}
              countriesPresence={this.state.Countries}
              chapters={this.state.Chapters}
              handleChangeInDropdown={this.handleChangeInDropdown.bind(this)}
              handleSearchByName={this.handleSearchByName.bind(this)}
              selectedOptions={this.state.selectedOptions}
              downloadCorporateMemberDirectoryReport={this.downloadCorporateMemberDirectoryReport.bind(
                this
              )}
            >
              <div className="membership-directory-table">
                <Table
                  columns={[
                    { name: "companyName", title: "Company Name" },
                    { name: "bussinessSectorName", title: "Business Sector" },
                    { name: "areaOfExpertiseName", title: "Area Of Expertise" },
                    {
                      name: "countriesWithPresence",
                      title: "Countries With Presence",
                    },
                    { name: "chapterNames", title: "Chapter" },
                    // { name: "corporateMemberId", title: "Actions" },
                  ]}
                  rows={this.state.memberDirectoryList}
                  isLoading={this.state.isLoading}
                  sortingColumns={["companyName"]}
                  pagination={this.state.pagination}
                  totalResultes={this.state.totalResultes}
                  totalCount={this.state.totalResultes}
                  setPagination={this.setPagination.bind(this)}
                  customScope={[
                    {
                      column: "corporateMemberId",
                      renderTableData: this.actions.bind(this),
                    },
                  ]}
                />
              </div>
            </SectionWithBorder>
          </div>
          
        </div>
      </>
    );
  }
}
