import React, { useEffect, useState } from "react";
import ValidationText from "../../../utils/validation/ValidationText";
import moment from "moment";
import TimePicker from "../../TimePicker.js/TimePicker";
import AvailabilityScheduleTable from "../AvailabilityScheduleTable";
import EntryManagementServices from "../../../services/axiosServices/apiServices/EntryManagementServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import Selector from "../../dropdown/commonDropdown/Selector";
import { CommonSuccessMessages } from "../../../utils/Messages";
import TinyMCEEditor from "../../ckEditor/TinyMCEEditor";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from "@reach/combobox";


import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";

const timeSlotObj = {
  eventTimeSlotId: null,
  isPhysical: null,
  isOnline: 0,
  isHybrid: "",
  availableSessions: 1,
  availableSeats: 0,
  timeSlotDate: "",
  eventStartTime: "",
  eventEndTime: "",
  lessons: [],
};
const lessonObj = {
  eventTimeSlotId: null,
  eventLessonDate: "",
  eventStartTime: "12:00 PM",
  eventEndTime: "12:00 PM",
  address: "",
  // postalCode : '',
  // zoneId : '',
  // locationId : '',
  // googleMapPath : '',
  // latitude : '',
  // longitude : '',
  // zoomAccountId : 0,
  zoomMeetingId: "",
  zoomWebinarId: "",
  otherZoomDetail: "",
};
const eventTimeSlotObj = {
  eventTimeSlotId: null,
  eventId: null,
  isRedirection: false,
  redirectURL: "",
  isPhysical: true,
  isOnline: false,
  isHybrid: false,
  availableSessions: 0,
  availableSeats: 0,
  timeSlotDate: "",
  eventStartTime: "",
  eventEndTime: "",
  postalCode: "",
  address: "",
  howToGetThere: "",
  isSGTech: true,
  isOtherAddress: false,
  isOnlineAddress: false,
  zoomAccountId: 0,
  isMeeting: true,
  isWebinar: false,
  isOthers: false,
  zoomMeetingId: "",
  zoomWebinarId: "",
  otherZoomDetail: "",
  selectedLocationId: 0,
  lessons: [],
  bookedSeats: 0,
};

const valObj = {
  isValid: true,
  error: {},
};

const entryServices = new EntryManagementServices();
const swalServices = new SwalServices();

function AvailabilityAndSchedule(props) {
  const [eventTimeSlot, setEventTimeSlot] = useState(eventTimeSlotObj);
  const [timeSlotList, setTimeSlotList] = useState([]);
  const [editLessonId, setEditLessonId] = useState(0);
  const [eventZoomAccounts, setEventZoomAccounts] = useState([]);
  const [locationAreaArr, setLocationAreaArr] = useState([]);
  const [sgTechLocation, setSGTechLocation] = useState({});
  const [validState, setValidState] = useState(valObj);
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    let eventId = props.eventId;
    let timeSlot = { ...eventTimeSlotObj };
    if (props.sgTechLocationAreas.length > 0) {
      setLocationAreaArr(props.sgTechLocationAreas);
      if (props.sgTechAddress) {
        timeSlot.address = props.sgTechAddress.address;
        if(props.sgTechAddress.locationArea !== ''){
          timeSlot.selectedLocationId = props.sgTechLocationAreas.find(
            (x) => x.label === props.sgTechAddress.locationArea
          ).value;
        }
        timeSlot.postalCode = props.sgTechAddress.postalCode;
        timeSlot.howToGetThere = props.sgTechAddress.howToGetThere;
        setSGTechLocation(props.sgTechAddress);
      }
    }
    if (!isNaN(eventId)) {
      // let timeSlot = { ...eventTimeSlot };
      timeSlot.eventId = props.eventId;
      // timeSlot.address = '';
      // timeSlot.selectedLocationId = '';
      // timeSlot.postalCode = '';
      // timeSlot.howToGetThere = '';
      if (eventId > 0) {
        // getLocationAreaByPostalCode('018983');
        getAllDropDownForEntryManagement(eventId);
        getLessonTimeSlotDetailsById(eventId, false, timeSlot);
        // getSGTechAddressesForEntries();
      }
    }
    setEventTimeSlot(timeSlot);
  }, []);

  const handleRadioButtons = (e) => {
    const id = e.target.id;
    const name = e.target.name;
    let detail = { ...eventTimeSlot };
    if (name === "typeOfEntry") {
      detail["isPhysical"] = false;
      detail["isOnline"] = false;
      detail["isHybrid"] = false;
      detail[id] = true;
    } else if (name === "courseLocation") {
      detail["isSGTech"] = false;
      detail["isOtherAddress"] = false;
      detail["isOnlineAddress"] = false;
      switchCourseLocation(id);
      if (id === "isSGTech") {
        let locationAreaList = [...locationAreaArr];
        detail.address = sgTechLocation.address;
        detail.postalCode = sgTechLocation.postalCode;
        detail.howToGetThere = sgTechLocation.howToGetThere;
        detail.selectedLocationId = locationAreaList.find(
          (x) => x.label === sgTechLocation.locationArea
        ).value;
        //panTo({ lat: 1.29823, lng: 103.78762 })
      }
      else {
        detail.address = '';
        detail.postalCode = '';
        detail.howToGetThere = '';
        detail.selectedLocationId = 0;
      }
      detail[id] = true;
    } else if (id === "isRedirection") {
      detail[id] = !detail["isRedirection"];
    } else if (name === "zoomType") {
      detail["isMeeting"] = false;
      detail["isWebinar"] = false;
      detail["isOthers"] = false;
      detail[id] = true;
      if (id === 'isMeeting') {
        detail.zoomAccountId = 0;
        detail.zoomMeetingId = '';
        detail.otherZoomDetail = '';
      } else if (id === 'isWebinar') {
        detail.zoomAccountId = 0;
        detail.zoomWebinarId = '';
        detail.otherZoomDetail = '';
      } else if (id === 'isOthers') {
        detail.zoomAccountId = 0;
        detail.zoomMeetingId = '';
        detail.zoomWebinarId = '';
      }
    }
    setEventTimeSlot(detail);
  };

  const resetEventTimeSlotFields = (e) => {
    let detail = { ...eventTimeSlot };
    detail.isPhysical = true;
    detail.isHybrid = false;
    detail.isOnline = false;
    detail.isMeeting = false;
    detail.isWebinar = false;
    detail.isOthers = false;
    detail.zoomAccountId = 0;
    detail.zoomMeetingId = '';
    detail.otherZoomDetail = '';
    detail.zoomWebinarId = '';

    if (detail.isSGTech === true) {
      let locationAreaList = [...locationAreaArr];
      detail.address = sgTechLocation.address;
      detail.postalCode = sgTechLocation.postalCode;
      detail.howToGetThere = sgTechLocation.howToGetThere;
      detail.selectedLocationId = locationAreaList.find(
        (x) => x.label === sgTechLocation.locationArea
      ).value;
    }
    setEventTimeSlot(detail);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;
    let detail = { ...eventTimeSlot };
    if (name === "availableSessions") {
      value = isNaN(value) ? 0 : Number(value);
      detail.lessons = [];
      for (let i = 0; i < value; i++) {
        let lesson = { ...lessonObj };
        if (eventTimeSlot.isSGTech === true) {
          lesson.address = eventTimeSlot.address;
        }
        detail.lessons.push(lessonObj);
      }
      detail[name] = value;
      //setEventTimeSlot(timeSlot);
    } else if (name === "postalCode") {
      detail[name] = value;
      if (value && !isNaN(value) && value.length > 5) {
        getLocationAreaByPostalCode(value);
      }
    } else if (name === "availableSeats") {
      value = isNaN(value) ? 0 : Number(value);
      detail[name] = value;
    }
    else if (name === "zoomMeetingId" || name === "zoomWebinarId") {
      detail[name] = value.split(" ").join("");
    }
    else {
      detail[name] = value;
    }
    setEventTimeSlot(detail);
  };

  const getAllDropDownForEntryManagement = (eventId) => {
    entryServices.getAllDropDownForEntryManagement(eventId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let eventZoomAccounts = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "EventZoomAccounts"
        );
        eventZoomAccounts = eventZoomAccounts.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        setEventZoomAccounts(eventZoomAccounts);
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  const getLessonTimeSlotDetailsById = (eventId, doResetTimeSlotObj, timeSlotObject) => {
    entryServices.getLessonTimeSlotDetailsById(eventId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let entryTimeSlotList =
          response.responseItem.responseContent.entryTimeSlotList;
        let entryLessonList =
          response.responseItem.responseContent.entryLessonList;
        entryTimeSlotList.forEach((v, i) => {
          v.lessons = entryLessonList.filter(
            (x) => x.eventTimeSlotId === v.eventTimeSlotId
          );
          if (v.lessons.length > 0) {
            if (v.isPhysical === true) {
            } else if (v.isOnline === true) {
              if (
                v.lessons[0].zoomMeetingId &&
                v.lessons[0].zoomMeetingId !== ""
              ) {
                v.isMeeting = true;
              } else if (
                v.lessons[0].zoomWebinarId &&
                v.lessons[0].zoomWebinarId !== ""
              ) {
                v.isWebinar = true;
              } else if (
                v.lessons[0].otherZoomDetail &&
                v.lessons[0].otherZoomDetail !== ""
              ) {
                v.isOthers = true;
              }
            } else if (v.isHybrid === true) {
              if (
                v.lessons[0].zoomMeetingId &&
                v.lessons[0].zoomMeetingId !== ""
              ) {
                v.isMeeting = true;
              } else if (
                v.lessons[0].zoomWebinarId &&
                v.lessons[0].zoomWebinarId !== ""
              ) {
                v.isWebinar = true;
              } else if (
                v.lessons[0].otherZoomDetail &&
                v.lessons[0].otherZoomDetail !== ""
              ) {
                v.isOthers = true;
              }
            }
          }
        });
        if (doResetTimeSlotObj === true) {
          let timeSlotObj = { ...eventTimeSlot }
          if (entryTimeSlotList.length > 0) {
            timeSlotObj.availableSessions = entryTimeSlotList[0].availableSessions;
            timeSlotObj.lessons = [];
            for (let i = 0; i < Number(timeSlotObj.availableSessions); i++) {
              let lesson = { ...lessonObj };
              if (eventTimeSlot.isSGTech === true) {
                lesson.address = eventTimeSlot.address;
              }
              timeSlotObj.lessons.push(lessonObj);
            }
          }
          timeSlotObj.howToGetThere = eventTimeSlot.howToGetThere;
          setEventTimeSlot(timeSlotObj);
        }
        else {
          let timeSlotObj = { ...timeSlotObject }
          if (entryTimeSlotList.length > 0) {
            timeSlotObj.availableSessions = entryTimeSlotList[0].availableSessions;
            timeSlotObj.lessons = [];
            for (let i = 0; i < Number(timeSlotObj.availableSessions); i++) {
              let lesson = { ...lessonObj };
              if (eventTimeSlot.isSGTech === true) {
                lesson.address = eventTimeSlot.address;
              }
              timeSlotObj.lessons.push(lessonObj);
            }
          }
          timeSlotObj.howToGetThere = eventTimeSlot.howToGetThere;
          setEventTimeSlot(timeSlotObj);
        }
        setTimeSlotList(entryTimeSlotList);
        // getSGTechAddressesForEntries(entryTimeSlotList);
      } else {
        swalServices.Error(response.message);
      }
    });
  };

  const addEditEntryLessonTimeSlotDetails = () => {
    if (validateTimeSlotAndLessons()) {
      let timSlotObj = { ...eventTimeSlot };
      let request = {};
      request.eventTimeSlotId = timSlotObj.eventTimeSlotId;
      request.eventId = props.eventId;
      request.isRedirection = timSlotObj.isRedirection;
      request.redirectURL = "";
      if (timSlotObj.isRedirection === true) {
        request.redirectURL = timSlotObj.redirectURL;
      }
      request.isPhysical = timSlotObj.isPhysical;
      request.isOnline = timSlotObj.isOnline;
      request.isHybrid = timSlotObj.isHybrid;
      request.availableSessions = timSlotObj.availableSessions;
      request.availableSeats = timSlotObj.availableSeats;
      // request.timeSlotDate = timSlotObj.timeSlotDate;
      request.eventStartTime = timSlotObj.eventStartTime;
      request.eventEndTime = timSlotObj.eventEndTime;
      request.entryLessonList = timSlotObj.lessons;
      // Location and zoom details
      if (request.isPhysical === true) {
        request.PostalCode = timSlotObj.postalCode;
        request.zoneId = 0;
        request.LocationId = timSlotObj.selectedLocationId;
        request.googleMapPath = currentMapUrl;
        request.latitude = currentLatitude;
        request.longitude = currentLongitude;
        request.howToGetThere = timSlotObj.howToGetThere;
        request.zoomAccountId = null;
      } else if (request.isOnline === true) {
        request.PostalCode = null;
        request.zoneId = null;
        request.LocationId = null;
        request.googleMapPath = null;
        request.latitude = null;
        request.longitude = null;
        request.howToGetThere = null;
        request.zoomAccountId = timSlotObj.zoomAccountId;
      } else if (request.isHybrid === true) {
        request.PostalCode = timSlotObj.postalCode;
        request.zoneId = 0;
        request.LocationId = timSlotObj.LocationId;
        request.googleMapPath = currentMapUrl;
        request.latitude = currentLatitude;
        request.longitude = currentLongitude;
        request.howToGetThere = timSlotObj.howToGetThere;
        request.zoomAccountId = timSlotObj.zoomAccountId;
      }

      request.entryLessonList.forEach((l, i) => {
        l.eventLessonDate = moment(l.eventLessonDate).format("yyyy-MM-DD");
        l.eventLessonDate = new Date(l.eventLessonDate);
        if (request.isOnline === true) {
          l.address = null;
          if (timSlotObj.isMeeting) {
            l.zoomMeetingId = timSlotObj.zoomMeetingId;
            l.zoomWebinarId = null;
            l.otherZoomDetail = null;
          }
          else if (timSlotObj.isWebinar) {
            l.zoomMeetingId = null;
            l.zoomWebinarId = timSlotObj.zoomWebinarId;
            l.otherZoomDetail = null;
          }
          else if (timSlotObj.isOthers) {
            l.zoomMeetingId = null;
            l.zoomWebinarId = null;
            l.otherZoomDetail = timSlotObj.otherZoomDetail;
          }
          // l.zoomMeetingId = timSlotObj.zoomMeetingId;
          // l.zoomWebinarId = timSlotObj.zoomWebinarId;
          // l.otherZoomDetail = timSlotObj.otherZoomDetail;
        } else if (request.isPhysical === true) {
          l.address = timSlotObj.address + ' ' + timSlotObj.postalCode;
          l.zoomMeetingId = null;
          l.zoomWebinarId = null;
          l.otherZoomDetail = null;
        } else if (request.isHybrid === true) {
          if (timSlotObj.isMeeting) {
            l.zoomMeetingId = timSlotObj.zoomMeetingId;
            l.zoomWebinarId = null;
            l.otherZoomDetail = null;
          }
          else if (timSlotObj.isWebinar) {
            l.zoomMeetingId = null;
            l.zoomWebinarId = timSlotObj.zoomWebinarId;
            l.otherZoomDetail = null;
          }
          else if (timSlotObj.isOthers) {
            l.zoomMeetingId = null;
            l.zoomWebinarId = null;
            l.otherZoomDetail = timSlotObj.otherZoomDetail;
          }
          l.address = timSlotObj.address;
          // l.zoomMeetingId = timSlotObj.zoomMeetingId;
          // l.zoomWebinarId = timSlotObj.zoomWebinarId;
          // l.otherZoomDetail = timSlotObj.otherZoomDetail;
        }
      });
      request.entryLessonList = request.entryLessonList.sort(
        (a, b) => a.eventLessonDate - b.eventLessonDate
      );
      request.timeSlotDate = request.entryLessonList[0].eventLessonDate;
      request.eventStartTime = request.entryLessonList[0].eventStartTime;
      request.eventEndTime = request.entryLessonList[0].eventEndTime;
      request.entryLessonList.forEach((e, i) => (e.eventLessonIndex = i + 1));
      entryServices
        .addEditEntryLessonTimeSlotDetails(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {

            // reset redirection url after time slot generated
            // let timSlotObj = { ...eventTimeSlot };
            // timSlotObj.redirectURL = "";
            // setEventTimeSlot(timSlotObj);
            resetEventTimeSlotFields();
            swalServices.Success("Sessions have been added successfully");
            getLessonTimeSlotDetailsById(props.eventId, true, eventTimeSlot);
          } else {
            swalServices.Error(response.message);
          }
        });
    } else {
      goToViolation("ErrorList");
    }
  };

  const getLocationAreaByPostalCode = (postalCode) => {
    const id = postalCode.slice(0, 2);
    entryServices.getLocationAreaByPostalCode(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let locations = response.responseItem.responseContent;
        let locationArea = locations.map((p) => ({
          value: p.locationAreaId,
          label: p.locationAreaName,
        }));
        setLocationAreaArr(locationArea);
        return locationArea;
      } else {
        swalServices.Error(response.message);
        return null;
      }
    });
  };

  const deleteEntryTimeSlotById = (value) => {
    let request = { id: value, deletedBy: 1, appId: 115 };
    swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "TimeSlot"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "TimeSlot"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response && !isNaN(value)) {
          entryServices.deleteEntryTimeSlotById(request).then((response) => {
            if (response.statusCode === 200) {
              swalServices.Success("Sessions have been deleted successfully");
              getLessonTimeSlotDetailsById(props.eventId, true, eventTimeSlot);
            } else {
              swalServices.Error(response.message);
            }
          });
        }
      });
  };

  // const getSGTechAddressesForEntries = () => {
  //   entryServices.getSGTechAddressesForEntries().then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.length > 0) {
  //       let sgTech = response.responseItem.responseContent.find(x => x.addressName === 'SGTech');
  //       if (sgTech) {
  //         setSGTechLocation(sgTech);
  //         let timeSlot = { ...eventTimeSlot }
  //         timeSlot.address = sgTech.address;
  //         timeSlot.postalCode = sgTech.postalCode;
  //         //timeSlot.howToGetThere = sgTech.howToGetThere;
  //         timeSlot.selectedLocationId = 57;
  //         setEventTimeSlot(timeSlot);
  //         // getLocationAreaByPostalCode(sgTech.postalCode);

  //       }
  //     } else {
  //       swalServices.Error(response.message);
  //     }
  //   });
  // }



  const editLessonData = (timeSlotId, lessonId, e) => {
    setEditLessonId(lessonId);
  };

  const saveLessonData = (timeSlotId, lessonId) => {
    updateSchedulerAndCapacityTable(timeSlotId);
  };

  const setTime = (time) => {
    let detail = [...timeSlotList];
    if (time.identity.includes("Event")) {
      const arr = time.identity.split("/");
      const tIndex = arr[0];
      const lIndex = arr[1];
      if (time.identity.includes("StartTime")) {
        detail[tIndex].lessons[lIndex].eventStartTime = time.selectedTime;
      } else if (time.identity.includes("EndTime")) {
        detail[tIndex].lessons[lIndex].eventEndTime = time.selectedTime;
      }
      setTimeSlotList(detail);
    } else {
      // if (time.identity.includes("eventStartTime")) {
      //   detail[tIndex].lessons[lIndex].eventStartTime = time.selectedTime;
      // } else if (time.identity.includes("eventEndTime")) {
      //   detail[tIndex].lessons[lIndex].eventEndTime = time.selectedTime;
      // }
    }
  };

  const handleChangeInLessons = (e, i) => {
    let value = e.target.value;
    const name = e.target.name;
    let timeSlot = { ...eventTimeSlot };
    const lesson = eventTimeSlot.lessons[i];
    let detail = { ...lesson };
    if (name === "zoomMeetingId" || name === "zoomWebinarId") {
      value = isNaN(value) ? 0 : Number(value);
    }
    detail[name] = value;
    timeSlot.lessons[i] = detail;
    setEventTimeSlot(timeSlot);
  };

  const setLessonTimeForGenerating = (time, i, element) => {
    let timeSlot = { ...eventTimeSlot };
    const lesson = eventTimeSlot.lessons[i];
    let detail = { ...lesson };
    // detail[element] = time;
    detail[element] = time.selectedTime;
    timeSlot.lessons[i] = detail;
    setEventTimeSlot(timeSlot);
  };

  const setHowToGetThere = (data) => {
    if (typeof data === 'string') {
      let detail = { ...eventTimeSlot };
      // var re = new RegExp('<a href="([^"]+)"', "g");
      // var result = data.replace(
      //   re,
      //   '<a href=\'$1\' target="_blank" rel="noopener noreferrer"'
      // );
      detail["howToGetThere"] = data;
      setEventTimeSlot(detail);
    }
  };

  const handleChangeInDropdown = (selected, drpIdentity) => {
    let timeSlot = { ...eventTimeSlot };
    if (drpIdentity === "EventZoomAccounts") {
      timeSlot.zoomAccountId = selected.value;
      // this.validateField("roleId");
    } else if (drpIdentity === "LocationArea") {
      timeSlot.selectedLocationId = selected.value;
      // this.validateField("roleId");
    }
    setEventTimeSlot(timeSlot);
  };

  const switchCourseLocation = (id) => {
    if (id === "isSGTech") {
    } else {
    }
  };

  const validateTimeSlotAndLessons = () => {
    let timeSlotVal = { ...eventTimeSlot };
    let valState = { ...validState };
    valState.isValid = true;
    let valError = [];
    let message = "";
    let field = "";
    let errorObj = {};
    let errors = [];
    if (timeSlotVal.isRedirection === true) {
      if (!timeSlotVal.redirectURL || timeSlotVal.redirectURL === "") {
        message = "Please enter redirection url";
        field = "redirectURL";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
    }
    if (timeSlotVal.isPhysical === true || timeSlotVal.isHybrid === true) {
      if (!timeSlotVal.address || timeSlotVal.address === "") {
        message = "Please enter address in course location section";
        field = "address";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
      if (!timeSlotVal.postalCode || timeSlotVal.postalCode === "") {
        message = "Please enter postal code";
        field = "postalCode";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
      // if (
      //   !timeSlotVal.selectedLocationId ||
      //   timeSlotVal.selectedLocationId === ""
      // ) {
      //   message = "Please select location area";
      //   field = "selectedLocationId";
      //   valError[field] = message;
      //   valState.isValid = false;
      //   errorObj = {};
      //   errorObj["field"] = field;
      //   errorObj["message"] = message;
      //   errors.push(errorObj);
      // }
      // if (!timeSlotVal.howToGetThere || timeSlotVal.howToGetThere === "") {
      //   message = "Please enter location details in how to get there";
      //   field = "howToGetThere";
      //   valError[field] = message;
      //   valState.isValid = false;
      //   errorObj = {};
      //   errorObj['field'] = field;
      //   errorObj['message'] = message;
      //   errors.push(errorObj);
      // }
    }
    if (timeSlotVal.isOnline === true || timeSlotVal.isHybrid === true) {
      if (!timeSlotVal.zoomAccountId || timeSlotVal.zoomAccountId === "") {
        message = "Please select a zoom account";
        field = "zoomAccountId";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
    }
    if (
      !timeSlotVal.availableSessions ||
      timeSlotVal.availableSessions === ""
    ) {
      message = "Please enter the number of sessions";
      field = "availableSessions";
      valError[field] = message;
      valState.isValid = false;
      errorObj = {};
      errorObj["field"] = field;
      errorObj["message"] = message;
      errors.push(errorObj);
    }
    if (!timeSlotVal.availableSeats || timeSlotVal.availableSeats === "") {
      message = "Please enter the number of available seats";
      field = "availableSeats";
      valError[field] = message;
      valState.isValid = false;
      errorObj = {};
      errorObj["field"] = field;
      errorObj["message"] = message;
      errors.push(errorObj);
    }
    if (timeSlotVal.isPhysical === true || timeSlotVal.isHybrid === true) {
      if (!timeSlotVal.address || timeSlotVal.address === "") {
        message = `Please enter location area for`;
        field = "locationArea";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
    }
    if (timeSlotVal.isOnline === true || timeSlotVal.isHybrid === true) {
      if (timeSlotVal.isMeeting === true) {
        if (!timeSlotVal.zoomMeetingId || timeSlotVal.zoomMeetingId === 0) {
          message = `Please enter zoom meeting id`;
          field = "zoomMeetingId";
          valError[field] = message;
          valState.isValid = false;
          errorObj = {};
          errorObj["field"] = field;
          errorObj["message"] = message;
          errors.push(errorObj);
        }
        else if (isNaN(timeSlotVal.zoomMeetingId)) {
          message = `Please enter valid zoom meeting id`;
          field = "zoomMeetingId";
          valError[field] = message;
          valState.isValid = false;
          errorObj = {};
          errorObj["field"] = field;
          errorObj["message"] = message;
          errors.push(errorObj);
        }
      } else if (timeSlotVal.isWebinar === true) {
        if (!timeSlotVal.zoomWebinarId || timeSlotVal.zoomWebinarId === 0) {
          message = `Please enter zoom webinar id`;
          field = "zoomMeetingId";
          valError[field] = message;
          valState.isValid = false;
          errorObj = {};
          errorObj["field"] = field;
          errorObj["message"] = message;
          errors.push(errorObj);
        }
        else if (isNaN(timeSlotVal.zoomWebinarId)) {
          message = `Please enter valid zoom webinar id`;
          field = "zoomWebinarId";
          valError[field] = message;
          valState.isValid = false;
          errorObj = {};
          errorObj["field"] = field;
          errorObj["message"] = message;
          errors.push(errorObj);
        }
      } else if (timeSlotVal.isOthers === true) {
        if (
          !timeSlotVal.otherZoomDetail ||
          timeSlotVal.otherZoomDetail === ""
        ) {
          message = `Please enter zoom meeting id`;
          field = "zoomMeetingId";
          valError[field] = message;
          valState.isValid = false;
          errorObj = {};
          errorObj["field"] = field;
          errorObj["message"] = message;
          errors.push(errorObj);
        }
      }
    }

    timeSlotVal.lessons.forEach((l, i) => {
      if (!l.eventLessonDate || l.eventLessonDate === "") {
        message = `Please enter date for lesson ${i + 1}`;
        field = "eventLessonDate";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
      else {
        timeSlotVal.lessons.forEach((lessonDetails, lessonDetailIndex) => {
          const startDate1 = new Date(`1/1/1900 ${l.eventStartTime}`);
          const endDate1 = new Date(`1/1/1900 ${l.eventEndTime}`);
          const startDate2 = new Date(`1/1/1900 ${lessonDetails.eventStartTime}`);
          const endDate2 = new Date(`1/1/1900 ${lessonDetails.eventEndTime}`);
          if (lessonDetailIndex > i) {
            if ((l.eventLessonDate && l.eventLessonDate !== "") && (l.eventStartTime && l.eventStartTime !== "") && (l.eventEndTime && l.eventEndTime !== "")
              && (lessonDetails.eventLessonDate && lessonDetails.eventLessonDate !== "") &&
              (lessonDetails.eventStartTime && lessonDetails.eventStartTime !== "") && (lessonDetails.eventEndTime && lessonDetails.eventEndTime !== "")) {
              if ((moment(l.eventLessonDate).format("yyyy-MM-DD") === moment(lessonDetails.eventLessonDate).format("yyyy-MM-DD")) && (((startDate2 > startDate1) && (startDate2 < endDate1))
                || ((endDate2 > startDate1) && (endDate2 < endDate1)) || (lessonDetails.eventStartTime === l.eventStartTime) || (lessonDetails.eventEndTime === l.eventEndTime))) {
                message = `Lesson ${i + 1} and lesson ${lessonDetailIndex + 1} are duplicate lessons`;
                field = "eventLessonDate";
                valError[field] = message;
                valState.isValid = false;
                errorObj = {};
                errorObj["field"] = field;
                errorObj["message"] = message;
                errors.push(errorObj);
              }
            }
          }
        });
      }
      if (!l.eventStartTime || l.eventStartTime === "") {
        message = `Please enter start time for lesson ${i + 1}`;
        field = "eventStartTime";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
      if (!l.eventEndTime || l.eventEndTime === "") {
        message = `Please enter end time for lesson ${i + 1}`;
        field = "eventEndTime";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
      if ((l.eventStartTime && l.eventStartTime !== "") && (l.eventEndTime && l.eventEndTime !== "")) {
        const startDate = new Date(`1/1/1900 ${l.eventStartTime}`);
        const endDate = new Date(`1/1/1900 ${l.eventEndTime}`);
        if (l.eventStartTime.toLowerCase() === l.eventEndTime.toLowerCase()) {
          message = `Please ensure that Start time and End time are not the same for lesson ${i + 1}`;
          field = "eventEndTime";
          valError[field] = message;
          valState.isValid = false;
          errorObj = {};
          errorObj["field"] = field;
          errorObj["message"] = message;
          errors.push(errorObj);
        }
        else if (startDate > endDate) {
          message = `Please ensure that end time is not less then start time for lesson ${i + 1}`;
          field = "eventEndTime";
          valError[field] = message;
          valState.isValid = false;
          errorObj = {};
          errorObj["field"] = field;
          errorObj["message"] = message;
          errors.push(errorObj);
        }
      }
    });
    valState.error = valError;
    setValidState(valState);
    //var errors = Object.entries(valError);
    setErrorList(errors);
    return valState.isValid;
    // return true;
  };

  const goToViolation = (id) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const nextToSchedule = () => {
    if (timeSlotList.length > 0) {
      props.nextToAvailabilitySchedule();
      props.executeScroll();
    }
    else {
      swalServices.Error("Please generate time slot!");
    }
  }

  const handleChangeInScheduleTable = (e, timeSlotIndex, lessonIndex) => {
    let value = e.target.value;
    const name = e.target.name;
    let timeSlotArr = [...timeSlotList];
    if (lessonIndex === -1) {
      if (name === "availableSeats") {
        value = isNaN(value) ? 0 : Number(value);
        timeSlotArr[timeSlotIndex][name] = value;
      }
      else if (name === "zoomMeetingId" || name === "zoomWebinarId") {
        timeSlotArr[timeSlotIndex][name] = value.split(" ").join("");
      }
      else {
        timeSlotArr[timeSlotIndex][name] = value;
      }
    } else {
      const lesson = timeSlotArr[timeSlotIndex].lessons[lessonIndex];
      let detail = { ...lesson };
      if (name === "zoomMeetingId" || name === "zoomWebinarId") {
        detail[name] = value.split(" ").join("");
      }
      else {
        detail[name] = value;
      }
      timeSlotArr[timeSlotIndex].lessons[lessonIndex] = detail;
    }
    setTimeSlotList(timeSlotArr);
  };

  const updateSchedulerAndCapacityTable = (timeSlotId) => {
    if (Number(timeSlotId) > 0) {
      if (validateSchedulerAndCapacityTable(timeSlotId)) {
        let timSlotArr = [...timeSlotList];
        const timeslot = timSlotArr.find(
          (x) => x.eventTimeSlotId === timeSlotId
        );
        let timSlotObj = { ...timeslot };
        let request = {};
        request.eventTimeSlotId = timSlotObj.eventTimeSlotId;
        request.eventId = props.eventId;
        request.isRedirection = timSlotObj.isRedirection;
        request.redirectURL = "";
        if (timSlotObj.isRedirection === true) {
          request.redirectURL = timSlotObj.redirectURL;
        }
        request.isPhysical = timSlotObj.isPhysical;
        request.isOnline = timSlotObj.isOnline;
        request.isHybrid = timSlotObj.isHybrid;
        request.availableSessions = timSlotObj.availableSessions;
        request.availableSeats = timSlotObj.availableSeats;
        request.eventStartTime = timSlotObj.eventStartTime;
        request.eventEndTime = timSlotObj.eventEndTime;
        request.entryLessonList = timSlotObj.lessons;
        // Location and zoom details
        if (request.isPhysical === true) {
          request.PostalCode = timSlotObj.postalCode;
          request.zoneId = 0;
          request.LocationId = timSlotObj.selectedLocationId;
          request.googleMapPath = "";
          request.latitude = "";
          request.longitude = "";
          request.howToGetThere = timSlotObj.howToGetThere;
          request.zoomAccountId = null;
        } else if (request.isOnline === true) {
          request.PostalCode = null;
          request.zoneId = null;
          request.LocationId = null;
          request.googleMapPath = null;
          request.latitude = null;
          request.longitude = null;
          request.howToGetThere = null;
          request.zoomAccountId = timSlotObj.zoomAccountId;
        } else if (request.isHybrid === true) {
          request.PostalCode = timSlotObj.postalCode;
          request.zoneId = 0;
          request.LocationId = timSlotObj.LocationId;
          request.googleMapPath = "";
          request.latitude = "";
          request.longitude = "";
          request.howToGetThere = timSlotObj.howToGetThere;
          request.zoomAccountId = timSlotObj.zoomAccountId;
        }

        request.entryLessonList.forEach((l, i) => {
          l.eventLessonDate = moment(l.eventLessonDate).format("yyyy-MM-DD");
          l.eventLessonDate = new Date(l.eventLessonDate);
          if (request.isOnline === true) {
            l.address = null;
          } else if (request.isPhysical === true) {
            //l.address = timSlotObj.address;
            l.zoomMeetingId = null;
            l.zoomWebinarId = null;
            l.otherZoomDetail = null;
          }
          // else if (request.isHybrid === true) {
          //   l.address = l.address;
          // }
        });
        request.entryLessonList = request.entryLessonList.sort(
          (a, b) => a.eventLessonDate - b.eventLessonDate
        );
        request.timeSlotDate = request.entryLessonList[0].eventLessonDate;
        request.eventStartTime = request.entryLessonList[0].eventStartTime;
        request.eventEndTime = request.entryLessonList[0].eventEndTime;
        request.entryLessonList.forEach((e, i) => (e.eventLessonIndex = i + 1));
        entryServices
          .addEditEntryLessonTimeSlotDetails(request)
          .then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              // setEventTimeSlot(eventTimeSlotObj);
              // Change For Zoom data
              setEditLessonId(0);
              getLessonTimeSlotDetailsById(props.eventId, true, eventTimeSlot);
              //saveLessonData
              resetEventTimeSlotFields();
              swalServices.Success("Session has been updated successfully");
            } else {
              swalServices.Error(response.message);
            }
          });
      }
    } else {
      goToViolation("ErrorList");
    }
  };

  const validateSchedulerAndCapacityTable = (timeSlotId) => {
    let timSlotArr = [...timeSlotList];
    let timeSlotVal = {
      ...timSlotArr.find((x) => x.eventTimeSlotId === timeSlotId),
    };
    // let timeSlotVal = { ...eventTimeSlot };
    let valState = { ...validState };
    valState.isValid = true;
    let valError = [];
    let message = "";
    let field = "";
    let errorObj = {};
    let errors = [];
    if (!timeSlotVal.availableSeats || timeSlotVal.availableSeats === "") {
      message = "Please enter the number of available seats";
      field = "availableSeats";
      valError[field] = message;
      valState.isValid = false;
      errorObj = {};
      errorObj["field"] = field;
      errorObj["message"] = message;
      errors.push(errorObj);
    }
    timeSlotVal.lessons.forEach((l, i) => {
      if (!l.eventLessonDate || l.eventLessonDate === "") {
        message = `Please enter date for lesson ${i + 1}`;
        field = "eventLessonDate";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
      else {
        timeSlotVal.lessons.forEach((lessonDetails, lessonDetailIndex) => {
          const startDate1 = new Date(`1/1/1900 ${l.eventStartTime}`);
          const endDate1 = new Date(`1/1/1900 ${l.eventEndTime}`);
          const startDate2 = new Date(`1/1/1900 ${lessonDetails.eventStartTime}`);
          const endDate2 = new Date(`1/1/1900 ${lessonDetails.eventEndTime}`);
          if (lessonDetailIndex > i) {
            if ((l.eventLessonDate && l.eventLessonDate !== "") && (l.eventStartTime && l.eventStartTime !== "") && (l.eventEndTime && l.eventEndTime !== "")
              && (lessonDetails.eventLessonDate && lessonDetails.eventLessonDate !== "") &&
              (lessonDetails.eventStartTime && lessonDetails.eventStartTime !== "") && (lessonDetails.eventEndTime && lessonDetails.eventEndTime !== "")) {
              if ((moment(l.eventLessonDate).format("yyyy-MM-DD") === moment(lessonDetails.eventLessonDate).format("yyyy-MM-DD")) && (((startDate2 > startDate1) && (startDate2 < endDate1))
                || ((endDate2 > startDate1) && (endDate2 < endDate1)) || (lessonDetails.eventStartTime === l.eventStartTime) || (lessonDetails.eventEndTime === l.eventEndTime))) {
                message = `Lesson ${i + 1} and lesson ${lessonDetailIndex + 1} are duplicate lessons`;
                field = "eventLessonDate";
                valError[field] = message;
                valState.isValid = false;
                errorObj = {};
                errorObj["field"] = field;
                errorObj["message"] = message;
                errors.push(errorObj);
              }
            }
          }
        });
      }
      if (!l.eventStartTime || l.eventStartTime === "") {
        message = `Please enter start time for lesson ${i + 1}`;
        field = "eventStartTime";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
      if (!l.eventEndTime || l.eventEndTime === "") {
        message = `Please enter end time for lesson ${i + 1}`;
        field = "eventEndTime";
        valError[field] = message;
        valState.isValid = false;
        errorObj = {};
        errorObj["field"] = field;
        errorObj["message"] = message;
        errors.push(errorObj);
      }
      if ((l.eventStartTime && l.eventStartTime !== "") && (l.eventEndTime && l.eventEndTime !== "")) {
        const startDate = new Date(`1/1/1900 ${l.eventStartTime}`);
        const endDate = new Date(`1/1/1900 ${l.eventEndTime}`);
        // if (startDate === endDate) {
        if (l.eventStartTime.toLowerCase() === l.eventEndTime.toLowerCase()) {
          message = `Please ensure that Start time and End time are not the same for lesson ${i + 1}`;
          field = "eventEndTime";
          valError[field] = message;
          valState.isValid = false;
          errorObj = {};
          errorObj["field"] = field;
          errorObj["message"] = message;
          errors.push(errorObj);
        }
        else if (startDate > endDate) {
          message = `Please ensure that end time is not less then start time for lesson ${i + 1}`;
          field = "eventEndTime";
          valError[field] = message;
          valState.isValid = false;
          errorObj = {};
          errorObj["field"] = field;
          errorObj["message"] = message;
          errors.push(errorObj);
        }
      }
      if (props.eventTypeId === 1) {
        if (timeSlotVal.isPhysical === true || timeSlotVal.isHybrid === true) {
          if (!l.address || l.address === "") {
            message = `Please enter location area for lesson ${i + 1}`;
            field = "locationArea";
            valError[field] = message;
            valState.isValid = false;
            errorObj = {};
            errorObj["field"] = field;
            errorObj["message"] = message;
            errors.push(errorObj);
          }
        }
        if (timeSlotVal.isOnline === true || timeSlotVal.isHybrid === true) {
          if (timeSlotVal.isMeeting === true) {
            if (!l.zoomMeetingId || l.zoomMeetingId === 0) {
              message = `Please enter zoom meeting id for lesson ${i + 1}`;
              field = "zoomMeetingId";
              valError[field] = message;
              valState.isValid = false;
              errorObj = {};
              errorObj["field"] = field;
              errorObj["message"] = message;
              errors.push(errorObj);
            }
            else if (isNaN(l.zoomMeetingId)) {
              message = `Please enter valid zoom meeting id for lesson ${i + 1}`;
              field = "zoomMeetingId";
              valError[field] = message;
              valState.isValid = false;
              errorObj = {};
              errorObj["field"] = field;
              errorObj["message"] = message;
              errors.push(errorObj);
            }
          } else if (timeSlotVal.isWebinar === true) {
            if (!l.zoomWebinarId || l.zoomWebinarId === 0) {
              message = `Please enter zoom webinar id for lesson ${i + 1}`;
              field = "zoomMeetingId";
              valError[field] = message;
              valState.isValid = false;
              errorObj = {};
              errorObj["field"] = field;
              errorObj["message"] = message;
              errors.push(errorObj);
            }
            else if (isNaN(l.zoomWebinarId)) {
              message = `Please enter valid zoom webinar id for lesson ${i + 1}`;
              field = "zoomWebinarId";
              valError[field] = message;
              valState.isValid = false;
              errorObj = {};
              errorObj["field"] = field;
              errorObj["message"] = message;
              errors.push(errorObj);
            }
          } else if (timeSlotVal.isOthers === true) {
            if (!l.otherZoomDetail || l.otherZoomDetail === "") {
              message = `Please enter zoom meeting id for lesson ${i + 1}`;
              field = "zoomMeetingId";
              valError[field] = message;
              valState.isValid = false;
              errorObj = {};
              errorObj["field"] = field;
              errorObj["message"] = message;
              errors.push(errorObj);
            }
          }
        }
      }
    });
    valState.error = valError;
    setValidState(valState);
    setErrorList(errors);
    return valState.isValid;
    // return true;
  };

  // Prasad Generate Map Changes
  const [currentLatitude, setCurrentLatitude] = React.useState(1.29823);
  const [currentLongitude, setCurrentLongitude] = React.useState(103.78762);
  const [currentMapUrl, setCurrentMapUrl] = React.useState("");

  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000
    }
  });

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    // setCurrentmarkers({ lat, lng });   
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(20);
    setCurrentLatitude(lat);
    setCurrentLongitude(lng);
    setCurrentMapUrl(`https://maps.google.com/maps?q=${lat},${lng}&z=18&hl=en&output=embed`);
  }, []);

  const handleInput = (e) => {
    setValue(e.target.value);
    let details = { ...eventTimeSlot };
    details.address = e.target.value;
    setEventTimeSlot(details);
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    var details = { ...eventTimeSlot };
    details.address = address;
    setEventTimeSlot(details);
    clearSuggestions();
  };

  const searchAddress = async (address) => {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      // if (eventTimeSlot.isSGTech === true) {
      //   panTo({currentLatitude, currentLongitude});
      // }
      // else {
      panTo({ lat, lng });
      // }
    }
    catch (error) {
    }
  }

  return (
    <div id="MainSection">
      {/* Validation Section Start*/}

      <div className="bg-white" id="ErrorList">
        {errorList.length > 0 ? (
          <div className="heading-part pl-7 py-4 border-b mb-5">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                The following field(s) are required:
              </h4>
            </div>
            <ul>
              {/* <li className=" text-[#c00000] cursor-pointer" onClick={() => goToViolation('testlinks')}>
              test link url
            </li> */}
              {errorList.map((error, i) => (
                <li
                  key={i}
                  className=" text-[#c00000] cursor-pointer"
                  onClick={() => goToViolation("testlinks")}
                >
                  {error.message}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>

      {/* Validation Section End*/}
      {/* Basic Information Start */}
      <div className="bg-white">
        <div className="heading-part pl-7 py-4 border-b">
          <div className="flex items-center justify-between handleClicksForTimePicker">
            <h4 className="small-title font-bold theme-color">
              Schedule &amp; Capacity
            </h4>
          </div>
        </div>
        <div className="px-9 py-4 pt-10">
          <div className="mb-10">
            <div className="grid grid-cols-12 gap-6">
              <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                <div className="grid grid-cols-12 gap-6 mb-10">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="flex items-center justify-between">
                      <div className="scedual-title-txt">
                        <h6 className="text-xl font-medium theme-color">
                          This schedule table shows all dates and timings of
                          this Conference:
                        </h6>
                      </div>

                      <div className="right-btn">
                        <div className="flex items-center justify-end">
                          <div className="btns mr-3">
                            <button
                              className=" px-5 mt-0 mb-3 py-3 btn btn-red-border text-xl"
                            // onClick={() => props.addNewDec()}
                            >
                              <div className="flex items-center font-semibold">
                                <span className="">Apply All Location</span>
                              </div>
                            </button>
                          </div>
                          <div className="btns">
                            <button
                              className=" px-5 mt-0 mb-3 py-3 btn btn-lighblue-border text-xl"
                            // onClick={() => props.addNewDec()}
                            >
                              <div
                                className="flex items-center font-semibold"
                                onClick={() => props.applyAllZoomId()}
                              >
                                <span className="">Apply All Zoom ID</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="">
                      <AvailabilityScheduleTable
                        timeSlotList={timeSlotList}
                        editLessonId={editLessonId}
                        eventTypeId={props.eventTypeId}
                        editLessonData={(timeSlotId, lessonId, e) =>
                          editLessonData(timeSlotId, lessonId, e)
                        }
                        saveLessonData={(timeSlotId, lessonId) =>
                          saveLessonData(timeSlotId, lessonId)
                        }
                        setTime={(time) => setTime(time)}
                        deleteEntryTimeSlotById={(timeSlotId) =>
                          deleteEntryTimeSlotById(timeSlotId)
                        }
                        handleChangeInScheduleTable={(
                          e,
                          timeSlotIndex,
                          lessonIndex
                        ) =>
                          handleChangeInScheduleTable(
                            e,
                            timeSlotIndex,
                            lessonIndex
                          )
                        }
                        updateSchedulerAndCapacityTable={() =>
                          updateSchedulerAndCapacityTable()
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6 mb-6">
                      <div className=" col-span-7 w-full">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Redirection
                          <span className="text-[#C00000]">*</span>{" "}
                        </label>
                        <div className="flex items-center justify-start">
                          <div className="m-3 mr-14">
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name="radio-buttons-redirection"
                                id="isRedirection"
                                className="form-radio"
                                value={eventTimeSlot.isRedirection}
                                onChange={(e) => handleRadioButtons(e)}
                                checked={eventTimeSlot.isRedirection === true}
                              />
                              <span className="text-xl  font-medium theme-color ml-2">
                                Yes
                              </span>
                            </label>
                          </div>

                          <div className="m-3">
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name="radio-buttons-redirection"
                                id="isRedirection"
                                className="form-radio"
                                value={eventTimeSlot.isRedirection}
                                onChange={(e) => handleRadioButtons(e)}
                                checked={eventTimeSlot.isRedirection === false}
                              />
                              <span className="text-xl  font-medium theme-color ml-2">
                                No
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {eventTimeSlot.isRedirection ? (
                        <div className=" col-span-7 w-full">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            Redirection Url
                            <span className="text-[#C00000]">*</span>{" "}
                          </label>
                          {/* <div className={`flex items-center justify-start ${timeSlotList.length > 0 ? "disabled-input" : null}`}> */}
                          <div className={`flex items-center justify-start ${eventTimeSlot.isRedirection !== true ? "disabled-input" : null}`}>
                            <div className="m-3 mr-14">
                              <label className="flex items-center">
                                <input
                                  id="redirectURL"
                                  className={`form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575] `}
                                  type="text"
                                  name="redirectURL"
                                  value={eventTimeSlot.redirectURL}
                                  onChange={(e) => handleChange(e)}
                                  disabled={eventTimeSlot.isRedirection !== true ? true : false}
                                //   onBlur={() => props.validateField("resourceName")}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="grid grid-cols-12 gap-6 mb-6">
                      <div className=" col-span-7 w-full">
                        <div className="flex items-center justify-between">
                          <div className="m-3">
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name="typeOfEntry"
                                id="isPhysical"
                                className="form-radio"
                                value={eventTimeSlot.isPhysical}
                                onChange={(e) => handleRadioButtons(e)}
                                checked={eventTimeSlot.isPhysical === true}
                              />
                              <span className="text-xl  font-medium theme-color ml-2">
                                Physical
                              </span>
                            </label>
                          </div>
                          <div className="m-3">
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name="typeOfEntry"
                                id="isOnline"
                                className="form-radio"
                                value={eventTimeSlot.isOnline}
                                onChange={(e) => handleRadioButtons(e)}
                                checked={eventTimeSlot.isOnline === true}
                              />
                              <span className="text-xl  font-medium theme-color ml-2">
                                Zoom
                              </span>
                            </label>
                          </div>
                          <>
                            <div className="ml-3 hidden">
                              <div className="pl-6 flex items-center url-part">
                                <span className="text-xl  font-medium theme-color mr-5">
                                  URL
                                </span>
                                <div className="flex item-center flex-col min-w-[35%]">
                                  <input
                                    id="isHybrid"
                                    name="videoUrl"
                                    className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="text"
                                    placeholder=""
                                    value={eventTimeSlot.isHybrid}
                                    onChange={(e) => handleRadioButtons(e)}
                                    checked={eventTimeSlot.isHybrid === true}
                                  />
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </>
                          <div className="m-3">
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name="typeOfEntry"
                                id="isHybrid"
                                className="form-radio"
                                value={eventTimeSlot.isHybrid}
                                onChange={(e) => handleRadioButtons(e)}
                                checked={eventTimeSlot.isHybrid === true}
                              />
                              <span className="text-xl  font-medium theme-color ml-2">
                                Hybrid
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {eventTimeSlot.isOnline || eventTimeSlot.isHybrid ? (
                      <div className="bg-white mt-5">
                        <div className="heading-part pl-7 py-4 border-b">
                          <div className="flex items-center justify-between">
                            <h4 className="small-title font-bold theme-color">
                              Select Zoom Account
                            </h4>
                          </div>
                        </div>
                        <div className="mt-5">
                          <div className="mb-10">
                            <div className="grid grid-cols-12 gap-6 mb-5">
                              <div className=" col-span-9 w-full">
                                <div className="flex items-center justify-between">
                                  <div className="m-3">
                                    <label className="flex items-center">
                                      <input
                                        type="radio"
                                        name="zoomType"
                                        id="isMeeting"
                                        className="form-radio"
                                        value={eventTimeSlot.isMeeting}
                                        onChange={(event) =>
                                          handleRadioButtons(event)
                                        }
                                        checked={
                                          eventTimeSlot.isMeeting === true
                                        }
                                      />
                                      <span className="text-xl  font-medium theme-color ml-2">
                                        Meeting
                                      </span>
                                    </label>
                                  </div>

                                  <div className="m-3">
                                    <label className="flex items-center">
                                      <input
                                        type="radio"
                                        name="zoomType"
                                        id="isWebinar"
                                        className="form-radio"
                                        value={eventTimeSlot.isWebinar}
                                        onChange={(event) =>
                                          handleRadioButtons(event)
                                        }
                                        checked={
                                          eventTimeSlot.isWebinar === true
                                        }
                                      />
                                      <span className="text-xl  font-medium theme-color ml-2">
                                        Webinar
                                      </span>
                                    </label>
                                  </div>

                                  <div className="m-3">
                                    <label className="flex items-center">
                                      <input
                                        type="radio"
                                        name="zoomType"
                                        id="isOthers"
                                        className="form-radio"
                                        value={eventTimeSlot.isOthers}
                                        onChange={(event) =>
                                          handleRadioButtons(event)
                                        }
                                        checked={
                                          eventTimeSlot.isOthers === true
                                        }
                                      />
                                      <span className="text-xl  font-medium theme-color ml-2">
                                        Others
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="grid grid-cols-12 gap-6 mb-5">
                              <div className="2xl:col-span-4 lg:col-span-4 col-span-4">
                                {eventTimeSlot.isOnline === true ||
                                  eventTimeSlot.isHybrid ? (
                                  <>
                                    {eventTimeSlot.isMeeting === true ? (
                                      <div className="2xl:col-span-4 lg:col-span-4 col-span-4">
                                        <label
                                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                          htmlFor={`zoomMeetingId`}
                                        >
                                          Zoom Meeting Id
                                          <span className="text-[#C00000]">
                                            *
                                          </span>
                                        </label>
                                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                                          <div className="2xl:col-span-9 lg:col-span-9 col-span-12 w-full">
                                            <input
                                              id={`zoomMeetingId`}
                                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                              type="text"
                                              name="zoomMeetingId"
                                              value={
                                                eventTimeSlot.zoomMeetingId
                                              }
                                              onChange={(e) => handleChange(e)}
                                            //   onBlur={() => props.validateField("resourceName")}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {eventTimeSlot.isWebinar === true ? (
                                      <div className="2xl:col-span-4 lg:col-span-4 col-span-4">
                                        <label
                                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                          htmlFor={`zoomWebinarId`}
                                        >
                                          Zoom Webinar Id
                                          <span className="text-[#C00000]">
                                            *
                                          </span>
                                        </label>
                                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                                          <div className="2xl:col-span-9 lg:col-span-9 col-span-12 w-full">
                                            <input
                                              id={`zoomWebinarId`}
                                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                              type="text"
                                              name="zoomWebinarId"
                                              value={
                                                eventTimeSlot.zoomWebinarId
                                              }
                                              onChange={(e) => handleChange(e)}
                                            //   onBlur={() => props.validateField("resourceName")}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {eventTimeSlot.isOthers === true ? (
                                      <div className="2xl:col-span-4 lg:col-span-4 col-span-4">
                                        <label
                                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                          htmlFor={`otherZoomDetail`}
                                        >
                                          Other Zoom Detail
                                          <span className="text-[#C00000]">
                                            *
                                          </span>
                                        </label>
                                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                                          <div className="2xl:col-span-9 lg:col-span-9 col-span-12 w-full">
                                            <input
                                              id={`otherZoomDetail`}
                                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                              type="text"
                                              name="otherZoomDetail"
                                              value={
                                                eventTimeSlot.otherZoomDetail
                                              }
                                              onChange={(e) => handleChange(e)}
                                            //   onBlur={() => props.validateField("resourceName")}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                              </div>
                            </div>

                            <div className="grid grid-cols-12 gap-6">
                              <div className="2xl:col-span-4 lg:col-span-4 col-span-4">
                                <label
                                  className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                  htmlFor="default"
                                >
                                  Zoom Account{" "}
                                  <span className="text-[#C00000]">*</span>
                                </label>
                                <Selector
                                  options={eventZoomAccounts}
                                  // isMulti
                                  closeMenuOnSelect={true}
                                  hideSelectedOptions={false}
                                  // components={{
                                  //   Option,
                                  // }}
                                  onChange={(selected, identity) =>
                                    handleChangeInDropdown(selected, identity)
                                  }
                                  allowSelectAll={false}
                                  value={eventZoomAccounts.filter(
                                    (item) =>
                                      item.value === eventTimeSlot.zoomAccountId
                                  )}
                                  drpIdentity="EventZoomAccounts"
                                />
                                <div>
                                  {" "}
                                  <ValidationText
                                  // error={props.validState.error.resourceName}
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {eventTimeSlot.isPhysical || eventTimeSlot.isHybrid ? (
                      <div className="bg-white mt-5">
                        <div className="heading-part pl-7 py-4 border-b">
                          <div className="flex items-center justify-between">
                            <h4 className="small-title font-bold theme-color">
                              Course Location
                            </h4>
                          </div>
                        </div>
                        <div className="mt-5">
                          <div className="mb-10">
                            <div className="grid grid-cols-12 gap-6">
                              <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                                <div className="grid grid-cols-12 gap-6 mb-5">
                                  <div className=" col-span-9 w-full">
                                    <div className="flex items-center justify-between">
                                      {/* <div className="col-span-4 pt-[5px]">
                                        <button
                                          className=" px-5 mt-0 mb-3 py-3 btn-green text-xl border text-white"
                                          onClick={() => getLessonTimeSlotDetailsById(props.eventId)}
                                        >
                                          <div className="flex items-center font-semibold">
                                            <span className="">GET API</span>
                                          </div>
                                        </button>
                                      </div> */}

                                      <div className="m-3">
                                        <label className="flex items-center">
                                          <input
                                            type="radio"
                                            name="courseLocation"
                                            id="isSGTech"
                                            className="form-radio"
                                            value={eventTimeSlot.isSGTech}
                                            onChange={(event) =>
                                              handleRadioButtons(event)
                                            }
                                            checked={
                                              eventTimeSlot.isSGTech === true
                                            }
                                          />
                                          <span className="text-xl  font-medium theme-color ml-2">
                                            SGTech
                                          </span>
                                        </label>
                                      </div>

                                      <div className="m-3">
                                        <label className="flex items-center">
                                          <input
                                            type="radio"
                                            name="courseLocation"
                                            id="isOtherAddress"
                                            className="form-radio"
                                            value={eventTimeSlot.isOtherAddress}
                                            onChange={(event) =>
                                              handleRadioButtons(event)
                                            }
                                            checked={
                                              eventTimeSlot.isOtherAddress ===
                                              true
                                            }
                                          />
                                          <span className="text-xl  font-medium theme-color ml-2">
                                            Other Address
                                          </span>
                                        </label>
                                      </div>

                                      <div className="m-3">
                                        {/* <label className="flex items-center">
                                          <input
                                            type="radio"
                                            name="courseLocation"
                                            id="isOnlineAddress"
                                            className="form-radio"
                                            value={eventTimeSlot.isOnlineAddress}
                                            onChange={(event) => handleRadioButtons(event)}
                                            checked={eventTimeSlot.isOnlineAddress === true}
                                          />
                                          <span className="text-xl  font-medium theme-color ml-2">
                                            Online
                                          </span>
                                        </label> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="grid grid-cols-12 gap-6 mb-6">

                                  <div className="2xl:col-span-6 lg:col-span-7 col-span-12">
                                    <label
                                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                      htmlFor="address"
                                    >
                                      Address{" "}
                                      <span className="text-[#C00000]">*</span>
                                    </label>
                                    <Combobox onSelect={handleSelect}>
                                      {/* <input
                                        id="address"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                        type="text"
                                        name="address"
                                        value={eventTimeSlot.address}
                                        onChange={(e) => handleChange(e)}
                                        disabled={
                                          eventTimeSlot.isSGTech === true
                                            ? true
                                            : false
                                        }
                                      //   onBlur={() => props.validateField("resourceName")}
                                      /> */}
                                      <ComboboxInput
                                        value={eventTimeSlot.address}
                                        // value={eventTimeSlot.address}
                                        // value={eventTimeSlot.address}
                                        name="address"
                                        onChange={(e) => handleInput(e)}
                                        disabled={
                                          eventTimeSlot.isSGTech === true
                                            ? true
                                            : false
                                        }
                                        placeholder=""
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                      />
                                      <ComboboxPopover>
                                        <ComboboxList>
                                          {status === "OK" &&
                                            data.map(({ id, description }) => (
                                              <ComboboxOption key={id} value={description} />
                                            ))}
                                        </ComboboxList>
                                      </ComboboxPopover>
                                    </Combobox>
                                    {/* <input
                                      id="address"
                                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                      type="text"
                                      name="address"
                                      value={eventTimeSlot.address}
                                      onChange={(e) => handleChange(e)}
                                      disabled={
                                        eventTimeSlot.isSGTech === true
                                          ? true
                                          : false
                                      }
                                    //   onBlur={() => props.validateField("resourceName")}
                                    /> */}
                                    <div>
                                      {" "}
                                      <ValidationText
                                      // error={props.validState.error.resourceName}
                                      />{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="grid grid-cols-12 gap-6 mb-6">
                                  <div className="2xl:col-span-6 lg:col-span-7 col-span-12">
                                    <label
                                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                      htmlFor="postalCode"
                                    >
                                      Postal Code{" "}
                                      <span className="text-[#C00000]">*</span>
                                    </label>
                                    <input
                                      id="postalCode"
                                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                      type="number"
                                      name="postalCode"
                                      maxLength={6}
                                      value={eventTimeSlot.postalCode}
                                      onChange={(e) => handleChange(e)}
                                      disabled={
                                        eventTimeSlot.isSGTech === true
                                          ? true
                                          : false
                                      }
                                    //   onBlur={() => props.validateField("resourceName")}
                                    />
                                    <div>
                                      {" "}
                                      <ValidationText
                                      // error={props.validState.error.resourceName}
                                      />{" "}
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="grid grid-cols-12 gap-6  mb-7">
                                  <div className="2xl:col-span-9 lg:col-span-9 col-span-12">
                                    <label
                                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                      htmlFor="LocationArea"
                                    >
                                      Location Area{" "}
                                      <span className="text-[#C00000]">*</span>{" "}
                                    </label>
                                    <div className="grid grid-cols-12 gap-6">
                                      <div className="col-span-8">
                                        <Selector
                                          drpIdentity="LocationArea"
                                          options={locationAreaArr}
                                          // isMulti
                                          closeMenuOnSelect={true}
                                          hideSelectedOptions={false}
                                          // components={{
                                          //   Option,
                                          // }}
                                          onChange={(selected, identity) =>
                                            handleChangeInDropdown(
                                              selected,
                                              identity
                                            )
                                          }
                                          allowSelectAll={false}
                                          value={locationAreaArr.filter(
                                            (item) =>
                                              item.value ===
                                              eventTimeSlot.selectedLocationId
                                          )}
                                          disabled={
                                            eventTimeSlot.isSGTech === true
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                      <div className="col-span-4 pt-[5px]">
                                        <button
                                          className=" px-5 mt-0 mb-3 py-3 btn btn-green text-xl border text-white"
                                          onClick={() => searchAddress(eventTimeSlot.address)}
                                        >
                                          <div className="flex items-center font-semibold">
                                            <span className="">
                                              Generate Map
                                            </span>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                    <div>
                                      <ValidationText
                                      // error={props.validState.error.resourceTypeId}
                                      />
                                    </div>
                                  </div>
                                </div> */}
                                {
                                  //   <div className="grid grid-cols-12 gap-6  mb-10">
                                  //   <div className="2xl:col-span-9 lg:col-span-9 col-span-12">
                                  //     <div className="google-map-code">
                                  //       {/* <PlacesWithStandaloneSearchBox /> */}
                                  //       <GoogleMapComponent
                                  //         onMapLoad={onMapLoad}
                                  //         currentLatitude={currentLatitude}
                                  //         currentLongitude={currentLongitude}
                                  //       // mapLink={`https://www.google.com/maps/search/?api=1&query=${defaultCenter.lat},${defaultCenter.lng}`}
                                  //       />
                                  //       {/* <iframe
                                  //         src="https://maps.google.com/maps?q=1.29823,103.78762&hl=es&z=14&amp;output=embed"
                                  //         height="400"
                                  //         frameBorder="0"
                                  //         style={{ border: 0 }}
                                  //         allowFullScreen=""
                                  //         aria-hidden="false"
                                  //         tabIndex="0"
                                  //       ></iframe> */}
                                  //     </div>
                                  //   </div>
                                  // </div>
                                }

                                <div className="grid grid-cols-12 gap-6">
                                  <div className="2xl:col-span-9 lg:col-span-9 col-span-12">
                                    <label
                                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                      htmlFor="default"
                                    >
                                      How to get there{" "}
                                      <span className="text-[#C00000]">*</span>
                                    </label>
                                    <div
                                      className="ck-editor-sec coursee-location"
                                      id="howToGetThere"
                                    >
                                      {/* <MyEditor
                                        key={"howToGetThere"}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setHowToGetThere(data);
                                        }}
                                        data={eventTimeSlot.howToGetThere}
                                      /> */}
                                      <TinyMCEEditor
                                        key={"howToGetThere"}
                                        onChange={(data) => {
                                          setHowToGetThere(data);
                                        }}
                                        data={eventTimeSlot.howToGetThere}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="grid grid-cols-12 gap-6 mb-6">
                      <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                          <div className={`2xl:col-span-9 lg:col-span-9 col-span-12`}>
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="availableSessions"
                            >
                              No. of sessions{" "}
                              <span className="text-[#C00000]">*</span>
                            </label>
                            <div className={`${timeSlotList.length > 0 ? 'disabled-input' : null}`}>
                              <input
                                id="availableSessions"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                type="text"
                                name="availableSessions"
                                placeholder="3"
                                value={eventTimeSlot.availableSessions}
                                onChange={(e) => handleChange(e)}
                                disabled={timeSlotList.length > 0 ? true : false}
                              //   onBlur={() => props.validateField("resourceName")}
                              />
                            </div>
                            <div>
                              {" "}
                              <ValidationText
                              // error={props.validState.error.resourceName}
                              />{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="2xl:col-span-9 lg:col-span-9 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="availableSeats"
                            >
                              No. of seats{" "}
                              <span className="text-[#C00000]">*</span>
                            </label>
                            <input
                              id="availableSeats"
                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                              type="text"
                              name="availableSeats"
                              value={eventTimeSlot.availableSeats}
                              onChange={(e) => handleChange(e)}
                            //   onBlur={() => props.validateField("resourceName")}
                            />
                            <div>
                              {/* {" "}
                              <ValidationText
                              // error={props.validState.error.resourceName}
                              />{" "} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {eventTimeSlot.lessons.map((lesson, lessonIndex) => (
                      <div
                        className="grid grid-cols-12 gap-6 mb-6"
                        key={`generateLessons${lessonIndex}`}
                      >
                        <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                          <label
                            className="block text-xl font-medium 2xl:mb-3 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            Date
                            <span className="text-[#C00000]">*</span>
                          </label>
                          <div className="grid grid-cols-12 gap-6 items-center w-full">
                            <div className="2xl:col-span-9 lg:col-span-9 col-span-12 w-full">
                              <input
                                id={`eventLessonDate${lessonIndex}`}
                                name="eventLessonDate"
                                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="date"
                                value={moment(lesson.eventLessonDate).format(
                                  "yyyy-MM-DD"
                                )}
                                onChange={(e) =>
                                  handleChangeInLessons(e, lessonIndex)
                                }
                                min={moment(new Date()).format("yyyy-MM-DD")}
                              //   onBlur={() =>
                              //     props.validateField("unpublishDate")
                              //   }
                              />
                              {/* <div>
                              <ValidationText
                              error={props.validState.error.unpublishDate}
                              />
                            </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                          <label
                            className="block text-xl font-medium 2xl:mb-3 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            Starts at
                            <span className="text-[#C00000]">*</span>
                          </label>
                          <div className="grid grid-cols-12 gap-6 items-center w-full">
                            <div className="2xl:col-span-9 lg:col-span-9 col-span-12 w-full">
                              <TimePicker
                                className="handleClicksForTimePicker"
                                identity={`eventStartTime${lessonIndex}`}
                                setTime={(time) =>
                                  setLessonTimeForGenerating(
                                    time,
                                    lessonIndex,
                                    "eventStartTime"
                                  )
                                }
                                value={lesson.eventStartTime}
                                index={lessonIndex}
                              />
                              {/* <NewTimePicker
                                  className='handleClicksForTimePicker'
                                  identity={`eventStartTime${lessonIndex}`}
                                  setTime={(time) => setLessonTimeForGenerating(time, lessonIndex, 'eventStartTime')}
                                  value={lesson.eventStartTime}
                                  index={lessonIndex}
                                /> */}
                              {/* <div>
                              <ValidationText
                              error={props.validState.error.unpublishDate}
                              />
                            </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                          <label
                            className="block text-xl font-medium 2xl:mb-3 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            Ends at
                            <span className="text-[#C00000]">*</span>
                          </label>
                          <div className="grid grid-cols-12 gap-6 items-center w-full">
                            <div className="2xl:col-span-9 lg:col-span-9 col-span-12 w-full">
                              <TimePicker
                                className="handleClicksForTimePicker"
                                key={`eventEndTime${lessonIndex}`}
                                identity={`eventEndTime${lessonIndex}`}
                                setTime={(time) =>
                                  setLessonTimeForGenerating(
                                    time,
                                    lessonIndex,
                                    "eventEndTime"
                                  )
                                }
                                value={lesson.eventEndTime}
                                index={lessonIndex + 50}
                              />
                              {/* <NewTimePicker
                                  className='handleClicksForTimePicker'
                                  identity={`eventEndTime${lessonIndex}`}
                                  setTime={(time) => setLessonTimeForGenerating(time, lessonIndex, 'eventEndTime')}
                                  value={lesson.eventEndTime}
                                  index={lessonIndex + 50}
                                /> */}
                              {/* <div>
                              <ValidationText
                              error={props.validState.error.unpublishDate}
                              />
                            </div> */}
                            </div>
                          </div>
                        </div>

                        {/* {
                            (eventTimeSlot.isOnline === true || eventTimeSlot.isHybrid) ?
                              <>
                                {
                                  eventTimeSlot.isMeeting === true ?
                                    <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`zoomMeetingId${lessonIndex}`}>
                                        Zoom Meeting Id
                                        <span className="text-[#C00000]">*</span>
                                      </label>
                                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                                        <div className="2xl:col-span-9 lg:col-span-9 col-span-12 w-full">
                                          <input
                                            id={`zoomMeetingId${lessonIndex}`}
                                            className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                            type="text"
                                            name="zoomMeetingId"
                                            value={lesson.zoomMeetingId}
                                            onChange={(e) => handleChangeInLessons(e, lessonIndex)}
                                          //   onBlur={() => props.validateField("resourceName")}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    : null
                                }
                                {
                                  eventTimeSlot.isWebinar === true ?
                                    <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`zoomWebinarId${lessonIndex}`}>
                                        Zoom Webinar Id
                                        <span className="text-[#C00000]">*</span>
                                      </label>
                                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                                        <div className="2xl:col-span-9 lg:col-span-9 col-span-12 w-full">
                                          <input
                                            id={`zoomWebinarId${lessonIndex}`}
                                            className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                            type="text"
                                            name="zoomWebinarId"
                                            value={lesson.zoomWebinarId}
                                            onChange={(e) => handleChangeInLessons(e, lessonIndex)}
                                          //   onBlur={() => props.validateField("resourceName")}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    : null
                                }
                                {
                                  eventTimeSlot.isOthers === true ?
                                    <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`otherZoomDetail${lessonIndex}`}>
                                        Other Zoom Detail
                                        <span className="text-[#C00000]">*</span>
                                      </label>
                                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                                        <div className="2xl:col-span-9 lg:col-span-9 col-span-12 w-full">
                                          <input
                                            id={`otherZoomDetail${lessonIndex}`}
                                            className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                            type="text"
                                            name="otherZoomDetail"
                                            value={lesson.otherZoomDetail}
                                            onChange={(e) => handleChangeInLessons(e, lessonIndex)}
                                          //   onBlur={() => props.validateField("resourceName")}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    : null
                                }
                              </>
                              :
                              null
                          } */}

                        {/* {(eventTimeSlot.isPhysical === true || eventTimeSlot.isHybrid === true) ?
                            <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                              <label
                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default">
                                Address
                                <span className="text-[#C00000]">*</span>
                              </label>
                              <div className="grid grid-cols-12 gap-6 items-center w-full">
                                <div className="2xl:col-span-9 lg:col-span-9 col-span-12 w-full">
                                  <input
                                    id={`address${lessonIndex}`}
                                    className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                    type="text"
                                    name="address"
                                    value={lesson.address}
                                    onChange={(e) => handleChangeInLessons(e, lessonIndex)}
                                  //   onBlur={() => props.validateField("resourceName")}
                                  />
                                </div>
                              </div>
                            </div>
                            : null
                          } */}
                      </div>
                    ))}

                    <div className="grid grid-cols-12 gap-6 mb-6">
                      <div className="col-span-12 mt-5">
                        <button
                          className=" px-5 mt-0 mb-3 py-3 btn btn-green text-xl border text-white"
                          onClick={() => {
                            addEditEntryLessonTimeSlotDetails();
                            props.executeScroll();
                          }}
                        >
                          <div className="flex items-center font-semibold">
                            <span className="">Generate</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Basic Information End */}
      {/* footer Button*/}
      <div className="grid grid-cols-12 gap-6">
        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
          <div className="flex flex-wrap justify-between space-x-10 mt-12">
            <div>
              {/* <NavLink
                to=""
                className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white hover:text-white"
              >
                {" "}
                Back
              </NavLink>  */}
              <button
                className="w-32 px-7 py-2 btn-sm btn btn-gray text-lg text-white"
                onClick={() => {
                  props.backFromAvailabilitySchedule();
                  props.executeScroll();
                }}
              >
                Back
              </button>
            </div>
            <div>
              {/* {props.resource.isPage ? ( */}
              <button
                className="w-32 px-7 py-2 mr-12 btn-sm btn btn-red text-lg text-white"
                onClick={() => {
                  if (validateTimeSlotAndLessons()) {
                    addEditEntryLessonTimeSlotDetails();
                  }

                }}
              // disabled={props.isLoaderEnabled}
              >
                Save
              </button>
              {/* ) : null} */}
              {/* {props.isLoaderEnabled ? (
            <ButtonLoader />
          ) : (
            <button
              className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
              onClick={() => props.addEditResources()}
            >
              Save
            </button>
          )} */}
              <button
                className="w-32 px-7  py-2 btn-sm btn btn-lighblue text-lg text-white"
                // onClick={() => props.onPreviewOpen()}
                onClick={() => { nextToSchedule(); props.executeScroll() }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvailabilityAndSchedule;
