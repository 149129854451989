import React, { Component } from "react";
import { Link } from "react-router-dom";
import DropdownSelect from "../../components/dropdown/Dropdown";
import Table from "../../components/table/Table";
import SwalServices from '../../services/swalServices/SwalServices';
import ResourceServices from '../../services/axiosServices/apiServices/ResourceServices';
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import { getAuthProps } from '../../utils/AuthenticationLibrary';
import { Navigate } from 'react-router-dom';
import moment from 'moment';
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import ActionDropdownButton from "../../components/UIComponent/ActionTableComponent/ActionDropdownButton";
import { CommonSuccessMessages } from "../../utils/Messages";
import { encryptAES } from "../../utils/Encryption";

class ViewResource extends Component {
  constructor(props) {
    super(props)
    this.ResourceServices = new ResourceServices();
    this.CommonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.authUser = getAuthProps();
    this.state = {
      setDropdownOpen: false,
      setDropdownIndex: 0,
      totalResultes: "",
      resourcesList: [],
      statuses: [],
      resourceTypes: [],
      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        filterByType: 0,
        filterByStatus: 0
      },
      resourceTypeId: [0],
      resourceStatusId: [0]
    }
    this.wrapperRef = React.createRef();
    //this.handleClickOutside = this.handleClickOutside.bind(this);


  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false })
    }
  }

  onDropDownChange = (index) => {
    this.setState({ setDropdownOpen: true });
    this.setState({ setDropdownIndex: index });
  }

  onClose = () => {
    this.setState({ setDropdownOpen: false })
  }

  actions = (element, index, value) => {
    return (element !== "resourceId") ? null
      : <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
        <div className="flex items-center relative"   >

          <ActionDropdownButton
            value={value}
            id={index}
            index={index}
            wrapperRef={this.wrapperRef}
            setDropdownIndex={this.state.setDropdownIndex}
            setDropdownOpen={this.state.setDropdownOpen}
            onDropdownAction={this.onDropDownChange}
            onActionClick1={this.redirectToCreateResources}
            onActionClick2={this.redirectToApproveResources}
            text1="View/Edit"
            text2="Post Approval"
          />

          <ActionDeleteButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onDelete}
            text="Delete"
          />
        </div>
      </td>

  }

  getAllResourcesList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });

    this.ResourceServices.getAllResourcesList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        response.responseItem.responseContent.itemList.forEach(resource => {
          if (resource.resourceName.length > 50) {
            resource.resourceName = resource.resourceName.substring(0, 50) + '...';
          }
        });
        this.setState({ resourcesList: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
        let newGetResourcesList = response.responseItem.responseContent.itemList;
        newGetResourcesList.map((key, index) => {
          key.publishDate = moment(key.publishDate).format('DD MMM YYYY');
        });
        this.setState({ resourcesList: newGetResourcesList }, () => {
          this.getAllResourceTypes();
        });
      }
      else {
        this.getAllResourceTypes();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  getAllResourceTypes = () => {
    this.ResourceServices.getAllResourceTypes().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ resourceTypes: response.responseItem.responseContent });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  getAllStatuses = () => {
    this.CommonServices.getAllStatus().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ statuses: response.responseItem.responseContent }, () => {
          this.getAllResourcesList();
        });
      }
      else {
        this.getAllResourcesList();

        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    if (drpIdentity === "Types") {
      detail.filterByType = id;
    }
    else if (drpIdentity === "Statuses") {
      detail.filterByStatus = id;
    }
    this.setState({ pagination: { ...detail } });
    this.getAllResourcesList();
  }

  searchByName = (value) => {

    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.pageNo = 1;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });

    }
    this.getAllResourcesList();
  }

  setPagination = (newPagination) => {

    this.setState({ pagination: newPagination });
    this.getAllResourcesList();
  }

  componentDidMount() {

    this.getAllStatuses();
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  redirectToCreateResources = (value) => {
    this.setState({ route: '/EditResource/ResourceId?=' + encryptAES(value) });
  }

  redirectToApproveResources = (value) => {
    this.setState({ route: '/PostApproval/resourceId?=' + encryptAES(value) });
  }

  onDelete = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Resource"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Resource"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.ResourceServices.deleteResources(request).then((response) => {
            if (response.statusCode === 200) {
              this.swalServices.Success("Deleted");
              this.getAllResourcesList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          })
        }
      });
  }

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (

      <div className="main-body-section" >
        <div className="flex">
          <div className="relative flex flex-col flex-1 "></div>
        </div>
        <div className="pb-10">
          <div className="custom-card shadow-lg mb-10">
            <div className="filter-info grid grid-cols-12 gap-6">
              <div className="filter-types xl:col-span-  lg:col-span-4 col-span-12">
                <label
                  className="filter-lable text-xl block font-medium mb-1 theme-color min-h-30"
                  htmlFor="default"
                >
                  Search by Name
                  {/* <span className="text-[#C00000]">*</span> */}
                </label>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) => this.searchByName(e.target.value)}
                />
              </div>

              <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                <label
                  className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                  htmlFor="default"
                >
                  Type
                  {/* <span className="text-[#C00000]">*</span> */}
                </label>
                <DropdownSelect
                  drpIdentity={"Types"}
                  optionArray={this.state.resourceTypes}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  value={this.state.pagination.filterByType} />
              </div>
              <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                <label
                  className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                  htmlFor="default"
                >
                  Status
                  {/* <span className="text-[#C00000]">*</span> */}
                </label>
                <DropdownSelect
                  drpIdentity={"Statuses"}
                  optionArray={this.state.statuses}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  value={this.state.pagination.filterByStatus} />
              </div>
            </div>
          </div>
          <div className="w-full mx-auto">
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              <div className="mb-4 sm:mb-0">
                <h1 className="table-title-txt theme-color font-bold">
                  {this.state.totalResultes} Resources
                </h1>
              </div>
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                <Link to="/CreatePreviewResource">
                  <button className="btn btn-red text-white">
                    <span className="text-xl text-white font-semibold tracking-normal">
                      {" "}
                      Create New{" "}
                    </span>
                  </button>
                </Link>
              </div>
            </div>

            <div className="relative">
              <div>
                <div className="" onMouseLeave={() => this.onClose()} >
                  <Table columns={[{ name: 'resourceName', title: 'Resources Name' }, { name: 'resourceTypeName', title: 'Type' }, { name: 'publishDate', title: 'Publish Date' }, { name: 'visibilityStatus', title: 'Visibility' }, { name: 'statusName', title: 'Status' }, { name: 'resourceId', title: 'Actions' }]}
                    rows={this.state.resourcesList}
                    isLoading={this.state.isLoading}
                    sortingColumns={["resourceName", "statusName"]}
                    pagination={this.state.pagination}
                    totalResultes={this.state.totalResultes}
                    totalCount={this.state.totalResultes}
                    setPagination={this.setPagination.bind(this)}
                    customScope={[
                      {
                        column: 'resourceId',
                        renderTableData: this.actions.bind(this)
                      },
                    ]} />
                </div>
                {/* <TableWithRadio /> */}
              </div>


            </div>
          </div>

        </div>
      </div>



    );
  }
}

export default ViewResource;
