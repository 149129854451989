import {
    AcceptTotalDTSMEPackagePaidAmountInReconciliation,
    GetDTSMEMarketPlaceCompanyInformationList,
    GetDTSMEMarketPlaceCompanyInformationById,
    AddEditDTSMEMarketPlaceCompanyInformation,
    GetALLMarketPlaceCompanyInformationList,
    GetAllDropdownsForDTSME,
    DownloadSheetForDTSMEPurchaseReport,
    GetDTSMEPurchaseRecordList,
    AddEditPartnerConsultantInformation,
    GetPartnerConsultantInformationList,
    GetPartnerConsultantInformationById,
    DeleteGetPartnerConsultantInformationById,
    AddEditSolutionProvidersInformation,
    GetALLSolutionProviderInformationList,
    GetSolutionProviderInformationById,
    DeleteSolutionProviderById,


    GetDTSMEMentorsInformationById,
    GetALLDTSMEMentorsInformation,
    AddEditDTSMEMentorsInformation,
    DeleteDTSMEMentorsInformationById





} from "../ApiEndPoints";
import {
    axiosGetAuthorizeMultiParams,
    axiosGetAuthorize,
    axiosPostAuthorize,
    axiosPostFileAuthorizeblob,
    axiosGet,
} from "../AxiosRequests";

export default class DtsmeServices {
    async acceptTotalDTSMEPackagePaidAmountInReconciliation(request) {
        return axiosGetAuthorizeMultiParams(AcceptTotalDTSMEPackagePaidAmountInReconciliation, request);
    }
    async getALLMarketPlaceCompanyInformationList(request) {
        return axiosGetAuthorize(GetALLMarketPlaceCompanyInformationList, request);
    }
    async getDTSMEMarketPlaceCompanyInformationList(request) {
        return axiosPostAuthorize(GetDTSMEMarketPlaceCompanyInformationList, request);
    }
    async getDTSMEMarketPlaceCompanyInformationById(request) {
        return axiosGetAuthorize(GetDTSMEMarketPlaceCompanyInformationById, request);
    }
    async getAllDropdownsForDTSME(request) {
        return axiosGetAuthorize(GetAllDropdownsForDTSME, request);
    }
    async addEditDTSMEMarketPlaceCompanyInformation(request) {
        return axiosPostAuthorize(AddEditDTSMEMarketPlaceCompanyInformation, request);
    }
    async downloadSheetForDTSMEPurchaseReport(request) {
        return axiosPostFileAuthorizeblob(DownloadSheetForDTSMEPurchaseReport, request);
    }
    async getDTSMEPurchaseRecordList(request) {
        return axiosPostAuthorize(GetDTSMEPurchaseRecordList, request);
    }
    async addEditPartnerConsultantInformation(request) {
        return axiosPostAuthorize(AddEditPartnerConsultantInformation, request);
    }
    async getPartnerConsultantInformationList(request) {
        return axiosPostAuthorize(GetPartnerConsultantInformationList, request);
    }
    async getPartnerConsultantInformationById(request) {
        return axiosGetAuthorize(GetPartnerConsultantInformationById, request);
    }
    async deleteGetPartnerConsultantInformationById(request) {
        return axiosGetAuthorize(DeleteGetPartnerConsultantInformationById, request);
    }
    async addEditSolutionProvidersInformation(request) {
        return axiosPostAuthorize(AddEditSolutionProvidersInformation, request);
    }
    async getALLSolutionProviderInformationList(request) {
        return axiosPostAuthorize(GetALLSolutionProviderInformationList, request);
    }
    async getSolutionProviderInformationById(request) {
        return axiosGetAuthorize(GetSolutionProviderInformationById, request);
    }
    async deleteSolutionProviderById(request) {
        return axiosGetAuthorize(DeleteSolutionProviderById, request);
    }
    async addEditDTSMEMentorsInformation(request) {
        return axiosPostAuthorize(AddEditDTSMEMentorsInformation, request);
    }
    async getALLDTSMEMentorsInformation(request) {
        return axiosPostAuthorize(GetALLDTSMEMentorsInformation, request);
    }
    async getDTSMEMentorsInformationById(request) {
        return axiosGetAuthorize(GetDTSMEMentorsInformationById, request);
    }
    async deleteDTSMEMentorsInformationById(request) {
        return axiosGetAuthorize(DeleteDTSMEMentorsInformationById, request);
    }
}
