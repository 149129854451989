import React from "react";
import DatePicker from "../../../datePicker/DatePicker";
import DropdownSelect from "../../../dropdown/Dropdown";
import Validation from "../../../../utils/validation/ValidationText";
import moment from "moment";
import TextInput from "../../../UIComponent/TextInput";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";

function CorporateMembershipSubscription(props) {
  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 px-3 lg:py-7 lg:pt-5 pt-4 pb-4">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Membership Type
                  <span className="text-[#c00000]">*</span>
                </h2>

                <DropdownSelect
                  drpIdentity={"MembershipTypesSubscription"}
                  optionArray={props.membershipTypes}
                  setFilterParameters={props.setFilterParameters}
                  value={props.membershipSubscription.membershipTypeId}
                  onBlur={(e) => props.validate("membershipTypeId", props.membershipSubscription)}
                />
                <Validation error={props.validState.error.membershipTypeId} />
              </div>
            </div>
          </div>
          {props.membershipSubscription.membershipTypeId > 0 && props.membershipSubscription.membershipTypeId === 5 ?
            <>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                      Please select the TAC that you are from
                      <span className="text-[#c00000]">*</span>
                    </h2>

                    <DropdownSelect
                      drpIdentity={"TACFromTypeSubscription"}
                      optionArray={props.tacNames}
                      setFilterParameters={props.setFilterParameters}
                      value={props.membershipSubscription.tacFromId}
                    // onBlur={(e) => props.validate("membershipTypeId", props.membershipSubscription)}
                    />
                    <Validation error={props.validState.error.tacFromId} />
                  </div>
                </div>
              </div>
              {props.membershipSubscription.tacFromId > 0 && props.membershipSubscription.tacFromId === 1 ?
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                        TAC Name
                        <span className="text-[#c00000]">*</span>
                      </h2>

                      <TextInput
                        type="text"
                        name="tacName"
                        value={props.membershipSubscription.tacName}
                        identity="MemebershipSubscription"
                        handleChange={(e) => props.handleChange(e, 'MemebershipSubscription')}
                      />
                      <Validation error={props.validState.error.tacName} />
                    </div>
                  </div>
                </div>
                : null}
            </>
            : null}
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Billing Category
                  <span className="text-[#c00000]">*</span>
                </h2>

                <DropdownSelect
                  drpIdentity={"corporateMemberBillingCategoryId"}
                  optionArray={props.billingCategory}
                  setFilterParameters={props.setFilterParameters}
                  value={props.membershipSubscription.billingCategoryId}
                  onBlur={(e) => props.validate("corporateMemberBillingCategoryId", props.membershipSubscription)}
                  disabled={true}
                />
                <Validation error={props.validState.error.corporateMemberBillingCategoryId} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Subscription Start Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="subscriptionStartDate"
                    handleChange={props.handleChange}
                    identity="MembershipSubscription"
                    value={moment(props.membershipSubscription.subscriptionStartDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />

                  {/* <Validation error={props.validState.error.subscriptionStartDate} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Subscription End Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="subcriptionEndDate"
                    handleChange={props.handleChange}
                    identity="MembershipSubscription"
                    value={moment(props.membershipSubscription.subcriptionEndDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />

                  {/* <Validation error={props.validState.error.subcriptionEndDate} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Updated By
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"updatedById"}
                  optionArray={props._by}
                  setFilterParameters={props.setFilterParameters}
                  value={props.membershipSubscription.updatedBy}
                // onBlur={(e) => props.validate("updatedById", props.membershipSubscription)}
                />
                {/* <Validation error={props.validState.error.updatedById} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Last Updated Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input">
                  {props.membershipSubscription.updatedAt === "" || props.membershipSubscription.updatedAt === null ?

                    <TextInput
                      disabled={true}
                      placeholder=""
                      type="text"
                      name="linkedIn"
                      value="None"
                      identity="BasicInformation"
                    />
                    :
                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      handleChange={props.handleChange}
                      identity="updatedAt"
                      value={moment(props.membershipSubscription.updatedAt).format("yyyy-MM-DD")}
                    />
                  }
                </div>
                {/* <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="updatedAt"
                    handleChange={props.handleChange}
                    identity="MembershipSubscription"
                    value={moment(props.membershipSubscription.updatedAt).format("yyyy-MM-DD")}
                  onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />

                  <Validation error={props.validState.error.updatedAt} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* Renew Button */}
        <div className="grid grid-cols-12 gap-6 lg:mt-10 md:mt-5 sm:mt-5 mt-5">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12 new-btn">
            {props.membershipSubscription.billingCategoryId !== 1 && props.membershipSubscription.billingCategoryId !== 7 && props.membershipSubscription.billingCategoryId !== 4
              && props.membershipSubscription.subcriptionEndDate !== null && props.membershipSubscription.subcriptionEndDate !== "" ?
              <div className="left-btn">
                <a
                  className="flex items-center justify-end"
                //href="/CreateCorporateMembers"
                >
                  {
                    props && props.isLoadingForRenewal === true ?
                      (
                        <ButtonLoader />
                      )
                      :
                      (
                        <button
                          className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                          onClick={() => props.renewCorporateMember()}
                        >
                          Renew
                        </button>
                      )
                  }
                </a>
              </div>
              :
              <div className="left-btn">
                <a
                  className="flex items-center justify-end"
                //href="/CreateCorporateMembers"
                >
                  {
                    props && props.isLoadingForRenewal === true ?
                      (
                        <ButtonLoader />
                      )
                      :
                      (
                        <button
                          className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                          //onClick={() => props.renewCorporateMember()}
                          onClick={() => props.renewCorporateMember()}
                        //disabled
                        >
                          Renew New
                        </button>
                      )
                  }
                </a>
              </div>}

            <div className="">
              <a
                className="flex items-center justify-end"
              //href="/CreateCorporateMembers"
              >
                {
                  props && props.isLoading === true ?
                    (
                      <ButtonLoader />

                    )
                    :
                    (
                      <button
                        className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                        onClick={() => props.onSave()}
                      >
                        Save
                      </button>
                    )
                }
              </a>
            </div>
          </div>
        </div>
      </div>




    </div>
  );
}

export default CorporateMembershipSubscription;
