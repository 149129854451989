import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionEditButton from "../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import { encryptAES } from "../../utils/Encryption";
import DtsmeServices from "../../services/axiosServices/apiServices/DtsmeServices";
import SwalServices from "../../services/swalServices/SwalServices";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import ValidationText from "../../utils/validation/ValidationText";
import moment from "moment";
import { saveAs } from "file-saver";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";

export default class MarketPlace extends Component {
    constructor(props) {
        super(props);
        this.dtsmeServices = new DtsmeServices();
        this.swalServices = new SwalServices();
        this.state = {
            DTSMEPurchaseReportRequest: {
                startDate: "",
                endDate: "",
              },
            isLoading: false,
            redirect: null,
            pagination: {
                orderByColumn: "",
                orderFlag: 0,
                pageNo: 1,
                pageSize: 10,
                searchText: "",
                requestDate: "",
                statusId: 0
            },
            validationRule: {
                startDate: [
                  {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "Start date for transaction report"),
                  },
                ],
                endDate: [
                  {
                    type: "require",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "End date for transaction report"),
                  },
                  {
                    type: "minEndDate",
                    minEndDate: "startDate",
                    message: CommonValidationMessages.SelectRequired.replace("{0}", "greater end date")
                  }
                ],
              },
            dtsmeMarketPlaceCompanyInformationList: [],
            totalResultes: 0,
            councilIndex: 0,
        }
    }
    validateField = (key) => {
        const newValidState = validate(
          key,
          this.state.DTSMEPurchaseReportRequest,
          this.state.validationRule,
          this.state.validState
        );
        this.setState({ validState: newValidState });
      };
    
      isValidateAllFields = () => {
        const newValidState = isValidForm(
          this.state.DTSMEPurchaseReportRequest,
          this.state.validationRule,
          this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
      };
      handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'startDate') {
          let date = this.state.DTSMEPurchaseReportRequest
          date.startDate = value
          this.setState({ startDate: date })
        }
        if (name === 'endDate') {
          let date = this.state.DTSMEPurchaseReportRequest
          date.endDate = value
          this.setState({ endDate: date })
        }
      }

    componentDidMount() {
        this.getDTSMEMarketPlaceCompanyInformationList();
    }

    getDTSMEMarketPlaceCompanyInformationList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.dtsmeServices.getDTSMEMarketPlaceCompanyInformationList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ dtsmeMarketPlaceCompanyInformationList: response.responseItem.responseContent.itemList });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    // Serach By Company Name
    searchByName = (value, identity) => {
        let detail = this.state.pagination;
        if (identity === "Company_Name") {
            detail.pageNo = 1;
            detail.pageSize = 10;
            detail.searchText = value;
            this.setState({ pagination: { ...detail } });
        }
    }

    // Handle pagination Method 
    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getDTSMEMarketPlaceCompanyInformationList(newPagination);
    };

    // Edit Method
    onEdit = (value, index) => {
        let dtsmeMarketplaceCompanyList = [...this.state.dtsmeMarketPlaceCompanyInformationList];
        this.setState({ redirect: '/dtsme/AddEditDTSMEMember/dtsmeMarketPlaceCompanyId?=' + encryptAES(dtsmeMarketplaceCompanyList[index].dtsmeMarketPlaceCompanyId) });
    }

    // Action Edit and Delete Method
    actions = (element, index, value) => {
        return (element !== "dtsmeMarketPlaceCompanyId") ? null :
            <td>
                <ActionContainer>
                    <ActionEditButton
                        value={value}
                        id={index}
                        index={index}
                        // icon={EditIcon}
                        onActionClick={this.onEdit.bind(this)}
                        text="Edit"
                    />
                    <ActionDeleteButton
                        value={value}
                        id={index}
                        index={index}
                    // onActionClick={this.onDelete.bind(this)}
                    />
                </ActionContainer>
            </td>
    }


   
    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} state={this.state.getResourcePagesList} />;
        }
        return (
            <>
                <div className="main-body-section">
                    
                    <div className="custom-card shadow-lg">
                        <div className="filter-info grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="filter-types xl:col-span-4 lg:col-span-6 col-span-12 pr-12">
                                        <label
                                            className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                            htmlFor="default"
                                        >
                                            Company Name
                                        </label>
                                        <input
                                            id="searchText"
                                            name="searchText"
                                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                            type="text"
                                            onChange={(event) => this.searchByName(event.target.value, "Company_Name")}
                                        />
                                    </div>
                                    <div className="xl:col-span-2 lg:col-span-6 col-span-12">
                                        <div className=" patner-search flex items-center justify-end pt-8">
                                            <div className="search">
                                                <button
                                                    className="btn btn-red text-xl text-white font-bold"
                                                    onClick={() => { this.getDTSMEMarketPlaceCompanyInformationList(); }}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <main className="pb-10 pt-8">
                        <div className="w-full mx-auto">
                            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-3xl md:text-4xl theme-color font-bold">
                                        {`Total:${this.state.totalResultes}`}
                                    </h1>
                                </div>
                                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                                    <Link to='/dtsme/AddEditDTSMEMember' >
                                        <button className="btn btn-red text-white" >
                                            <span className="text-xl text-white font-semibold tracking-normal"> Add New</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="relative">
                                <div>
                                    <div className=" mt-6">
                                        <Table
                                            columns={[
                                                { name: "dtsmeMarketPlaceCompanyName", title: "Company Name", },
                                                { name: "email", title: "Email" },
                                                { name: "techSolution", title: "TechSolution" },
                                                { name: "vendorCategory", title: "VendorCategory" },
                                                { name: "dtsmeMarketPlaceCompanyId", title: "Action" },
                                            ]}
                                            pagination={this.state.pagination}
                                            rows={this.state.dtsmeMarketPlaceCompanyInformationList}
                                            sortingColumns={["dtsmeMarketPlaceCompanyName", "vendorCategory", "techSolution"]}
                                            isLoading={this.state.isLoading}
                                            totalResultes={this.state.totalResultes}
                                            totalCount={this.state.totalResultes}
                                            setPagination={this.setPagination.bind(this)}
                                            customScope={[
                                                {
                                                    column: "dtsmeMarketPlaceCompanyId",
                                                    renderTableData: this.actions.bind(this),
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </>
        )
    }
}