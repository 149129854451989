// Import react components and libraries
import React, { Component } from "react";
import DropdownSelect from "../../components/dropdown/Dropdown";
import DatePicker from "../../components/datePicker/DatePicker";
import TableRadio from "../../components/table/TableRadio";
import { Link, Navigate } from "react-router-dom";
import StrategicMemberTable from "../../components/table/StrategicMemberTable";
import ValidationText from "../../utils/validation/ValidationText";
import moment from "moment";
import {
  CommonSuccessMessages,
  CommonValidationMessages,
} from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import withParams from "../../HOC/withParams";
import AddEditStrategicMemberModal from "../../components/partnerships/AddEditStrategicMemberModal";

// Import Api services
import PartnershipsServices from "../../services/axiosServices/apiServices/PartnershipsServices";
import ResourceServices from "../../services/axiosServices/apiServices/ResourceServices";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import { checkParameters } from "../../utils/Encryption";

class AddEditProject extends Component {
  constructor(props) {
    super(props);
    this.ResourceServices = new ResourceServices();
    this.partnershipServices = new PartnershipsServices();
    this.CommonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.authUser = getAuthProps();
    this.state = {
      setDropdownOpen: false,
      setDropdownIndex: 0,
      totalResultes: "",
      setAddEditStrategicMember: false,
      dynamicArray: [
        {
          id: "IBM",
          name: "json tan",
          deignationname: "Vice President ",
          Email: "jason.tan@imda.com",
          number: 99990000,
        },
        {
          id: 2,
          name: "json tan",
          deignationname: "Manager",
          Email: "jason.tan@imda.com",
          number: 99990000,
        },
      ],
      statuses: [],
      resourceTypes: [],
      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        filterByType: 0,
        filterByStatus: 0,
      },
      resourceTypeId: [0],
      resourceStatusId: [0],

      // Akshay 17/08/2022
      // strategicMemberList: [
      //   {
      //     updatedAt: "dfdsf",
      //     email: "dsfsdf",
      //     contactNo: "dssd",
      //     designation: "csdc",
      //     lastName: "xchbxv",
      //     firstName: "csxccs",
      //     companyName: "Company",
      //   },
      // ],
      secretariatList: [],
      agencyOfficerList: [],
      projectDetails: {
        projectId: 0,
        projectName: "",
        sGTechSecretariatId: 0,
        projectDescription: "",
        internalComments: "",
        projectStartDate: "",
        projectEndDate: "",
        agencyId: 0,
        projectAgencyOfficerMappingId: 0,
        projectStrategycMemberMappingId: 0,
        isAgencyInvolved: "false",
        isStrategicMemberInvolved: "false",
        previousAgencyId: 0,
      },
      agencyOfficerDetails: {
        agencyOfficerId: 0,
        agentName: "",
        designation: "",
        emailAddress: "",
        contactNo: "",
        agencyId: 0,
        projectId: 0,
        projectAgencyOfficerMappingId: 0,
        isKeyContactPerson: false,
      },
      officerArr: [],
      officerList: [],
      agencyList: [],
      validationRules: {
        projectName: [
          {
            type: "require",
            message: "Please fill this field",
          },
        ],
        projectDescription: [
          {
            type: "require",
            message: "Please fill this field",
          },
        ],
        sGTechSecretariatId: [
          {
            type: "require",
            message: "Please select SGTech Secretariat",
          },
        ],
        internalComments: [
          {
            type: "require",
            message: "Please fill this field",
          },
        ],
        agencyId: [
          {
            type: "require",
            message: "Please select Existing Agency",
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      projectId: 0,
      route: "",
      isAgencyChanged: false,

      // strategic member start akshay pop up
      strategicMemberDetails: {
        corporateMemberId: 0,
        individualMemberId: 0,
        companyName: "",
        firstName: "",
        lastName: "",
        designation: "",
        contactNo: "",
        email: "",
        projectId: 0,
      },

      companyForStrategicMember: [],
      strategicMemberList: [],
      companyOfficerByCompanyForStrategicMember: [],
      tempOfficer: [],
      officerArrStrategic: [],
      validationRulesStrategic: {
        corporateMemberId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "company"
            ),
          },
        ],
        individualMemberId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "officer"
            ),
          },
        ],
      },
      validStateStrategic: {
        isValid: true,
        error: {},
      },
      // end
    };
    
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
  };

  actions = (element, index, value) => {
    return element !== "id" ? null : (
      <td className="px-3 first:pl-5 last:pr-5 py-0 whitespace-nowrap font-sm">
        <div className="whitespace-nowrap tble-td-txt underline cursor-pointer decoration-[#c00000] text-[#c00000]">
          Select Strategic Member
        </div>
      </td>
    );
  };
  // Akshay 17/08/2022 start

  componentDidMount() {
    let params = this.props.params.projectId;
    let projectID = 0;
    if (params && (projectID = checkParameters(params, "Number"))) {
      if (projectID > 0 && projectID != null && projectID !== undefined) {
        this.setState({ projectId: projectID });
        this.getGovernmentProjectById(projectID);

      } else {
        this.getAllSecretariatList();
      }
    } else {
      // this.getAllStrategicMemberList();
      this.getAllSecretariatList();
    }
  }

  // Strategic member akshay popup start

  addStrategicMember = () => {
    if (this.isValidStrategicMember()) {
      let request = this.state.strategicMemberDetails;

      let member = this.state.companyForStrategicMember.find(
        (x) => x.value === request.corporateMemberId
      );

      request.companyName = member.label;
      request.projectId = this.state.projectId;

      this.setState({ isLoading: true });
      this.partnershipServices.addStrategicMember(request).then((response) => {
        if (
          response.statusCode === 200 &&
          response.responseItem != null &&
          response.responseItem.responseContent.errorType === "EXISTS"
        ) {
          this.swalServices.Error("Strategic member already joined project.");
        } else if (
          response.statusCode === 200 &&
          response.responseItem != null
        ) {
          this.swalServices.Success("Strategic member inserted successfully.");
          // this.getAllStrategicMemberList();
          this.getStrategicMemberListForAddEditProject(this.state.projectId);
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    }
  };

  getStrategicMemberListForAddEditProject = (id) => {
    this.setState({ isLoading: true });
    this.partnershipServices
      .getStrategicMemberListForAddEditProject(id)
      .then((response) => {
        if ((response.statusCode === 200) & (response.responseItem !== null)) {
          this.setState({
            strategicMemberList: response.responseItem.responseContent,
          });
          this.getAllSecretariatList();
          // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
        } else {
          this.getAllSecretariatList();
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  getAllCompanyForStrategicMember = () => {
    this.setState({ isLoading: true });
    this.partnershipServices
      .getAllCompanyForStrategicMember()
      .then((response) => {
        if (response != null) {
          var dropdown = response.responseItem.responseContent;
          dropdown.forEach(function (item) { delete item["dropdownName"]; });
          dropdown = dropdown.map((p) => ({ value: p.id, label: p.name }));
          this.setState({ companyForStrategicMember: dropdown });
          // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  getCompanyOfficerByCompanyForStrategicMember = (id) => {
    this.setState({ isLoading: true });
    this.partnershipServices
      .getCompanyOfficerByCompanyForStrategicMember(id)
      .then((response) => {
        if (response != null) {
          this.setState({
            companyOfficerByCompanyForStrategicMember:
              response.responseItem.responseContent,
          });

          var tempOfficer = response.responseItem.responseContent;
          var officerArr = [];
          officerArr = tempOfficer.map((member) => ({
            id: member.individualMemberId,
            name: member.firstName + " " + member.lastName,
          }));
          var officerArray = officerArr.map(p => ({
            value: p.id, label: p.name
          }));
          this.setState({ officerArrStrategic: officerArray });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  setFilterParametersStrategic = (id, drpIdentity) => {
    let details = this.state.strategicMemberDetails;
    if (drpIdentity === "CompanyName") {
      details["corporateMemberId"] = id.value;
      this.validateStrategicMember("corporateMemberId");
      this.getCompanyOfficerByCompanyForStrategicMember(id.value);
      this.setState({ strategicMemberDetails: details });
    } else if (drpIdentity === "CompanyOfficerName") {
      details["individualMemberId"] = id.value;
      this.validateStrategicMember("individualMemberId");
      // let tempStrategicMember = this.state.strategicMemberDetails;
      this.setState({ strategicMemberDetails: details });

      let member = this.state.companyOfficerByCompanyForStrategicMember.find(
        (x) => x.individualMemberId === id.value
      );
      this.setState({ strategicMemberDetails: member });
    }
  };

  validateStrategicMember = (key) => {
    const returnValidState = validate(
      key,
      this.state.strategicMemberDetails,
      this.state.validationRulesStrategic,
      this.state.validStateStrategic
    );
    this.setState({ validStateStrategic: returnValidState });
  };

  isValidStrategicMember = () => {
    const returnValidState = isValidForm(
      this.state.strategicMemberDetails,
      this.state.validationRulesStrategic,
      this.state.validStateStrategic
    );
    this.setState({ validStateStrategic: returnValidState });
    return returnValidState.isValid;
  };
  // end

  getGovernmentProjectById = (id) => {
    this.setState({ isLoading: true });
    this.partnershipServices.getGovernmentProjectById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let project = this.state.projectDetails;
        project.projectId = response.responseItem.responseContent.projectId;
        project.projectName = response.responseItem.responseContent.projectName;
        project.sGTechSecretariatId =
          response.responseItem.responseContent.sgTechSecretariatId;
        project.projectDescription =
          response.responseItem.responseContent.projectDescription;
        project.projectEndDate =
          response.responseItem.responseContent.projectEndDate;
        project.projectStartDate =
          response.responseItem.responseContent.projectStartDate;
        project.internalComments =
          response.responseItem.responseContent.internalComments;
        project.isAgencyInvolved =
          response.responseItem.responseContent.isAgencyInvolved === true
            ? "true"
            : "false";
        project.isStrategicMemberInvolved =
          response.responseItem.responseContent.isStrategicMemberInvolved ===
            true
            ? "true"
            : "false";
        project.agencyId = response.responseItem.responseContent.agencyId;
        project.previousAgencyId = response.responseItem.responseContent.agencyId;
        this.setState({ projectDetails: project }, () => {
          this.getAllAgencyOfficerList(project.agencyId)
        }
        );
      } else {
        this.getAgencyOfficerListForAddEditProject(id);
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAllStrategicMemberList = () => {
    this.setState({ isLoading: true });
    this.partnershipServices.getAllStrategicMemberList().then((response) => {
      if (response != null) {
        this.setState({
          strategicMemberList: response.responseItem.responseContent,
        });
        // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  deleteGovernmentStrategicMember = (projectStrategycMemberMappingId, strategicMemberId, projectId) => {
    let request = {};
    request.projectStrategycMemberMappingId = projectStrategycMemberMappingId;
    request.StrategicMemberId = strategicMemberId;
    request.ProjectId = projectId;
    this.setState({ isLoading: true });
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "member"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "member"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.partnershipServices
            .deleteStrategicMemberFromAddEditProject(request)
            .then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success(
                  "Strategic member deleted successfully."
                );
                this.getStrategicMemberListForAddEditProject(
                  this.state.projectId
                );
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
        }
      });
  };

  getAllSecretariatList = () => {
    this.setState({ isLoading: true });
    this.partnershipServices.getAllSecretariatList().then((response) => {
      if (response != null) {
        this.setState({
          secretariatList: response.responseItem.responseContent,
        }, () => {
          this.getAllGovernmentAgencyList();
          this.getAllCompanyForStrategicMember();
        });
        // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
      } else {
        this.getAllGovernmentAgencyList();
        this.getAllCompanyForStrategicMember();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAllAgencyOfficerList = (id) => {
    this.setState({ isLoading: true });
    this.partnershipServices.getAllAgencyOfficerList(id).then((response) => {
      if (response != null) {
        this.setState({
          officerList: response.responseItem.responseContent,
        });
        // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
        var tempOfficer = response.responseItem.responseContent;
        var officerArr = [];
        officerArr = tempOfficer.map((member) => ({
          id: member.agencyOfficerId,
          name: member.agentName,
        }));
        this.setState({ officerArr: officerArr }, () => {
          this.getAgencyOfficerListForAddEditProject(this.state.projectId);
        });
      } else {
        this.getStrategicMemberListForAddEditProject(this.state.projectId);
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  addEditAgencyOfficer = () => {
    let request = this.state.agencyOfficerDetails;
    request.projectId = this.state.projectId;
    if (request.agencyOfficerId > 0) {
      this.partnershipServices
        .addAgencyOfficerToAddEditProject(request)
        .then((response) => {
          if (
            response.statusCode === 200 &&
            response.responseItem != null &&
            response.responseItem.responseContent.errorType === "EXISTS"
          ) {
            this.swalServices.Error(
              "Agency member already attached with this project."
            );
          } else if (
            response.statusCode === 200 &&
            response.responseItem != null
          ) {
            this.swalServices.Success("Agency details updated successfully.");
          } else {
            this.swalServices.Error(response.message);
          }
          this.getAgencyOfficerListForAddEditProject(this.state.projectId);
        });
    }
  };

  deleteAgencyOfficer = (agencyOfficerId, projectAgencyOfficerMappingId) => {
    let request = {
      agencyOfficerId: agencyOfficerId,
      projectAgencyOfficerMappingId: projectAgencyOfficerMappingId,
      projectId: this.state.projectId,
    };
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "officer"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "officer"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.partnershipServices
            .deleteAgencyOfficerFromAddEditProject(request)
            .then((response) => {
              if (response.statusCode === 200) {
                this.swalServices.Success(
                  "Agency officer deleted successfully."
                );
                this.getAgencyOfficerListForAddEditProject(
                  this.state.projectId
                );
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
        }
      });
  };

  getAllGovernmentAgencyList = () => {
    this.partnershipServices.getAllGovernmentAgencyList().then((response) => {
      if (response != null) {
        this.setState({ agencyList: response.responseItem.responseContent });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  addEditProject = () => {
    if (this.isValidProjectDetails()) {
      if ((this.state.projectDetails.projectEndDate && this.state.projectDetails.projectStartDate) || (!this.state.projectDetails.projectEndDate && this.state.projectDetails.projectStartDate) || (!this.state.projectDetails.projectEndDate && !this.state.projectDetails.projectStartDate)) {
        let request = this.state.projectDetails;
        request.isAgencyInvolved =
          request.isAgencyInvolved === "true" ? true : false;
        request.isStrategicMemberInvolved =
          request.isStrategicMemberInvolved === "true" ? true : false;
        request.agencyId = request.agencyId !== null ? request.agencyId : 0;
        this.partnershipServices.addEditProject(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorType !== 'EXISTS') {
            this.swalServices.Success("Project details inserted successfully.");
            let detail = this.state.projectDetails;
            detail.projectId = response.responseItem.responseContent.keyId;
            this.setState({
              projectId: response.responseItem.responseContent.keyId,
            });
            this.getGovernmentProjectById(
              response.responseItem.responseContent.keyId
            );
            this.setState({ projectDetails: detail });
            // this.setState({ route: "/Partnerships" });
            this.getAgencyOfficerListForAddEditProject(response.responseItem.responseContent.keyId);
            this.getStrategicMemberListForAddEditProject(response.responseItem.responseContent.keyId);
          } else {
            this.swalServices.Error(response.responseItem.responseContent.errorMessage);
          }
          // this.getAllAgencyOfficerList();
        });
      }
      else {
        this.swalServices.Error("Please select start date.");
      }
    }
  };

  getAgencyOfficerListForAddEditProject = (id) => {
    this.setState({ isLoading: true });
    this.partnershipServices
      .getAgencyOfficerListForAddEditProject(id)
      .then((response) => {
        if (response != null) {
          var det = response.responseItem.responseContent;
          var temp = [];
          temp = det.map((member) => ({
            isKeyContactPerson:
              member.isKeyContactPerson === true ? "true" : "false",
            projectAgencyOfficerMappingId: member.projectAgencyOfficerMappingId,
            agencyOfficerId: member.agencyOfficerId,
            agentName: member.agentName,
            designation: member.designation,
            emailAddress: member.emailAddress,
            contactNo: member.contactNo,
            agencyId: member.agencyId,
            updatedAt: member.updatedAt,
          }));
          this.setState({ agencyOfficerList: temp }, () => {
            this.getStrategicMemberListForAddEditProject(this.state.projectId);
          });
          // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
          // var tempOfficer = response.responseItem.responseContent;
          // var officerArr = [];
          // officerArr = tempOfficer.map((member) => ({
          //   id: member.agencyOfficerId,
          //   name: member.agentName,
          // }));
          // this.setState({ officerArr: officerArr });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  updateAgencyOfficerKeyStatusForAddeEditProject = (agencyDetails) => {
    let request = this.state.agencyOfficerDetails;
    request.projectId = this.state.projectId;
    request.isKeyContactPerson =
      request.isKeyContactPerson === "true" ? true : false;
    request.agencyOfficerId = agencyDetails.agencyOfficerId;
    this.partnershipServices
      .updateAgencyOfficerKeyStatusForAddeEditProject(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.getAgencyOfficerListForAddEditProject(this.state.projectId);
          let res = {
            agencyOfficerId: 0,
            agentName: "",
            designation: "",
            emailAddress: "",
            contactNo: "",
            agencyId: 0,
            projectId: 0,
            projectAgencyOfficerMappingId: 0,
            isKeyContactPerson: false,
          };
          this.swalServices.Success("Agency officer detail updated successfully.");
          this.setState({ agencyOfficerDetails: res });

        } else {
          this.swalServices.Error(response.message);
        }
        this.getAgencyOfficerListForAddEditProject(this.state.projectId);
      });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.projectDetails;
    if (name === 'projectStartDate' && (value !== detail[name] && value > detail['projectEndDate'])) {
      detail['projectEndDate'] = null;
    }
    detail[name] = value;
    this.setState({ projectDetails: { ...detail } });
  }

  handleChangeRadioButtons(event) {
    const value = event.target.value;
    const id = event.target.id;
    const name = event.target.name;
    let detail = this.state.projectDetails;
    detail[id] = value;
    detail[name] = value;
    this.setState({ projectDetails: { ...detail } });
  }
  handleChangeRadioButtonsKeyPerson(event, agentDetails) {
    const value = event.target.value;
    const id = event.target.id;
    const name = event.target.name.split("_");
    let detail = this.state.agencyOfficerDetails;
    detail[id] = value;
    detail[name[0]] = value;
    // detail.agencyOfficerId = agentDetails.agencyOfficerId;
    detail.projectAgencyOfficerMappingId =
      agentDetails.projectAgencyOfficerMappingId;
    detail.projectId = agentDetails.projectId ? agentDetails.project : 0;
    this.setState({ agencyOfficerDetails: detail }, () =>
      this.updateAgencyOfficerKeyStatusForAddeEditProject(agentDetails)
    );
  }

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.projectDetails;
    let officer = this.state.agencyOfficerDetails;
    if (drpIdentity === "Secretariat") {
      detail["sGTechSecretariatId"] = id;
      this.validateProjectDetails("sGTechSecretariatId");
    } else if (drpIdentity === "Agency") {
      detail["agencyId"] = id;
      this.validateProjectDetails("agencyId");
    } else if (drpIdentity === "AgencyOfficer") {
      officer["agencyOfficerId"] = id;
      // this.validateField("sGTechSecretariatId");
      this.setState({ agencyOfficerDetails: { ...officer } });
      if (id > 0) {
        let member = this.state.officerList.find((x) => x.agencyOfficerId === id);
        this.setState({ agencyOfficerDetails: member });
      }
    }
    this.setState({ projectDetails: { ...detail } });
  };

  validateProjectDetails = (key) => {
    const returnValidState = validate(
      key,
      this.state.projectDetails,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: returnValidState });
  };

  isValidProjectDetails = () => {
    const returnValidState = isValidForm(
      this.state.projectDetails,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  };
  //end

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div>
        <div className="main-body-section">
          <div className="bg-white pb-7">
            <div className=" heading-part pl-7 py-4 border-b">
              <h4 className="small-title font-bold theme-color">
                Project Details
              </h4>
            </div>
            <div className="px-6 pt-10">
              <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 sm:grid-cols-12 gap-6 w-full">
                <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                  <h2 className="text-base theme-color font-bold mb-1">
                    Project Name <span className="text-[#C00000]">*</span>
                  </h2>
                  <div className="w-full">
                    <input
                      className="w-full"
                      type="text"
                      id="projectName"
                      name="projectName"
                      onChange={(event) => this.handleChange(event)}
                      onBlur={() => this.validateProjectDetails("projectName")}
                      value={this.state.projectDetails.projectName}
                    />
                  </div>
                  <div>
                    <ValidationText
                      error={this.state.validState.error.projectName}
                    />
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                  <h2 className="text-base theme-color font-bold mb-1 ">
                    SGTech Secretariat <span className="text-[#C00000]">*</span>
                  </h2>
                  <div className="w-full">
                    <DropdownSelect
                      drpIdentity="Secretariat"
                      optionArray={this.state.secretariatList}
                      setFilterParameters={this.setFilterParameters.bind(this)}
                      value={this.state.projectDetails.sGTechSecretariatId}
                    />
                  </div>
                  <div>
                    <ValidationText
                      error={this.state.validState.error.sGTechSecretariatId}
                    />
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                  <h2 className="text-base theme-color font-bold mb-1">
                    Project Description{" "}
                    <span className="text-[#C00000]">*</span>
                  </h2>
                  <div className="w-full">
                    <input
                      className="w-full"
                      type="text"
                      id="projectDescription"
                      name="projectDescription"
                      onChange={(event) => this.handleChange(event)}
                      onBlur={() =>
                        this.validateProjectDetails("projectDescription")
                      }
                      value={this.state.projectDetails.projectDescription}
                    />
                  </div>
                  <div>
                    <ValidationText
                      error={this.state.validState.error.projectDescription}
                    />
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                  <h2 className="text-base theme-color font-bold mb-1">
                    Internal Comments <span className="text-[#C00000]">*</span>
                  </h2>
                  <div className="w-full">
                    <input
                      className="w-full"
                      type="text"
                      id="internalComments"
                      name="internalComments"
                      onChange={(event) => this.handleChange(event)}
                      onBlur={() =>
                        this.validateProjectDetails("internalComments")
                      }
                      value={this.state.projectDetails.internalComments}
                    />
                  </div>
                  <div>
                    {" "}
                    <ValidationText
                      error={this.state.validState.error.internalComments}
                    />{" "}
                  </div>
                </div>

                <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <h2 className="text-base theme-color font-bold mb-1">
                    Project Start Date
                    {/* <span className="text-[#C00000]">*</span> */}
                  </h2>
                  <div>
                    <DatePicker
                      handleChange={this.handleChange.bind(this)}
                      value={moment(
                        this.state.projectDetails.projectStartDate
                      ).format("yyyy-MM-DD")}
                      name="projectStartDate"
                    />
                    <div>
                      {/* <ValidationText error={props.validState.error.unpublishDate} /> */}
                    </div>
                  </div>
                </div>

                <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <h2 className="text-base theme-color font-bold mb-1">
                    Project End Date
                    {/* <span className="text-[#C00000]">*</span> */}
                  </h2>
                  <div className="w-full">
                    <DatePicker
                      handleChange={this.handleChange.bind(this)}
                      value={moment(
                        this.state.projectDetails.projectEndDate
                      ).format("yyyy-MM-DD")}
                      name="projectEndDate"
                      minDate={moment(
                        this.state.projectDetails.projectStartDate
                      ).format("yyyy-MM-DD")}
                    />
                    <div>
                      {/* <ValidationText error={props.validState.error.unpublishDate} /> */}
                    </div>
                  </div>
                </div>
                <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <h2 className="text-base theme-color font-bold mb-1">
                    Agency Involved
                    <span className="text-[#C00000]">*</span>
                  </h2>
                  <div className="w-full">
                    <div className="flex items-center justify-start">
                      <div className="m-3 mr-20">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="isAgencyInvolved"
                            id="isAgencyInvolved"
                            className="form-radio"
                            // value={this.state.projectDetails.isAgencyInvolved}
                            value={true}
                            onChange={(event) =>
                              this.handleChangeRadioButtons(event)
                            }
                            checked={
                              this.state.projectDetails.isAgencyInvolved ===
                              "true"
                            }
                          />
                          <span className="text-base theme-color ml-2">
                            Yes
                          </span>
                        </label>
                      </div>

                      <div className="m-3">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="isAgencyInvolved"
                            id="isAgencyInvolved"
                            className="form-radio"
                            // value={this.state.projectDetails.isAgencyInvolved}
                            value={false}
                            onChange={(event) =>
                              this.handleChangeRadioButtons(event)
                            }
                            checked={
                              this.state.projectDetails.isAgencyInvolved ===
                              "false"
                            }
                          />
                          <span className="text-base theme-color ml-2">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <h2 className="text-base theme-color font-bold mb-1">
                    Strategic Member Involved
                    <span className="text-[#C00000]">*</span>
                  </h2>
                  <div className="w-full">
                    <div className="flex items-end justify-between">
                      <div className="flex items-center justify-start">
                        <div className="m-3 mr-20">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="isStrategicMemberInvolved"
                              id="isStrategicMemberInvolved"
                              className="form-radio"
                              value={true}
                              onChange={(event) =>
                                this.handleChangeRadioButtons(event)
                              }
                              checked={
                                this.state.projectDetails
                                  .isStrategicMemberInvolved === "true"
                              }
                            />
                            <span className="text-base theme-color ml-2">
                              Yes
                            </span>
                          </label>
                        </div>

                        <div className="m-3">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="isStrategicMemberInvolved"
                              id="isStrategicMemberInvolvedNo"
                              className="form-radio"
                              // value={this.state.projectDetails.isStrategicMemberInvolved}
                              value={false}
                              onChange={(event) =>
                                this.handleChangeRadioButtons(event)
                              }
                              checked={
                                this.state.projectDetails
                                  .isStrategicMemberInvolved === "false"
                              }
                            />
                            <span className="text-base theme-color ml-2">
                              No
                            </span>
                          </label>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                  {this.state.projectDetails.isAgencyInvolved === "true" ? (
                    <div >
                      <h2 className="text-base theme-color font-bold mb-1">
                        Select Existing Agency
                        <span className="text-[#C00000]">*</span>
                      </h2>
                      <div className="w-full">
                        <DropdownSelect
                          drpIdentity={"Agency"}
                          optionArray={this.state.agencyList}
                          setFilterParameters={this.setFilterParameters.bind(
                            this
                          )}
                          value={this.state.projectDetails.agencyId}
                        />
                      </div>
                      <div>
                        <ValidationText
                          error={this.state.validState.error.agencyId}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>


                <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 pt-8">
                  <div className="flex items-end justify-end">
                    <button
                      className="w-32 h-[50px] px-7 py-2 btn-sm btn-red text-lg text-white"
                      onClick={() => this.addEditProject()}
                    // disabled={props.isLoaderEnabled}
                    >
                      Save
                    </button>
                  </div>
                </div>                
              </div>
            </div>

            {this.state.projectDetails.isAgencyInvolved === "true" ? (
              <div className="bg-white pb-0">
                <div className=" heading-part px-7 py-4 border-b w-full"></div>
                <div className="px-6 pt-10">
                  <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 sm:grid-cols-12 gap-6 w-full items-left justify-between">
                    <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                      <h2 className="text-base theme-color font-bold mb-1">
                        Agency Officer
                      </h2>
                      <div className="w-full">
                        <DropdownSelect
                          drpIdentity={"AgencyOfficer"}
                          optionArray={this.state.officerArr}
                          setFilterParameters={this.setFilterParameters.bind(this)}
                          value={
                            this.state.agencyOfficerDetails.agencyOfficerId
                          }
                          disabled={this.state.projectId > 0 && this.state.projectDetails.previousAgencyId === this.state.projectDetails.agencyId ? false : true}
                        />
                        <div>
                          {!this.state.agencyOfficerDetails.agencyOfficerId ? (
                            <ValidationText error={"Please select agency officer"} />
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts flex justify-end">
                      {this.state.projectId > 0 && this.state.projectDetails.previousAgencyId === this.state.projectDetails.agencyId ? (
                        <button
                          className="btn btn-red text-white font-semibold link mt-7"
                          onClick={() => this.addEditAgencyOfficer()}
                        >
                          <span className="text-xl text-white font-semibold tracking-normal">
                            Add officer
                          </span>
                        </button>
                      ) : (
                        <button
                          disabled={true}
                          className=" btn btn-red text-white font-semibold link mt-7 cursor-not-allowed"
                        >
                          <span className="text-xl text-white font-semibold tracking-normal">
                            Add officer
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="xl:col-span-12 lg:col-span-12 sm:col-span-12">
                    <div className="table-sections-strategy mt-7 w-full">
                      <TableRadio
                        agencyOfficerList={this.state.agencyOfficerList}
                        deleteAgencyOfficer={this.deleteAgencyOfficer.bind(
                          this
                        )}
                        handleChangeRadioButtonsKeyPerson={this.handleChangeRadioButtonsKeyPerson.bind(
                          this
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.projectDetails.isStrategicMemberInvolved === "true" ? (
              <div className="px-6 mt-10 pt-2 border-t">
                <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 sm:grid-cols-12 gap-6 w-full items-center justify-between">
                  <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 pt-5">
                    <h2 className="text-base theme-color font-bold mb-1">
                      Strategic Member
                    </h2>
                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 flex justify-end">
                    {this.state.projectId > 0 ? (
                      <button
                        className="btn btn-red text-white font-semibold link mt-7"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ setAddEditStrategicMember: true });
                        }}
                      >
                        <span className="text-xl text-white font-semibold tracking-normal">
                          Add Member
                        </span>
                      </button>
                    ) : (
                      // make this button not editable with class
                      <button
                        disabled={true}
                        className="btn btn-red text-white font-semibold link mt-7 cursor-not-allowed"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ setAddEditStrategicMember: true });
                        }}
                      >
                        <span className="text-xl text-white font-semibold tracking-normal">
                          Add Member
                        </span>
                      </button>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12">
                    <div className="table-sections-strategy mt-7 w-full ">
                      <div className="add-edit-project-bottom-table">                        
                        <StrategicMemberTable
                          strategicMemberList={this.state.strategicMemberList}
                          delete={this.deleteGovernmentStrategicMember.bind(
                            this
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="flex justify-between space-x-10 mt-12">
              <Link
                to="/Partnerships"
                className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white hover:text-white"
              >
                {" "}
                Back
              </Link>
            </div>
          </div>
        </div>
        <div className="membership-task-list-process-sec">
          <AddEditStrategicMemberModal
            setAddEditStrategicMember={this.state.setAddEditStrategicMember}
            setOpenModal={() =>
              this.setState({ setAddEditStrategicMember: false })
            }
            setFilterParameters={this.setFilterParametersStrategic.bind(this)}
            strategicMemberDetails={this.state.strategicMemberDetails}
            validState={this.state.validStateStrategic}
            officerArr={this.state.officerArrStrategic}
            companyForStrategicMember={this.state.companyForStrategicMember}
            addStrategicMember={this.addStrategicMember.bind(this)}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setAddEditStrategicMember: false });
            }}
            projectId={this.state.projectId}
          />
        </div>
      </div>
    );
  }
}
export default withParams(AddEditProject);