// import './App.css';
import { getAuthProps } from './utils/AuthenticationLibrary';
import LayoutNavigation from './navigation/LayoutNavigation';
import ComponentNavigation from '../src/navigation/ComponentNavigation';
import './css/style.scss';
import './css/responsive.scss';


function App() {
  const authDetails = getAuthProps();

  if (window.location.pathname === "/") {
    window.location.href = "/Login";
  }
  else {
    if (window.location.pathname.toLocaleLowerCase() !== "/login" && window.location.pathname.toLocaleLowerCase() !== "/resetpassword" && window.location.pathname.toLocaleLowerCase() !== "/forgetpassword") {
      if (authDetails) {
        ComponentNavigation.forEach(element => {
          element.authDetails = authDetails;
        });
      }
      else {
        // if (window.location.pathname.toLocaleLowerCase() === "/login") {
        //   window.location.href = "/Login";
        // }
        // else if (window.location.pathname.toLocaleLowerCase() === "/forgetpassword") {
        //   window.location.href = "/ForgetPassword";
        // }
        // else if (window.location.pathname.toLocaleLowerCase() === "/resetpassword") {
        //   window.location.href = "/ResetPassword";
        // }

        switch (window.location.pathname.toLocaleLowerCase()) {
          case "/resetpassword":
            window.location.href = "/ResetPassword";
            break;
          case "/forgetpassword":
            window.location.href = "/ForgetPassword";
            break;
          case "/login":
            window.location.href = "/Login";
            break;
          default:
            window.location.href = "/Login";

        }

      }
    }
  }

  return (
    <LayoutNavigation componentRoutes={ComponentNavigation} authDetails={authDetails} />

  );
}

export default App;
