import React from "react";
import DropdownSelect from "../dropdown/Dropdown";
import ValidationText from "../../utils/validation/ValidationText";

function FormSettingPageManagement(props) {
    return (
        <div className="flex gap-4 mb-9 mt-9">
            <div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        First Name
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"firstNameAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.firstNameAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.firstNameAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Email
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"emailAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.emailAvailabilityId}
                            disabled={true}
                        />
                        <ValidationText error={props.validState.error.emailAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Company Name
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"companyAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.companyAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.companyAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Designation
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"designationAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.designationAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.designationAvailabilityId} />
                    </div>
                </div>

                <div className="flex items-center mt-9 url-part">
                    <h2 className="text-xl text-[#757575] font-bold  w-[170px]">
                        PDF Upload
                    </h2>
                    <div className="flex item-center flex-col w-[350px] px-9">
                        <form className="custom-uploader border flex items-center border-[#757575] text-center px-14 py-3">
                            <label
                                htmlFor="upload"
                                className="text-uplod block text-sm text-[#757575]"
                            >
                                Upload PDF
                            </label>
                            <img
                                className="uplod-svg inline-flex w-5 h-5 fill-[#757575] ml-4"
                                src="/pdf-upload.svg"
                                alt=""
                            ></img>
                            <input
                                className="sr-only"
                                id="upload"
                                type="file"
                                onChange={(event) => props.onFileChange(event)}
                            />
                        </form>
                        {props.formAvailability.pdfDisplayName !== "" ? (
                            <div>
                                <div className="relative mt-1">
                                    {props.formAvailability.pdfDisplayName}
                                    {/* <span
                                        className="absolute top-[-5px] right-[-18px] bg-red-700 h-4 w-4 rounded-full flex items-center justify-center cursor-pointer"
                                        onClick={() => props.onFileCancel()}
                                    >
                                        <p className="text-white text-[10px]">
                                            &#10006;
                                        </p>
                                    </span> */}
                                </div>
                            </div>
                        ) : null}
                        {!props.formAvailability.reportValidation ? (
                            <ValidationText error={"Please upload PDF"} />
                        ) : null}
                    </div>
                </div>
            </div>
            <div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Last Name
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"lastNameAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.lastNameAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.lastNameAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Contact Number
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"contactAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.contactAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.contactAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Company UEN
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"UENAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.UENAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.UENAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Membership Status
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"membershipStatusAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.membershipStatusAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.membershipStatusAvailabilityId} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormSettingPageManagement;
