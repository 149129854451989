import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import ModalBasic from "../../pages/component/ModalBasic";
import "react-image-crop/dist/ReactCrop.css";
import { APIURL, GetImage } from "../../services/axiosServices/ApiEndPoints";
import SwalServices from "../../services/swalServices/SwalServices";

// Use to crop the image

/**
 * TOOD :
 *      1. Add proper alert message to this component
 *      2. Enable the heigh and width validation
 *      3. Remove file from the file input after corp : Nigam need to look
 *      4. Change the file name as per the componenet name
 */

const swalServices = new SwalServices();

export default function ImageCropperWithUpload(props) {
  const fileUploadName = "my_file_";

  const generateIds = {
    cropimg: "cropimg_" + props.keyId,
  };
  const allowedFileTypes = `image/gif image/png, image/jpeg, image/x-png`;
  /// Set Croping stuff
  const [crop, setCrop] = useState({
    aspect: 16 / 9,
    height: props.height,
    unit: "px",
    width: props.width,
    x: 0,
    y: 107,
  });

  // following use when ImageSelect is called
  const [viewImage, setViewImage] = useState(null);
  // following use when Image Use for the Crop
  const [image, setImage] = useState(null);
  // following use set Crop Image but not saved
  const [imageUrl, setImageUrl] = useState(null);
  // following use set Crop Image but not saved
  const [corpImage, setcorpImage] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [widthCompressionRation, setWidthCompressionRation] = useState(0);
  const [heightCompressionRation, setHeightCompressionRation] = useState(0);

  // Prasad Chnages
  //const [scale, setScale] = useState(1);

  const ref = React.useRef();

  let initImage =
    APIURL +
    GetImage +
    "?type=" +
    props.imageIdentity +
    "&&fileName=" +
    props.intitImage;

  const handleFileChange = (e) => {
    let image = e.target.files[0];
    if (image) {
      validateFileSize(
        image,
        function () {
          const imageReader = new FileReader();
          imageReader.readAsDataURL(image);
          imageReader.onloadend = () => {
            setViewImage(imageReader.result);
          };
          setImageModalOpen(true);
        },
        e
      );
    } else {
      e.target.value = null;
    }
  };

  /// Validate the file Size
  const validateFileSize = (image, callBackFunction, e) => {
    if (props.isCheckValidation) {
      // set the size dymemic
      //Initiate the FileReader object.
      let reader = new FileReader();
      //Read the contents of Image File.
      reader.readAsDataURL(image);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        let checkImage = new Image();

        //Set the Base64 string return from FileReader as source.
        checkImage.src = e.target.result;

        //Validate the File Height and Width.
        checkImage.onload = function () {
          let height = this.height;
          let width = this.width;
          // if (height > 200 || width > 200) {

          //    //show width and height to user
          //     document.getElementById("width").innerHTML=width;
          //     document.getElementById("height").innerHTML=height;
          //     alert("Height and Width must not exceed 200px.");
          //     return false;
          // }
          // alert("Uploaded image has valid Height and Width.");
          if (image.size > props.MaxFileSize) {
            // 2 MiB for bytes.
            swalServices.Alert("File size must be less than 2 mb");
            // alert("File size must under " + props.MaxFileSizeMessage + "!");
            return false;
          } else if (
            props?.isCropperSizeFixed &&
            props.isCropperSizeFixed === true &&
            (height < Number(props.height) || width < Number(props.width))
          ) {
            swalServices.Alert(
              `Maximum image size is ${props.width} X ${props.height}`
            );
            return false;
          } else if (image) {

            setImageUrl(null);
            let img1 = new Image();
            img1.src = window.URL.createObjectURL(image);
            img1.onload = function () {
              window.URL.revokeObjectURL(img1.src);
              callBackFunction();
            };
          }
        };
      };
    } else {
      callBackFunction();
    }
  };

  const onImageLoad = (image) => {

    //NIGAMBHAU
    let aspRatio = props.width / props.height;
    let cWidth = 0;
    let cHeight = 0;
    if (
      props?.isCropperSizeFixed &&
      props.isCropperSizeFixed === true
    ) {
      if (
        props.width > image.target.width ||
        props.height > image.target.width / 2 / aspRatio
      ) {
        cWidth = image.target.width;
        cHeight = image.target.width / aspRatio;
      } else {
        cWidth = props.width;
        cHeight = props.height;
      }
    } else {
      cWidth = image.target.width / 2;
      cHeight = image.target.width / 2 / aspRatio;
    }
    let cX = (image.target.width - cWidth) / 2;
    let cY = (image.target.height - cHeight) / 2;
    setWidthCompressionRation(image.target.width / image.target.naturalWidth);
    setHeightCompressionRation(
      image.target.height / image.target.naturalHeight
    );
    // if (props && props.isCropperSizeFixed && props.isCropperSizeFixed === true) {
    //   aspRatio = image.target.naturalWidth / image.target.naturalHeight;
    //   cWidth = ((image.target.naturalWidth * widthCompressionRation)/ 2);
    //   cHeight = ((image.target.naturalWidth * widthCompressionRation)/ 2) / aspRatio;
    //   cX = (image.target.naturalWidth - cWidth) / 2;
    //   cY = (image.target.naturalHeight - cHeight) / 2;
    // }

    let corpNew = {
      aspect: 16 / 9,
      height: cHeight,
      unit: "px",
      width: cWidth,
      x: cX,
      y: cY,
    };

    if (
      props?.isCropperSizeFixed &&
      props.isCropperSizeFixed === true
    ) {
      // corpNew.height = Number(props.height);
      // corpNew.width = Number(props.width);
      corpNew.x = 0;
      corpNew.y = 0;
    }
    setCrop(corpNew);
    setImage(image);
    if (corpNew) {
      setCrop(corpNew);
      getCroppedImage(image.target, corpNew, "newFile.jpeg");
    }
  };

  function imageCrop(crop) {
    if (
      props?.isCropperSizeFixed &&
      props.isCropperSizeFixed === true
    ) {
      crop.height = props.height * heightCompressionRation;
      crop.width = props.width * widthCompressionRation;
      if (crop.height > 0 && crop.width > 0) {
        setCrop(crop);
      }
    } else {
      if (crop.height > 0 && crop.width > 0) {
        setCrop(crop);
      }
    }
  }

  function imageCropComplete(crop) {
    userCrop(crop);
  }

  function userCrop(crop) {
    if (image && crop.width && crop.height) {
      getCroppedImage(image.target, crop, "newFile.jpeg");
    }
  }

  function getCroppedImage(image, crop, fileName) {
    const imageCanvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    if (
      props?.isCropperSizeFixed &&
      props.isCropperSizeFixed === true
    ) {
      imageCanvas.width = crop.width * scaleX;
      imageCanvas.height = crop.height * scaleY;
    } else {
      imageCanvas.width = crop.width;
      imageCanvas.height = crop.height;
    }
    const imgCx = imageCanvas.getContext("2d");

    if (
      props?.isCropperSizeFixed &&
      props.isCropperSizeFixed === true
    ) {
      imgCx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    } else {
      imgCx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    }

    const imageData64 = imageCanvas.toDataURL();
    setImageUrl(imageData64);
  }

  const onCancel = () => {
    setImageUrl(null);
    setViewImage(null);
    setImage(null);
    setImageModalOpen(false);
  };

  // Save the image in base64 and send to the parent
  const saveImage = (index) => {
    onCancel();
    setcorpImage(imageUrl);

    let file = {
      fileName: "",
      fileStr: imageUrl,
      storagePath: props.imageIdentity,
    };
    props.onSaveImage(file, props.keyId, props.imageIdentity, index);
  };

  // Empty file input to allow same image
  const emptyFileInput = () => {
    ref.current.value = "";
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
        <div className="img-logo-upload text-center" key={`CropperControlSec` + props.keyId}>
          {corpImage ? (
            <img
              id={"defaultimage_" + props.keyId}
              src={corpImage}
              className="border border-slate-300 w-full mb-7"
              alt="../default-img.png"
            ></img>
          ) : initImage ? (
            <img
              id={generateIds.cropimg}
              src={
                APIURL +
                GetImage +
                "?type=" +
                props.imageIdentity +
                "&&fileName=" +
                props.intitImage
              }
              className="border border-slate-300 w-full mb-7"
              alt=""
            ></img>
          ) : (
            <img
              id={generateIds.cropimg}
              src={corpImage}
              className="border border-slate-300 w-full mb-7"
              alt=""
            ></img>
          )}
          <label
            htmlFor={fileUploadName + props.keyId}
            aria-controls="add-edit-modal"
            className="2xl:px-10 2xl:mt-7 lg:px-2 lg:mt-3 py-2 btn-red-border text-xl font-bold lg:text-sm border text-[#C00000]"
            onClick={emptyFileInput}
          >
            {props.uploadBtn}
          </label>
          <input
            type="file"
            id={fileUploadName + props.keyId}
            style={{ display: "none" }}
            src="../default-img.png"
            accept={allowedFileTypes}
            onChange={handleFileChange}
            ref={ref}
          />
          <p className="mt-5 text-center font-normal 2xl:text-xl lg:text-xl text-base theme-color w-[300px] mx-auto">
            {props.dimensionText}
          </p>
        </div>
      </div>
      <ModalBasic
        key={props.keyId}
        id={"set-image-upload" + props.keyId}
        modalOpen={imageModalOpen}
        setModalOpen={() => setImageModalOpen(false)}
        title="Upload Image"
      >
        {/* Modal content */}
        <div className="px-14 py-4">
          <div className="mb-10">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                <label
                  className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                  htmlFor="default"
                >
                  Crop Your Image Below
                </label>
                {/* Prasad Changes */}
                {/* <div className="mb-16">
                  <label htmlFor="scale-input">Scale: </label>
                  <input
                    id="scale-input"
                    type="number"
                    step="0.1"
                    value={scale}
                    disabled={!viewImage}
                    onChange={(e) => setScale(Number(e.target.value))}
                  />
                </div> */}
                {viewImage != null ? (
                  <ReactCrop
                    key={props.keyId}
                    crop={crop}
                    onChange={imageCrop}
                    onComplete={imageCropComplete}
                    aspect={props.aspactRatio}
                  >
                    <img
                      id={"my_fileimg" + props.keyId}
                      src={viewImage}
                      onLoad={onImageLoad}
                      alt=""
                    />
                  </ReactCrop>
                ) : null}
              </div>
              <div className="xl:col-span-5">
                <label
                  className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                  htmlFor="default"
                >
                  Preview
                </label>
                <div className="cropped-img-view">
                  <img id={"prvImg" + props.keyId} src={imageUrl} alt=""></img>
                </div>
                {/* Modal footer Button*/}
                <div className="flex flex-wrap justify-center space-x-10 mt-7">
                  <button
                    id={"btnCancel" + props.keyId}
                    className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white"
                    onClick={(e) => {
                      e.stopPropagation();
                      onCancel();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                    id={"btnSave" + props.imageIdentity + props.keyId}
                    onClick={(e) => {
                      e.stopPropagation();
                      saveImage(props.keyId);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBasic>
    </div>
  );
}
