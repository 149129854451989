import moment from "moment";
import React, { Component } from "react";
import BatchJobExtractionServices from "../../services/axiosServices/apiServices/BatchJobExtractionServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../components/common/pageLoader/PageLoader";

export default class MembershipReport extends Component {
    constructor(props) {
        super(props);
        this.batchJobExtractionServices = new BatchJobExtractionServices();
        this.swalServices = new SwalServices();
        this.state = {
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                searchText: "",
            },
            isLoading: false,
            currentDate: new Date(),
            membershipReportList: [],
            totalCount: 0,

        };
    }

    componentDidMount = () => {
        this.getAllMembershipReportList();

    }

    getAllMembershipReportList = () => {
        this.setState({ isLoading: true });
        let pagination = { ...this.state.pagination };
        this.batchJobExtractionServices.getAllMembershipReportList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList;
                this.setState({
                    membershipReportList: details,
                    totalCount: response.responseItem.responseContent.totalCount,
                })
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }


    generateMembershipReport = () => {
        if (this.state.currentDate) {
            this.setState({ isLoading: true });
            let request = {
                currentDate: this.state.currentDate
            };
            this.batchJobExtractionServices.generateMembershipReport(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success("Membership record generated successfully.");
                    this.getAllMembershipReportList();
                }
                else if (response.statusCode === 409) {
                    this.swalServices.Error(response.message);
                }
                else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            });
        }
        else {
            this.swalServices.Alert('Please select date');
        }
    }



    downloadSheetByMembershipReportId = (membershipReportId) => {
        this.setState({ isLoading: true });
        let request = [];
        request.push(membershipReportId);
        this.batchJobExtractionServices.downloadSheetByMembershipReportId(request).then((response) => {
            if (response.status === 200 && response.data != null) {
                // var filename = `${detail}${formattedDate}`;
                let blob = new Blob([response.data])
                saveAs(blob, `MembershipReport` + ".xlsx");
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'currentDate') {
            this.setState({ currentDate: value })
        }

    }

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="disabled-input">
                                <input
                                    id="currentDate"
                                    name="currentDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.currentDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-span-4">
                            <button className="btn btn-red text-xl border text-white mt-8" onClick={() => this.generateMembershipReport()}>
                                Generate Membership Report
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            {this.state.isLoading ? <PageLoader /> :
                                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                                    <thead>
                                        <tr className="text-base font-bold flex">
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Generate Date
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Number of Records
                                                </div>
                                            </th>

                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                                                <div className="font-bold tble-th-txt text-center">Action</div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="scrollit text-sm custom-tbody">
                                        {this.state.membershipReportList.map((e, key) => {
                                            return (
                                                <tr className="custom-tbl-td flex items-center" key={key}>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%] ">
                                                        {moment(e.generationDate).format("DD MMM YYYY")}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                                                        {e.numberOfRecords}
                                                    </td>

                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                        <button
                                                            className="action-btn mx-auto flex items-center"
                                                            aria-controls="add-edit-modal"
                                                            onClick={() => {
                                                                this.downloadSheetByMembershipReportId(e.membershipReportId);
                                                            }}
                                                        >
                                                            <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
                                                                Download
                                                            </p>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}
