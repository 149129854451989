import React, { Component } from 'react'
import { getAuthProps } from '../../utils/AuthenticationLibrary';
import { CommonValidationMessages } from '../../utils/Messages';
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import ValidationText from '../../utils/validation/ValidationText';

import FieldManagementServices from '../../services/axiosServices/apiServices/FieldManagementServices';
import SwalServices from '../../services/swalServices/SwalServices';

export default class CreateReport extends Component {
    constructor(props) {
        super(props);
        this.fieldManagementServices = new FieldManagementServices();
        this.swalServices = new SwalServices();
        this.state = {
            isLoading: false,
            redirect: null,
            reportDetails: {
                reportName: "",
                reportScript: "",
            },
            validState: {
                isValid: true,
                error: {}
            },
            validationRule: {
                reportName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'report name')
                    }
                ],
                reportScript: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'report script')
                    }
                ],
            },
        };
    }

    componentDidMount() {
        let cookie = getAuthProps();
        const adminUserId = (cookie?.adminUserId) ? Number(cookie.adminUserId) : 0;
        if (adminUserId > 0) {
            this.setState({ createdBy: adminUserId });
        }
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.reportDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }

    validateField = (key) => {
        const newValidState = validate(key, this.state.reportDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.reportDetails;
        detail[name] = value;
        this.setState({ reportDetails: { ...detail } });
    }

    addSuperReport = () => {
        if (this.isValidateAllFields()) {
            let request = this.state.reportDetails;
            this.fieldManagementServices.addSuperReport(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    if (response.responseItem.responseContent.errorType === 'Exists') {
                        this.swalServices.Error("Report name is already exists.");
                    }
                    else {
                        this.swalServices.Success("Report details add successfully.");
                    }
                    this.reSet();
                } else {
                    this.swalServices.Error(response.message);
                }
            });
        }
    };

    reSet = () => {
        let details = this.state.reportDetails
        details.reportName = ''
        details.reportScript = ''
        this.setState({ reportDetails: details })
    }

    render() {
        return (
            <div className="main-body-section">
                <div className="bg-white pb-10">
                    <div className="heading-part py-3 border-b">
                        <div className="flex items-center justify-between pl-8">
                            <h4 className="small-title font-bold theme-color ">
                                Add Report
                            </h4>
                        </div>
                    </div>
                    <div className="filter-info grid grid-cols-12 gap-6 px-8 pt-8">
                        <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                            <label
                                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                htmlFor="default"
                            >
                                Report Name
                            </label>
                            <input
                                id="reportName"
                                name="reportName"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                value={this.state.reportDetails.reportName}
                                onChange={(event) => this.handleChange(event)}
                                onBlur={() => this.validateField("reportName")}
                            />
                            <ValidationText
                                error={this.state.validState.error.reportName}
                            />
                        </div>
                        <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                            <label
                                className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                htmlFor="default"
                            >
                                Report Script
                            </label>
                            <input
                                id="reportScript"
                                name="reportScript"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                value={this.state.reportDetails.reportScript}
                                onChange={(event) => this.handleChange(event)}
                                onBlur={() => this.validateField("reportScript")}
                            />
                            <ValidationText
                                error={this.state.validState.error.reportScript}
                            />
                        </div>
                        <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                            <button
                                className="btn btn-red text-xl text-white font-bold mt-8"
                                onClick={() => {
                                    this.addSuperReport();
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}