import React from "react";
import ModalBasic from "../../component/ModalBasic";

const SendInvoice = (props) => {
    return (
        <ModalBasic
            id="send-invoice-modal"
            modalOpen={props.setSendInvoiceModal}
            setModalOpen={props.setOpenModal}
            title=""
            noCloseIcon={true}
        >
            <div className="text-center pb-2">
                <h1 className="text-xl text-[#757575] font-normal leading-loose p-8 py-4">
                    The invoice of {props.companyName} has ben sent successfully to {props.email}
                </h1>
                <button
                    className="bg-[#c00000] text-base text-[#fff] font-bold px-12 py-2 my-4"
                    onClick={props.onClose}
                >
                    Ok
                </button>
            </div >
        </ModalBasic>
    );
}
export default SendInvoice;