import React from "react";
import ImageCropperWithUpload from "../ImageUpload/UploadImage";
import ValidationText from "../../utils/validation/ValidationText";

function Images(props) {
  return (
    <div key={`ImagesComponent_${props.identity}`}>
      <div className="List-text-icon">
        <div className="mt-10 mb-10 border border-[#757575]">
          <div className="filter-types col-span-12 font-bold border-b border-[#757575] bg-[#f0f0f0] px-7 py-3 relative">
            <h2 className="filter-lable  font-medium theme-color">Images</h2>
            {props.pageTypeId === 3 ?
              <div className="close-icon-btn absolute top-[50%] right-[10px] translate-y-[-50%]">
                <button
                  className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                  onClick={() => props.removeComponent()}
                >
                  <div className="sr-only">Close</div>
                  <svg className="w-4 h-4 fill-current">
                    <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                  </svg>
                </button>
              </div>
              : null}
          </div>
          <div className="filter-types col-span-12 p-8">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12">
                <div className="flex items-center">
                  <div className="w-[40%] mr-4 pt-1">
                    <h2 className="filter-lable  font-medium theme-color">
                      Component Unique Id :
                    </h2>
                  </div>
                  <div className="w-[60%]">
                    {/* <TextInput /> */}
                    <input
                      id="default"
                      name="componentId"
                      className="w-full outline-none appearance-none form-input rounded-none bg-zinc-100"
                      type="text"
                      value={props.componentDetails.componentId}
                      onChange={(event) =>
                        props.handleChange(event, props.identity)
                      }
                      disabled={true}
                    //onBlur={() => props.validateField("publishDate")}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12">
                <div className="flex items-center">
                  <div className="w-[40%] mr-4 pt-1">
                    <h2 className="filter-lable  font-medium theme-color">
                      Component Name :
                    </h2>
                  </div>
                  <div className="w-[60%]">
                    {/* <TextInput /> */}
                    {/* <input
                      id="default"
                      name="componentName"
                      className="w-full outline-none appearance-none form-input rounded-none"
                      type="text"
                      value={props.componentDetails.componentName}
                      onChange={(event) =>
                        props.handleChange(event, props.identity)
                      }
                    /> */}
                    <input
                      id="default"
                      name="componentName"
                      className={`w-full outline-none appearance-none form-input rounded-none ${(props.componentDetails.componentName) ? 'bg-zinc-100' : ''}`}
                      type="text"
                      value={props.componentDetails.componentName}
                      onChange={(event) =>
                        props.handleChange(event, props.identity)
                      }
                      disabled={!!(props.componentDetails.componentName)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            props?.componentDetails.componentContent.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="border-section-main" >
                    <div className="col-span-12 pb-10 mt-10 relative">
                      <div className="grid grid-cols-12 gap-6">
                        <div
                          className="xl:col-span-6 lg:col-span-6 col-span-12  px-7 pb-7"
                          key={"ImageDetails_" + index}
                        >
                          <div className="grid grid-cols-12 gap-6 w-full ">
                            <div className="xl:col-span-8 lg:col-span-8 col-span-12">
                              <label
                                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                htmlFor="default"
                              >
                                Alt Tag
                              </label>
                              <div className="flex items-center">
                                {/* <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="altTag"
                                                    value={props.componentDetails.componentContent.altTag}
                                                    onChange={(event) => props.handleChangeInImageComp(event, props.identity)}
                                                    // onBlur={(e) => props.validate("companyDescription", props.organizationProfile)}
                                                /> */}
                                <input
                                  id="altTag"
                                  name="altTag"
                                  className="w-full outline-none appearance-none form-input rounded-none"
                                  type="text"
                                  value={value.altTag}
                                  onChange={(event) =>
                                    props.handleChangeInImageComp(
                                      event,
                                      props.identity,
                                      index
                                    )
                                  }
                                //onBlur={() => props.validateField("publishDate")}
                                />
                                {/* <ValidationText error={props.validState.error.companyDescription} /> */}
                              </div>

                              <div className="bg-white pt-10 pb-12">
                                <div className="grid grid-cols-12 gap-6 w-full ">
                                  <div className="2xl:col-span-8 lg:col-span-8 col-span-12 pr-7">
                                    <label
                                      className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                      htmlFor="default"
                                    >
                                      Image Size (px)
                                    </label>
                                    <div className="grid grid-cols-12 gap-6 items-center w-full">
                                      <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                                        {/* <TextInput placeholder="100" /> */}
                                        <input
                                          id="width"
                                          name="width"
                                          className="w-full outline-none appearance-none form-input rounded-none"
                                          type="text"
                                          value={value.width}
                                          onChange={(event) =>
                                            props.handleChangeInImageComp(
                                              event,
                                              props.identity,
                                              index
                                            )
                                          }
                                        //onBlur={() => props.validateField("publishDate")}
                                        />
                                      </div>
                                      <div className=" 2xl:col-span-2 lg:col-span-2 col-span-12 text-center w-full font-bold text-[20px] text-[#757575]">
                                        &#x2715;
                                      </div>
                                      <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                                        {/* <TextInput placeholder="100"/> */}
                                        <input
                                          id="height"
                                          name="height"
                                          className="w-full outline-none appearance-none form-input rounded-none"
                                          type="text"
                                          value={value.height}
                                          onChange={(event) =>
                                            props.handleChangeInImageComp(
                                              event,
                                              props.identity,
                                              index
                                            )
                                          }
                                        //onBlur={() => props.validateField("publishDate")}
                                        />
                                      </div>
                                    </div>

                                  </div>

                                </div>
                                <div>
                                  {(Number(value.width) > 1200) ? (
                                    <ValidationText error={"Please enter width less than 1200px"} />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          {(index > 0) ?
                            <div className="close-icon-btn absolute right-10 top-0">
                              <button
                                className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575] close-button"
                                onClick={() => props.removeImageFromImagesComponent(index, props.identity)}
                              >
                                <div className="sr-only">Close</div>
                                <svg className="w-4 h-4 fill-current">
                                  <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                                </svg>
                              </button>
                            </div>
                            : null
                          }
                        </div>
                        <div
                          className="2xl:col-span-4 lg:col-span-4 col-span-12  mt-14"
                          key={"ImageSec_" + index}
                        >
                          <div
                            className="speaker-img-cropper laoutImage-part"
                            key={"ImageSecCropper_" + index}
                          >
                            <ImageCropperWithUpload
                              // key={"ImageComp_" + index }
                              key={`ImageComp${props.identity}_${index}`}
                              keyId={`ImageComp${props.identity}_${index}`}
                              height={value.height}
                              width={value.width}
                              isCheckValidation={true}
                              MaxFileSize={2097152}
                              MaxFileSizeMessage={"2MB"}
                              onSaveImage={(file, id, imageIdentity) =>
                                props.saveMainImage(
                                  file,
                                  id,
                                  imageIdentity,
                                  props.identity
                                )
                              }
                              imageIdentity={"\\PageManagement\\ImageComponent"}
                              intitImage={value.image}
                              aspactRatio={value.width / value.height}
                              uploadBtn={"Upload Image"}
                              dimensionText={`(Image size limit: ${value.width} X ${value.height} Maximum Size: 2MB)`}
                              isCropperSizeFixed={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

          <div className="2xl:col-span-12 lg:col-span-12 col-span-12 px-7 pb-7">
            <div className="flex justify-end">
              <button
                className=" px-7 py-3 btn-red text-xl border text-white"
                onClick={() => props.addNewImage(props.identity)}
              >
                <div className="flex items-end font-semibold">
                  <span className="mr-3">
                    <img src="/plus-round-icon.svg" alt="" />
                  </span>
                  <span className="">Add Image</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Images;
