// Import react libraries and components
import React, { Component } from 'react'
import DropdownSelect from "../../components/dropdown/Dropdown";
import Table from '../../components/table/Table';
import ActionDeleteButton from '../../components/UIComponent/ActionTableComponent/ActionDeleteButton';
import ActionEditButton from '../../components/UIComponent/ActionTableComponent/ActionEditButton';
import { getAuthProps } from '../../utils/AuthenticationLibrary';
import { CommonSuccessMessages, CommonValidationMessages } from '../../utils/Messages';
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import ValidationText from '../../utils/validation/ValidationText';

// Import api services
import FieldManagementServices from '../../services/axiosServices/apiServices/FieldManagementServices';
import SwalServices from '../../services/swalServices/SwalServices';

export default class RunReport extends Component {
    constructor(props) {
        super(props);
        this.fieldManagementServices = new FieldManagementServices();
        this.swalServices = new SwalServices();
        this.state = {
            recordFound: false,
            dynamicColumns : [],
            reportList: [],
            totalResultes: 0,
            isLoading: false,
            redirect: null,
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
            },
            reportDetails: {
                superReportId: 0,
                superReportFilter: "",
            },
            actionDropdown: ["View/Edit", "Delete"],
            superReportDropdown: [],
            validState: {
                isValid: true,
                error: {}
            },
            validationRule: {
                superReportFilter: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'report filter')
                    }
                ],
                superReportId: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'report type')
                    }
                ],
            },
            createdBy: 0,
        };
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.reportDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;

    }
    validateField = (key) => {
        const newValidState = validate(key, this.state.reportDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    componentDidMount() {
        let cookie = getAuthProps();
        const adminUserId = (cookie?.adminUserId) ? Number(cookie.adminUserId) : 0;
        if (adminUserId > 0) {
            this.setState({ createdBy: adminUserId });
        }
        this.getAllSuperReportsDropdown();
    }

    reset = () => {
        let details = this.state.reportDetails;
        details.superReportId = 0;
        details.superReportFilter = "";
        this.setState({ reportDetails: details },() => {
            this.setState({ recordFound : false })
        });
    }

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.reportDetails;
        detail[name] = value;
        this.setState({ reportDetails: { ...detail } });
    }

    onDropdown = (id, drpIdentity) => {
        let details = this.state.reportDetails
        if (drpIdentity === 'SuperReportType') {
            details["superReportId"] = id;
            this.validateField("superReportId");
        }
        this.setState({ reportDetails: details });
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getAllTagList();
    };

    getAllSuperReportsDropdown = () => {
        this.setState({ isLoading: true });
        this.fieldManagementServices.getAllSuperReportsDropdown().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ superReportDropdown: response.responseItem.responseContent });
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };


    getAllRecordsBySuperReportId = () => {
        if (this.isValidateAllFields()) {
            let reportDetails = this.state.reportDetails
            let request = [reportDetails.superReportId, reportDetails.superReportFilter];
            this.fieldManagementServices.getAllRecordsBySuperReportId(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    let message = response.responseItem.responseContent.filter(x => x.message)
                    if (message && message.length > 0) {
                        let message = response.responseItem.responseContent.find(x => x.message).message
                        this.swalServices.Alert(message);
                    }
                    else {
                        this.setState({ isLoading : true })
                        let res = response.responseItem.responseContent
                        if(res && res.length > 0){
                            let dynamicColumns = Object.keys(res[0]).map(key => ({ name: key, title: key }));
                            this.setState({ dynamicColumns : dynamicColumns},() => {
                                let list = response.responseItem.responseContent
                                this.setState({ reportList: list },() => {
                                    this.setState({ recordFound : true },() => {
                                        this.setState({ isLoading : false })
                                    })
                                })
                            })
                        }
                    }
                } else {
                    this.swalServices.Error(response.message);
                }
                this.reset();
            });
        }
    };

    render() {
        return (
            <div className="main-body-section">
                <div className="bg-white pb-10">
                    <div className="filter-info grid grid-cols-12 gap-6 px-8 pt-8">
                        <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                            <label
                                className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                htmlFor="default"
                            >
                                Select Report
                            </label>
                            <DropdownSelect
                                drpIdentity="SuperReportType"
                                optionArray={this.state.superReportDropdown}
                                setFilterParameters={this.onDropdown.bind(this)}
                                value={this.state.reportDetails.superReportId}
                                onBlur={() => this.validateField("superReportId")}
                            />
                            <ValidationText
                                error={this.state.validState.error.superReportId}
                            />
                        </div>
                        <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                            <label
                                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                htmlFor="default"
                            >
                                Report Filter
                            </label>
                            <input
                                id="superReportFilter"
                                name="superReportFilter"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                value={this.state.reportDetails.superReportFilter}
                                onChange={(event) => this.handleChange(event)}
                                onBlur={() => this.validateField("superReportFilter")}
                            />
                            <ValidationText
                                error={this.state.validState.error.superReportFilter}
                            />
                        </div>
                    </div>
                    <div className="mx-8 mt-10">
                        <div className="flex items-center justify-between">
                            <button
                                className="btn btn-lighblue text-xl text-white font-bold mr-5"
                                onClick={() => {
                                    this.reset();
                                }}
                            >
                                Reset
                            </button>

                            <button
                                className="btn btn-red text-xl text-white font-bold"
                                onClick={() => {
                                    this.getAllRecordsBySuperReportId();
                                }}
                            >
                                Get Records
                            </button>
                        </div>
                    </div>
                </div>
                {this.state.recordFound === true ?
                    <div className='table-section'>
                        <div className="bottom-list-display">
                            <main className="pb-10">
                                <div className="w-full mx-auto">
                                    <div className="relative">
                                        <div className="tags-manage-sec">
                                            <div className=" mt-6">
                                                <Table
                                                    columns = {this.state.dynamicColumns}
                                                    sortingColumns={[""]}
                                                    pagination={this.state.pagination}
                                                    rows={this.state.reportList}
                                                    isLoading={this.state.isLoading}
                                                    isPaginationNotRequired = {true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                    : null}
            </div>
        )
    }
}