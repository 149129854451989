import React, { useEffect, useState } from 'react'
import ModalBasic from '../../pages/component/ModalBasic';



export default function SelectMemberModel(props) {

    const [allMemberArr, setAllMemberArr] = useState([]);
    const [selectedMemberArr, setSelectedMemberArr] = useState([]);
    const [memberType, setMemberType] = useState("");

    useEffect(() => {
        if (props.memberId === 1) {
            setAllMemberArr(props.allOrdinaryMember);
            setSelectedMemberArr(props.selectedOrdinaryMember);
            setMemberType("Ordinary Members");
        } else if (props.memberId === 2) {
            setAllMemberArr(props.allAssociateMember);
            setSelectedMemberArr(props.selectedAssociateMember);
            setMemberType("Associate Members");
        } else if (props.memberId === 3) {
            setAllMemberArr(props.allStartUpMember);
            setSelectedMemberArr(props.selectedStartUpMember);
            setMemberType("Startup Members");
        } else if (props.memberId === 4) {
            setAllMemberArr(props.allChapter);
            setSelectedMemberArr(props.selectedChapter);
            setMemberType("Chapter Members");
        }
    }, [props.memberId, props.allOrdinaryMember, props.allAssociateMember, props.allStartUpMember, props.allChapter,
    props.selectedOrdinaryMember, props.selectedAssociateMember, props.selectedStartUpMember, props.selectedChapter]);
    return (
        <div className="select-all-member-popup">
            <ModalBasic
                id="select-All-Members"
                modalOpen={props.selectAllMembersPopup}
                setModalOpen={() => props.setOpenModal(memberType)}
            >
                <div className="bg-white mb-10">
                    <div className="">
                        <div className="grid grid-cols-12 2xl:gap-6 gap-3 w-full 2xl:px-10 lg:px-3 pr-0">
                            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                <h1 className="text-4xl text-[#c00000] font-bold leading-loose py-4">
                                    Select All Members
                                </h1>
                            </div>
                            <div className="col-span-12">
                                <div className="grid grid-cols-12 2xl:gap-6 gap-3 items-center">
                                    <div className="col-span-5">
                                        <div className="border border-[#757575] p-5">
                                            <div className="list-members">
                                                <input
                                                    className="input-texts form-input rounded-none w-full shadow-red py-3 text-lg"
                                                    placeholder="Search"
                                                    type="text"
                                                    value={props.allMemberSearchValue}
                                                    onChange={(event) => props.searchByName(event.target.value, memberType, "All")}
                                                />
                                                <div className="table-listing mt-5">
                                                    <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200">
                                                        {/* Table body */}
                                                        <tbody className="text-sm custom-tbody">
                                                            {allMemberArr.map(
                                                                (mem, key) => {
                                                                    return (
                                                                        <>{
                                                                            mem.isShowData === true ?
                                                                                <tr className="custom-tbl-td" key={key}>
                                                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575]">
                                                                                        <div className="check-box-sec flex items-center">
                                                                                            <input
                                                                                                id={`Member_/_${memberType}_A/${key}`}
                                                                                                name={`Member_/_${memberType}_A/${key}`}
                                                                                                type="checkbox"
                                                                                                className="form-checkbox"
                                                                                                checked={mem.isAllSelected}
                                                                                                value={mem.isAllSelected}
                                                                                                onChange={(event) => props.handleChangeCheckBoxForMembers(event, memberType, key, "All")}
                                                                                            />
                                                                                            <span className="block text-xl theme-color pl-4">
                                                                                                {memberType === "Chapter Members" ?
                                                                                                    <>{mem.chapterName}</>
                                                                                                    : <> {mem.companyName}</>}

                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                : null
                                                                        }
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="center-btns">
                                            <div className="flex flex-col justify-center">
                                                <button className="btn btn-red-border text-xl text-white 2xl:mb-8 mb-4 2xl:py-9"
                                                    onClick={() => props.assignMembers(memberType)}>
                                                    Assign{" "}
                                                    <span className="ml-3 fa fa-angle-right"></span>
                                                </button>
                                                <button className="btn btn-red-border text-xl text-white 2xl:my-8 my-4 2xl:py-9"
                                                    onClick={() => props.UnassignMembers(memberType)}>
                                                    <span className="mr-3 fa fa-angle-left"></span>{" "}
                                                    Unassign
                                                </button>
                                                {/* <button className="btn btn-lighblue-border text-xl text-white 2xl:mt-8 mt-4 2xl:py-9">
                                                    Upload List
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-5">
                                        <div className="border border-[#757575] p-5">
                                            <div className="list-members">
                                                <input
                                                    className="input-texts form-input rounded-none w-full shadow-red py-3 text-lg"
                                                    placeholder="Search"
                                                    type="text"
                                                    value={props.selectedMemberSearchValue}
                                                    onChange={(event) => props.searchByName(event.target.value, memberType, "Selected")}
                                                />
                                                <div className="table-listing mt-5">
                                                    <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200">
                                                        {/* Table body */}
                                                        <tbody className="text-sm custom-tbody">
                                                            {selectedMemberArr.map(
                                                                (mem, key) => {
                                                                    return (
                                                                        <>
                                                                            {mem.isShowData === true ?
                                                                                <tr className="custom-tbl-td" key={key}>
                                                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575]">
                                                                                        <div className="check-box-sec flex items-center">
                                                                                            <input
                                                                                                id={`Member_/_${memberType}_S/${key}`}
                                                                                                name={`Member_/_${memberType}_S/${key}`}
                                                                                                type="checkbox"
                                                                                                className="form-checkbox"
                                                                                                checked={mem.isSelected}
                                                                                                value={mem.isSelected}
                                                                                                onChange={(event) => props.handleChangeCheckBoxForMembers(event, memberType, key, "Selected")}
                                                                                            />
                                                                                            <span className="block text-xl theme-color pl-4">
                                                                                                {memberType === "Chapter Members" ?
                                                                                                    <>{mem.chapterName}</>
                                                                                                    : <> {mem.companyName}</>}
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                : null}
                                                                        </>

                                                                    );
                                                                }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBasic>
        </div>
    )
}
