// Import react library and components
import React, { Component } from 'react';
import Table from '../../components/table/Table';
import ActionContainer from '../../components/UIComponent/ActionTableComponent/actionContainer';
import moment from 'moment';
import { encryptAES } from '../../utils/Encryption';
import { Navigate } from 'react-router-dom';

//import API services
import GoGlobalServices from '../../services/axiosServices/apiServices/GoGlobalService';
import SwalServices from '../../services/swalServices/SwalServices';
import DropdownSelect from '../../components/dropdown/Dropdown';

export default class GoGlobalAccountApproval extends Component {
    constructor(props) {
        super(props);
        this.GoGlobalServices = new GoGlobalServices();
        this.SwalServices = new SwalServices();
        this.state = {
            redirect: null,
            pagination: {
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                pageNo: 1,
                pageSize: 10,
                searchText: "",
                requestDate: "",
                statusId: 0
            },
            statusList: [],
            totalResultes: "",
            councilIndex: 0,
            isLoading: false,
            setMembershipDirectoryEnquiry: false,
            sendEscalation: false,
            goGlobalAccountApprovalList: [],
            getMemebrDirectoryEnquiryById: {},
        }
    }

    //Method declaration section
    componentDidMount() {
        this.getStatusList();
        //this.getGoGlobalAccountApprovalList();
    }

    //API method declaration start

    // Get all go global account approval list

    getStatusList = () => {
        //this.setState({ isLoading: true });
        // this.GoGlobalServices.getStatusList().then((response) => {
        //     if (response.statusCode === 200 && response.responseItem != null) {
        //         this.setState({ statusList: response.responseItem.responseContent },
        //             ()=>{
        //                 this.getGoGlobalAccountApprovalList();
        //             })
        //     } else {
        //         this.SwalServices.Error(response.message);
        //         this.getGoGlobalAccountApprovalList();
        //     }
        //     //this.setState({ isLoading: false });
        // });
        let arry = [
            {
                id: 2,
                name: "Submitted"
            },
            {
                id: 4,
                name: "Approved"
            },
            {
                id: 12,
                name: "Rejected"
            }
        ];
        this.setState({ statusList: arry },
            () => {
                this.getGoGlobalAccountApprovalList();
            });
    }

    getGoGlobalAccountApprovalList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.GoGlobalServices.getGoGlobalAccountApprovalList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let newGoGlobalAccountApprovalList = response.responseItem.responseContent.itemList;
                newGoGlobalAccountApprovalList.forEach((key, index) => {
                    key.requestDate = moment(key.requestDate).format("DD MMM YYYY");
                });
                this.setState({ goGlobalAccountApprovalList: newGoGlobalAccountApprovalList });

                this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
            } else {
                this.SwalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    //Internal method declaration section start
    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getGoGlobalAccountApprovalList();
    };

    onViewGoGlobalAccountApproval = (e, index, value) => {
        let editGoGlobalAccountRequest = [...this.state.goGlobalAccountApprovalList];
        this.setState({ redirect: '/goGlobal/ViewGoGlobalAccount?=' + encryptAES(editGoGlobalAccountRequest[index]?.goGlobalMemberId) });
    }

    // Handle change for search company name
    searchByName = (value, identity) => {
        let detail = this.state.pagination;
        if (identity === "companyName") {
            detail.pageNo = 1;
            detail.pageSize = 10;
            detail.searchText = value;
            this.setState({ pagination: { ...detail } });
        }
    };

    // Handle change for request date
    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({
            pagination: { ...detail }
        });
    }

    // Action method for Action column on table
    actions = (element, index, value) => {
        return element !== "goGlobalMemberId" ? null : (
            <td>
                <ActionContainer >
                    <h2
                        className='underline text-[#81a3b8] cursor-pointer'
                        onClick={(e) => this.onViewGoGlobalAccountApproval(e, index, value)}

                    >View</h2>
                </ActionContainer >
            </td>
        );
    };

    setFilterParameters = (id, drpIdentity) => {
        let detail = this.state.pagination;
        if (drpIdentity === "Status") {
            detail.statusId = id;
            detail.pageNo = 1;
        }
        this.setState({ pagination: detail });
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="main-body-section">
                <div className="custom-card shadow-lg">
                    <div className="filter-info grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="filter-types xl:col-span-4 lg:col-span-6 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Company Name
                                    </label>
                                    <input
                                        id="searchText"
                                        name="searchText"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="text"
                                        onChange={(event) => this.searchByName(event.target.value, "companyName")}
                                    />
                                </div>
                                <div className="filter-types xl:col-span-3 lg:col-span-6 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Date
                                    </label>
                                    <input
                                        id="requestDate"
                                        name="requestDate"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        value={this.state.pagination.requestDate}
                                        // value={moment(
                                        //     this.state.notificationDetails.startDate
                                        // ).format("yyyy-MM-DD")}
                                        onChange={(event) => this.handleChange(event)}
                                    />
                                </div>
                                <div className="filter-types xl:col-span-3 lg:col-span-6 col-span-12 pr-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        htmlFor="default"
                                    >
                                        Status
                                    </label>
                                    <DropdownSelect
                                        drpIdentity={"Status"}
                                        optionArray={this.state.statusList}
                                        setFilterParameters={this.setFilterParameters.bind(this)}
                                        value={this.state.pagination.statusId}
                                    //disabled={true}
                                    />
                                </div>
                                <div className="xl:col-span-2 lg:col-span-6 col-span-12">
                                    <div className=" patner-search flex items-center justify-end pt-8">
                                        <div className="search">
                                            <button
                                                className="btn btn-red text-xl text-white font-bold"
                                                onClick={() => {
                                                    this.getGoGlobalAccountApprovalList();
                                                }}
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <main className="pb-10 pt-8">
                    <div className="w-full mx-auto">
                        <div className="relative">
                            <div>
                                <div className=" mt-6">
                                    <Table
                                        columns={[
                                            { name: "companyName", title: "Company Name", },
                                            { name: "requestDate", title: "Date of Request" },
                                            { name: "sgTechMember", title: "SGTech Member" },
                                            { name: "accountStatus", title: "Status" },
                                            { name: "goGlobalMemberId", title: "Action" },
                                        ]}
                                        rows={this.state.goGlobalAccountApprovalList}
                                        isLoading={this.state.isLoading}
                                        sortingColumns={["requestDate", "companyName", "accountStatus"]}
                                        pagination={this.state.pagination}
                                        totalResultes={this.state.totalResultes}
                                        totalCount={this.state.totalResultes}
                                        setPagination={this.setPagination.bind(this)}
                                        customScope={[
                                            {
                                                column: 'goGlobalMemberId',
                                                renderTableData: this.actions.bind(this)
                                            },
                                        ]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}